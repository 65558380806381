import React from 'react';
import { pick } from 'lodash';

import { useDialogStore, useStore } from 'store';

import { useDivisionForm } from 'components/divisions/division-form/use-division-form';
import { ToastError } from 'components/toast';

import { updateDivision } from 'utils/apis/divisions';
import { IUseActionsOptionParam } from 'utils/actions';
import { delay } from 'utils/delay';

import { IDivisionActionConfig, IDivisionActionName } from './types';
import { IDivision } from 'types';
import { IDivisionFormData } from 'components/divisions/division-form/division-form';

export const useEditDivisionAction = (
  divisions: IDivision[],
  options: IUseActionsOptionParam<IDivision>,
): IDivisionActionConfig => {
  const { toastStore } = useStore();
  const { close } = useDialogStore();

  const handleSubmit = async (values: IDivisionFormData): Promise<IDivision | void> => {
    try {
      const division = await updateDivision(values);
      toastStore.success('Division has been edited successfully!');
      await delay();
      await options?.onSuccess?.(IDivisionActionName.EDIT, division);
      close();
      return division;
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
      await options?.onFailure?.();
    }
  };

  const handler = useDivisionForm({
    title: 'Edit Division',
    division: pick(divisions[0], ['id', 'name']) as IDivision,
    handleSubmit,
  });

  return {
    name: IDivisionActionName.EDIT,
    shouldDisplay: () => true,
    icon: 'edit',
    title: 'Edit',
    handler,
  };
};
