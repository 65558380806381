import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { IconName } from 'blueprint5-icons';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { useStore } from 'store';
import { FormInput, useMm3Form } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { LoaderWithText, ThumbnailWithRefresh } from 'components/thumbnail';
import { ToastError } from 'components/toast';
import ScrollWrapper from 'components/scroll-wrapper';
import { ProductDataSection } from 'components/product-data-section';
import { InfoBoxV2 } from 'components/info-box-v2';
import { CastCrewActionsList } from 'components/action';
import { IconAlias } from 'icons/icon-aliases';
import { CustomDescriptionField, customLabels } from 'pages/cast-crew-details/utils';

import { updateCastCrew } from 'utils/apis/cast-crew';
import { Pages } from 'utils/actions';
import { ISearchFilter } from 'types';
import { ICastCrewActionName } from 'utils/actions/cast-crew';
import { Routes } from 'utils/routes';

import { formatDate } from 'utils/date';
import { IGroupNameEditFormFields } from 'utils/apis/groups';
import { formatField, formatOwnership } from 'utils/general';
import { useEditMode, useRefreshDataSection } from 'utils/hooks';
import { ActionsMenu } from 'components/action/utils';

import './style.scss';

const inputStyle = { gridAutoColumns: '1fr' };
const CastCrewDetailsLoadedPage: React.FC<{
  castCrew: PmGenericCastCrew;
  setCastCrew: React.Dispatch<React.SetStateAction<PmGenericCastCrew>>;
}> = observer(({ castCrew, setCastCrew }) => {
  const { toastStore } = useStore();
  const { id } = useParams<{ id: string }>();
  const [editModeEnabled, setEditModeEnabled] = useEditMode();
  const navigate = useNavigate();
  const persistentFilters = useMemo<ISearchFilter[]>(
    () => (id ? [['default_layer.meta.cast_crew.cast_crew_id', 'eq', id]] : []),
    [id],
  );

  const handleSubmit = useCallback(
    async (values: IGroupNameEditFormFields): Promise<void> => {
      try {
        toastStore.info('Saving...');
        const result = await updateCastCrew(values);
        if (result) {
          setCastCrew(result);
        }
        setEditModeEnabled(false);
        toastStore.clearAll();
        toastStore.success('Saved', 1000);
      } catch (e) {
        toastStore.clearAll();
        toastStore.error(<ToastError error={e} />);
      }
    },
    [setCastCrew, setEditModeEnabled, toastStore],
  );

  const { onSubmit, valid, formData, handlers } = useMm3Form<PmGenericCastCrew>(
    castCrew,
    Model.CAST_CREW,
    handleSubmit,
  );

  const actions = useMemo(
    (): (ISectionHeaderAction | IconName)[] =>
      editModeEnabled
        ? [
            { type: 'save', handler: onSubmit, disabled: !valid },
            { type: 'cancel', handler: (): void => setEditModeEnabled(false) },
          ]
        : [{ type: 'edit', handler: () => setEditModeEnabled(true) }],
    [editModeEnabled, onSubmit, setEditModeEnabled, valid],
  );

  const refreshDataSection = useRefreshDataSection();

  const onSuccess = useCallback(
    (action: ICastCrewActionName, updatedCastCrew?: PmGenericCastCrew) => {
      switch (action) {
        case ICastCrewActionName.UPLOAD_PREVIEW_IMAGE:
        case ICastCrewActionName.DELETE_PREVIEW:
          updatedCastCrew && setCastCrew(updatedCastCrew);
          break;
        case ICastCrewActionName.DELETE:
          navigate(`${Routes.CAST_CREW}`);
          break;
        default:
          refreshDataSection();
      }
    },
    [navigate, refreshDataSection, setCastCrew],
  );

  const { name, meta, preview_image, preview_image_id, updated_at, created_at } = castCrew;

  return (
    <>
      <SectionHeader
        title={name}
        actions={actions}
        dropdownMenu={
          <ActionsMenu
            component={CastCrewActionsList}
            items={[castCrew]}
            options={{ context: 'single', page: Pages.DETAILS, onSuccess }}
          />
        }
        useBackground
      />
      <div className="cast-crew-details__wrapper h-100">
        <ScrollWrapper>
          <InfoBoxV2
            gridTemplateColumns="1fr 2fr"
            useBackground
            wrapperClassName="pt-3 text-break h-100"
            title="overview"
            className="infobox--use-background"
          >
            <Row
              label="Name"
              value={
                editModeEnabled ? (
                  <FormInput
                    name="name"
                    label=""
                    style={inputStyle}
                    {...formData.name}
                    {...handlers}
                    className="mb-1"
                    required={false}
                  />
                ) : (
                  formatField(name)
                )
              }
            />
            <CustomDescriptionField
              formData={formData}
              handlers={handlers}
              editModeEnabled={editModeEnabled}
              castCrew={castCrew}
              inputStyle={inputStyle}
            />
            <Row
              label={customLabels.description}
              rawLabel
              value={
                editModeEnabled ? (
                  <FormInput
                    name="meta.description"
                    style={inputStyle}
                    textarea
                    className="mb-0"
                    {...formData.meta?.description}
                    {...handlers}
                  />
                ) : (
                  formatField(meta?.description)
                )
              }
            />
            <Row label="Owner" value={formatOwnership(castCrew)} />
            <Row label="Updated at" rawLabel value={formatDate(updated_at)} />
            <Row label="Created at" rawLabel value={formatDate(created_at)} />
            <Row
              valueClassName="cast-crew-details_avatar-value"
              label="Avatar"
              value={
                <ThumbnailWithRefresh
                  fallbackIconName={IconAlias.CAST_CREW}
                  entity={{ preview_image_id, preview_image }}
                  placeholder={<LoaderWithText text="processing" />}
                />
              }
            />
          </InfoBoxV2>
        </ScrollWrapper>
        <ProductDataSection
          page={Pages.CAST_CREW_PRODUCTS_TAB}
          persistentFilters={persistentFilters}
          showParentsOnlyFilter={false}
        />
      </div>
    </>
  );
});

export default CastCrewDetailsLoadedPage;
