import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Routes } from 'utils/routes';
import { InfoBoxV2 } from 'components/info-box-v2';
import { Thumbnail } from 'components/thumbnail';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { AssetVideoPlayer } from 'components/common';
import { ThumbnailFileStatus } from 'components/file-status';

import { formatDate } from 'utils/date';
import { IAsset, IMm3Asset, DetailsPageTabs, IAssetAggregation, IMm3AssetType } from 'types';
import { getIngestStatus, getMainType, getPreviewImageUrl } from 'utils/asset';
import { AssetGeneralTabAboutSection } from 'pages/asset-details/asset-general-tab/asset-about-section';
import { AssetGeneralTabSettingsSection } from 'pages/asset-details/asset-general-tab/asset-settings-section';
import { AssetAudioPlayer } from 'components/audio-player/audio-player';
import { getProductsByAssetId } from 'utils/apis/product';
import { formatFullName } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { DivisionsInfo } from 'utils/ui';

import { FormContacts, FormDivisions, FormOrganization, IUseFormReturn, formatFormLabel } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IAssetFormFields } from 'components/asset/asset-edit-form/sections/types';
import { AssetFilters } from 'utils/asset';
import { isDivisionAcessLevelNotAllowed } from 'utils/access-level';

import './style.scss';

interface IAssetGeneralTabProps {
  asset?: IAsset | IMm3Asset;
  setActiveTab: (tab: DetailsPageTabs) => void;
  setAsset: React.Dispatch<React.SetStateAction<IAsset>>;
  subAssetsAggregations?: IAssetAggregation<IMm3AssetType>;
  form: IUseFormReturn<IAssetFormFields> | IUseMm3FormReturn<IMm3Asset>;
  editModeEnabled: boolean;
}

const showThumbnailTypes = new Set([AssetFilters.IMAGE, AssetFilters.DOCUMENT]);

const AssetGeneralTab: React.FC<IAssetGeneralTabProps> = ({
  asset,
  setActiveTab,
  setAsset,
  subAssetsAggregations,
  form,
  editModeEnabled,
}) => {
  const { id, created_at, updated_at, published_at, preview_image, owner, owner_organization, division_ids } =
    asset || {};
  const type = getMainType(asset as IAsset | IMm3Asset);
  const fetcher = useCallback(() => getProductsByAssetId(id), [id]);
  const [products] = useRemote(fetcher, []);
  const products_count = products?.length || 0;

  const productsSectionMessage = useMemo((): string | JSX.Element => {
    const message = products_count > 1 ? `${products_count - 1} +More` : `1 Product`;
    return products_count ? (
      <>
        <Thumbnail image={products?.[0]?.preview_image?.url} />
        <Link
          to={`${Routes.ASSETS}/${id}/${DetailsPageTabs.PRODUCTS}`}
          onClick={() => setActiveTab(DetailsPageTabs.PRODUCTS)}
        >
          {message}
        </Link>
      </>
    ) : (
      '0 Products'
    );
  }, [id, products, products_count, setActiveTab]);

  const isVideo = type === AssetFilters.VIDEO;
  const isAudio = type === AssetFilters.AUDIO;
  const showThumbnail = showThumbnailTypes.has(type as AssetFilters);
  const { formData, handlers } = form;

  const divisionsLabel = useMemo(
    () => (editModeEnabled ? formatFormLabel('Divisions', formData?.division_ids?.required) : 'Divisions'),
    [editModeEnabled, formData?.division_ids?.required],
  );

  return (
    <div className="asset-general-tab h-100">
      <div className="asset-general-tab__wrapper h-100">
        <div className="asset-general-tab__info">
          <AssetGeneralTabAboutSection asset={asset} subAssetsAggregations={subAssetsAggregations} />
          <InfoBoxV2 gridTemplateColumns={'minmax(135px, 1fr) 2fr'} title="Ownership" wrapperClassName="mt-3">
            <Row
              label="Owner"
              hideIfNoValue
              value={
                editModeEnabled ? (
                  <FormContacts
                    label=""
                    name="owner_id"
                    {...formData.owner_id}
                    {...handlers}
                    placeholder="Select Owner"
                    large
                  />
                ) : (
                  formatFullName(owner)
                )
              }
            />
            <Row
              label={divisionsLabel}
              rawLabel
              hideIfNoValue
              value={
                editModeEnabled ? (
                  <FormDivisions
                    name="division_ids"
                    large
                    placeholder="Select Divisions"
                    {...formData.division_ids}
                    {...handlers}
                    disabled={isDivisionAcessLevelNotAllowed()}
                  />
                ) : (
                  <DivisionsInfo division_ids={division_ids} />
                )
              }
              size="m"
            />
            <Row
              label="Organization"
              hideIfNoValue
              value={
                editModeEnabled ? (
                  <FormOrganization
                    label=""
                    name="owner_organization_id"
                    {...formData.owner_organization_id}
                    {...handlers}
                  />
                ) : (
                  owner_organization?.name
                )
              }
            />
          </InfoBoxV2>
          <AssetGeneralTabSettingsSection asset={asset as IAsset} setAsset={setAsset} />
          <InfoBoxV2 gridTemplateColumns={'minmax(135px, 1fr) 2fr'} title="Timestamps" wrapperClassName="mt-3">
            <Row label="Created at" rawLabel value={formatDate(created_at)} size="m" />
            <Row label="Last Updated" value={formatDate(updated_at)} size="m" />
            <Row label="Published at" rawLabel value={formatDate(published_at)} size="m" />
          </InfoBoxV2>

          <div className="asset-general-tab__product mt-3">
            <div className="asset-general-tab__product-title">Product(s)</div>
            <div className="asset-general-tab__product-thumb">{productsSectionMessage}</div>
          </div>
        </div>

        <div className="asset-general-tab__preview-area">
          {showThumbnail && (
            <Thumbnail
              size="hd"
              image={getPreviewImageUrl(preview_image)}
              placeholder={<ThumbnailFileStatus status={getIngestStatus(asset)} size={35} withBackground />}
            />
          )}

          {isVideo ? <AssetVideoPlayer video={asset as IMm3Asset} /> : <></>}
          {isAudio ? <AssetAudioPlayer asset={asset} key={asset?.id} /> : <></>}
        </div>
      </div>
    </div>
  );
};

export default AssetGeneralTab;
