import React, { useCallback, useMemo } from 'react';
import omit from 'lodash/omit';
import { IResult } from '@mediafellows/chipmunk';

import { AssetsDataSection } from 'components/asset/assets-data-section';
import { GroupAssetsSortDataSection } from 'components/group-items-sort-data-section';
import { ToastError } from 'components/toast';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { tuco } from 'utils/chipmunk';
import { IAsset, ISearchFilter, ItemId, IGroup } from 'types';
import { useStore } from 'store';
import { ParametronOrder, ParametronSort } from 'helpers/filters/types';

import { customSortMenu } from './custom-sort-menu';
import { submitFiltersWithSeqNumber } from 'helpers/filters/helpers';

const defaultParams = {
  sort: ParametronSort.SEQUENCE_NUMBER,
  order: ParametronOrder.ASCENDING,
};

const GroupDetailAssetsTab: React.FC<{
  isSortModeEnabled: boolean;
  groupId: ItemId;
  actionOptions: Partial<IUseActionsOptionParam<IGroup>>;
}> = ({ isSortModeEnabled, groupId, actionOptions }) => {
  const persistentFilters = useMemo(() => [['group_ids', 'eq', groupId]] as ISearchFilter[], [groupId]);
  const { toastStore } = useStore();

  const executor = useCallback(
    async (options): Promise<IResult<IAsset>> => {
      try {
        let opts = omit(options, ['type']);
        // temporary solutions for implicitely adding subassets
        const filters = [...opts.body.search.filters, ['parent_id', 'not_exist']];
        opts = { ...opts, body: { search: { filters: filters } } };

        return await tuco<IAsset>('getGroupAssets', opts);
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Sorry, something went wrong!" />);
        const objects = [];
        return { objects: [], object: objects[0] };
      }
    },
    [toastStore],
  );

  if (!groupId) {
    return <></>;
  }

  if (isSortModeEnabled) {
    return <GroupAssetsSortDataSection groupId={groupId.toString()} />;
  }

  return (
    <AssetsDataSection
      persistentFilters={persistentFilters}
      page={Pages.SCREENING_ROOM_DETAILS}
      actionsOptions={actionOptions}
      executor={executor}
      defaultParams={defaultParams}
      customSortMenu={customSortMenu}
      customSubmitFilter={submitFiltersWithSeqNumber}
    />
  );
};

export default GroupDetailAssetsTab;
