import React, { useCallback } from 'react';
import { ProducerGenericReport, ProducerGenericReportProducer } from '@mediafellows/mm3-types';
import { endOfYear, addYears } from 'date-fns';
import { Loader } from '@mantine/core';
import cx from 'classnames';

import { FormDate, FormImage, FormInput, FormOrganization, FormSelect, useMm3Form } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { formatProducerType } from 'components/producer-portal/utils';
import { ISearchFilter, ReportTypes } from 'types';
import { defaultMinDate } from 'utils/date';
import { customValidator, getAllowedExtension, initialValues } from './utils';

import { FormSubmitSection } from 'components/form-submit-section';

import './form.scss';

interface IProducerPortalFormProps {
  report?: ProducerGenericReport;
  handleSubmit: (values: ProducerGenericReport) => Promise<ProducerGenericReport | void>;
}

const maxDate = endOfYear(addYears(new Date(), 10));
const organizationFilters: ISearchFilter[] = [['is_producer', 'eq', true]];

const ProducerPortalForm: React.FC<IProducerPortalFormProps> = ({ report = initialValues, handleSubmit }) => {
  const isEditMode = Boolean(report?.id);
  const { handlers, formData, onSubmit, valid, values, isSending } = useMm3Form<
    ProducerGenericReportProducer & { localFileUrl?: string }
  >(report, Model.PRODUCER_PORTAL, handleSubmit, customValidator);

  const setFileUrl = useCallback(
    (url: string) => {
      handlers.onChange({ localFileUrl: url });
    },
    [handlers],
  );

  const onChange = useCallback(
    ({ type }: Partial<ProducerGenericReportProducer>): void => {
      handlers.onChange({
        type,
        ...(type !== ReportTypes.Producer ? { producer_organization_id: null } : {}),
      });
    },
    [handlers],
  );
  return (
    <>
      <form
        className={cx('d-flex flex-column justify-content-between h-100', { 'form-container--loading': isSending })}
        onSubmit={onSubmit}
      >
        <FormInput name="name" label="Name" {...handlers} {...formData?.name} />
        <FormSelect
          large
          name="type"
          label="Type"
          {...handlers}
          {...formData.type}
          onChange={onChange}
          formatLabel={formatProducerType}
          disabled={isEditMode}
        />
        <FormDate
          large
          name="date_from"
          label="From Date"
          maxDate={new Date(values.date_to || maxDate)}
          {...handlers}
          {...formData?.date_from}
        />
        <FormDate
          large
          name="date_to"
          minDate={new Date(values.date_from || defaultMinDate)}
          maxDate={maxDate}
          label="To Date"
          {...handlers}
          {...formData?.date_to}
        />
        {values.type === ReportTypes.Producer ? (
          <FormOrganization
            name="producer_organization_id"
            label="Producer Organization"
            {...formData.producer_organization_id}
            {...handlers}
            required
            additionalFilters={organizationFilters}
          />
        ) : (
          <></>
        )}

        <FormImage
          name="source_url"
          label="Report File"
          className="py-3"
          fileLabel="Report"
          customMessage="Select a file to upload"
          setFileUrl={setFileUrl}
          extensions={getAllowedExtension(values.type as ReportTypes)}
          {...formData.source_url}
          {...handlers}
        />

        <FormSubmitSection
          labels={{ confirm: isEditMode ? 'Save' : 'Create' }}
          submitDisabled={!valid || !values?.source_url}
        />
      </form>
      {isSending && (
        <div className="wizard__loader overflow-hidden opacity-1">
          <Loader />
          <div className="py-4">{isEditMode ? 'Updating' : 'Creating'} your report...</div>
        </div>
      )}
    </>
  );
};

export { ProducerPortalForm };
