import { IShouldDisplayActionFunction, Pages } from 'utils/actions/types';
import { ICastCrewActionName } from './types';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';

export const shouldDisplayForSingleItem: IShouldDisplayActionFunction<PmGenericCastCrew, ICastCrewActionName> = (
  action,
  index,
  user,
  items,
  options,
) => {
  return items?.length === 1 && options.context === 'single';
};

export const shouldDisplayOnProductPageSingleItem: IShouldDisplayActionFunction<
  PmGenericCastCrew,
  ICastCrewActionName
> = (action, index, user, items, options) => {
  return items?.length === 1 && options.page === Pages.PRODUCT_CAST_CREW_TAB;
};

export const shouldDisplayOnEntityPage: IShouldDisplayActionFunction<PmGenericCastCrew, ICastCrewActionName> = (
  action,
  index,
  user,
  items,
  options,
) => {
  return options.page === Pages.LIST || options.page === Pages.DETAILS;
};
