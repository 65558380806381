import { IAsset, IMm3Asset } from 'types';
import { AssetFilters, getMainType } from 'utils/asset';

export enum AssetType {
  AUDIO = 'Asset3::Digital::Audio',
  DOCUMENT = 'Asset3::Digital::Document',
  IMAGE = 'Asset3::Digital::Image',
  OTHER = 'Asset3::Digital::Other',
  SUBTITLE = 'Asset3::Digital::Subtitle',
  VIDEO = 'Asset3::Digital::Video',
}

//TODO take these values from BE
export const extensionsByClassification = {
  image: '.jpg .jpeg .png .bmp .tiff .tif .webp .psb'.split(' '),
  video:
    '.3g2 .3gp .amv .asf .avchd .avi .bin .ccd .cdi .daa .divx .f4v .flv .h264 .h265 .hevc .img .iso .m1a .m1p .m1t .m1v .m2a .m2p .m2t .m2ts .m2v .m3u8 .m4v .mkv .mov .mp2 .mp4 .mpe .mpeg .mpg .mpv .mts .mxf .nrg .ogm .ogv .pdi .qt .rm .rmvb .swf .ts .uif .vob .webm .wmv .xdi .xvid'.split(
      ' ',
    ),
  audio: '.aac .ac3 .aif .aiff .amr .ape .dts .flac .m4a .mka .mks .mp3 .oga .ogg .wav .wma'.split(' '),
  document: '.doc .docx .pdf .txt .rtf .odt .ott .tex .xls .xlsx .ppt .pptx .csv .html .htm .xml .md'.split(' '),
  subtitle: '.srt .vtt .ttml'.split(' '),
};

export const getAssetType = (type?: string, extension?: string): string => {
  const fileExtension = `.${extension}`;

  switch (true) {
    case type?.includes('image') || extensionsByClassification.image?.includes(fileExtension):
      return AssetType.IMAGE;
    case type?.includes('video') || extensionsByClassification.video?.includes(fileExtension):
      return AssetType.VIDEO;
    case extensionsByClassification.audio?.includes(fileExtension):
      return AssetType.AUDIO;
    case extensionsByClassification.document?.includes(fileExtension):
      return AssetType.DOCUMENT;
    case extensionsByClassification.subtitle?.includes(fileExtension):
      return AssetType.SUBTITLE;
    default:
      return AssetType.OTHER;
  }
};

export const isAssetVideo = (asset: IMm3Asset | IAsset): boolean => getMainType(asset) === AssetFilters.VIDEO;
