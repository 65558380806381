import React, { useCallback } from 'react';
import { toNumber } from 'lodash';
import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import { Routes } from 'utils/routes';

import { SectionHeader } from 'components/section-header';
import { SmartGroupDetailsTabsView } from './smart-group-tabs-view';
import SmartGroupDetailsOverview from './smart-group-details-overview';
import { BodyWrapper } from 'components/layout';

import { useTab } from 'utils/hooks';
import { changeAccessLevelSlider } from 'utils/change-access';
import { ITitleDividerActionName, Pages } from 'utils/actions/types';
import { IGroupActionName } from 'utils/actions/common';
import { Classes } from 'utils/ui';

import { ISmartGroup, DetailsPageTabs } from 'types';

import { useNavigate } from 'react-router';
import { changeGroupAccessImmediate } from 'utils/apis/groups';
import { parseDataSize, pluralWordInflect } from 'utils/general';

import { IAssetActionName } from 'utils/actions/asset';
import { IProductActionName } from 'utils/actions/product/types';
import { SmartGroupListSchema } from 'utils/schemas/smart-group';
import { ActionsMenu } from 'components/action/utils';
import { SmartGroupActionsList } from 'components/action/smart-group';
import { useEditAction } from 'utils/actions/smart-group/edit';

import { ISmartGroupActionName } from 'utils/actions/smart-group';
import { useMainAssetCountInGroup } from 'utils/hooks/asset';

import 'components/marketing-entity-detail/style.scss';

const tabOptions = [
  DetailsPageTabs.ASSETS,
  DetailsPageTabs.PRODUCTS,
  DetailsPageTabs.CONTACTS,
  // DetailsPageTabs.ORGANIZATIONS,
];
const DEFAULT_TAB = DetailsPageTabs.PRODUCTS;

type ISmartGroupDetailsLoaded = {
  smartGroup: ISmartGroup;
  setSmartGroup: React.Dispatch<React.SetStateAction<ISmartGroup>>;
  refreshSmartGroup: VoidFunction;
};

export const SmartGroupDetailsLoaded: React.FC<ISmartGroupDetailsLoaded> = observer(
  ({ smartGroup, setSmartGroup, refreshSmartGroup }) => {
    const { smartGroupId = '' } = useParams<{ smartGroupId: string }>();
    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useTab(DEFAULT_TAB, tabOptions);

    const { name, access_level, owner, pm_statistics_data, am_statistics_data, users_count } = smartGroup || {};
    const mobile_sync_size = toNumber(am_statistics_data?.mobile_sync_size);
    const [mainAssetCount] = useMainAssetCountInGroup(smartGroupId);

    const handleAccessLevelChange = useCallback(
      async (newAccessLevel: string) => {
        return changeAccessLevelSlider<ISmartGroup>({
          apiCall: async (data) =>
            changeGroupAccessImmediate({ ...data, item_ids: [smartGroupId] }, SmartGroupListSchema),
          divisionIds: owner?.division_ids,
          newAccessLevel,
          entitySetter: setSmartGroup,
        });
      },
      [smartGroupId, owner?.division_ids, setSmartGroup],
    );

    const onActionSuccess = useCallback(
      async (action?: string): Promise<void> => {
        switch (action) {
          case ISmartGroupActionName.DELETE:
            navigate(`${Routes.MOBILE_APP_SYNC}`);
            break;
          case IGroupActionName.ADD_ENTITIES:
          case IAssetActionName.REMOVE_FROM_GROUP:
          case IProductActionName.REMOVE_FROM_MOBILE_SYNC:
          case ISmartGroupActionName.EDIT:
            setTimeout(refreshSmartGroup, 1000);
            break;
        }
      },
      [refreshSmartGroup, navigate],
    );
    const action = useEditAction([smartGroup], {
      onSuccess: refreshSmartGroup,
      context: 'single',
      page: Pages.SMART_GROUP_DETAILS,
      groupId: smartGroupId,
      groupParamName: 'smartGroupId',
      groupName: ITitleDividerActionName.SMART_GROUP,
    });

    const handleTabChange = useCallback(
      (tab): void => {
        setCurrentTab(tab);
      },
      [setCurrentTab],
    );

    return (
      <BodyWrapper>
        <SectionHeader
          title={name}
          subtitle={
            <span className={Classes.TEXT_MUTED}>
              {`${parseDataSize(mobile_sync_size) || '0 B'} | ${pluralWordInflect(
                'Product',
                pm_statistics_data?.count || 0,
              )}  | ${pluralWordInflect('Asset', mainAssetCount || 0)}
              | ${pluralWordInflect('Contact', users_count || 0)}`}
            </span>
          }
          useBackground
          accessLevel={access_level}
          onAccessLevelChange={handleAccessLevelChange}
          dropdownMenu={
            <ActionsMenu
              component={SmartGroupActionsList}
              items={[smartGroup]}
              options={{ context: 'single', page: Pages.SMART_GROUP_DETAILS, onSuccess: onActionSuccess }}
            />
          }
          actions={[{ type: 'edit', disabled: action.isDisabled, handler: action.handler }]}
        />
        <div className="marketing-entity-details__content-wrapper">
          <SmartGroupDetailsOverview smartGroup={smartGroup} />

          <div className="marketing-entity-details__tabs-wrapper">
            <SmartGroupDetailsTabsView
              smartGroup={smartGroup}
              currentTab={currentTab}
              onTabChangeHandle={handleTabChange}
              onSuccess={onActionSuccess}
            />
          </div>
        </div>
      </BodyWrapper>
    );
  },
);
