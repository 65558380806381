import React from 'react';
import { InfoBoxV2 } from 'components/info-box-v2';
import { UserAvatar } from 'components/user-avatar';
import { ResponsibleUser, getResponsibleUserLabel } from 'utils/ui';
import { Row } from 'components/label-value-pair';
import { useUploadPreviewImageAction } from 'utils/actions/contact';
import { formatDate } from 'utils/date';
import { IContact } from 'types';
import { IUseActionsOptionParam } from 'utils/actions';

interface IContactOverviewPreviewProps {
  contact: IContact;
  options: IUseActionsOptionParam;
}

const ContactOverviewTabPreview: React.FC<IContactOverviewPreviewProps> = ({ contact, options }) => {
  const { handler: openAvatarUploadDialog } = useUploadPreviewImageAction([contact], options);
  const { role_name, created_at, last_login_at, activated_at, status } = contact;
  const label = getResponsibleUserLabel();
  return (
    <div className="contact-general-tab__account">
      <div className="contact-general-tab__avatar-wrapper">
        <div className="contact-general-tab__avatar">
          <UserAvatar
            offset={13}
            size={23}
            user={contact}
            onClick={openAvatarUploadDialog as () => void}
            shouldRefresh
          />
        </div>
      </div>
      <div className="contact-general-tab__account-info">
        <InfoBoxV2 gridTemplateColumns="2fr 3fr" wrapperClassName="p-3 m-3" useBackground>
          <Row label="Status" value={status} size="m" />
          {/* This would be deleted once we implement the graphic element on avatar to indicate the role of user */}
          <Row label="Platform Role" value={role_name} size="m" />
          {label === 'Responsible for' && (
            <Row label={label} value={<ResponsibleUser contact={contact} />} size="m" hideIfNoValue />
          )}
          <Row label="Created at" rawLabel value={formatDate(created_at)} size="m" />
          <Row label="Activated at" rawLabel value={formatDate(activated_at)} size="m" />
          <Row label="Last Login" value={formatDate(last_login_at)} size="m" />
        </InfoBoxV2>
      </div>
    </div>
  );
};
export default ContactOverviewTabPreview;
