import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { FilterText } from 'helpers/filters/fields';
import { Switch } from '@mantine/core';
import { ParametronSort } from 'helpers/filters/types';
import { toggleParam, submitFilters, ISubmitFiltersParams } from 'helpers/filters/helpers';
import { useStore } from 'store';

import { useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';
import { defaultSearchParams } from 'utils/default-search-param';

import { useLocation } from 'react-router-dom';
import { IFilters } from 'components/product-filters/types';
import { parseQueryParams } from 'utils/general';
import { IProduct } from 'types';
import { SharedProductFilters } from 'components/product-filters/shared-product-filters';

const defaultParamComponentValues = (queryParams): typeof defaultSearchParams => ({
  include_deleted: queryParams['include_deleted'] === true,
  sort: ParametronSort.UPDATED_AT,
});

const ProductFilters: React.FC<{ customSubmitFilter?: (obj: ISubmitFiltersParams) => void }> = observer(
  ({ customSubmitFilter = submitFilters }) => {
    const { dataSectionStore } = useStore<IProduct>();
    const location = useLocation();

    const queryParams = parseQueryParams(location.search);
    const [paramComponentValues, setParamComponentValues] = useState(defaultParamComponentValues(queryParams));

    const handleSubmit = useCallback(
      (action): void => {
        customSubmitFilter?.({
          action,
          setParamComponentValues,
          customParams: defaultSearchParams,
        });
      },
      [customSubmitFilter],
    );

    const [filterValues, filterHandlers] = useFilters<IFilters>(dataSectionStore, handleSubmit);

    const handleToggleParam = (event): void => {
      toggleParam({
        event,
        paramComponentValues,
        setParamComponentValues,
      });
    };

    return (
      <div className="entity-filters">
        <DataSectionPreset filterHandlers={filterHandlers} />

        <div className="entity-filters__new-filter">
          <div className="entity-filters__title mb-3">
            <h4 className="mb-0">New Filter</h4>
          </div>

          <form>
            <FilterText
              label="Search List"
              placeholder="Search"
              name="_"
              filter={filterValues._}
              onChange={filterHandlers.onChange}
            />

            <SharedProductFilters
              dataSectionStore={dataSectionStore}
              filterHandlers={filterHandlers}
              filterValues={filterValues}
            />

            <Switch
              label="Include deleted"
              data-param={'include_deleted'}
              onChange={handleToggleParam}
              checked={paramComponentValues.include_deleted}
            />
          </form>
        </div>
      </div>
    );
  },
);

export default ProductFilters;
