import React from 'react';
import { Progress } from '@mantine/core';
import { Intent } from 'utils/ui';

const DeliveryProgressBar: React.FC<{ value: number; variant: Intent; className: string; hidden?: boolean }> = ({
  value,
  variant: intent,
  className,
  hidden = false,
}) => {
  if (hidden) return <></>;

  return <Progress className={className} variant={intent} value={value ?? null} />;
};

export default DeliveryProgressBar;
