import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { toNumber } from 'lodash';
import { useParams } from 'react-router-dom';
import { IconName } from 'blueprint5-icons';
import { observer } from 'mobx-react-lite';

import { Routes } from 'utils/routes';

import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';
import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { Loading } from 'components/loading';
import { MobileAppSyncDetailsTabsView } from './mobile-app-sync-tabs-view';
import MobileAppSyncDetailsOverview from './mobile-app-sync-details-overview';
import { BodyWrapper } from 'components/layout';
import { MobileAppSyncActions } from 'components/action';

import { useEditMode, useRemote, useTab } from 'utils/hooks';
import { changeAccessLevelSlider } from 'utils/change-access';
import { Pages } from 'utils/actions/types';
import { IGroupActionName } from 'utils/actions/common';
import { Classes } from 'utils/ui';

import { Model } from 'helpers/filters/types';
import { IMobileAppSync, IGroupItem, DetailsPageTabs } from 'types';

import 'components/marketing-entity-detail/style.scss';
import { useNavigate } from 'react-router';
import { loadGroup, loadGroupItems, changeGroupAccessImmediate } from 'utils/apis/groups';
import { parseDataSize, pluralWordInflect } from 'utils/general';

import { IAssetActionName } from 'utils/actions/asset';
import { IProductActionName } from 'utils/actions/product/types';
import { mobileAppSyncDetailsSchema } from 'utils/schemas/mobile-app-sync';
import { useEditMobileSync } from 'pages/mobile-app-sync-details/use-edit-mobile-sync';
import { ActionsMenu } from 'components/action/utils';
import { useMainAssetCountInGroup } from 'utils/hooks/asset';

const tabOptions = [DetailsPageTabs.ASSETS, DetailsPageTabs.PRODUCTS];
const DEFAULT_TAB = DetailsPageTabs.PRODUCTS;

const EmptySection: React.FC = () => {
  return (
    <BodyWrapper>
      <SectionMessage intent={SectionMessageType.EMPTY}>No Access Privilege</SectionMessage>
    </BodyWrapper>
  );
};

const MobileAppSyncDetails: React.FC = observer(() => {
  const { mobileSyncId = '' } = useParams<{ mobileSyncId: string }>();
  const navigate = useNavigate();

  const [editModeEnabled, setEditModeEnabled] = useEditMode();
  const [currentTab, setCurrentTab] = useTab(DEFAULT_TAB, tabOptions);
  const downloadPermission = useRef(false);

  const fetchMobileAppSync = useCallback(async () => {
    if (!mobileSyncId) {
      return;
    }
    return loadGroup<IMobileAppSync>(mobileSyncId, mobileAppSyncDetailsSchema);
  }, [mobileSyncId]);

  const [mobileAppSync, loading, setMobileAppSync, refreshMobileAppSync] = useRemote<IMobileAppSync | undefined>(
    fetchMobileAppSync,
  );

  const { id, name, access_level, pm_statistics_data, am_statistics_data } = mobileAppSync || {};
  const mobile_sync_size = toNumber(am_statistics_data?.mobile_sync_size);
  const [mainAssetCount] = useMainAssetCountInGroup(id);

  const editForm = useEditMobileSync({ mobileAppSync, setEditModeEnabled, refreshMobileAppSync });

  const { onChange } = editForm.handlers;
  useEffect(() => {
    if (!name) {
      return;
    }
    onChange({ name });
  }, [name, onChange]);

  useEffect(() => {
    async function fetchProductGroupItems(): Promise<void> {
      if (!mobileSyncId) return;
      const productGroupItems = await loadGroupItems<IGroupItem>({
        group_ids: mobileSyncId,
        target: Model.PRODUCT_GROUP_ITEMS,
        per: 1,
      });
      downloadPermission.current = productGroupItems?.[0]?.permissions?.includes('download');
    }
    fetchProductGroupItems();
  }, [mobileSyncId]);

  const handleAccessLevelChange = useCallback(
    async (newAccessLevel: string) => {
      return changeAccessLevelSlider<IMobileAppSync>({
        apiCall: async (data) =>
          changeGroupAccessImmediate({ ...data, item_ids: [mobileSyncId] }, mobileAppSyncDetailsSchema),
        newAccessLevel,
        entitySetter: setMobileAppSync,
      });
    },
    [mobileSyncId, setMobileAppSync],
  );

  const onActionSuccess = useCallback(
    async (action?: string): Promise<void> => {
      switch (action) {
        case IGroupActionName.DELETE:
          navigate(`${Routes.MOBILE_APP_SYNC}`);
          break;
        case IGroupActionName.ADD_ENTITIES:
        case IAssetActionName.REMOVE_FROM_GROUP:
        case IProductActionName.REMOVE_FROM_MOBILE_SYNC:
          setTimeout(refreshMobileAppSync, 1000);
          break;
      }
    },
    [refreshMobileAppSync, navigate],
  );

  const editModeActions = useMemo(
    (): (ISectionHeaderAction | IconName)[] => [
      {
        type: 'save',
        handler: editForm.onSubmit,
        disabled: !editForm.valid,
      },
      {
        type: 'cancel',
        handler: (): void => {
          editForm.resetFields();
          setEditModeEnabled(false);
        },
      },
    ],
    [editForm, setEditModeEnabled],
  );

  const commonActions = useMemo(
    (): (ISectionHeaderAction | IconName)[] => [{ type: 'edit', handler: () => setEditModeEnabled(true) }],
    [setEditModeEnabled],
  );

  const headerActions = useMemo(
    (): (ISectionHeaderAction | IconName)[] => (editModeEnabled ? editModeActions : commonActions),
    [commonActions, editModeActions, editModeEnabled],
  );

  const handleTabChange = useCallback(
    (tab): void => {
      setCurrentTab(tab);
    },
    [setCurrentTab],
  );

  if (loading) {
    return (
      <BodyWrapper>
        <Loading text="Loading Mobile Selection details" />
      </BodyWrapper>
    );
  }

  if (!mobileAppSync && !loading) {
    return <EmptySection />;
  }

  return (
    <BodyWrapper>
      <SectionHeader
        title={name}
        subtitle={
          <span className={Classes.TEXT_MUTED}>
            {`${parseDataSize(mobile_sync_size) || '0 B'} | ${pluralWordInflect(
              'Product',
              pm_statistics_data?.count || 0,
            )}  | ${pluralWordInflect('Asset', mainAssetCount || 0)} `}
          </span>
        }
        useBackground
        accessLevel={access_level}
        onAccessLevelChange={handleAccessLevelChange}
        dropdownMenu={
          <ActionsMenu
            component={MobileAppSyncActions}
            items={[mobileAppSync]}
            options={{ context: 'single', page: Pages.DETAILS, onSuccess: onActionSuccess }}
          />
        }
        actions={headerActions}
      />
      <div className="marketing-entity-details__content-wrapper">
        <MobileAppSyncDetailsOverview mobileAppSync={mobileAppSync} form={editForm} editModeEnabled={editModeEnabled} />

        <div className="marketing-entity-details__tabs-wrapper">
          <MobileAppSyncDetailsTabsView
            editModeEnabled={editModeEnabled}
            mobileAppSync={mobileAppSync}
            currentTab={currentTab}
            onTabChangeHandle={handleTabChange}
            onSuccess={onActionSuccess}
          />
        </div>
      </div>
    </BodyWrapper>
  );
});

export default MobileAppSyncDetails;
