export const collectionSchema = `
affiliation_id,
owner_id,
owner_organization_id,
created_at,
updated_at,
id,
name,
purpose,
type,
access_level,
allowed_item_types,
preview_image_id,
meta,
owner,
preview_image,
categories,
sequence_number,
sublist,
`;

export const collectionDetailsSchema = `${collectionSchema}, owner_organization`;

export const showcaseSchema = `
affiliation_id,
owner_id,
owner_organization_id,
created_at,
updated_at,
id,
name,
purpose,
type,
access_level,
allowed_item_types,
meta,
owner,
categories,
sequence_number,
`;
