import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ColorSwatch } from '@mantine/core';

import { useDataSectionStore } from 'store/hooks';

import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { ActionsMenu } from 'components/action/utils';
import { GenreActionsList } from 'components/action';
import { Loading } from 'components/loading';
import { Thumbnail } from 'components/thumbnail';
import { PreviewInfo } from 'components/preview-tab';
import { Row, yn } from 'components/label-value-pair';
import { EmptySectionMessage } from 'components/section-message';

import { IUseActionsContext, Pages } from 'utils/actions';
import { useEditGenreAction } from 'utils/actions/genre/edit';
import { findNodeById } from './utils';

import { IActionName } from 'utils/actions/types';
import { IListItem, ICategory, IGenreTreeItem } from 'types';

export const GenrePreviewTab: React.FC<{
  isSortModeEnabled: boolean;
  onSuccess: (action?: IActionName, genre?: ICategory, items?: IListItem[]) => void;
  genreTree: IGenreTreeItem[];
}> = observer(({ isSortModeEnabled, onSuccess, genreTree }) => {
  const { searchStore, activeItem } = useDataSectionStore<ICategory>();
  const { running = true } = searchStore || {};
  const activeTreeItem = useMemo(
    () => (activeItem ? findNodeById(genreTree, activeItem?.id) : genreTree?.[0]),
    [activeItem, genreTree],
  );
  const activeGenre = useMemo(() => activeTreeItem || activeItem, [activeTreeItem, activeItem]);

  const { color, name, preview_image, invisible, sequence_no, parent, id } = activeGenre || {};

  const options = useMemo(
    () => ({
      context: 'single' as IUseActionsContext,
      page: Pages.DETAILS,
      onSuccess,
      parentId: activeGenre?.id,
    }),
    [activeGenre?.id, onSuccess],
  );
  const actions: ISectionHeaderAction[] = [
    { type: 'edit', handler: useEditGenreAction(activeGenre ? [activeGenre] : [], options).handler, disabled: !id },
  ];

  if (!activeGenre) return <EmptySectionMessage />;
  return (
    <div className="genre-data-section-preview">
      <SectionHeader
        useBorder
        title={name}
        actions={actions}
        isSortModeEnabled={isSortModeEnabled}
        useBackground
        dropdownMenu={<ActionsMenu component={GenreActionsList} items={[activeGenre]} options={options} />}
      />
      {running ? (
        <Loading text="Loading Data" />
      ) : (
        <>
          <div className="entity-preview__thumb mt-2">
            <Thumbnail image={preview_image?.url} fallbackIconName="diagram-tree" />
          </div>
          <PreviewInfo gridTemplateColumns="120px 1fr">
            <Row label="parent" value={parent?.name} size="m" hideIfNoValue />
            <Row
              label="Sub-genres"
              rawLabel
              value={(activeGenre as IGenreTreeItem)?.children?.length}
              size="m"
              hideIfNoValue
            />
            <Row
              label="color"
              value={
                <>
                  {color ? (
                    <span className="d-flex gap-2 align-items-center">
                      {color}
                      <ColorSwatch color={color} />
                    </span>
                  ) : (
                    <> — </>
                  )}
                </>
              }
              size="m"
            />

            <Row label="invisible" value={yn(invisible)} size="m" />
            <Row label="sequence number" value={sequence_no} size="m" />
          </PreviewInfo>
        </>
      )}
    </div>
  );
});

export default React.memo(GenrePreviewTab);
