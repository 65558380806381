import React, { useCallback, useEffect, useMemo } from 'react';
import { groupBy } from 'lodash';
import { observer } from 'mobx-react-lite';

import { showcaseRenderItemParser } from 'components/showcase-form/utils';
import { SortDataSection } from 'components/sort-data-section';
import { FormSubmitSection } from 'components/form-submit-section';
import { IFormMultiSelectOption } from 'helpers/form';
import { parseItem } from 'helpers/form/fields/select-helpers';
import { FormMultiEntitiesSelect } from 'helpers/form/custom-fields/form-multi-entities-select';
import { useSortDataSectionStore } from 'store/hooks';
import { fetchListItems } from 'utils/apis/showcase';
import { IEntitiesToQuery } from 'utils/apis/showcase-item';
import { useDisableWhileSubmitting } from 'utils/hooks/submit';
import { IAllowedListItemType, IItem, ISearchFilter } from 'types';

import { itemRenderer } from './utils';

import './style.scss';

type IEntitiesFilters = {
  [key in IEntitiesToQuery]?: ISearchFilter[];
};

interface IFormAddItemsToShowcaseFormProps {
  allowed_item_types: IAllowedListItemType[];
  additionalFilters?: IEntitiesFilters;
  onSubmit: (items: IFormMultiSelectOption[], purpose?: string) => Promise<void>;
  sliderId?: number | null;
  placeholder?: string;
  label?: string;
  hideClearAllButton?: boolean;
}

const labels = { confirm: 'Save' };
export const FormSliderShowcase: React.FC<IFormAddItemsToShowcaseFormProps> = observer(
  ({ sliderId = 0, allowed_item_types, additionalFilters, onSubmit, placeholder, label, hideClearAllButton }) => {
    const fetcher = useCallback(async (): Promise<IFormMultiSelectOption[]> => {
      if (!sliderId) {
        return [];
      }

      const { objects } = await fetchListItems({ listIds: [sliderId] });
      return objects.map((item) => ({ ...parseItem(item.entity as IItem), id: item.entity_id, item }));
    }, [sliderId]);

    const { initStore, updateStore, list, save, isLoading } = useSortDataSectionStore<IFormMultiSelectOption>();

    const filters = useMemo<IEntitiesFilters>(() => {
      const listByTypes = groupBy(list, 'itemType');
      return allowed_item_types.reduce((acc, key) => {
        const selectedIds = listByTypes[key]?.map((e) => e.value);
        const value = additionalFilters?.[key] || [];

        return {
          ...acc,
          [key]: [...value, ...(selectedIds?.length ? [['id', 'not_in', selectedIds]] : [])],
        } as IEntitiesFilters;
      }, {} as IEntitiesFilters);
    }, [additionalFilters, allowed_item_types, list]);

    const updateParser = useCallback(
      ([newItem] = []) => {
        if (!newItem?.value) {
          return;
        }

        updateStore([newItem, ...list]);
      },
      [list, updateStore],
    );

    useEffect(() => {
      initStore({ fetcher, onSave: onSubmit });
    }, [fetcher, initStore, onSubmit]);

    const handleItemRemove = useCallback(
      (itemId = 0) => {
        const filtered = (list || []).filter((item) => item?.value !== itemId.toString());
        updateStore(filtered);
      },
      [list, updateStore],
    );

    const handleSubmit = useCallback(
      async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await save();
      },
      [save],
    );

    const { onSubmit: submit, disabled } = useDisableWhileSubmitting(handleSubmit);
    return (
      <form onSubmit={submit} className="h-100 slider-showcase-form__wrapper">
        <FormMultiEntitiesSelect
          allowedItemTypes={allowed_item_types as IEntitiesToQuery[]}
          onSelectedItemsChange={updateParser}
          omni={false}
          value={[]}
          placeholder={placeholder || `Add Items to ${allowed_item_types?.includes('user') ? 'Hosts' : 'Showcase'}`}
          hideSelectedItems
          additionalFilters={filters}
          customRenderItemParser={showcaseRenderItemParser}
          isAncestryMode={allowed_item_types?.includes('product')}
          label={label}
          hideClearAllButton={hideClearAllButton}
        />
        <SortDataSection
          itemRenderer={itemRenderer}
          onItemRemove={handleItemRemove}
          className="showcase-add-items__sort-section mb-3"
          hideEmptyMsg={!isLoading}
        />
        <FormSubmitSection submitDisabled={disabled} loading={disabled} labels={labels} />
      </form>
    );
  },
);
