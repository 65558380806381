import React, { useCallback } from 'react';

import { ISelectionStandardized } from 'types';
import { FormAccessLevel, useForm } from 'helpers/form';
import { FormSubmitSection } from 'components/form-submit-section';
import { useStore } from 'store';
import { Model } from 'helpers/filters/types';
import { IUseActionsOptionParam } from 'utils/actions';
import { ISelectionActionName } from 'utils/actions/selection/types';
import { changeGroupAccessImmediate } from 'utils/apis/groups';
import { useRefreshDataSection } from 'utils/hooks';
import { SelectionSchema } from 'utils/schemas';

interface ISelectionAccessChangeImmediateForm {
  item_ids: (number | string)[];
  access_level?: string;
}

export const SelectionAccessChangeForm: React.FC<{
  items: ISelectionStandardized[];
  options: IUseActionsOptionParam;
}> = ({ items, options }) => {
  const { dialogStore, toastStore } = useStore();
  const refreshDataSection = useRefreshDataSection();
  const submitForm = useCallback(
    async (data: ISelectionAccessChangeImmediateForm) => {
      try {
        const updatedSelection = await changeGroupAccessImmediate(data, SelectionSchema);

        options.context === 'single' &&
          options?.onSuccess?.(ISelectionActionName.CHANGE_ACCESS, updatedSelection.object);
        toastStore.success('Access changed');
        dialogStore.close();
        refreshDataSection();
      } catch (error) {
        toastStore.error(error);
      }
    },
    [dialogStore, options, refreshDataSection, toastStore],
  );
  const initialValues = {
    access_level: items[0].access_level,
    item_ids: items.map((asset) => asset.id),
  };
  const { formData, handlers, valid, onSubmit } = useForm<ISelectionAccessChangeImmediateForm>(
    initialValues,
    Model.GROUPS,
    submitForm,
  );
  return (
    <form onSubmit={onSubmit}>
      <FormAccessLevel
        name="access_level"
        label="Access"
        large
        {...formData.access_level}
        {...handlers}
        required
        placeholder="Select Access"
      />
      <FormSubmitSection submitDisabled={!valid} />
    </form>
  );
};
