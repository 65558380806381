import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row, yn } from 'components/label-value-pair';
import { EventTiming } from 'components/event-timing';
import { LoaderWithText, ThumbnailWithRefresh } from 'components/thumbnail';

import { IMarketingEvent } from 'types';
import { IconAlias } from 'icons/icon-aliases';
import { formatOwnership } from 'utils/general';

import './itv-event-details.scss';

interface ITVMarketingEvent extends IMarketingEvent {
  standalone?: boolean;
  text_color?: string | null;
}

export interface IEventOverviewTabProps {
  event?: ITVMarketingEvent;
}

export const EventOverviewTab: React.FC<IEventOverviewTabProps> = ({ event }) => {
  const {
    name,
    event_password,
    preview_image,
    description,
    access_level,
    allow_registered_user_access,
    subevent,
    standalone,
    text_color,
    location,
    preview_image_id,
  } = event || {};

  const textColor = text_color ?? '#ffffff';
  const ownershipInfo = formatOwnership(event);

  return (
    <div className="event-detail__tab__wrapper">
      <div className="event-overview__content">
        <InfoBoxV2 gridTemplateColumns="1fr 3fr" title="Overview" wrapperClassName="pt-4" newline>
          <Row label="Name" value={name} className="text-wrap text-break" size="m" />
          <Row label="Event identifier" rawValue value={event?.purpose} className="text-wrap text-break" size="m" />
          {!subevent && <Row label="Password" rawValue value={event_password} size="m" />}
          <Row label="Ownership" rawValue value={ownershipInfo} className="text-wrap text-break" size="m" />
          <Row label="Access level" value={access_level} size="m" />
          {!subevent && <Row label="Allow active contacts" value={yn(allow_registered_user_access)} size="m" />}
          <EventTiming event={event} />
          <Row label="Location" rawLabel value={location} size="m" />
          <Row label="Is Sub-Event" rawLabel value={yn(subevent)} size="m" />
          <Row label="Is Standalone Page" rawLabel value={yn(standalone)} size="m" />
          <Row
            label="Standalone Text Color"
            rawLabel
            value={
              <>
                {standalone && (
                  <span className="event-overview__color-preview" style={{ backgroundColor: textColor }} />
                )}
                <span>{standalone ? textColor : '—'} </span>
              </>
            }
            size="m"
            valueClassName="event-overview__color-preview-wrap"
          />
          <Row label="Description" html value={description} size="m" />
        </InfoBoxV2>

        <ThumbnailWithRefresh
          fallbackIconName={IconAlias.EVENT}
          entity={{ preview_image_id, preview_image }}
          className="event-overview__preview-image"
          placeholder={<LoaderWithText text="processing" />}
        />
      </div>
    </div>
  );
};
