import React from 'react';

import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { DataSectionRefreshButton } from 'components/data-section-refresh-button';
import DivisionsDataSection from 'components/divisions/divisions-data-section/divisions-data-section';

const DivisionsManagement: React.FC = () => {
  return (
    <div className="d-flex flex-column h-100 w-100">
      <div className="d-flex align-items-center justify-content-between">
        <DashboardBreadcrumbs className="d-inline-block mb-3" />
        <DataSectionRefreshButton />
      </div>
      <DivisionsDataSection />
    </div>
  );
};

export default DivisionsManagement;
