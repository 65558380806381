import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import cx from 'classnames';
import { ActionIcon } from '@mantine/core';
import { Player, VideoAsset, VideoPlayer } from '@mediafellows/videoplayer';
import { CaretLeft, CaretRight } from 'blueprint5-icons';

import { useWindowSize } from 'utils/hooks';
import { getPreviewImage, getThumbnailType } from 'utils/list-item';
import { isProduct } from 'utils/general';
import { Thumbnail } from 'components/thumbnail';
import { PDFPreview } from 'components/pdf-preview';
import { UserAvatar } from 'components/user-avatar';
import { OrganizationLogo } from 'components/organization';
import { AssetAudioPlayer } from 'components/audio-player/audio-player';

import { getPreviewImageUrl } from 'utils/asset';
import { MantineIcon } from 'utils/ui/icon';

import { IAsset, IEntity, IListItem, IProductAsset, IPreviewImage } from 'types';

import './style.scss';

export enum ThumbMode {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}
export type ICarouselItem = IListItem | IProductAsset;

export interface ICarouselProps {
  data: ICarouselItem[];
  forceThumbMode?: ThumbMode;
  breakpoint?: number;
  entity?: string;
  useCustomTypeParse?: boolean;
  customTagParser?: (entity: IEntity) => string;
  customThumbnail?: (entity: IEntity) => JSX.Element;
}

const Carousel: React.FC<ICarouselProps> = (props) => {
  const { data, forceThumbMode, breakpoint = 1400, entity = 'asset', customTagParser, customThumbnail } = props;
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [thumbMode, setThumbMode] = useState<ThumbMode>(forceThumbMode || ThumbMode.HORIZONTAL);
  const thumbsRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const size = useWindowSize();
  const isVideo = data[currentSlide]?.[entity]?.['@type'] === 'asset/digital/video';
  const playersRef = useRef<Record<number, Player>>({});

  useEffect(() => {
    setCurrentSlide(0);
  }, [data]);

  // handle scroll to active thumb for horizontal mode
  useEffect(() => {
    if (thumbMode !== ThumbMode.HORIZONTAL || !thumbsRef.current) {
      return;
    }

    const activeThumb = thumbsRef.current.querySelector(`div[data-index="${currentSlide}"]`) as HTMLDivElement;
    const thumbArrowOffset = 46;

    thumbsRef.current.scrollTo({
      top: 0,
      left: activeThumb.offsetLeft - thumbArrowOffset,
      behavior: 'smooth',
    });
  }, [currentSlide, thumbMode]);

  // handle mousewheel scroll for horizontal mode
  useEffect(() => {
    const thumbsEl = thumbsRef.current;
    const carouselEl = carouselRef.current;

    if (!thumbsEl || !carouselEl || thumbMode === ThumbMode.VERTICAL) {
      return;
    }

    const onWheel = (e): void => {
      e.preventDefault();
      thumbsEl.scrollTo({
        left: thumbsEl.scrollLeft + e.deltaY / 0.4,
        behavior: 'smooth',
      });
    };

    carouselEl.addEventListener('wheel', onWheel);
    return () => carouselEl.removeEventListener('wheel', onWheel);
  }, [thumbMode]);

  // handle auto-switch between vertical and horizontal thumbs
  useEffect(() => {
    if (forceThumbMode) {
      return;
    }

    if (size.width >= breakpoint && thumbMode !== ThumbMode.VERTICAL) {
      setThumbMode(ThumbMode.VERTICAL);
    }

    if (size.width < breakpoint && thumbMode === ThumbMode.VERTICAL) {
      setThumbMode(ThumbMode.HORIZONTAL);
    }
  }, [size, thumbMode, forceThumbMode, breakpoint]);

  const updateCurrentSlide = useCallback(
    (index: number): void => {
      if (currentSlide !== index) playersRef.current?.[currentSlide]?.pause?.();

      setCurrentSlide(index);
    },
    [currentSlide],
  );

  const handleThumbClick = useCallback(
    (e): void => {
      const index = e.currentTarget.dataset.index;
      updateCurrentSlide(~~index);
    },
    [updateCurrentSlide],
  );

  const handleThumbsArrowClick = useCallback(
    (e): void => {
      const arrow = e.currentTarget.dataset.arrow;
      updateCurrentSlide(arrow === 'prev' ? currentSlide - 1 : currentSlide + 1);
    },
    [updateCurrentSlide, currentSlide],
  );

  const thumbnailRenderer = (item: ICarouselItem, type: string, previewImage: IPreviewImage | null): JSX.Element => {
    if (customThumbnail) return customThumbnail(item?.[entity]);

    if (!type.includes('user') && !type.includes('organization'))
      return (
        <Thumbnail
          showType
          assetType={type}
          image={getPreviewImageUrl(previewImage)}
          customTag={customTagParser?.(item?.[entity])}
        />
      );

    if (type.includes('user'))
      return <UserAvatar offset={25} size={40} className="custom-carousel__slide-img" showTag user={item?.[entity]} />;

    if (type.includes('organization')) return <OrganizationLogo organization={item?.[entity]} showTag />;
    return <></>;
  };

  if (!data.length) {
    return null;
  }

  return (
    <div
      className={cx('custom-carousel', `custom-carousel--${thumbMode}-thumbs`, {
        'custom-carousel__slide--video': isVideo,
      })}
      ref={carouselRef}
    >
      <div className="custom-carousel__slider">
        <ReactCarousel
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          useKeyboardArrows={true}
          onChange={updateCurrentSlide}
          selectedItem={currentSlide}
        >
          {data.map((item, index) => {
            if (index < currentSlide - 2 || index > currentSlide + 2) {
              return <></>;
            }

            const itemEntity = item?.[entity] || {};
            const type = getThumbnailType(item, entity).toLowerCase();
            const previewImage = getPreviewImage(item, entity);

            return (
              <div key={index} className="custom-carousel__slide-wrap">
                <div key={index} className="custom-carousel__slide">
                  {(type.includes('image') ||
                    type.includes('user') ||
                    type.includes('group') ||
                    type.includes('news') ||
                    type.includes('organization') ||
                    type.includes('collection') ||
                    isProduct(itemEntity) ||
                    type.includes('category') ||
                    ((type.includes('document') || type.includes('pdf')) && !itemEntity.stream_url) ||
                    type.includes('group')) && (
                    <Thumbnail
                      size="hd"
                      className="custom-carousel__slide-img"
                      assetType={type}
                      showType
                      customTag={customTagParser?.(item?.[entity])}
                      image={getPreviewImageUrl(previewImage)}
                    />
                  )}
                  {(type.includes('pdf') || type.includes('document')) && <PDFPreview source={itemEntity.stream_url} />}
                  {type.includes('video') && (
                    <VideoPlayer
                      onPlayer={(player: Player) => {
                        playersRef.current[index] = player;
                      }}
                      key={item.id}
                      className={'custom-carousel__video'}
                      video={itemEntity as VideoAsset}
                      autoplay={false}
                      posterUrl={previewImage?.url}
                    />
                  )}
                  {type.includes('audio') && <AssetAudioPlayer asset={itemEntity as IAsset} />}
                </div>
              </div>
            );
          })}
        </ReactCarousel>
      </div>
      <div className="custom-carousel__thumbnails-wrap">
        <ActionIcon
          size="sm"
          variant="default"
          className={cx('custom-carousel__arrows custom-carousel__arrows--prev p-0', {
            'custom-carousel__arrows--disabled': currentSlide === 0,
          })}
          onClick={handleThumbsArrowClick}
          data-arrow={'prev'}
        >
          <MantineIcon icon={<CaretLeft size={20} />} />
        </ActionIcon>

        <ActionIcon
          size="sm"
          variant="default"
          className={cx('custom-carousel__arrows custom-carousel__arrows--next p-0', {
            'custom-carousel__arrows--disabled': currentSlide === data.length - 1,
          })}
          onClick={handleThumbsArrowClick}
          data-arrow={'next'}
        >
          <MantineIcon icon={<CaretRight size={20} />} />
        </ActionIcon>
        <div className="custom-carousel__thumbnails" ref={thumbsRef}>
          {data.map((item, index) => {
            const type = getThumbnailType(item, entity);
            const previewImage = getPreviewImage(item, entity);

            return (
              <div
                className={cx('custom-carousel__thumb', {
                  'custom-carousel__thumb--active': currentSlide === index,
                  'custom-carousel__big-thumbs': customThumbnail,
                })}
                key={index}
                data-index={index}
                onClick={handleThumbClick}
              >
                {thumbnailRenderer(item, type, previewImage)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
