import React, { useState, useEffect } from 'react';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';

import DateElement from 'components/date-list-element/date-list-element';
import { ISelectionDataSectionWrappedComponent } from './types';

import startCase from 'lodash/startCase';

import { getEntityLabel } from 'utils/general';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import useSelectedOption from 'utils/hooks/selected-option';

import './style.scss';

export const SelectionDataSectionListItem: React.FC<ISelectionDataSectionWrappedComponent> = ({
  selection,
  contextMenu,
  isChecked,
  isAllItemsSelected,
  handleClick,
}) => {
  const { name, main_entity_type, entityCount, owner, access_level, created_at } = selection;
  const selectedOption = useSelectedOption({ value: access_level });
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);
  return (
    <div
      className={cx('selections-list-item product-selections', {
        'entity-list-item--deleted': isEntityDeleted(selection),
      })}
    >
      <div
        className={cx('default-selections-list-item__checkbox selections-list-item__checkbox', {
          [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
        })}
      >
        <Checkbox onChange={handleClick} checked={itemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
      </div>
      <div className="entity-list-item-row__title default-selections-list-item__title">
        <h4 className="text-nowrap text-truncate">{name}</h4>
      </div>
      <div className="default-selections-list-item__assets">
        {entityCount} {startCase(getEntityLabel(main_entity_type))}
      </div>
      <div>{owner}</div>
      <div>{access_level}</div>
      <div className="text-nowrap text-truncate">
        <DateElement date={created_at} mfxIcon="created-at" />
      </div>
      {Boolean(contextMenu) && contextMenu}
    </div>
  );
};
