import React, { useCallback } from 'react';
import { AmAmazonapacLocalizationOrderSeriesSeries } from '@mediafellows/mm3-types';

import { useMm3Form } from 'helpers/form/use-mm3-form';
import { Model } from 'helpers/filters/types';
import { useStore } from 'store';
import { ToastError } from 'components/toast';
import { initialValues } from './initial-values';

import LocalizationOrderWizard from './order-wizard';
import { createOrder, updateOrder } from 'utils/apis/localization-order';
import { ICreateWorkFlowOrderPayload } from 'utils/actions/types';

interface ILocalizationOrderWizardContainer {
  onFinish: (order: AmAmazonapacLocalizationOrderSeriesSeries) => void;
  localizationOrder?: AmAmazonapacLocalizationOrderSeriesSeries;
}

export const LocalizationOrderWizardContainer: React.FC<ILocalizationOrderWizardContainer> = ({
  onFinish,
  localizationOrder,
}) => {
  const { toastStore } = useStore();

  const handleConfirm = useCallback(
    async (values: AmAmazonapacLocalizationOrderSeriesSeries): Promise<void> => {
      try {
        if (!values) {
          return;
        }

        const targetDate = values['launch_target_string']
          ? { launch_target: new Date(values['launch_target_string'] as string).toISOString() }
          : {};
        values = { ...values, ...targetDate };

        const order = values.id ? await updateOrder(values) : await createOrder(values);
        toastStore.success(`Localization Order was successfully ${values?.id ? 'updated' : 'created'}!`);
        onFinish(order);
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [toastStore, onFinish],
  );

  const form = useMm3Form<ICreateWorkFlowOrderPayload<AmAmazonapacLocalizationOrderSeriesSeries>>(
    {
      ...initialValues,
      ...localizationOrder,
    } as ICreateWorkFlowOrderPayload<AmAmazonapacLocalizationOrderSeriesSeries>,
    Model.LOCALIZATION_ORDERS_SERIES,
    handleConfirm,
  );

  return <LocalizationOrderWizard orderForm={form} onFinish={onFinish} />;
};
