import React, { useCallback, Dispatch, SetStateAction } from 'react';
import { Button, Flex } from '@mantine/core';
import { Plus } from 'blueprint5-icons';

import { useNavigate } from 'react-router';

import { Routes } from 'utils/routes';
import { MantineIcon, Intent } from 'utils/ui';

import { DataSectionControls } from 'components/data-section-controls';
import { useUploadAssetDialog } from 'components/upload-asset-product-dialog';

import { useAssetsUploadStore } from 'store/hooks';
import { useRefreshDataSection, useAssetActionsOptions } from 'utils/hooks';
import { useAssignToProductAction } from 'utils/actions/asset/assign-asset-to-product-action';
import { IAssetActionName } from 'utils/actions/asset';
import { Pages } from 'utils/actions/types';

import { DetailsPageTabs, IAsset, IMm3Asset } from 'types';
import { flags } from 'utils/flags';
import { AssetType } from 'utils/format-asset-type';

const page = Pages.DETAILS;

const tabsWithToggleButton = new Set([DetailsPageTabs.PRODUCTS, DetailsPageTabs.SUB_ASSETS]);
const subAssetsTabs = new Set([
  DetailsPageTabs.SUBTITLES,
  DetailsPageTabs.AUDIO_TRACKS,
  DetailsPageTabs.REPORTS,
  DetailsPageTabs.SUB_ASSETS,
]);
const tabToClassification = {
  [DetailsPageTabs.SUBTITLES]: flags.hasCustomClassifications ? undefined : 'document/subtitle',
  [DetailsPageTabs.REPORTS]: 'document/report',
};

const tabToName = {
  [DetailsPageTabs.SUBTITLES]: 'Subtitle',
  [DetailsPageTabs.AUDIO_TRACKS]: 'Audio Track',
  [DetailsPageTabs.REPORTS]: 'QC Report',
  [DetailsPageTabs.SUB_ASSETS]: 'Ancillary Asset',
};

export const AssetDetailsButtons: React.FC<{
  asset: IAsset | IMm3Asset;
  setAsset: Dispatch<SetStateAction<IAsset | IMm3Asset>>;
  refreshAsset: VoidFunction;
  activeTab: DetailsPageTabs;
  editModeEnabled: boolean;
}> = ({ asset, setAsset, refreshAsset, editModeEnabled, activeTab }) => {
  const navigate = useNavigate();
  const refreshDataSection = useRefreshDataSection();
  const onSuccess = useCallback(
    async (action?: string, asset?: IAsset | IMm3Asset) => {
      switch (action) {
        case IAssetActionName.ASSIGN_TO_PRODUCT:
          setTimeout(refreshDataSection, 1000);
          break;
        case IAssetActionName.DELETE:
          navigate(Routes.ASSETS);
          break;
        case IAssetActionName.CHANGE_ACCESS:
          if (asset) {
            setAsset(asset);
          }
          break;
        case IAssetActionName.UPLOAD_ASSET:
          if (asset) {
            setAsset({ ...asset, file_status: 'ingesting' });
          }
          break;
        case IAssetActionName.UPLOAD_PREVIEW:
        case IAssetActionName.EXTRACT_PREVIEW:
          if (asset) {
            setAsset({ ...asset, preview_image: { ...asset?.preview_image, status: 'ingesting' } });
          }
          break;
        case IAssetActionName.DOWNLOAD:
          break;
        case IAssetActionName.UPLOAD_SUBTITLE:
          asset && setAsset(asset);
          break;
        default:
          await refreshAsset();
      }
    },
    [refreshDataSection, navigate, setAsset, refreshAsset],
  );

  const customOptions = useAssetActionsOptions();

  const { handler } = useAssignToProductAction([asset], {
    ...customOptions,
    context: 'single',
    onSuccess,
    page,
  });

  const { setInitialValues } = useAssetsUploadStore();

  const withParentField = (asset as IMm3Asset)?.type && (asset as IMm3Asset)?.type === AssetType.VIDEO;

  const [openDialog] = useUploadAssetDialog<IMm3Asset>({
    onSuccess,
    withParentField,
    context: 'multi',
  });

  const uploadAsset = useCallback(() => {
    if (!asset.id) {
      return;
    }

    setInitialValues({ parent_id: asset.id, classification: tabToClassification[activeTab] });
    openDialog();
  }, [activeTab, asset.id, openDialog, setInitialValues]);

  return (
    <Flex gap="xs" className="ms-auto me-4 align-self-center">
      <Button
        key="upload-assets-btn"
        onClick={uploadAsset}
        variant="primary"
        size="xs"
        hidden={!subAssetsTabs.has(activeTab) || !flags.isMm3Assets}
        leftSection={<MantineIcon icon={<Plus />} />}
      >
        Upload {tabToName[activeTab] || ''}
      </Button>

      <Button
        leftSection={<MantineIcon icon={<MantineIcon icon="plus" />} />}
        onClick={handler}
        disabled={editModeEnabled}
        hidden={DetailsPageTabs.PRODUCTS !== activeTab}
        variant={Intent.PRIMARY}
      >
        Assign
      </Button>

      <DataSectionControls hidden={!tabsWithToggleButton.has(activeTab)} />
    </Flex>
  );
};
