import React, { useMemo, useEffect, useCallback } from 'react';
import { isString, isEmpty, map, omit, filter, difference, flatten } from 'lodash';
import { Button, Popover, NavLink, Tooltip } from '@mantine/core';

import { FormInput, FormDate } from 'helpers/form';
import { IAssetWithArtifact, IAssetArtifact } from 'utils/actions/types';

import { IDeliveryFormProps } from 'components/delivery/types';
import { InfoBoxV2 } from 'components/info-box-v2';
import { SummaryListView } from 'components/delivery/summary-list-view/summary-list-view';
import { parseDataSize } from 'utils/general';
import { Intent } from 'utils/ui/intent';
import { useRemote } from 'utils/hooks';
import { Loading } from 'components/loading';
import { EmptySectionMessage } from 'components/section-message';
import { getTotalSize } from 'pages/delivery-details/utils';
import { getAssetArtifacts, getAssets } from 'utils/apis/asset';
import { loadOrganizationDeliveryInfos } from 'pages/organization-details/api';
import { IAsset, IIdentifiable, IOrganization, ItemId } from 'types';
import { getFutureDateTime, mergeArtifacts } from 'components/delivery/utils';
import { ToastError } from 'components/toast';
import { useStore } from 'store';
import { flags } from 'utils/flags';

import '../style.scss';

export const DeliverySummaryStep: React.FC<IDeliveryFormProps> = ({ deliveryForm: form }) => {
  const { toastStore } = useStore();
  const [assets, setAssets] = React.useState<IAsset[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const minDate = getFutureDateTime(5);
  const organizationId = form.values.organization_id;

  const fetchOrganizationDeliveryInfos = useCallback(async (): Promise<(IOrganization & IIdentifiable) | undefined> => {
    return organizationId ? await loadOrganizationDeliveryInfos(organizationId.toString()) : undefined;
  }, [organizationId]);

  const [organization] = useRemote<IOrganization & IIdentifiable>(fetchOrganizationDeliveryInfos);

  const deliveryInformations = filter(organization?.delivery_informations, { label: 'File Naming Information' }) || [];

  const assetsIds: string[] = useMemo(() => {
    if (isEmpty(form.values.asset_ids)) {
      return [];
    }

    return form.values.asset_ids.map((assetId: string) => {
      if (isString(assetId) && assetId.includes('-')) {
        return assetId.split('-')[1];
      } else {
        return assetId;
      }
    });
  }, [form.values.asset_ids]);

  const removeAsset = useCallback(
    (id: ItemId) => {
      const artefacts = form?.values.artefacts?.filter((artefact) => artefact.asset_id === id) as IAssetArtifact[];
      const updatedAssetList = filter(form?.values.asset_ids, (assetId) => !(assetId as string).includes(`${id}`));
      const updatedArtifacts = difference(form?.values.artefacts, artefacts);

      form?.handlers.onChange({ asset_ids: updatedAssetList, artefacts: updatedArtifacts });
    },
    [form?.values.asset_ids, form?.values.artefacts, form?.handlers],
  );

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (isEmpty(assetsIds)) {
        setAssets([]);
        form.handlers.onChange({ artefacts: [] });
        return;
      }

      setLoading(true);
      const assets = await getAssets(assetsIds);

      const oldArtifacts = map(form.values.delivery_items, (item) => omit(item, 'uuid'));
      let artefacts: (IAssetArtifact[] | IAssetArtifact)[] = [];

      try {
        artefacts = flags.isMm3Assets
          ? await getAssetArtifacts(assetsIds)
          : await Promise.all(assetsIds.map(getAssetArtifacts));
      } catch (error) {
        toastStore.error(
          <ToastError error={error} placeholder={'Sorry, something went wrong while fetching artifacts'} />,
        );
      }

      artefacts = flags.isMm3Assets ? artefacts : flatten(artefacts as IAssetArtifact[][]);

      let formatedArtifacts = map(artefacts as IAssetArtifact[], (obj) => ({ ...obj, checked: true }));
      formatedArtifacts = filter(
        formatedArtifacts,
        (artefact) => !artefact.storage_url || !artefact.storage_url.includes('null://'),
      );

      const newArtefacts = !isEmpty(oldArtifacts) ? mergeArtifacts(formatedArtifacts, oldArtifacts) : formatedArtifacts;

      setAssets(assets);
      form.handlers.onChange({ artefacts: newArtefacts });

      setLoading(false);
    };

    fetchData();
  }, [assetsIds, form.handlers, toastStore, form.values.delivery_items]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <Loading text="Loading Files" />
        </div>
      )}

      <div className="delivery-summary-wizard__wrapper h-100">
        <div className="delivery-summary-wizard__container">
          <div className="delivery-summary-wizard__fields">
            <FormInput name="name" required label="Package Name" {...form.formData.name} {...form.handlers} />
            <Tooltip label='If no date is selected, the delivery will commence 5 min after you press "Deliver"'>
              <span>
                <FormDate
                  key="scheduled_at"
                  name="scheduled_at"
                  label="Scheduled Date"
                  minDate={minDate}
                  withTime
                  large
                  hideTodayButton={false}
                  {...form.formData.scheduled_at}
                  {...form.handlers}
                />
              </span>
            </Tooltip>
            <div className="delivery__wizard-size">
              <label className="bp3-label">Size</label>
              <div>{isLoading ? ' ...' : parseDataSize(getTotalSize(form.values.artefacts || []))}</div>
            </div>

            {!!deliveryInformations.length && (
              <div className="delivery-menu-options">
                <Popover position="bottom" withArrow shadow="md">
                  <Popover.Target>
                    <Button variant={Intent.PRIMARY}>Check File Naming</Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    {deliveryInformations?.map((deliveryInfo) => (
                      <NavLink
                        key={deliveryInfo.id}
                        label={deliveryInfo.spec_type}
                        href={deliveryInfo.page_url || deliveryInfo.document}
                        target="_blank"
                      />
                    ))}
                  </Popover.Dropdown>
                </Popover>
              </div>
            )}
          </div>

          <InfoBoxV2
            gridTemplateColumns="1fr"
            title={`Files to be delivered (${
              isLoading ? '...' : filter(form.values.artefacts, { checked: true })?.length
            })`}
            className="delivery-summary-wizard__list"
          >
            {!isLoading && !assets?.length && <EmptySectionMessage />}

            {!isLoading &&
              assets?.map((asset: IAssetWithArtifact) => {
                return <SummaryListView form={form} asset={asset} key={asset.id} removeAsset={removeAsset} />;
              })}
          </InfoBoxV2>
        </div>
      </div>
    </>
  );
};
