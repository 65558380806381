export const guildListSchema = `
  id,
  access_level,
  codes_count,
  requested_codes_count,
  export_url,
  am_statistics_data,
  pm_statistics_data,
  created_at,
  updated_at,
  deleted_at,
  expires_at,
  scheduled_at,
  name,
  preview_image {
    url
  },
  preview_image_id,
  users_count,
  owner_id,
  owner_organization_id,
  description,
`;

export const guildDetailsSchema = `${guildListSchema}, owner, owner_organization`;
