import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import {
  Combobox,
  Pill,
  PillsInput,
  useCombobox,
  CloseButton,
  Loader,
  Menu,
  ComboboxOptionProps,
  Button,
  Flex,
} from '@mantine/core';
import cx from 'classnames';
import { isEqual, compact } from 'lodash';

import { FormGroup } from 'helpers/form/fields/form-group';
import { formatFormLabel, getFieldPlaceholder } from 'helpers/form/fields/helpers';
import { useDebounce } from 'utils/hooks';
import { useMultiselectRemoteSetup, useMultiselectStaticSetup, usePreventBackspaceClick } from './hooks';
import { IContact, IProduct } from 'types';
import { DefaultCreateTagElement, GroupTagElement, exactEmailMatch, filterSelectedItems, tagRenderer } from './utils';
import { itemRenderer, parseItem, IFormMultiSelectOption } from 'helpers/form/fields/select-helpers';
import { by } from 'utils/general';
import { IFormMultiSelectProps } from './types';
import { AncestryDropdown, IBreadcrumbProps } from './ancestry-dropdown/ancestry-dropdown';
import { Classes, MantineIcon } from 'utils/ui';

import './style.scss';

const defaultBreadcrumbs: IBreadcrumbProps[] = [{ href: 'root', icon: 'double-chevron-left', text: 'Top' }];

export const FormMultiSelect: React.FC<IFormMultiSelectProps> = (props) => {
  const {
    name,
    label = '',
    onChange,
    onBlur,
    fetchValues,
    validation,
    touched,
    value,
    omni = false,
    rawLabel = false,
    required,
    isAncestryMode = false,
    options,
    onSelectedItemsChange,
    queryDelay = 250,
    inline = false,
    allowNewItems = false,
    validateNewItem = (s: string): boolean => s?.trim().length > 0,
    placeholder,
    fitInParent,
    createTagElement: CreateTagElement = DefaultCreateTagElement,
    className,
    customRenderItemParser,
    customTagRenderer,
    hideClearAllButton = false,
    hideSelectedItems = false,
    disabled,
    labelComponent,
    forceReInitiating,
    inputSize = 'md',
    tagsSize = 'md',
  } = props;

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const formattedLabel = label?.length && !rawLabel && !label.endsWith('s') ? `${label}s` : label;

  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage);
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbProps[]>(defaultBreadcrumbs);

  // use array for value - we need to be able to trigger query useEffect on every setInputValue
  // needed for ancestry filters
  const [inputValue, setInputValue] = useState<string[]>(['']);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<IFormMultiSelectOption[]>([]);
  const [selectedItems, setSelectedItems] = useState<IFormMultiSelectOption[]>([]);
  const isRemoteMode = Boolean(fetchValues);
  const [isRemoteInitialized, setIsRemoteInitialized] = useState<null | boolean>(!isRemoteMode);
  const [canLoadRemoteOptions, setCanLoadRemoteOptions] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, queryDelay);
  const filters = useRef<unknown>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const isMounted = useRef(false);
  const latestSelectedItemsChange = useRef<IFormMultiSelectOption[]>([]);

  usePreventBackspaceClick(inputRef);
  useMultiselectStaticSetup(options, isRemoteMode, setItems, setSelectedItems, value, isRemoteInitialized);
  useMultiselectRemoteSetup(
    isRemoteMode,
    value,
    setIsRemoteInitialized,
    fetchValues,
    setSelectedItems,
    canLoadRemoteOptions,
    setIsLoading,
    setItems,
    debouncedInputValue,
    filters,
    forceReInitiating,
  );

  useEffect(() => {
    if (isMounted.current && !isEqual(selectedItems, latestSelectedItemsChange.current)) {
      latestSelectedItemsChange.current = selectedItems;
      onSelectedItemsChange?.(selectedItems);
      return;
    }

    latestSelectedItemsChange.current = selectedItems;
    isMounted.current = true;
  }, [selectedItems, onSelectedItemsChange]);

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const handleChange = useCallback(
    (values: IFormMultiSelectOption[]): void => {
      const formattedValues = Number.isNaN(Number(values?.[0]?.value))
        ? values.reduce((acc, { value }) => [value, ...acc], [])
        : values.reduce((acc, { value }) => [Number(value), ...acc], []);
      onChange?.({ [name]: formattedValues });
      setSelectedItems(values);
      if (inputValue[0] !== '') {
        setInputValue(['']);
      }
    },
    [inputValue, name, onChange],
  );

  const itemListPredicate = useCallback(
    (q: string) => {
      if (isRemoteMode) return;
      const itemsById = by<IFormMultiSelectOption>(selectedItems, 'value');
      const searchQuery = new RegExp(q, 'i');

      const filtered = items.filter(
        ({ value, label }) => !itemsById[value] && (q.startsWith('id:') || searchQuery.test(label)),
      );
      setItems(filtered);
    },
    [isRemoteMode, items, selectedItems],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      combobox.updateSelectedOptionIndex();
      setInputValue([event.currentTarget.value]);
      itemListPredicate(event.currentTarget.value);
    },
    [combobox, itemListPredicate],
  );

  const handlePasteInput = useCallback((e: React.ClipboardEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    e.preventDefault();

    const pastedText = e.clipboardData.getData('text/plain');
    setInputValue((curr) => [(curr || '') + pastedText]);
  }, []);

  const createNewItemFromQuery = useMemo(() => {
    if (!allowNewItems) {
      return undefined;
    }

    return (query: string) => {
      const value = query.trim();
      return { label: value, value };
    };
  }, [allowNewItems]);

  const handleAdd = useCallback((): void => {
    const trimmedValue = inputValue[0].trim();
    const emailMatch = exactEmailMatch([...items, ...selectedItems], trimmedValue);
    if (
      !validateNewItem(trimmedValue) ||
      emailMatch ||
      !trimmedValue ||
      selectedItems.some((e) => e.value === trimmedValue)
    ) {
      return;
    }

    const newItem = createNewItemFromQuery?.(trimmedValue);
    if (!newItem) {
      return;
    }
    handleChange([newItem, ...selectedItems]);
  }, [createNewItemFromQuery, handleChange, inputValue, items, selectedItems, validateNewItem]);

  const onItemSelect = useCallback(
    (val: string, itemProps?: ComboboxOptionProps): void => {
      const targetEl = document.getElementById(val);
      const isAncestryArrowBtn =
        targetEl?.classList.contains('js-product-search-item__ancestry') ||
        targetEl?.closest('.js-product-search-item__ancestry');
      const hasItemProps = Object.keys(itemProps || {}).length;

      if (isAncestryArrowBtn && !hasItemProps) {
        const parentId = targetEl?.dataset.parentId;
        const text = targetEl?.dataset.text;

        filters.current = [['parent_id', 'eq', parentId]];

        setIsLoading(true);
        setBreadcrumbs((breadcrumbs) => [...breadcrumbs, { text, href: parentId }]);
        setInputValue(['']);
        return;
      }
      if (val === '$create') {
        handleAdd();
      } else {
        const item = items.find((item) => item.value == val);
        if (!item) return;
        const type = item['@type'] || (item as IProduct).type || item['$type'];
        const newItem = type ? parseItem(item) : (item as IFormMultiSelectOption);
        if (newItem && selectedItems.every(({ value }) => value !== newItem.value)) {
          handleChange([newItem, ...selectedItems]);
          handleBlur?.();
        }
      }
    },
    [handleAdd, handleBlur, handleChange, items, selectedItems],
  );

  const onRemove = useCallback(
    (item: IFormMultiSelectOption): void => {
      handleChange(selectedItems.filter((v) => v.value !== item.value));
    },
    [handleChange, selectedItems],
  );

  const handleClearAll = useCallback(
    (name: string): void => {
      setSelectedItems([]);
      onChange?.({ [name]: [] });
    },
    [onChange],
  );

  const handleGroupAdd = (parsedInputItems): void => {
    const filteredUserEmails = parsedInputItems.filter((item) => {
      const emailMatch = exactEmailMatch([...items, ...selectedItems], item);

      if (emailMatch || selectedItems.some((e) => e.value === item.value)) {
        return;
      }

      return item;
    });

    handleChange([...filteredUserEmails, ...selectedItems]);
  };

  const handleEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
        handleAdd();
      }
    },
    [handleAdd],
  );

  const handleInputPropsClick = useCallback((): void => {
    if (!isRemoteMode || disabled) return;
    setCanLoadRemoteOptions(true);
  }, [disabled, isRemoteMode]);

  const handleItemRenderer = useCallback(
    (item: IFormMultiSelectOption) => {
      const hashData = item?.id && new Map(selectedItems.map((item) => [item.id, item]));
      const isItemSelected = hashData && hashData?.has(item?.id);
      let itemProps = customRenderItemParser ? customRenderItemParser(item) : { disabled: false };
      itemProps = {
        ...itemProps,
        disabled: isItemSelected || itemProps.disabled,
      };
      return itemRenderer(item, onItemSelect, itemProps, { isAncestryMode });
    },
    [customRenderItemParser, onItemSelect, isAncestryMode, selectedItems],
  );

  const handleBreadcrumbClick = async (e): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target;
    const menuItem = target.closest(`.${Classes.MENU_ITEM}`);
    const isLink = target.classList.contains(Classes.BREADCRUMB);
    let parentId;

    if (isLink) {
      parentId = target.getAttribute('href');
    }

    if (!isLink && menuItem) {
      parentId = menuItem.getAttribute('href');
    }

    if (!parentId) return;

    filters.current = parentId === 'root' ? undefined : [['parent_id', 'eq', parentId]];

    const breadcrumbIndex = breadcrumbs.findIndex((item) => item.href === parentId);
    const updated = breadcrumbs.slice(0, breadcrumbIndex + 1);

    setIsLoading(true);
    setBreadcrumbs(updated);
    setInputValue(['']);
  };

  const handleItemListRender = (): React.ReactElement => {
    const filteredItems = filterSelectedItems(items, selectedItems).filter((item) => item != null);
    const hasItems = filteredItems.length;

    if (isAncestryMode) {
      return (
        <AncestryDropdown
          isLoading={isLoading}
          items={filteredItems as IProduct[]}
          renderItem={handleItemRenderer}
          breadcrumbs={breadcrumbs}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      );
    }

    if (isLoading) {
      return <Combobox.Empty>Loading...</Combobox.Empty>;
    }

    // identifies emails separated by comma and space
    const listRegex = /([^,\s]+@[^,\s]{2,}\.[^,\s]{2,})+/gi;
    const inputeItems = inputValue.toString()?.match(listRegex) ?? [];

    let parsedInputItems = inputeItems.map((email) => {
      email = email.trim();
      const systemUser = filteredItems.find((item) => (item as IContact)?.email === email);
      if (systemUser) return systemUser;

      if (!validateNewItem(email)) return;

      return createNewItemFromQuery?.(email);
    });
    parsedInputItems = compact(parsedInputItems);

    const trimmedValue = inputValue[0].trim();
    const emailMatch = exactEmailMatch([...filteredItems, ...selectedItems], trimmedValue);

    const showCreateTag = allowNewItems && validateNewItem(trimmedValue) && !emailMatch;
    const isGroupTag = !showCreateTag && parsedInputItems.length > 1;

    if (hasItems) {
      return (
        <>
          {showCreateTag && <CreateTagElement text={inputValue[0]} onClick={handleAdd} />}
          {isGroupTag ? (
            <GroupTagElement
              text={`${parsedInputItems.length} recipients`}
              onClick={() => handleGroupAdd(parsedInputItems)}
            />
          ) : (
            <Menu>{filteredItems.map(handleItemRenderer)}</Menu>
          )}
        </>
      );
    }

    if (showCreateTag) {
      return (
        <Menu>
          <CreateTagElement text={inputValue[0]} onClick={handleAdd} />
        </Menu>
      );
    }

    return <Combobox.Empty> Nothing found.</Combobox.Empty>;
  };

  const tags = useMemo(() => {
    return selectedItems.map(
      (item) => customTagRenderer?.(item) || tagRenderer(omni, onRemove, disabled, tagsSize)(item),
    );
  }, [customTagRenderer, disabled, tagsSize, omni, onRemove, selectedItems]);

  const isWrapInputDisabled = !isRemoteInitialized || disabled;

  const formattedPlaceholder = getFieldPlaceholder({
    placeholder,
    disabled: isWrapInputDisabled,
    defaultPlaceholder: allowNewItems ? `Add ${formattedLabel}` : `Search ${formattedLabel}`,
  });

  const isEmptyMultiSelect = !selectedItems.length;

  const InputRightSection = useMemo((): JSX.Element | null => {
    if (isLoading) return <Loader size={18} />;
    if (hideClearAllButton || inputValue[0]) return null;
    return (
      <CloseButton
        size="md"
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => handleClearAll(name)}
        aria-label="Clear field"
        disabled={isEmptyMultiSelect || isWrapInputDisabled}
        className="form-multi-select__clear-btn"
      />
    );
  }, [handleClearAll, hideClearAllButton, inputValue, isEmptyMultiSelect, isLoading, isWrapInputDisabled, name]);

  const InputField = useMemo((): JSX.Element => {
    if (omni) {
      return (
        <Flex align="center" gap="xs">
          <Combobox.DropdownTarget>
            <PillsInput
              onClick={() => combobox.openDropdown()}
              rightSectionWidth={!isLoading && !hideClearAllButton ? 'max-content' : undefined}
              size={inputSize}
              disabled={disabled}
            >
              <Combobox.EventsTarget>
                <PillsInput.Field
                  ref={inputRef}
                  onFocus={() => {
                    combobox.openDropdown();
                    handleInputPropsClick();
                  }}
                  onBlur={handleBlur}
                  value={inputValue[0]}
                  onChange={handleInputChange}
                  onKeyDown={handleEnter}
                  placeholder={selectedItems.length > 0 ? undefined : formattedPlaceholder}
                  onPaste={handlePasteInput}
                />
              </Combobox.EventsTarget>
            </PillsInput>
          </Combobox.DropdownTarget>
          <Button
            leftSection={<MantineIcon icon="cross" />}
            size="sm"
            onMouseDown={(event) => event.preventDefault()}
            onClick={() => handleClearAll(name)}
            aria-label="Clear field"
            disabled={isEmptyMultiSelect || isWrapInputDisabled}
            variant="filled"
            className="form-multi-select--large__clear-btn"
          >
            Clear all
          </Button>
        </Flex>
      );
    }
    return (
      <Combobox.DropdownTarget>
        <PillsInput
          onClick={() => combobox.openDropdown()}
          rightSection={InputRightSection}
          size={inputSize}
          disabled={disabled}
        >
          <Pill.Group>
            {hideSelectedItems ? null : tags}
            <Combobox.EventsTarget>
              <PillsInput.Field
                ref={inputRef}
                onFocus={() => {
                  combobox.openDropdown();
                  handleInputPropsClick();
                }}
                onBlur={handleBlur}
                value={inputValue[0]}
                onChange={handleInputChange}
                onKeyDown={handleEnter}
                placeholder={selectedItems.length > 0 ? undefined : formattedPlaceholder}
                onPaste={handlePasteInput}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>
    );
  }, [
    InputRightSection,
    combobox,
    disabled,
    formattedPlaceholder,
    handleBlur,
    handleClearAll,
    handleEnter,
    handleInputChange,
    handleInputPropsClick,
    handlePasteInput,
    hideClearAllButton,
    hideSelectedItems,
    inputValue,
    isEmptyMultiSelect,
    isLoading,
    isWrapInputDisabled,
    inputSize,
    name,
    omni,
    selectedItems.length,
    tags,
  ]);

  return (
    <FormGroup
      label={labelComponent || formatFormLabel(formattedLabel, required)}
      labelFor={name}
      helperText={showError ? 'This field is required' : ''}
      inline={inline}
      className={cx('form-multi-select', className, {
        'form-multi-select-group--inline': inline,
        'form-multi-select-wrap--fit-in-parent': fitInParent,
        'form-multi-select--large': omni,
        'form-multi-select--active': combobox.dropdownOpened,
        'form-multi-select-ancestry': isAncestryMode,
      })}
    >
      <Combobox
        store={combobox}
        onOptionSubmit={onItemSelect}
        portalProps={{
          className: cx('form-multi-select-portal', { 'form-multi-select-portal--large': omni }),
        }}
        position="bottom-start"
      >
        {InputField}
        {omni && <Pill.Group>{hideSelectedItems ? null : tags}</Pill.Group>}
        <Combobox.Dropdown hidden={disabled}>
          <Combobox.Options style={{ overflowY: 'auto', listStyle: 'none' }}>{handleItemListRender()}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </FormGroup>
  );
};
