export const SmartGroupListSchema = `
  access_level,
  am_statistics_data,
  asset_filters,
  created_at,
  deleted_at,
  description,
  expires_at,
  id,
  name,
  owner,
  owner_organization,
  pm_statistics_data,
  preview_image,
  preview_image_id,
  product_filters,
  settings,
  updated_at,
  users_count,
  user_filters
`;

export const SmartGroupDetailsSchema = `{
  access_level,
  am_statistics_data,
  asset_filters,
  created_at,
  deleted_at,
  expires_at,
  external_reference,
  id,
  name,
  organizations { id },
  owner,
  owner_id,
  owner_organization,
  owner_organization_id,
  pm_statistics_data,
  preview_image,
  preview_image_id,
  preview_image_id,
  product_filters,
  scheduled_jobs_count,
  settings,
  type,
  updated_at,
  users_count,
  user_filters
}`;
