import { Model } from 'helpers/filters/types';
import { chipmunk } from 'utils/chipmunk';
import omit from 'lodash/omit';

import { IAudioTrack, ISubtitle, ItemId } from 'types';

export const getAssetAudioArtifacts = async (asset_id?: ItemId | null): Promise<IAudioTrack[]> => {
  if (!asset_id) {
    return [];
  }

  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.AUDIO_TRACKS, 'query', {
      params: { asset_id: asset_id },
    });
    return objects;
  });
};

export const updateAudioTrack = async (audio: Partial<IAudioTrack>): Promise<ISubtitle> => {
  const { object } = await chipmunk.run(({ action }) => {
    return action(Model.AUDIO_TRACKS, 'update', {
      params: { asset_id: audio.asset_id, artefact_id: audio?.id },
      body: {
        ...omit(audio, ['id', 'asset_id']),
      },
    });
  });

  return object;
};
