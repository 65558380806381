import React from 'react';
import { observer } from 'mobx-react-lite';

import { IProduct, IProductLayer } from 'types';

import { useFormsStore } from 'store/hooks';

import { ProductMetadataTabEditFormView } from 'pages/product-details/product-metadata-tab/product-metadata-tab-edit-form-view';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

export const ProductMetadataTabEdit: React.FC = observer(() => {
  const { forms } = useFormsStore();

  const layerForm = forms['layerForm'] as IUseMm3FormReturn<IProductLayer>;
  const productForm = forms['productForm'] as IUseMm3FormReturn<IProduct>;
  return <ProductMetadataTabEditFormView layerForm={layerForm} productForm={productForm} />;
});

export default ProductMetadataTabEdit;
