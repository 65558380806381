import React from 'react';
import { observer } from 'mobx-react-lite';

import { FilterCheckbox, FilterDateRange, FilterSelect, FilterSwitch, FilterTree } from 'helpers/filters/fields';
import { IFiltersHandlers, organizationOptionsProvider, ownersOptionsProvider } from 'helpers/filters';
import { FilterButtonGroup } from 'helpers/filters/fields/filter-button-group';
import { Mm3ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { Model } from 'helpers/filters/types';

import {
  accessOptionsProvider,
  classificationsOptionsProvider,
  protectionLevelsOptionsProvider,
  fileStatusOptionsProvider,
  statusOptionsProvider,
  IAssetFilters,
  divisionTreeProvider,
} from 'components/asset/asset-filters/utils';
import { AdditionalFiltersCmp } from 'components/asset/asset-filters/additional-filters';
import { AssetParentFilter } from 'components/asset/asset-filters/parent-filters';

import { DataSectionStore } from 'store/data-section-store';
import { IAsset } from 'types';
import { useBasicStore } from 'store/hooks';

const mainClassificationProvider = new Mm3ContextDataProvider(Model.MM3_ASSETS, 'main_classification');

export const SharedAssetFilters: React.FC<{
  dataSectionStore: DataSectionStore<IAsset>;
  filterHandlers: IFiltersHandlers<IAssetFilters>;
  filterValues: IAssetFilters;
  withMainAssetsFilter?: boolean;
}> = observer(({ dataSectionStore, filterValues, filterHandlers, withMainAssetsFilter }) => {
  const { searchStore } = dataSectionStore;
  const { divisions } = useBasicStore();
  return (
    <>
      <AdditionalFiltersCmp filterValues={filterValues} filterHandlers={filterHandlers} />
      <FilterButtonGroup
        label="Type"
        name="main_classification"
        disabled={searchStore?.running}
        filter={filterValues.main_classification}
        optionsProvider={mainClassificationProvider}
        onChange={filterHandlers.onChange}
      />
      <AssetParentFilter
        filterHandlers={filterHandlers}
        filterValues={filterValues}
        withMainAssetsFilter={withMainAssetsFilter}
      />

      <FilterCheckbox
        label="Access"
        name="access_level"
        optionsProvider={accessOptionsProvider}
        filter={filterValues.access_level}
        onChange={filterHandlers.onChange}
        disabled={searchStore.running}
        aggregations={searchStore.aggregationValues('access_level')}
      />

      <FilterCheckbox
        label="Status"
        name="status"
        optionsProvider={statusOptionsProvider}
        disabled={searchStore.running}
        filter={filterValues.status}
        onChange={filterHandlers.onChange}
        aggregations={searchStore.aggregationValues('status')}
      />

      <FilterSelect
        label="File Status"
        name="file_status"
        placeholder="Select File Status"
        optionsProvider={fileStatusOptionsProvider}
        filter={filterValues.file_status}
        onChange={filterHandlers.onChange}
        aggregations={searchStore.aggregationValues('file_status')}
      />

      <FilterSelect
        label="Asset Type"
        name="classification"
        placeholder="Select Asset Type"
        optionsProvider={classificationsOptionsProvider}
        filter={filterValues.classification}
        onChange={filterHandlers.onChange}
        aggregations={searchStore.aggregationValues('classification')}
      />

      <FilterSelect
        label="Protection Levels"
        name="protection_levels"
        placeholder="Select Protection Level"
        optionsProvider={protectionLevelsOptionsProvider}
        filter={filterValues.protection_levels}
        onChange={filterHandlers.onChange}
        aggregations={searchStore.aggregationValues('protection_levels')}
      />

      <FilterDateRange
        label="Updated at"
        name="updated_at"
        filter={filterValues.updated_at}
        onChange={filterHandlers.onChange}
      />

      <FilterDateRange
        label="Created at"
        name="created_at"
        filter={filterValues.created_at}
        onChange={filterHandlers.onChange}
      />
      <FilterSelect
        label="Owner"
        name="owner_id"
        placeholder="Select Owner"
        optionsProvider={ownersOptionsProvider}
        filter={filterValues.owner_id}
        onChange={filterHandlers.onChange}
      />
      <FilterSelect
        label="Owner Organization"
        name="owner_organization_id"
        placeholder="Select Organization"
        optionsProvider={organizationOptionsProvider}
        filter={filterValues.owner_organization_id}
        onChange={filterHandlers.onChange}
      />
      {!!divisions.length && (
        <FilterTree
          label="Divisions"
          name="division_ids"
          optionsProvider={divisionTreeProvider}
          filter={filterValues.division_ids}
          onChange={filterHandlers.onChange}
          aggregations={searchStore.aggregationValues('division_ids')}
        />
      )}
      <FilterSwitch
        label="Unassigned assets only"
        name="products_count"
        filter={filterValues.products_count}
        onChange={filterHandlers.onChange}
        checkedValue={0}
        notCheckedValue={false}
      />
    </>
  );
});
