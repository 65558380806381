import React, { useCallback, useEffect } from 'react';
import { uniq, map, pullAll, capitalize, omit } from 'lodash';
import { McGenericRecommendationAsset, McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { IResult } from '@mediafellows/chipmunk';
import { Loader } from '@mantine/core';
import { Intent, LoaderSize } from 'utils/ui';

import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';

import { IFormMultiSelectOption } from 'helpers/form';
import { IQueryParams, IContact, ItemId, IGroup, ISelection, ISearchFilter, IIdentifiable, IOrganization } from 'types';
import { ICreateWorkFlowPayload, IRecipient } from 'utils/actions/types';
import { emailValidation } from 'utils/validations';
import { queryContactSelections, queryQueue, queryRecipientContacts } from 'utils/apis/recipients';
import { findEmails, searchContacts } from 'utils/apis/contacts';
import { useRemote } from 'utils/hooks';
import { getRecommendationDataFromGroup } from 'components/recommendation/recommend-group/apis';
import { loadAllContactSelectionItems, loadAssetSelectionItems, loadProductSelectionItems } from 'utils/apis/selection';
import { getRecommendationAssetsFilters } from 'utils/recommendation';
import { IItemId } from 'components/form-selection-items';

export const querySelections = async (
  params: IQueryParams,
  filters: ISearchFilter[] = [],
): Promise<((IGroup | IContact | IOrganization) & IIdentifiable)[]> => {
  if (params?.ids?.length === 0) {
    return [];
  }

  return queryContactSelections(params, filters);
};

export enum AssetStepIds {
  Assets,
  Message,
  Recipients,
  Settings,
  Summary,
}

export enum StepIds {
  Products,
  Assets,
  Message,
  Recipients,
  Settings,
  Summary,
}

export const assetsKeys = ['asset_ids'];
export const productKeys = ['productList'];
export const recipientsKeys = ['cc_list'];
export const settingsKeys = ['expires_at', 'requires_login', 'allows_asset_download'];
export const messageKeys = ['subject', 'message'];

export const getTabId = (tab: IItemId): number => {
  if (typeof tab === 'string') {
    tab = capitalize(tab);
    return StepIds[tab];
  }

  return tab;
};

export const isExternalUser = (id: ItemId): boolean => {
  const [valid] = emailValidation({ allow_blank: false }, `${id}`);
  return valid;
};

interface IParcedRecipients {
  recipient_list: IRecipient[];
  recipients: string[];
  contact_selections: ISelection[];
}

export const parseRecipients = (value: IFormMultiSelectOption[] = []): IParcedRecipients => {
  return value.reduce(
    (acc: IParcedRecipients, item) => {
      if (!item?.value) {
        return acc;
      }
      const { value } = item;

      if (item.itemType === 'group') {
        return {
          ...acc,
          recipients: [...acc.recipients, value.toString()],
          contact_selections: [...acc.contact_selections, item as ISelection],
        };
      }
      const recipient_type = isExternalUser(value) ? 'email' : 'user';
      return {
        ...acc,
        recipient_list: [...acc.recipient_list, { recipient_type, recipient_id: `${value}` }],
        recipients: [...acc.recipients, value.toString()],
      };
    },
    { recipient_list: [], recipients: [], contact_selections: [] } as IParcedRecipients,
  );
};
export const parseCCList = (value: IFormMultiSelectOption[] = []): string[] => {
  return value.reduce((acc, item) => {
    if (!item?.value || item?.itemType === 'group') return acc;
    if (Boolean((item as unknown as IContact).email)) return [...acc, (item as unknown as IContact).email];

    return [...acc, item?.value.toString()];
  }, []);
};

export const fetchRecipients = async ({
  ids,
  q,
  include_deleted = false,
}: IQueryParams): Promise<(IContact | IGroup | string)[]> => {
  // identifies multiple email addresses (can be comma separated) from the query
  const listRegex = /([^,\s]+@[^,\s]{2,}\.[^,\s]{2,})+/gi;
  const list = q?.match(listRegex) ?? [];
  if (list.length > 1) {
    const internalUsers = await findEmails(q as string, list);
    const emails = map(internalUsers, 'email');
    const externalUsers = pullAll(list, emails);

    return [...externalUsers, ...internalUsers];
  }
  const queryRecommendationRecipients = queryQueue([queryRecipientContacts, querySelections]);

  if (!ids?.length) {
    return queryRecommendationRecipients({ q, include_deleted }) as Promise<(IContact | IGroup | string)[]>;
  }

  const { externalIds, internalIds } = ids.reduce(
    ({ externalIds, internalIds }, id) => {
      if (typeof id === 'string' && isExternalUser(id)) {
        return { internalIds, externalIds: [...externalIds, id] };
      }
      return { externalIds, internalIds: [...internalIds, id] };
    },
    { externalIds: [] as string[], internalIds: [] as string[] },
  );

  const internalUsers = (await queryRecommendationRecipients({ ids: internalIds, q, include_deleted })) as (
    | IContact
    | IGroup
    | string
  )[];

  return [...internalUsers, ...externalIds];
};

export const useInitiateRecommendationWithGroupData = ({
  groupId,
  form: {
    handlers: { onChange },
  },
}: {
  form: IUseMm3FormReturn<ICreateWorkFlowPayload & McGenericRecommendationProduct>;
  groupId?: ItemId;
}): void => {
  const fetchRecommendationItems = useCallback(async () => {
    if (groupId) return getRecommendationDataFromGroup(groupId);
  }, [groupId]);

  const [groupValues] = useRemote(fetchRecommendationItems);
  useEffect(() => {
    if (!groupValues) {
      return;
    }

    onChange(groupValues);
  }, [groupValues, onChange]);
};

export const validateEmail = (query: string): boolean => {
  return isExternalUser(query);
};

export const countRecipients = ({
  recipients,
  contact_selections,
}: Pick<ICreateWorkFlowPayload, 'recipients' | 'contact_selections'>): number => {
  return (
    (recipients?.length || 0) -
    (contact_selections?.length || 0) +
    (contact_selections || []).reduce((acc, cur) => acc + (cur.users_count || 0), 0)
  );
};

const prepareEmailRecipients = async (
  emailList?: string[] | null,
  contactSelection?: ISelection[],
): Promise<string[]> => {
  const selectionList = contactSelection?.length ? await loadAllContactSelectionItems(contactSelection) : [];
  return uniq((emailList || []).concat((selectionList || []).map((e) => e.email)));
};

export const prepareRecipientLists = async (
  values: Pick<
    ICreateWorkFlowPayload<McGenericRecommendationProduct>,
    | 'recipient_list'
    | 'cc_contact_selections'
    | 'contact_selections'
    | 'cc_list'
    | 'bcc_list'
    | 'bcc_contact_selections'
  >,
): Promise<Pick<McGenericRecommendationProduct, 'cc_list' | 'recipient_list' | 'bcc_list'>> => {
  const recipients = values.recipient_list.filter((e) => (e.recipient_type as string) !== 'group');

  const selectionContacts = values.contact_selections.length
    ? await loadAllContactSelectionItems(values.contact_selections)
    : [];
  const recipient_list = [
    ...recipients,
    ...selectionContacts?.map(({ id }): { recipient_id: string; recipient_type: 'user' | 'email' } => ({
      recipient_id: id,
      recipient_type: 'user',
    })),
  ];

  const cc_list = await prepareEmailRecipients(values.cc_list, values.cc_contact_selections);
  const bcc_list = await prepareEmailRecipients(values.bcc_list, values.bcc_contact_selections);

  return { recipient_list, cc_list, bcc_list };
};

interface IRecipientResult {
  emails: string[];
  emailsCount: number;
}

interface ICCRecipientResult {
  ccEmails: string[];
  ccEmailsCount: number;
}

type IUserListProps = {
  users?: string[] | null;
  count?: number | null;
  loading?: boolean;
  numberOfShownUsers?: number;
};

export const UsersList: React.FC<IUserListProps> = ({ users = [], count, numberOfShownUsers = 3, loading }) => {
  const part = users?.slice(0, numberOfShownUsers).join(', ') || '—';
  const usersCount = count ?? (users?.length || 0);
  const suffix =
    usersCount > numberOfShownUsers ? (
      <span>
        ...
        <span>+{usersCount - numberOfShownUsers}</span>
      </span>
    ) : null;

  return loading ? (
    <Loader size={LoaderSize.SMALL} variant={Intent.PRIMARY} />
  ) : (
    <>
      {part} {suffix}
    </>
  );
};

interface IRecipientListProps {
  recipient_list?: (
    | { recipient_id: ItemId; recipient_type: 'user' }
    | { recipient_id: string; recipient_type: 'email' }
  )[];
  contact_selections?: { id: ItemId }[];
  isEditingRecipients?: boolean;
  numberOfShownUsers?: number;
}

export const RecipientList: React.FC<IRecipientListProps> = ({
  recipient_list,
  contact_selections,
  isEditingRecipients,
  numberOfShownUsers,
}) => {
  const fetchRecipients = useCallback(async (): Promise<IRecipientResult> => {
    if (isEditingRecipients) {
      return { emails: [], emailsCount: 0 };
    }
    const selectionIds = (contact_selections || []).map((e) => e.id);

    const { ids, emails } = (recipient_list || []).reduce(
      (acc: { ids: string[]; emails: string[] }, recipient) => {
        if (recipient.recipient_type === 'user') return { ...acc, ids: [...acc.ids, recipient.recipient_id] };
        return { ...acc, emails: [...acc.emails, recipient.recipient_id] };
      },
      { ids: [], emails: [] },
    );

    const users = ids.length ? await queryRecipientContacts({ ids: ids.slice(0, 3) }) : [];

    const { objects: selectionUsers, pagination } = selectionIds.length
      ? await searchContacts(
          [
            ['group_ids', 'in', selectionIds],
            ...(emails?.length ? ([['email', 'not_in', emails]] as ISearchFilter[]) : []),
            ...(ids?.length ? ([['id', 'not_in', ids]] as ISearchFilter[]) : []),
          ],
          { per: 3 },
        )
      : ({ objects: [] as IContact[] } as IResult<IContact>);

    const list: string[] = emails.concat(
      [...users, ...selectionUsers].map(({ first_name, last_name }) => `${first_name} ${last_name}`) as string[],
    );

    return { emails: list || [], emailsCount: (recipient_list?.length || 0) + (pagination?.total_count || 0) };
  }, [contact_selections, isEditingRecipients, recipient_list]);
  const [result, loading] = useRemote(fetchRecipients);

  const { emails, emailsCount } = result || {};
  return <UsersList numberOfShownUsers={numberOfShownUsers} users={emails} count={emailsCount} loading={loading} />;
};

interface ICopyUsersList {
  usersList?: string[] | null;
  selectionList: ISelection[];
  isEditingRecipients?: boolean;
}

export const CopyUsersList: React.FC<ICopyUsersList> = ({ selectionList, usersList, isEditingRecipients }) => {
  const fetchCCRecipients = useCallback(async (): Promise<ICCRecipientResult> => {
    if (isEditingRecipients) {
      return { ccEmails: [], ccEmailsCount: 0 };
    }
    const selectionIds = selectionList.map((e) => e.id);

    const emails = uniq(usersList || []);

    const { objects: selectionUsers, pagination } = selectionIds.length
      ? await searchContacts(
          [
            ['group_ids', 'in', selectionIds],
            ...(emails.length ? ([['email', 'not_in', emails]] as ISearchFilter[]) : []),
          ],
          { per: 3 },
        )
      : ({ objects: [] as IContact[] } as IResult<IContact>);

    return {
      ccEmails: emails.concat(selectionUsers.map(({ email }) => email)),
      ccEmailsCount: (pagination?.total_count || 0) + (usersList?.length || 0),
    };
  }, [selectionList, usersList, isEditingRecipients]);

  const [result, loading] = useRemote(fetchCCRecipients);

  const { ccEmails, ccEmailsCount } = result || {};
  return <UsersList users={ccEmails} count={ccEmailsCount} loading={loading} />;
};

const fieldsToOmit = [
  'include_descendants',
  'descendants_ids',
  'recipients',
  'cc_recipients',
  'bcc_recipients',
  'contact_selections',
  'cc_contact_selections',
  'bcc_contact_selections',
  'assetList',
  'asset_selections',
  'product_selections',
  'productList',
];

export function removeInternalAttributes<T extends McGenericRecommendationAsset | McGenericRecommendationProduct>(
  values: ICreateWorkFlowPayload<T>,
): T {
  return omit<T>(values, ...fieldsToOmit) as T;
}

export const prepareAssetList = async (
  values: Pick<ICreateWorkFlowPayload<McGenericRecommendationAsset>, 'asset_selections' | 'asset_ids' | 'assetList'>,
): Promise<Pick<McGenericRecommendationAsset, 'asset_ids'>> => {
  const assetFilters = getRecommendationAssetsFilters();
  const selectionAssets = values.asset_selections.length
    ? await loadAssetSelectionItems(
        undefined,
        values.asset_selections.map((selection) => selection.id),
        assetFilters,
      )
    : [];

  const selectionAssetsIds = selectionAssets.map((asset) => asset.id);
  const allAssetIds = [...values.asset_ids, ...selectionAssetsIds] as number[];
  const asset_ids = uniq(allAssetIds);
  return { asset_ids };
};

interface IParcedProducts {
  productList: string[];
  product_selections: ISelection[];
  product_ids: number[];
}
export const parseProducts = (options: IFormMultiSelectOption[]): IParcedProducts => {
  return options.reduce(
    (acc: IParcedProducts, item) => {
      const { value } = item;
      if (value && item.itemType === 'group') {
        return {
          ...acc,
          productList: [value.toString(), ...acc.productList],
          product_selections: [item as ISelection, ...acc.product_selections],
        };
      }
      return {
        ...acc,
        productList: [value.toString(), ...acc.productList],
        product_ids: [Number(value), ...acc.product_ids],
      };
    },
    { productList: [], product_selections: [], product_ids: [] },
  );
};

export const prepareProductList = async (
  values: ICreateWorkFlowPayload<McGenericRecommendationProduct>,
): Promise<Pick<McGenericRecommendationProduct, 'product_ids'>> => {
  const selectionproducts = values.product_selections.length
    ? await loadProductSelectionItems(
        undefined,
        values.product_selections.map((selection) => selection.id),
      )
    : [];

  const selectionproductsIds = selectionproducts.map((product) => product.id);
  const allProductIds = [...(values?.product_ids || []), ...selectionproductsIds];
  const product_ids = uniq(allProductIds);
  return { product_ids };
};
