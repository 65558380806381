import { IGenericMm3Asset, IAsset, IAssetPayload, IMm3AssetPayload, IDeepPartial } from 'types';
import { IUploadedFileById } from 'pages/assets-upload/api';

export const prepareAssetCreationPayload = (
  filesRemoteById: IUploadedFileById,
  id: string,
  file: IAssetPayload & { division_ids?: number[] | null },
): { asset: Partial<IAsset> } => {
  const data = {
    asset: {
      access_level: file.access_level,
      division_ids: file.division_ids,
      status: file.status,
      file_size: filesRemoteById[id].size,
      file_name: decodeURIComponent(file.file_name),
      watermark: file.watermark,
      type: file.classification,
      language_ids: file.languages,
      classification: file.classification,
      permissions: file.permissions,
      protection_levels: file.protection_levels,
      sharable: file.sharable,
      burned_in_subtitles_language_id: file.burned_in_subtitles_language_id,
      dubbed_language_id: file.dubbed_language_id,
    },
    layer: {
      ...file.layer,
      classification: file.classification,
      language_id: 'en',
      name: file.name,
    },
    src_url: filesRemoteById[id].uploadURL,
  };

  return data;
};

export const prepareMm3AssetCreationPayload = (
  filesRemoteById: IUploadedFileById,
  id: string,
  file: IMm3AssetPayload & { division_ids?: number[] | null },
): IDeepPartial<IGenericMm3Asset & { source_url?: string }> => {
  return {
    access_level: file.access_level,
    status: file.status,
    file_size: filesRemoteById[id].size,
    classification: file.classification as IGenericMm3Asset['classification'],
    permissions: file.permissions,
    protection_levels: file.protection_levels,
    sharable: file.sharable,
    name: file.name,
    parent_id: file.parent_id,
    source_url: filesRemoteById[id].uploadURL,
    division_ids: file.division_ids,
    meta: {
      ...file.layer,
      ...file['meta'],
      dubbed_language_id: file.dubbed_language_id,
      file_name: decodeURIComponent(file.file_name),
    },
  };
};
