import React, { useCallback } from 'react';
import cx from 'classnames';
import { IconName } from 'blueprint5-icons';
import { ButtonGroup, Button, ActionIcon, Popover, Menu, Divider } from '@mantine/core';
import { AsyncSliderSelect } from 'components/slider-select/slider-select';
import { FileStatus } from 'components/file-status';
import { SortButton } from 'components/sort-buttons';
import { getAccessLevelOptions } from 'utils/access-level';
import useSelectedOption from 'utils/hooks/selected-option';
import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';
import { Intent } from 'utils/ui';
import { Download, Edit, Envelope, FloppyDisk, More, Phone, Trash, Undo, LabTest } from 'blueprint5-icons';
import { IOption } from 'types';

import './style.scss';

export type IActionType = 'save' | 'cancel' | 'edit' | 'download' | 'email' | 'phone' | 'delete' | 'more';

const actionIconMap: Record<IActionType, JSX.Element> = {
  save: <FloppyDisk />,
  edit: <Edit />,
  download: <Download />,
  cancel: <Undo />,
  email: <Envelope />,
  phone: <Phone />,
  delete: <Trash />,
  more: <More />,
};

export interface ISectionHeaderAction {
  icon?: IconName;
  intent?: Intent;
  type?: IActionType;
  title?: string;
  handler?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  submenu?: (IconName | ISectionHeaderAction | JSX.Element)[];
  divider?: boolean;
  disabled?: boolean;
}

export interface ISectionHeaderProps {
  className?: string;
  title?: string;
  subtitle?: string | JSX.Element;
  accessLevel?: string;
  fileStatus?: string;
  onAccessLevelChange?: (newAccessLevel: string) => Promise<boolean>;
  actions?: (IconName | ISectionHeaderAction)[];
  dropdownMenu?: JSX.Element;
  useBackground?: true;
  useBorder?: boolean;
  onActionClick?: (icon: IconName) => void;
  customLeftRenderer?: () => JSX.Element;
  customRightRenderer?: () => JSX.Element;
  setIsSortModeEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  isSortModeEnabled?: boolean;
  horizontalTitle?: boolean;
  transparent?: boolean;
  hideSortButton?: boolean;
  hideDivisionAccessLevel?: boolean;
}

const LeftSection: React.FC<ISectionHeaderProps> = (props) => {
  const { title, subtitle, customLeftRenderer, horizontalTitle } = props;

  if (customLeftRenderer) {
    return customLeftRenderer();
  }

  return (
    <>
      {title && <h3 className={'section-header__title text-truncate'}>{title}</h3>}
      {subtitle && (
        <span className={cx(Classes.TEXT_MUTED, 'section-header__subtitle', { 'px-2': horizontalTitle })}>
          {subtitle}
        </span>
      )}
    </>
  );
};

const getActionIcon = (action: ISectionHeaderAction): JSX.Element => {
  if (action.type && actionIconMap[action.type]) {
    return actionIconMap[action.type];
  }

  if (action.icon) {
    return <MantineIcon icon={action.icon} />;
  }

  return <MantineIcon icon={<LabTest />} />;
};

const renderMenuItem = (action: ISectionHeaderAction, index: number): JSX.Element => {
  if (action.divider) {
    return <Divider key={index} />;
  }

  if (React.isValidElement(action)) {
    return action;
  }

  const icon = getActionIcon(action);
  return (
    <Menu>
      <Menu.Item
        key={`${icon}_${index}`}
        variant={action.intent}
        onClick={action.handler}
        leftSection={<MantineIcon icon={action.icon} />}
      >
        {action.title}
      </Menu.Item>
    </Menu>
  );
};

export const RightSection: React.FC<ISectionHeaderProps & { accessLevelOptions?: IOption[] }> = (props) => {
  const {
    actions,
    dropdownMenu,
    customRightRenderer,
    onActionClick,
    accessLevel,
    onAccessLevelChange,
    fileStatus,
    isSortModeEnabled,
    setIsSortModeEnabled,
    hideSortButton = false,
    accessLevelOptions,
  } = props;

  const handleActionClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      const icon = e.currentTarget.getAttribute('data-icon') as IconName;
      onActionClick?.(icon);
    },
    [onActionClick],
  );

  const renderAction = (action: IconName | ISectionHeaderAction, index: number): JSX.Element => {
    if (!action) {
      return <></>;
    }

    if (typeof action === 'string') {
      return (
        <Button size="xs" key={action} className="ms-2" data-icon={action} onClick={handleActionClick}>
          <MantineIcon icon={action} />
        </Button>
      );
    }

    if (action.divider) {
      return <Divider key={index} />;
    }

    const { handler, disabled = false } = action;
    const icon = getActionIcon(action);

    if (Array.isArray(action.submenu)) {
      const submenuDropdown = <Menu>{action.submenu?.map(renderMenuItem)}</Menu>;

      const keyPrefix = action.icon || action.title || action.type;

      return (
        <Popover key={`${keyPrefix}_popover`} position="bottom" withinPortal withArrow>
          <Popover.Target>
            <ActionIcon
              data-icon={icon}
              variant="subtle"
              color="gray.5"
              radius="sm"
              key={`${keyPrefix}_button`}
              className="ms-2"
            >
              <MantineIcon icon={icon} />
            </ActionIcon>
          </Popover.Target>
          <Popover.Dropdown style={{ border: 'none' }}>{submenuDropdown}</Popover.Dropdown>
        </Popover>
      );
    }

    return (
      <ActionIcon
        variant="subtle"
        key={index}
        className="ms-2"
        color="gray.5"
        onClick={handler}
        disabled={disabled || isSortModeEnabled}
      >
        <MantineIcon icon={icon} />
      </ActionIcon>
    );
  };

  if (customRightRenderer) {
    return customRightRenderer();
  }

  return (
    <div className="section-header__right">
      <FileStatus size={15} status={fileStatus} />
      {setIsSortModeEnabled && !hideSortButton && (
        <SortButton isSortModeEnabled={Boolean(isSortModeEnabled)} setIsSortModeEnabled={setIsSortModeEnabled} />
      )}
      {accessLevel && onAccessLevelChange && (
        <>
          <AsyncSliderSelect
            className={'section-header__status-select'}
            options={accessLevelOptions || []}
            onChange={onAccessLevelChange}
            value={accessLevel}
            disabled={isSortModeEnabled}
          />
          <Divider orientation="vertical" mx={10} />
        </>
      )}
      <>
        <ButtonGroup variant="subtle">
          {actions?.map(renderAction)}
          {!isSortModeEnabled && Boolean(dropdownMenu) && dropdownMenu}
        </ButtonGroup>
      </>
    </div>
  );
};

const SectionHeader: React.FC<ISectionHeaderProps> = (props) => {
  const {
    className,
    useBackground,
    useBorder,
    accessLevel,
    horizontalTitle,
    transparent,
    hideDivisionAccessLevel = true,
  } = props;
  const accessLevelOptions = getAccessLevelOptions(hideDivisionAccessLevel);
  const selectedOption = useSelectedOption({ value: accessLevel, options: accessLevelOptions });

  return (
    <div
      className={cx('section-header', className, {
        'section-header--use-background': useBackground,
        'section-header--use-border': useBorder,
        'section-header--transparent': transparent,
        [`section-header--${selectedOption?.color}`]: Boolean(selectedOption),
      })}
    >
      <div className={cx('section-header__left position-relative', { 'd-inline-flex': horizontalTitle })}>
        <LeftSection {...props} />
      </div>
      <div className="section-header__right">
        <RightSection {...props} accessLevelOptions={accessLevelOptions} />
      </div>
    </div>
  );
};

export default SectionHeader;
