import React, { useCallback, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RenderTreeNodePayload } from '@mantine/core/lib/components/Tree/Tree';
import { useTree } from '@mantine/core';

import { useDataSectionStore } from 'store/hooks';

import DivisionDataSectionItems from './division-data-section-items-list';
import { DivisionPreviewTab } from './division-data-section-preview';
import { DivisionDataSectionItem } from 'components/divisions/division-data-section-item/division-data-section-item';

import { divisionListSchema } from 'utils/schemas';
import useControlDataSection from 'utils/hooks/control-data-section';
import { IUseActionsContext, IUseActionsOptionParam, Pages } from 'utils/actions';
import { useRefreshDataSection } from 'utils/hooks';
import { Model } from 'helpers/filters/types';
import { IActionName } from 'utils/actions/types';
import { IDivisionActionName } from 'utils/actions/division/types';
import { getDivisionsTree } from './utils';
import { chipmunk } from 'utils/chipmunk';

import { IDivision, IDivisionTreeItem } from 'types';

import './style.scss';

const DivisionsDataSection: React.FC = observer(() => {
  const refresh = useRefreshDataSection();

  const params = useMemo(
    () => ({
      model: Model.DIVISIONS,
      schema: divisionListSchema,
      stats: '',
      executor: (opts) =>
        chipmunk.unfurl(Model.DIVISIONS, 'search', {
          schema: divisionListSchema,
          ...opts,
        }),
    }),
    [],
  );

  useControlDataSection(params);

  const { updateStore, searchStore, active } = useDataSectionStore<IDivision>();
  const { objects: storeObjects = [], running = true } = searchStore || {};
  const divisionsTree = useMemo(() => getDivisionsTree(storeObjects), [storeObjects]);
  const tree = useTree();

  useEffect(() => {
    if (active || running) return;
    const firstItem = divisionsTree?.[0];
    firstItem && updateStore({ active: firstItem.id });
  }, [active, running, divisionsTree, updateStore]);

  const handleItemSelect = useCallback(
    async ({ currentTarget }) => {
      const selectedId = currentTarget.dataset.id;
      updateStore({ active: parseInt(selectedId) });
    },
    [updateStore],
  );

  const onSuccess = useCallback(
    async (action?: IActionName, updatedDivision?: IDivision | IDivision[]): Promise<void> => {
      if (!searchStore) {
        return;
      }
      switch (action) {
        case IDivisionActionName.EDIT:
          const newDivision = updatedDivision as IDivision;
          const updated = storeObjects.map((object) => {
            if (object.id === newDivision?.id) {
              return { ...object, ...updatedDivision };
            }
            return object;
          });
          searchStore?.update({ objects: updated });
          break;
        case IDivisionActionName.DELETE:
          const deletedDiv = updatedDivision?.[0] as IDivision;
          const updatedObjects = storeObjects.filter((object) => object.id !== deletedDiv?.id);
          searchStore?.update({ objects: updatedObjects });
          deletedDiv?.id === active && updateStore({ active: deletedDiv.parent_id || null });
          break;
        case IDivisionActionName.ADD_SUB_DIVISION:
          const newSubGenre = updatedDivision as IDivision;
          newSubGenre?.parent_id && updateStore({ active: newSubGenre.parent_id });
          searchStore?.update({ objects: [...searchStore.objects, newSubGenre] });
          newSubGenre?.parent_id && tree.expand(newSubGenre?.parent_id.toString());
          break;
        default:
          refresh();
          break;
      }
    },
    [active, refresh, searchStore, storeObjects, tree, updateStore],
  );

  const handleItemRenderer = useCallback(
    ({ node, expanded, hasChildren, elementProps }: RenderTreeNodePayload): React.ReactElement => {
      const options: IUseActionsOptionParam<IDivision> = {
        context: 'single' as IUseActionsContext,
        page: Pages.DETAILS,
        onSuccess,
        parentId: (node as unknown as IDivision).id,
      };
      return (
        <DivisionDataSectionItem
          key={node.value}
          division={node as unknown as IDivisionTreeItem}
          handleClick={handleItemSelect}
          options={options}
          expanded={expanded}
          hasChildren={hasChildren}
          elementProps={elementProps}
        />
      );
    },
    [handleItemSelect, onSuccess],
  );

  return (
    <div className="data-section division-data-section__container">
      <DivisionDataSectionItems
        title="Divisions"
        className="division__data-section"
        itemRenderer={handleItemRenderer}
        divisionsTree={divisionsTree}
        tree={tree}
      />
      <DivisionPreviewTab onSuccess={onSuccess} divisionsTree={divisionsTree} />
    </div>
  );
});

export default DivisionsDataSection;
