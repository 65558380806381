import { UmGenericListItem, UmGenericListShowcase, UmGenericListCollection } from '@mediafellows/mm3-types';
import { flags } from 'utils/flags';

import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';
import { useRemoveFromListAction } from 'utils/actions/list-item';

import { useAddItemsToShowcaseAction } from './add-items';

import { useDeleteAction } from './delete';
import { useEditShowcaseAction } from './edit';
import { useUploadPreviewAction } from './upload-preview';
import { useDeletePreviewAction } from './delete-preview-action';

import { IShowcaseActionConfig, IShowcaseActionName } from './types';
import { useManageSliderShowcase } from './manage-slider-showcase';

const { showAutonomousShowcaseButtons } = flags;

export const useShowcaseActions = (
  entities: ((UmGenericListShowcase | UmGenericListCollection) & { listItem?: UmGenericListItem })[] = [],
  options: IUseActionsOptionParam<(UmGenericListShowcase | UmGenericListCollection) & { listItem?: UmGenericListItem }>,
): IUseActionsResponse<IShowcaseActionName> => {
  const deleteAction = useDeleteAction(entities, options);
  const addItems = useAddItemsToShowcaseAction(entities, options);
  const editAction = useEditShowcaseAction(entities as UmGenericListShowcase[], options);
  const uploadPreview = useUploadPreviewAction(entities, options);
  const deletePreview = useDeletePreviewAction(entities, options);
  const generateShowcase = useManageSliderShowcase(entities, options);
  const removeFromCollection = useRemoveFromListAction(
    entities.map((e) => e.listItem as UmGenericListItem),
    options as unknown as IUseActionsOptionParam<UmGenericListItem>,
  ) as unknown as IShowcaseActionConfig;
  const actions = [
    removeFromCollection,
    addItems,
    editAction,
    ...(showAutonomousShowcaseButtons ? [generateShowcase] : []),
    uploadPreview,
    deletePreview,
    deleteAction,
  ];

  return useActions(actions, options, entities);
};
