import React from 'react';
import cx from 'classnames';
import { CloseButton } from '@mantine/core';

import { Thumbnail } from 'components/thumbnail';

import useSelectedOption from 'utils/hooks/selected-option';
import { AmGenericNews } from '@mediafellows/mm3-types';
import { dateWithoutHours, formatDate } from 'utils/date';
import { Classes } from 'utils/ui';
import { IFormMultiSelectOption } from 'helpers/form';

interface INewsSearchItem {
  news: AmGenericNews;
  handleSelect?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onRemove?: (item: IFormMultiSelectOption) => void;
}

const NewsSearchItem: React.FC<INewsSearchItem> = ({ news, handleSelect, onRemove }) => {
  const { access_level, title, date, id, preview_image } = news;
  const selectedOption = useSelectedOption({ value: access_level });

  return (
    <div
      key={`news-${id}`}
      className={cx('entity-search-list-item', {
        [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
      })}
    >
      <div
        className={cx(`searchbox__container ${Classes.MENU_ITEM}`, { 'searchbox__with-delete': onRemove })}
        data-id={id}
        data-item-type="news"
        onClick={handleSelect}
      >
        <div className="searchbox__thumb">
          <Thumbnail image={preview_image?.url} customTag="news" showType />
        </div>
        <div>
          <div className="text-truncate">{title}</div>
          <div className={Classes.TEXT_MUTED}>{formatDate(date, dateWithoutHours)}</div>
        </div>
        {onRemove && <CloseButton onClick={() => onRemove(news as IFormMultiSelectOption)} />}
      </div>
    </div>
  );
};

export { NewsSearchItem };
