import React from 'react';

import { FormAccessLevel, FormDate, FormInput } from 'helpers/form';
import { ISmartGroupFormComponent } from 'types/smart-group';
import { maxAccessDate } from 'utils/constants';

export const keysGeneral = ['name', 'access_level', 'expires_at'];

export const SmartGroupFormGeneral: React.FC<ISmartGroupFormComponent> = ({ form }) => {
  return (
    <div className="d-flex flex-column">
      <FormInput name="name" label="Name" {...form.formData.name} {...form.handlers} />
      <div className="d-grid gap-2 w-100" style={{ gridTemplateColumns: '1fr 1fr' }}>
        <FormAccessLevel name="access_level" label="Access Level" {...form.formData.access_level} {...form.handlers} />
        <FormDate
          name="expires_at"
          label="Smart Group expires at"
          placeholder="Select date"
          minDate={new Date()}
          maxDate={maxAccessDate}
          withTime
          large
          {...form.formData.expires_at}
          {...form.handlers}
        />
      </div>
    </div>
  );
};
