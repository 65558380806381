import React from 'react';
import cx from 'classnames';
import { CloseButton } from '@mantine/core';

import { OrganizationLogo } from 'components/organization/organization-logo';

import { formatField, pluralEntityInflect } from 'utils/general';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { Classes } from 'utils/ui';
import { IFormMultiSelectOption } from 'helpers/form';

import { IOrganization, IStyled } from 'types';

interface IOrganizationSearchItem extends IStyled {
  organization: IOrganization;
  handleSelect?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onRemove?: (item: IFormMultiSelectOption) => void;
}

export const OrganizationSearchItem: React.FC<IOrganizationSearchItem> = ({
  organization,
  handleSelect,
  className,
  onRemove,
}) => {
  const { name, classification, id, '@type': type, phones, users_count } = organization;
  const { entityWithCount } = pluralEntityInflect('Contact', users_count || 0);
  const isDeleted = isEntityDeleted(organization);

  return (
    <li key={`organization-${id}`}>
      <div
        data-id={id}
        data-item-type={type}
        className={cx(`searchbox__organization ${Classes.MENU_ITEM}`, {
          'searchbox__entity--deleted': isDeleted,
          'searchbox__with-delete': onRemove,
          className,
        })}
        onClick={handleSelect}
      >
        <div className="searchbox__avatar">
          <OrganizationLogo organization={organization} size={onRemove ? 'xs' : 'small'} />
        </div>
        <div>
          <div className="text-truncate">{name}</div>
          <div className={`${Classes.TEXT_MUTED} text-truncate`}>{entityWithCount}</div>
          <div className={cx('text-truncate', Classes.TEXT_MUTED)}>
            {formatField([classification, phones?.[0]?.number].filter(Boolean), '-', ' | ')}
          </div>
        </div>
        {onRemove && <CloseButton onClick={() => onRemove(organization as IFormMultiSelectOption)} />}
      </div>
    </li>
  );
};
