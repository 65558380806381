export const divisionListSchema = `
id,
name,
owner_id,
ancestry,
owner_organization_id,
parent_id,
updated_at,
users_count
`;
