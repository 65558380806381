import { IIdentifiable, ISmartGroup } from 'types';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions';

export enum ISmartGroupActionName {
  COPY = 'copy',
  EDIT = 'edit',
  DELETE = 'delete',
  RESTORE = 'restore',
}

export type ISmartGroupActionConfig = IActionConfig<IIdentifiable, ISmartGroupActionName>;

export type IShouldDisplaySmartGroupActionFunction = IShouldDisplayActionFunction<ISmartGroup, ISmartGroupActionName>;
