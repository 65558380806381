import { merge } from 'lodash';
import { IMm3Asset } from 'types';
import { getRequiredError, IValidationResult } from 'helpers/form/mm3';

export const customValidator = (
  values: IMm3Asset,
  validation: IValidationResult<IMm3Asset>,
): IValidationResult<IMm3Asset> => {
  const classificationValidation = !values?.classification
    ? {
        classification: { required: true, ...getRequiredError() },
      }
    : { classification: { required: true } };

  return merge({}, validation, classificationValidation) as IValidationResult<IMm3Asset>;
};
