import React, { useCallback, useEffect, useMemo } from 'react';
import { Flex, Switch } from '@mantine/core';
import { IFormMultiSelectOption, FormMultiSelect, formatFormLabel } from 'helpers/form';
import {
  fetchRecipients,
  validateEmail,
  parseCCList,
  parseRecipients,
  isExternalUser,
} from 'components/recommendation/recommendation-shared/utils';
import { IRecommendProductsStep } from 'components/recommendation/recommend-products/types';
import { IContact, IGroup, IQueryParams, ISelection } from 'types';

export const RecommendProductsStepRecipients: React.FC<IRecommendProductsStep> = ({
  form: { formData, handlers, values },
}) => {
  const { onChange } = handlers;

  const setRecipients = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      const { recipient_list, recipients, contact_selections } = parseRecipients(options);
      onChange({ recipient_list, recipients, contact_selections });
    },
    [onChange],
  );

  const setCCList = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      const cc_list = parseCCList(options);
      const cc_contact_selections = options.filter((e) => e.itemType === 'group') as ISelection[];

      onChange({ cc_list, cc_contact_selections });
    },
    [onChange],
  );

  const setBCCList = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      const bcc_list = parseCCList(options);
      const bcc_contact_selections = options.filter((e) => e.itemType === 'group') as ISelection[];

      onChange({ bcc_list, bcc_contact_selections });
    },
    [onChange],
  );

  const handleToggle = useCallback(
    (field: 'requires_login') => () => {
      handlers.onChange({ [field]: !values[field] });
    },
    [handlers, values],
  );

  const hasExternalRecipients = values?.recipients.some(isExternalUser);
  useEffect(() => {
    if (!hasExternalRecipients) {
      return;
    }

    handlers.onChange({ requires_login: false });
  }, [handlers, hasExternalRecipients]);

  const fetchUsers = useCallback(({ ids, q }: IQueryParams): Promise<(string | IContact | IGroup)[]> => {
    return fetchRecipients({ ids, q });
  }, []);

  const RecipientsLabel = useMemo(() => {
    return (
      <Flex gap="sm" className="w-100" align="center">
        <span>{formatFormLabel('Recipients', formData.recipients.required)}</span>

        <Switch
          size="md"
          label="Require Login"
          data-param="requires_login"
          onChange={handleToggle('requires_login')}
          checked={Boolean(values.requires_login)}
          disabled={hasExternalRecipients}
        />
      </Flex>
    );
  }, [formData.recipients.required, handleToggle, hasExternalRecipients, values.requires_login]);

  return (
    <div className="recommend-products-wizard__recipients-wrapper">
      <div className="recommend-products-wizard__recipients-container">
        <FormMultiSelect
          fetchValues={fetchUsers}
          fitInParent
          name="recipients"
          omni
          allowNewItems
          onSelectedItemsChange={setRecipients}
          validateNewItem={validateEmail}
          required
          {...formData.recipients}
          onBlur={handlers.onBlur}
          className="recommend-products-wizard-recipients-field"
          placeholder="Add Recipients"
          labelComponent={RecipientsLabel}
        />

        <FormMultiSelect
          fitInParent
          fetchValues={fetchUsers}
          name="cc_recipients"
          label="CC List"
          rawLabel
          omni
          allowNewItems
          onSelectedItemsChange={setCCList}
          validateNewItem={validateEmail}
          {...formData.cc_recipients}
          {...handlers}
        />
        <FormMultiSelect
          fetchValues={fetchRecipients}
          fitInParent
          name="bcc_recipients"
          label="BCC List"
          rawLabel
          omni
          allowNewItems
          onSelectedItemsChange={setBCCList}
          validateNewItem={validateEmail}
          {...formData.bcc_recipients}
          {...handlers}
        />
      </div>
    </div>
  );
};
