import React, { useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { useDataSectionStore } from 'store/hooks';

import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { ActionsMenu } from 'components/action/utils';
import { Loading } from 'components/loading';
import { PreviewInfo } from 'components/preview-tab';
import { Row } from 'components/label-value-pair';
import { EmptySectionMessage } from 'components/section-message';
import { DivisionActionsList } from 'components/action';

import { IUseActionsContext, Pages } from 'utils/actions';
import { useEditDivisionAction } from 'utils/actions/division/edit';
import { findNodeById } from './utils';
import { formatDate } from 'utils/date';
import { Routes } from 'utils/routes';

import { IActionName } from 'utils/actions/types';
import { IDivisionTreeItem, IDivision } from 'types';

export const DivisionPreviewTab: React.FC<{
  onSuccess: (action?: IActionName, division?: IDivision) => void;
  divisionsTree: IDivisionTreeItem[];
}> = observer(({ onSuccess, divisionsTree }) => {
  const { searchStore, activeItem } = useDataSectionStore<IDivision>();
  const { running = true } = searchStore || {};

  const activeTreeItem = useMemo(
    () => (activeItem ? findNodeById(divisionsTree, activeItem?.id) : divisionsTree?.[0]),
    [activeItem, divisionsTree],
  );
  const activeDivision = useMemo(() => activeTreeItem || activeItem, [activeTreeItem, activeItem]);

  const { name, id, created_at, updated_at, users_count } = activeDivision || {};

  const options = useMemo(
    () => ({
      context: 'single' as IUseActionsContext,
      page: Pages.DETAILS,
      onSuccess,
      parentId: activeDivision?.id,
    }),
    [activeDivision?.id, onSuccess],
  );
  const actions: ISectionHeaderAction[] = [
    {
      type: 'edit',
      handler: useEditDivisionAction(activeDivision ? [activeDivision] : [], options).handler,
      disabled: !id,
    },
  ];
  const { clearOldFilters } = useDataSectionStore();
  const onClick = useCallback(() => {
    clearOldFilters(Routes.CONTACTS);
  }, [clearOldFilters]);

  if (!activeDivision) return <EmptySectionMessage />;
  return (
    <div className="division-data-section-preview">
      <SectionHeader
        useBorder
        title={name}
        actions={actions}
        useBackground
        dropdownMenu={<ActionsMenu component={DivisionActionsList} items={[activeDivision]} options={options} />}
      />
      {running ? (
        <Loading text="Loading Data" />
      ) : (
        <>
          <PreviewInfo gridTemplateColumns="170px 1fr">
            <Row
              label="Sub-divisions"
              rawLabel
              value={(activeDivision as IDivisionTreeItem).children?.length || 0}
              size="m"
            />
            <Row
              label="Contacts"
              rawLabel
              value={
                !users_count ? (
                  users_count
                ) : (
                  <Link onClick={onClick} to={`${Routes.CONTACTS}?division_ids=${activeDivision.id}`}>
                    {users_count}
                  </Link>
                )
              }
              size="m"
              hideIfNoValue
            />
            <Row rawLabel label="Created at" value={formatDate(created_at)} />
            <Row rawLabel label="Updated at" value={formatDate(updated_at)} />
          </PreviewInfo>
        </>
      )}
    </div>
  );
});

export default React.memo(DivisionPreviewTab);
