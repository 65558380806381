import React from 'react';
import { observer } from 'mobx-react-lite';

import { IFiltersHandlers } from 'helpers/filters';
import { DataSectionStore } from 'store/data-section-store';
import { IProduct } from 'types';
import { IFilters } from 'components/product-filters/types';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { SharedProductFilters } from 'components/product-filters/shared-product-filters';

import './styles.scss';

export const SmartGroupProductFilters: React.FC<{
  dataSectionStore: DataSectionStore<IProduct>;
  filterValues: IFilters;
  filterHandlers: IFiltersHandlers<IFilters>;
}> = observer(({ dataSectionStore, filterValues, filterHandlers }) => {
  return (
    <FormSectionWrapperV2 className="smart-group-wizard__filters-tab">
      <SharedProductFilters
        dataSectionStore={dataSectionStore}
        filterHandlers={filterHandlers}
        filterValues={filterValues}
      />
    </FormSectionWrapperV2>
  );
});
