import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import map from 'lodash/map';

import { SelectionListItemActionsList } from 'components/action';
import { DataSection } from 'components/data-section';
import { SelectionsPreviewTab } from 'components/selection/selection-preview-tab';
import { SelectionsSelectedTabItem } from 'components/selection/selection-selected-tab-item';
import mapSelection from 'components/selection/map-selection';
import { AssetSelectionDataSectionListItem } from 'components/selection/asset-selection-data-section-list-item/asset-selection-data-section-list-item';
import SelectionListItem from 'components/selection/default-selection-list-item/selection-list-item';
import { defaultSelectionFilters, SelectionsFilters } from 'components/selections-filters';

import { useStore } from 'store';
import { Pages } from 'utils/actions/types';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';
import { Model } from 'helpers/filters/types';
import { GroupTypes, ISelection, ISelectionStandardized } from 'types';

import { useRefreshDataSection, useAssetActionsOptions } from 'utils/hooks';
import { SelectionSchema } from 'utils/schemas';
import { DataSectionPage } from 'components/layout';
import { ActionsMenu } from 'components/action/utils';

import './style.scss';

export const mapAssetSelection = (assetSelection: ISelection): ISelectionStandardized =>
  mapSelection(assetSelection, assetSelection?.am_statistics_data?.count);

const sidebarTabs = {
  preview: <SelectionsPreviewTab selectionMapper={mapAssetSelection} />,
  filters: <SelectionsFilters />,
  selectedItemRenderer(assetSelection: ISelection) {
    return <SelectionsSelectedTabItem selection={mapAssetSelection(assetSelection)} />;
  },
};

const assetsDataFilters: IFilterOption[] = [
  ['type', 'eq', GroupTypes.SELECTION],
  ['main_entity_type', 'eq', 'assets'],
];

const page = Pages.SELECTION_LIST;

const AssetSelections: React.FC<{}> = observer(() => {
  const {
    dataSectionStore: { checked },
  } = useStore();

  useControlDataSection({
    persistFilters: true,
    schema: SelectionSchema,
    model: Model.GROUPS,
    defaultFilters: defaultSelectionFilters,
    filters: assetsDataFilters,
    stats: 'access_level',
    presetSuffix: 'asset-selections',
  });

  const mapSelectionsArray = useCallback((selections) => map(selections, (s) => mapAssetSelection(s)), []);

  const onSuccess = useRefreshDataSection();

  const customAssetActionOption = useAssetActionsOptions();
  const handleItemRenderer = useCallback(
    (assetSelectionData: ISelection): JSX.Element => {
      const selection = mapAssetSelection(assetSelectionData);

      return (
        <SelectionListItem
          key={selection.id}
          selection={selection as ISelectionStandardized}
          contextMenu={
            <ActionsMenu
              component={SelectionListItemActionsList}
              items={[selection]}
              options={{ ...customAssetActionOption, context: 'single', page, onSuccess }}
            />
          }
          component={AssetSelectionDataSectionListItem}
        />
      );
    },
    [customAssetActionOption, onSuccess],
  );

  return (
    <DataSectionPage>
      <DataSection
        className="asset-selection__data-section"
        itemRenderer={handleItemRenderer}
        contextMenu={
          <ActionsMenu
            component={SelectionListItemActionsList}
            items={mapSelectionsArray(checked)}
            options={{ ...customAssetActionOption, context: 'multi', page, onSuccess }}
          />
        }
        tabs={sidebarTabs}
        showListTileViewToggleButton={false}
      />
    </DataSectionPage>
  );
});

export default AssetSelections;
