import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Checkbox } from '@mantine/core';
import { AmGenericAsset3DigitalSubtitle } from '@mediafellows/mm3-types';

import { Routes } from 'utils/routes';
import { Thumbnail } from 'components/thumbnail';
import { IAssetDataSectionWrappedComponent } from './types';

import { isEntityDeleted } from 'utils/is-entity-deleted';
import useSelectedOption from 'utils/hooks/selected-option';
import { ScheduledChangeIndicator } from 'components/common/scheduled-change-info';
import { FileStatus } from 'components/file-status';
import { ThumbnailFileStatus } from 'components/file-status';
import { MarketingAssetStar } from './marketing-asset-star';
import { getIngestStatus, getMainType, getPreviewImageUrl } from 'utils/asset';
import { AssetListItemAttributes } from 'components/asset/asset-data-section-item/asset-list-attributes';
import { AssetStatusIndicator } from 'components/asset';
import { IMm3Asset } from 'types';
import { AssetStatuses } from 'types';
import { ParentBreadcrumbs } from 'components/parent-breadcrumb';

export const AssetDataSectionListItem: React.FC<IAssetDataSectionWrappedComponent> = ({
  asset,
  contextMenu,
  handleClick,
  isAllItemsSelected,
  isChecked,
  isActive,
  page,
  disableMarketingStar,
  setProductAssets,
}) => {
  const { name, preview_image, access_level } = asset;
  const type = getMainType(asset);
  const { parent } = asset as AmGenericAsset3DigitalSubtitle;

  const selectedOption = useSelectedOption({ value: access_level, hideDivisionAccessLevel: false });
  const previewImageUrl = getPreviewImageUrl(preview_image);

  return (
    <div
      className={cx('entity-list-item', {
        'entity-list-item--active': isActive,
        'entity-list-item--deleted': isEntityDeleted(asset),
        'entity-list-item--inactive': asset?.status === AssetStatuses.OFFLINE,
      })}
      onClick={handleClick}
    >
      <div className="entity-list-item-row">
        <div
          className={cx('entity-list-item-row__checkbox', {
            [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
          })}
        >
          <Checkbox onChange={handleClick} checked={isChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
        </div>

        <div className="entity-list-item-row__thumb">
          <Thumbnail
            showType
            assetType={type}
            image={previewImageUrl}
            placeholder={<ThumbnailFileStatus status={getIngestStatus(asset)} size={35} small />}
          />
        </div>

        <div className="entity-list-item-row__content">
          <ParentBreadcrumbs title={parent?.name} id={parent?.id} to={`${Routes.ASSETS}/${parent?.id}`} layout="list">
            <AssetStatusIndicator asset={asset} padded />
            <ScheduledChangeIndicator entity={asset} className="mx-1" />
            <FileStatus status={getIngestStatus(asset)} />
            {Boolean(contextMenu) && contextMenu}
          </ParentBreadcrumbs>
          <div className="d-flex align-items-center">
            <MarketingAssetStar
              asset={asset as IMm3Asset & AmGenericAsset3DigitalSubtitle}
              page={page}
              disableMarketingStar={disableMarketingStar}
              setProductAssets={setProductAssets}
            />

            <div className="entity-list-item-row__title" title={name}>
              <Link to={`${Routes.ASSETS}/${asset?.id}`}>
                <h4 className="text-nowrap text-truncate">{name}</h4>
              </Link>
              <div className="d-flex align-items-center"></div>
            </div>
          </div>
          <AssetListItemAttributes asset={asset as IMm3Asset} />
        </div>
      </div>
    </div>
  );
};
