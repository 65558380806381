import { PmItvstudiosLayer3MotionPictureStandard, PmItvstudiosProduct3 } from '@mediafellows/mm3-types';

export const productLayerInitialValues = {
  language_id: 'en',
  meta: {
    bbfc: undefined,
    category_ids: [],
    co_producers: [],
    due_delivery_date: undefined,
    duration: undefined,
    duration_text: undefined,
    global: undefined,
    language_ids: [],
    logline: undefined,
    number_of_seasons_manual: undefined,
    produced_by_external: [],
    producer_organization_ids: [],
    product_state: undefined,
    production_end: undefined,
    production_start: undefined,
    resolutions: [],
    share_link: undefined,
    synopsis: undefined,
    territories_sold_in: [],
    territory_count: undefined,
    tx_date: undefined,
    original_release_date: undefined,
    tx_date_text: undefined,
    year_of_production: undefined,
    language_versions: [],
  },
  title: undefined,
  type: 'Layer3::MotionPicture::Standard',
} as Partial<PmItvstudiosLayer3MotionPictureStandard>;

export const productInitialValues = {
  access_level: 'company',
  external_reference: undefined,
  parent_id: undefined,
  sequence_number: undefined,
  type: undefined,
  meta: {
    slug: undefined,
    ccid: undefined,
    paris_id: undefined,
  },
  permissions: [],
  division_ids: [],
} as Partial<PmItvstudiosProduct3>;
