import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { UmGenericListCollection, UmGenericListShowcase } from '@mediafellows/mm3-types';

import { IListItem } from 'types';
import { SortDataSection } from 'components/sort-data-section';
import { useSortDataSectionStore } from 'store/hooks';
import { ISortableItem } from 'store/sort-data-store';
import { CollectionDataSectionListItem } from 'components/collection';
import { ShowcaseSearchItem } from 'components/showcase-search-item';
import { sortShowcases } from 'utils/apis/showcase';
import noop from 'lodash/noop';
import { delay } from 'utils/delay';

const sortShowcaseItemRenderer = (item: ISortableItem): React.ReactElement => {
  if ((item as UmGenericListCollection).type === 'List::Collection') {
    return <CollectionDataSectionListItem collection={item as unknown as UmGenericListCollection} />;
  }
  return <ShowcaseSearchItem showcase={item as unknown as UmGenericListShowcase} handleSelect={noop} />;
};

export const ShowcaseSortDataSection: React.FC<{
  items?: IListItem[];
  onSuccess?: (lists: ISortableItem[]) => void;
}> = observer(({ items, onSuccess }) => {
  const { initStore } = useSortDataSectionStore();
  const fetcher = useCallback(async (): Promise<ISortableItem[]> => (items || []) as ISortableItem[], [items]);

  const onSave = useCallback(
    async (list) => {
      const lists = await sortShowcases(list);
      await delay(1000);
      onSuccess?.(lists as ISortableItem[]);
    },
    [onSuccess],
  );

  useEffect(() => {
    initStore({ fetcher, onSave });
  }, [fetcher, initStore, onSave]);

  return (
    <SortDataSection
      itemRenderer={sortShowcaseItemRenderer}
      className="h-100 showcase-edit-form__sort-section"
      hideDivisionAccessLevel
    />
  );
});

export default ShowcaseSortDataSection;
