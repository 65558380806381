import React from 'react';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormDate } from 'helpers/form';
import { maxAccessDate } from 'utils/constants';

import type { IUseFormReturn } from 'helpers/form';

import { IAccessPrivilege } from './types';

type IKeys = 'expires_at';

export const keysSettings: IKeys[] = ['expires_at'];

interface IAccessPrivilegeGrantFormSettings {
  form: IUseFormReturn<Pick<IAccessPrivilege, IKeys>>;
  groupName?: string;
}

export const AccessPrivilegeGrantFormSettings: React.FC<IAccessPrivilegeGrantFormSettings> = ({
  form,
  groupName = 'Access privilege',
}) => {
  return (
    <FormSectionWrapperV2 padded={false} gridTemplateColumns={'1fr'}>
      <FormDate
        name="expires_at"
        label={`${groupName} expires at`}
        placeholder="Select date"
        minDate={new Date()}
        maxDate={maxAccessDate}
        withTime
        large
        {...form.formData.expires_at}
        {...form.handlers}
      />
    </FormSectionWrapperV2>
  );
};
