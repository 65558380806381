import { IDivisionTreeItem } from 'types';
import { IActionConfig, IShouldDisplayActionFunction } from 'utils/actions';

export enum IDivisionActionName {
  ADD_SUB_DIVISION = 'add-sub-division',
  EDIT = 'edit',
  DELETE = 'delete',
}

export type IDivisionActionConfig = IActionConfig<IDivisionTreeItem, IDivisionActionName>;

export type IShouldDisplayDivisionActionFunction = IShouldDisplayActionFunction<IDivisionTreeItem, IDivisionActionName>;
