import React from 'react';
import { observer } from 'mobx-react-lite';
import { ActionIcon, Avatar } from '@mantine/core';
import { ChevronDown, ChevronRight } from 'blueprint5-icons';
import { RenderTreeNodePayload } from '@mantine/core/lib/components/Tree/Tree';
import cx from 'classnames';

import { useDataSectionStore } from 'store/hooks';

import { ActionsMenu } from 'components/action/utils';
import { DivisionActionsList } from 'components/action';

import { IUseActionsOptionParam } from 'utils/actions';
import { Classes, MantineIcon } from 'utils/ui';

import { IDivisionTreeItem } from 'types';
import { stringToColour } from 'components/avatar';
import { getTitle } from 'components/avatar/avatar-utils';
import { pluralEntityInflect } from 'utils/general';

import './style.scss';

interface IDivisionDataSectionItemProps extends Partial<RenderTreeNodePayload> {
  division: IDivisionTreeItem;
  handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  options: IUseActionsOptionParam;
}
const noOp = (): null => null;

export const DivisionDataSectionItem: React.FC<IDivisionDataSectionItemProps> = observer(
  ({ division, handleClick, expanded, hasChildren, elementProps, options }) => {
    const { id, name } = division;
    const { getIsActive } = useDataSectionStore();
    const { entityWithCount } = pluralEntityInflect('sub-division', division?.children?.length || 0);
    return (
      <div
        onClick={handleClick}
        data-id={id}
        className={cx('division-list-item', {
          'entity-list-item--active': getIsActive(id),
        })}
      >
        <div className="d-flex gap-2">
          {!expanded && (
            <ActionIcon
              {...elementProps}
              onClick={hasChildren ? elementProps?.onClick : noOp}
              style={{ cursor: hasChildren ? 'pointer' : 'default' }}
            >
              <MantineIcon icon={<ChevronRight color={!hasChildren ? 'transparent' : 'currentColor'} />} />
            </ActionIcon>
          )}
          {expanded && (
            <ActionIcon
              {...elementProps}
              onClick={hasChildren ? elementProps?.onClick : noOp}
              style={{ cursor: hasChildren ? 'pointer' : 'default' }}
            >
              <MantineIcon icon={<ChevronDown color={!hasChildren ? 'transparent' : 'currentColor'} />} />
            </ActionIcon>
          )}
          <div className="avatar d-flex align-items-center">
            <Avatar
              className="avatar__content-size-16 avatar__content avatar__content--image"
              style={{
                background: stringToColour(`${name}`),
              }}
            >
              {getTitle(name)}
            </Avatar>
          </div>
        </div>
        <div className="text-truncate text-nowrap">
          <div className="d-flex justify-content-between selections-list-item__title">
            <div className="text-nowrap text-truncate">{name}</div>
            <ActionsMenu component={DivisionActionsList} items={[division]} options={options} />
          </div>
          <div className={`d-flex gap-5 ${Classes.TEXT_MUTED}`}>
            <span>{entityWithCount}</span>
          </div>
        </div>
      </div>
    );
  },
);
