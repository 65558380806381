import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import { IParametronData } from '@mediafellows/parametron';

import { useControlDataSection, IFilterOption, useRefreshDataSection } from 'utils/hooks';
import { InFilter, Model, QFilter } from 'helpers/filters/types';
import { DataSection, ICustomSortMenuProp } from 'components/data-section';
import {
  CastCrewSelectedItem,
  CastCrewPreview,
  ProductCastCrewFilters,
  CastCrewFilters,
  CastCrewDataSectionItem,
} from 'components/cast-crew';
import { castCrewSchema } from 'utils/schemas/cast-crew';
import { CastCrewActionsList } from 'components/action';
import { useDataSectionStore } from 'store/hooks';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import { ParametronStore } from 'store/parametron-store';
import { IExtendedProductCastCrew, IProduct } from 'types';

import {
  IExtendedCastCrewAttributesProps,
  ICastCrewListItemAttributesProps,
} from 'components/cast-crew/data-section-item';

import { IActionOpts, IResult } from '@mediafellows/chipmunk';
import { IDataSectionDefaultParams } from 'components/data-section/data-section';
import { ActionsMenu } from 'components/action/utils';

const defaultFilters = {
  _: new QFilter(''),
  owner_id: new InFilter('owner_id', []),
  owner_organization_id: new InFilter('owner_organization_id', []),
  kind: new InFilter('kind', []),
};

const params = {
  model: Model.CAST_CREW,
  presetSuffix: 'cast_crew',
  defaultFilters,
  schema: castCrewSchema,
  stats: 'owner_organization_id,owner_id',
};

interface ICastCrewDataSectionProps {
  defaultParams?: IDataSectionDefaultParams;
  customSortMenu?: ICustomSortMenuProp;
  executor?: (opts?: IActionOpts) => Promise<IResult>;
  AttributesCmp?: React.FC<IExtendedCastCrewAttributesProps | ICastCrewListItemAttributesProps>;
  filters?: IFilterOption[];
  headerRightSection?: React.ReactElement;
  handleUpdate?: (data: IParametronData, searchStore: ParametronStore | null) => Promise<void>;
  page?: Pages;
  options?: Partial<IUseActionsOptionParam<PmGenericCastCrew>>;
  persistFilters?: boolean;
}

export const CastCrewDataSection: React.FC<ICastCrewDataSectionProps> = observer(
  ({
    AttributesCmp,
    customSortMenu,
    defaultParams,
    executor,
    filters,
    handleUpdate,
    headerRightSection,
    page = Pages.LIST,
    persistFilters,
    options,
  }) => {
    useControlDataSection({
      params: defaultParams,
      ...params,
      handleUpdate,
      filters,
      executor,
      persistFilters,
    });
    const { checked } = useDataSectionStore<IExtendedProductCastCrew>();
    const onSuccess = useRefreshDataSection();

    const itemRenderer = useCallback(
      (cast: IExtendedProductCastCrew & { products: IProduct[] }): React.ReactElement => {
        return (
          <CastCrewDataSectionItem
            cast={cast}
            key={cast.id}
            AttributesCmp={AttributesCmp}
            contextMenu={
              <ActionsMenu
                component={CastCrewActionsList}
                items={[{ ...cast, id: cast.cast_crew_id || cast.id }]}
                options={{ context: 'single', page, onSuccess, ...options }}
              />
            }
          />
        );
      },
      [AttributesCmp, onSuccess, page, options],
    );
    const sidebarTabs = {
      preview: <CastCrewPreview />,
      filters: page === Pages.PRODUCT_CAST_CREW_TAB ? <ProductCastCrewFilters /> : <CastCrewFilters />,
      selectedItemRenderer(cast: IExtendedProductCastCrew): React.ReactElement {
        return <CastCrewSelectedItem cast={cast} key={cast.id} />;
      },
    };

    return (
      <DataSection
        headerRightSection={headerRightSection}
        tabs={sidebarTabs}
        itemRenderer={itemRenderer}
        showListTileViewToggleButton={false}
        customSortMenu={customSortMenu}
        defaultParams={defaultParams}
        customOrderRightSection
        contextMenu={
          <ActionsMenu
            component={CastCrewActionsList}
            items={checked?.map((e) => ({ ...e, id: e.cast_crew_id || e.id }))}
            options={{ context: 'multi', page, onSuccess, ...options }}
          />
        }
      />
    );
  },
);
