import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';

import { useDeleteAction } from './delete';
import { useEditDivisionAction } from './edit';
import { useAddSubDivisionAction } from './add-sub-division';

import { IDivisionActionConfig, IDivisionActionName } from './types';
import { IDivisionTreeItem } from 'types';

export const useDivisionActions = (
  entities: IDivisionTreeItem[] = [],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IDivisionActionName> => {
  const editAction = useEditDivisionAction(entities, options);
  const deleteAction = useDeleteAction(entities, options);
  const addSubGenre = useAddSubDivisionAction(entities, options);

  const actions = [editAction, addSubGenre, deleteAction];
  return useActions(actions as IDivisionActionConfig[], options, entities);
};
