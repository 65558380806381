import { IOption, IOptionColor } from 'types';
import { IActiveOption } from 'components/slider-select/types';
import { useMemo } from 'react';
import { getAccessLevelOptions } from 'utils/access-level';

export interface IUseSelectedOption {
  options?: IOption[];
  value?: string;
  hideDivisionAccessLevel?: boolean;
}

const getDefaultColor = (index: number): IOptionColor => {
  const colors = Object.values(IOptionColor);
  return colors[index % colors.length];
};

const useSelectedOption = ({
  value,
  options,
  hideDivisionAccessLevel = true,
}: IUseSelectedOption): IActiveOption | undefined => {
  const accessLevelOptions = options ? options : getAccessLevelOptions(hideDivisionAccessLevel);

  const activeOption = useMemo(() => {
    if (!value) {
      return;
    }
    const order = accessLevelOptions.findIndex((option) => option.value === value);
    if (order === -1) {
      return;
    }
    const option = accessLevelOptions[order];
    return {
      ...option,
      color: option.color || getDefaultColor(order),
      order,
    };
  }, [accessLevelOptions, value]);
  return activeOption;
};

export default useSelectedOption;
