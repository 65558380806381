import React, { useCallback } from 'react';

import { useStore } from 'store';

import { IProduct } from 'types';

import { FormSubmitSection } from 'components/form-submit-section';

import { IAccessChangeImmediateForm } from 'utils/actions/asset/';
import { changeProductAccessImmediate } from 'utils/actions/product';
import { IProductActionName } from 'utils/actions/product/types';
import { IUseActionsOptionParam } from 'utils/actions';

import { Model } from 'helpers/filters/types';
import { FormChecklist, FormAccessLevel } from 'helpers/form';
import { useMm3Form } from 'helpers/form/use-mm3-form';
import { ToastError } from 'components/toast';

export const AccessChangeImmediateForm: React.FC<{
  items: IProduct[];
  options: IUseActionsOptionParam;
  showPermissions: boolean;
}> = ({ items, options, showPermissions }) => {
  const formId = `product-access-change-immediate`;
  const { dialogStore, toastStore } = useStore();

  const submitForm = useCallback(
    async (data: IAccessChangeImmediateForm) => {
      try {
        if (!items.every((item) => item.division_ids?.length) && data.access_level === 'division') {
          toastStore.error('Does not belong to any divisions');
          return;
        }
        const updatedProduct = await changeProductAccessImmediate(data);
        options.context === 'single' && options?.onSuccess?.(IProductActionName.CHANGE_ACCESS, updatedProduct.object);
        toastStore.success('Access changed');
        dialogStore.close();
      } catch (error) {
        options?.onFailure?.();
        toastStore.error(<ToastError error={error} placeholder="Something went wrong" />);
      }
    },
    [dialogStore, items, options, toastStore],
  );
  const initialValues =
    items.length > 1
      ? {
          access_level: '',
          permissions: [],
          item_ids: items.map((product) => product.id),
        }
      : {
          access_level: items[0].access_level,
          permissions: items[0]?.permissions,
          item_ids: items.map((product) => product.id),
        };

  const { formData, handlers, valid, onSubmit } = useMm3Form<IAccessChangeImmediateForm>(
    initialValues,
    Model.PRODUCTS,
    submitForm,
  );

  return (
    <form onSubmit={onSubmit}>
      <FormAccessLevel
        name="access_level"
        label="Access"
        large
        {...formData.access_level}
        {...handlers}
        placeholder="Select Access"
      />
      {showPermissions && (
        <FormChecklist
          name="permissions"
          label="Permissions"
          formId={formId}
          large
          {...formData?.permissions}
          {...handlers}
        />
      )}

      <FormSubmitSection submitDisabled={!valid} />
    </form>
  );
};
