import { IShouldDisplayGroupActionFunction } from 'utils/actions/common/types';
import { IItem, IProduct } from 'types';
import { IShouldDisplayActionFunction, IShouldDisplayCommonActionFunction, Pages } from 'utils/actions';
import { isInMainPage } from 'utils/actions/utils';
import { IProductActionName } from './types';
import { areAllNotDeleted, areAllDeleted } from 'utils/is-entity-deleted';
import { flags } from 'utils/flags';

export type IShouldDisplayProductActionFunction = IShouldDisplayActionFunction<IProduct, IProductActionName>;

export const shouldDisplayRemoveFromGroup: IShouldDisplayProductActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    entities?.length > 0 &&
    areAllNotDeleted(entities) &&
    [
      Pages.EVENT_PRODUCTS_TAB,
      Pages.SELECTION_DETAILS,
      Pages.SELECTION_ENTITY_TAB,
      Pages.ACCESS_PRIVILEGES_DETAILS,
      Pages.ACCESS_GROUP_DETAILS,
      Pages.GUILD_DETAILS,
      Pages.SMART_GROUP_DETAILS,
    ].includes(options.page as Pages)
  );
};

export const shouldDisplayRemoveFromMobileSync: IShouldDisplayProductActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    entities?.length > 0 && areAllNotDeleted(entities) && Pages.MOBILE_APP_SYNC_PRODUCTS_TAB === (options.page as Pages)
  );
};

export const shouldDisplayClone: IShouldDisplayProductActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && areAllNotDeleted(entities);
};

export const shouldDisplayAssociate: IShouldDisplayProductActionFunction = (action, index, user, entities, options) => {
  return (
    entities?.length === 1 &&
    options.context === 'single' &&
    areAllNotDeleted(entities) &&
    flags.showRelatedProductsFeature
  );
};

export const shouldDisplayRestore: IShouldDisplayProductActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && areAllDeleted(entities);
};

export const shouldDisplayDelete: IShouldDisplayProductActionFunction = (action, index, user, entities, options) => {
  return (
    entities?.length > 0 &&
    areAllNotDeleted(entities) &&
    (isInMainPage(options) || options.page === Pages.PRODUCT_CHILDREN_TAB)
  );
};

export const shouldDisplayUnassign: IShouldDisplayProductActionFunction = (action, index, user, entities, options) => {
  return entities?.length > 0 && options.page === Pages.ASSET_PRODUCTS_TAB && areAllNotDeleted(entities);
};

export const shouldDisplayUpdate: IShouldDisplayProductActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && areAllNotDeleted(entities);
};

export const shouldDisplayAddToSelection: IShouldDisplayProductActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && areAllNotDeleted(entities);
};

export const shouldDisplayPassOnData: IShouldDisplayProductActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    entities?.length > 0 &&
    entities[0]?.children_count > 0 &&
    options.context === 'single' &&
    areAllNotDeleted(entities)
  );
};

export const shouldDisplayReassign: IShouldDisplayProductActionFunction = (action, index, user, entities) => {
  return Boolean(areAllNotDeleted(entities) && entities?.[0]?.parent_id);
};

export const shouldDisplayRecommend: IShouldDisplayProductActionFunction = (action, index, user, entities, options) => {
  return (
    entities?.length > 0 &&
    areAllNotDeleted(entities) &&
    flags.showRecommendationFeature &&
    options.page !== Pages.SELECTION_DETAILS
  );
};

export const shouldDisplayAssignToProduct: IShouldDisplayProductActionFunction = (action, index, user, entities) => {
  return areAllNotDeleted(entities);
};

export const shouldDisplayChangeAccess: IShouldDisplayProductActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && areAllNotDeleted(entities);
};

export const shouldDisplayPreviewAsClient: IShouldDisplayProductActionFunction = (action, index, user, entities) => {
  return entities?.length === 1 && areAllNotDeleted(entities);
};

export const shouldDisplayRemoveFromRelated: IShouldDisplayProductActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return entities?.length > 0 && options.page === Pages.PRODUCT_RELATED_TAB && areAllNotDeleted(entities);
};

export const shouldDisplayAccessPrivilegeProductAccessChange: IShouldDisplayProductActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    entities.length === 1 &&
    areAllNotDeleted(entities) &&
    [Pages.ACCESS_PRIVILEGES_DETAILS].includes(options.page as Pages)
  );
};

export const shouldDisplayAccessPrivilegeTitle: IShouldDisplayCommonActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return areAllNotDeleted(entities) && [Pages.ACCESS_PRIVILEGES_DETAILS].includes(options.page as Pages);
};

export const shouldDisplayNotDeleted: IShouldDisplayGroupActionFunction = (action, index, user, entities) =>
  entities?.length > 0 && areAllNotDeleted(entities as IItem[]);

export const shouldDisplayAddCastCrew: IShouldDisplayProductActionFunction = (action, index, user, entities) =>
  entities?.length === 1 && areAllNotDeleted(entities as IItem[]) && flags.showCastCrewFeature;

export const shouldDisplaAccessPrivilege: IShouldDisplayGroupActionFunction = (action, index, user, entities) =>
  entities?.length > 0 && areAllNotDeleted(entities as IItem[]) && flags.showAccessPrivilegeFeature;

export const shouldDisplayDelivery: IShouldDisplayProductActionFunction = (action, index, user, entities, options) => {
  return (
    entities?.length > 0 &&
    areAllNotDeleted(entities) &&
    flags.showDeliveryFeature &&
    options.page !== Pages.SELECTION_DETAILS
  );
};

export const shouldDisplayCreateMobileAppSync: IShouldDisplayGroupActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return shouldDisplayNotDeleted(action, index, user, entities, options) && flags.showMobileSelectionsFeature;
};
