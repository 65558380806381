import React from 'react';

import { IUseFormReturn } from 'helpers/form';

import { IAssetFormFields } from './types';
import { IAssetPresetFields } from 'types/asset';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IMm3Asset } from 'types/index';

export interface IAssetCustomRowProps<T extends {}> extends Pick<IUseFormReturn<T>, 'handlers' | 'formData'> {
  hideLabel?: boolean;
}

export const CustomAssetRow: React.FC<IAssetCustomRowProps<Partial<IAssetFormFields | IAssetPresetFields>>> = () => {
  return <></>;
};

export interface IAssetMm3CustomRowProps<T extends {}> extends Pick<IUseMm3FormReturn<T>, 'handlers' | 'formData'> {
  hideLabel?: boolean;
}

export const CustomAssetMm3Row: React.FC<IAssetMm3CustomRowProps<IMm3Asset>> = () => {
  return <></>;
};
