import React, { useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { Button } from '@mantine/core';
import { omit, uniq } from 'lodash';

import { useStore } from 'store';

import { Wizard, WizardStep, useWizardSteps } from 'components/wizard';
import { ScreeningRoomStepAssets } from './screening-room-step-assets';
import { ScreeningRoomStepContacts } from './screening-room-step-contacts';
import { ScreeningRoomStepSummary } from './screening-room-step-summary';
import { ScreeningRoomStepGeneral } from './screening-room-step-general';
import { FormActionSection } from 'components/form-submit-section';
import { ToastError } from 'components/toast';

import { IconAlias } from 'icons';
import { IScreeningRoomForm, getContactsAndAssetsIds, StepIds } from './utils';
import { saveGroup } from 'utils/apis/groups';
import { useForm } from 'helpers/form';

import { IGroup, ItemId } from 'types';
import { Model } from 'helpers/filters/types';
import { MantineIcon } from 'utils/ui/icon';
import { ArrowLeft } from 'blueprint5-icons';

interface IScreeningRoomWizardContainer {
  onFinish: (screeningRoom: IGroup) => void;
  groupId?: ItemId;
  screeningRoom?: IScreeningRoomForm;
  tab?: number;
}

const ScreeningRoomWizard: React.FC<IScreeningRoomWizardContainer> = ({ onFinish, tab, screeningRoom }) => {
  const { toastStore } = useStore();
  const [assetItemIds, setAssetItemIds] = useState<ItemId[]>([]);
  const [contacttItemIds, setContactItemIds] = useState<ItemId[]>([]);
  const [loadingSummaryInfo, setLoadingSummaryInfo] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const handleConfirm = useCallback(
    async (values: IScreeningRoomForm): Promise<void> => {
      try {
        if (!values) {
          return;
        }
        setIsCreating(true);
        const assetIds = values.subAssetIds ? [...assetItemIds, ...values?.subAssetIds] : assetItemIds;
        const screeningRoomForm = {
          ...omit(values, ['assets', 'contacts']),
          assets_item_ids: uniq(assetIds),
          contacts_item_ids: contacttItemIds,
        };
        const result = await saveGroup('selection', screeningRoomForm);

        toastStore.success('Screening room was successfully created!');
        setIsCreating(false);
        onFinish(result?.object);
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [assetItemIds, contacttItemIds, toastStore, onFinish, setIsCreating],
  );

  const initialValues: IScreeningRoomForm = {
    description: '',
    name: '',
    access_level: 'viewable',
    group_preview_image_url: screeningRoom?.preview_image?.url || '',
    contacts_item_ids: undefined,
    assets_item_ids: undefined,
    assets: undefined,
    contacts: undefined,
    subAssetIds: undefined,
    ...(screeningRoom || {}),
  };

  const form = useForm<IScreeningRoomForm>(initialValues, Model.GROUPS, handleConfirm);

  const steps = [
    { id: StepIds.General, disabled: !form.valid },
    { id: StepIds.Assets, disabled: !form.valid },
    {
      id: StepIds.Contacts,
      disabled: !form.valid,
    },

    {
      id: StepIds.Summary,
      nextButtonTitle: 'Create',
      action: form.onSubmit,
    },
  ];

  const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(steps, {
    defaultStepId: tab,
  });

  useEffect(() => {
    if (currentStepId === StepIds.Summary) {
      setLoadingSummaryInfo(true);
      getContactsAndAssetsIds(form.values).then(({ assets_item_ids, contacts_item_ids }) => {
        setAssetItemIds(assets_item_ids);
        setContactItemIds(contacts_item_ids);
        setLoadingSummaryInfo(false);
      });
    }
  }, [currentStepId, form.values]);

  return (
    <Wizard
      selectedStepId={currentStepId}
      onChange={setCurrentStepId}
      showStepTitleIndex
      fitInParent
      isLoading={isCreating}
      footerPadded={false}
      footer={
        <FormActionSection className="d-flex justify-content-between w-100">
          <Button
            className={cx('m-0', { invisible: backButton.hide })}
            onClick={backButton.action}
            disabled={backButton.hide || isCreating}
            variant="subtle"
            leftSection={<MantineIcon icon={<ArrowLeft />} />}
          >
            {backButton.title}
          </Button>
          <Button
            className="m-0"
            variant="primary"
            onClick={nextButton.action}
            disabled={nextButton.disabled || isCreating}
          >
            {nextButton.title}
          </Button>
        </FormActionSection>
      }
    >
      <WizardStep
        id={StepIds.General}
        title="General info"
        icon="media"
        disabled={isStepDisabled(StepIds.General)}
        panel={<ScreeningRoomStepGeneral form={form} />}
      />

      <WizardStep
        id={StepIds.Assets}
        title="Assets"
        icon={IconAlias.ASSET}
        disabled={isStepDisabled(steps[1].id)}
        panel={<ScreeningRoomStepAssets form={form} />}
      />

      <WizardStep
        id={StepIds.Contacts}
        title="Contacts"
        icon={IconAlias.CONTACT}
        disabled={isStepDisabled(steps[2].id)}
        panel={<ScreeningRoomStepContacts form={form} />}
      />

      <WizardStep
        id={StepIds.Summary}
        title="Summary"
        icon="menu"
        disabled={isStepDisabled(steps[3].id)}
        panel={
          <ScreeningRoomStepSummary
            form={form}
            assetsCount={assetItemIds.length}
            contactsCount={contacttItemIds.length}
            loading={loadingSummaryInfo}
            isEditingRecipients={currentStepId === StepIds.Contacts}
          />
        }
      />
    </Wizard>
  );
};

export default ScreeningRoomWizard;
