import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Product3Types, DetailsPageTabs, IProductAncestryInfo, IProduct, IProductAsset, ViewMode } from 'types';
import { formatProductTabName, tabToType } from 'utils/product';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ProductAnalyticsTab } from './product-analytics-tab';
import { ProductMetadataTab } from './product-metadata-tab';
import { ProductAssetsTab } from './product-assets-tab';
import { ProductRelatedTab } from './product-related-tab';
import { ProductTimelineTab } from './product-timeline-tab';
import { ProductCastCrewTab } from './cast-crew-tab';
import { ProductOverviewTabMode } from './product-overview-tab/product-overview-tab-mode';
import { ProductAccessTab } from './access-tab';
import { IScrollableTab } from 'components/scrollable-tabs/scrollable-tabs';
import { flags } from 'utils/flags';

export const tabOptions = [
  DetailsPageTabs.OVERVIEW,
  DetailsPageTabs.SERIES,
  DetailsPageTabs.SEASONS,
  DetailsPageTabs.EPISODES,
  DetailsPageTabs.EPISODE_VERSION,
  DetailsPageTabs.FILM_VERSION,
  DetailsPageTabs.METADATA,
  DetailsPageTabs.ANALYTICS,
  DetailsPageTabs.ASSETS,
  DetailsPageTabs.ACCESS,
  DetailsPageTabs.TIMELINE,
  DetailsPageTabs.RELATED,
  DetailsPageTabs.PROGRAMS,
  DetailsPageTabs.CAST_CREW,
];

export const showDataSectionToggleButton = (activeTab: DetailsPageTabs): boolean =>
  [
    DetailsPageTabs.SERIES,
    DetailsPageTabs.SEASONS,
    DetailsPageTabs.EPISODES,
    DetailsPageTabs.EPISODE_VERSION,
    DetailsPageTabs.FILM_VERSION,
    DetailsPageTabs.ASSETS,
    DetailsPageTabs.RELATED,
    DetailsPageTabs.PROGRAMS,
    DetailsPageTabs.ACCESS,
    DetailsPageTabs.CAST_CREW,
  ].includes(activeTab);

const typeToDirectChildrenTypes = {
  [Product3Types.PROGRAM]: [Product3Types.FILM_VERSION],
  [Product3Types.FILM_VERSION]: [] as Product3Types[],
  [Product3Types.EPISODE_VERSION]: [] as Product3Types[],
  [Product3Types.EPISODE]: [Product3Types.EPISODE_VERSION],
  [Product3Types.SEASON]: [Product3Types.EPISODE],
  [Product3Types.SERIES]: [Product3Types.SEASON],
  [Product3Types.FORMAT]: [Product3Types.SERIES, Product3Types.PROGRAM],
};

export const getParentType = (productType?: Product3Types): Product3Types | undefined => {
  if (!productType) {
    return;
  }
  const [parentType] = Object.entries(typeToDirectChildrenTypes).find(([, value]) => value.includes(productType)) || [];

  return parentType as Product3Types;
};

export const isDirectDescendantsTab = (type?: Product3Types, activeTab?: DetailsPageTabs): boolean => {
  const childType = activeTab && tabToType[activeTab];
  if (!type || !childType) {
    return false;
  }

  return typeToDirectChildrenTypes[type].includes(childType);
};

export const childrenTabs = [
  DetailsPageTabs.SERIES,
  DetailsPageTabs.SEASONS,
  DetailsPageTabs.EPISODES,
  DetailsPageTabs.EPISODE_VERSION,
  DetailsPageTabs.PROGRAMS,
  DetailsPageTabs.FILM_VERSION,
];

export const sortableTabs = [
  ...childrenTabs,
  DetailsPageTabs.ASSETS,
  DetailsPageTabs.RELATED,
  DetailsPageTabs.CAST_CREW,
];

export const editableTabs = [DetailsPageTabs.OVERVIEW, DetailsPageTabs.METADATA];

export const useRedirectFromEmptyChildTab = (activeTab: DetailsPageTabs, ancestry?: IProductAncestryInfo): void => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { productId = '' } = useParams<{ productId: string }>();

  useEffect(() => {
    const isInitialized = ancestry?.product_id === parseInt(productId);
    const hasChildren = ancestry?.[`effective_${activeTab}_count`];

    if (!isInitialized || hasChildren || !childrenTabs.includes(activeTab)) {
      return;
    }

    const updatedPath = pathname.replace(activeTab, DetailsPageTabs.OVERVIEW);
    navigate(updatedPath);
  }, [ancestry, activeTab, navigate, productId, pathname]);
};

export const getTabsList = ({
  product,
  productAssets,
  ancestry,
  mode,
  isSortModeEnabled,
  getIsDisabled,
  getChildTab,
  setProduct,
  setProductAssets,
  setMode,
}: {
  product: IProduct;
  productAssets?: IProductAsset[];
  ancestry: IProductAncestryInfo;
  getIsDisabled: (tab: DetailsPageTabs) => boolean;
  getChildTab: (type: Product3Types) => JSX.Element;
  isSortModeEnabled: boolean;
  setProduct: React.Dispatch<React.SetStateAction<IProduct>>;
  setProductAssets: React.Dispatch<React.SetStateAction<IProductAsset[]>>;
  mode: ViewMode;
  setMode: (mode: ViewMode) => void;
}): IScrollableTab[] => [
  {
    id: DetailsPageTabs.OVERVIEW,
    panel: (
      <ProductOverviewTabMode
        product={product}
        mode={mode}
        setProduct={setProduct}
        setMode={setMode}
        assets={productAssets}
      />
    ),
    isVisible: true,
    disabled: getIsDisabled(DetailsPageTabs.OVERVIEW),
  },
  {
    id: DetailsPageTabs.SERIES,
    formatTitle: formatProductTabName,
    panel: getChildTab(Product3Types.SERIES),
    isVisible: ancestry.effective_series_count > 0,
    disabled: getIsDisabled(DetailsPageTabs.SERIES),
  },
  {
    id: DetailsPageTabs.SEASONS,
    formatTitle: formatProductTabName,
    panel: getChildTab(Product3Types.SEASON),
    isVisible: ancestry.effective_seasons_count > 0,
    disabled: getIsDisabled(DetailsPageTabs.SEASONS),
  },
  {
    id: DetailsPageTabs.EPISODES,
    formatTitle: formatProductTabName,
    panel: getChildTab(Product3Types.EPISODE),
    isVisible: ancestry.effective_episodes_count > 0,
    disabled: getIsDisabled(DetailsPageTabs.EPISODES),
  },
  {
    id: DetailsPageTabs.EPISODE_VERSION,
    formatTitle: formatProductTabName,
    panel: getChildTab(Product3Types.EPISODE_VERSION),
    isVisible: ancestry.effective_episode_versions_count > 0,
    disabled: getIsDisabled(DetailsPageTabs.EPISODE_VERSION),
  },
  {
    id: DetailsPageTabs.PROGRAMS,
    formatTitle: formatProductTabName,
    panel: getChildTab(Product3Types.PROGRAMS),
    isVisible: ancestry.effective_programs_count > 0,
    disabled: getIsDisabled(DetailsPageTabs.PROGRAMS),
  },
  {
    id: DetailsPageTabs.FILM_VERSION,
    formatTitle: formatProductTabName,
    panel: getChildTab(Product3Types.FILM_VERSION),
    isVisible: ancestry.effective_film_versions_count > 0,
    disabled: getIsDisabled(DetailsPageTabs.FILM_VERSION),
  },
  {
    id: DetailsPageTabs.ANALYTICS,
    panel: <ProductAnalyticsTab />,
    isVisible: true,
    disabled: getIsDisabled(DetailsPageTabs.ANALYTICS),
  },
  {
    id: DetailsPageTabs.METADATA,
    panel: <ProductMetadataTab product={product} mode={mode} setProduct={setProduct} setMode={setMode} />,
    isVisible: true,
    disabled: getIsDisabled(DetailsPageTabs.METADATA),
  },
  {
    id: DetailsPageTabs.ASSETS,
    panel: <ProductAssetsTab {...{ isSortModeEnabled, setProductAssets, ancestry }} />,
    isVisible: true,
    disabled: getIsDisabled(DetailsPageTabs.ASSETS),
  },
  {
    id: DetailsPageTabs.RELATED,
    panel: <ProductRelatedTab {...{ product, isSortModeEnabled, setProduct }} />,
    isVisible: flags.showRelatedProductsFeature,
    disabled: getIsDisabled(DetailsPageTabs.RELATED),
  },
  {
    id: DetailsPageTabs.TIMELINE,
    panel: <ProductTimelineTab />,
    isVisible: true,
    disabled: getIsDisabled(DetailsPageTabs.TIMELINE),
  },
  {
    id: DetailsPageTabs.CAST_CREW,
    formatTitle: () => 'Cast & Crew',
    panel: <ProductCastCrewTab product={product} setProduct={setProduct} isSortModeEnabled={isSortModeEnabled} />,
    isVisible: flags.showCastCrewFeature,
    disabled: getIsDisabled(DetailsPageTabs.CAST_CREW),
  },
  {
    id: DetailsPageTabs.ACCESS,
    panel: <ProductAccessTab product={product} />,
    isVisible: true,
    disabled: getIsDisabled(DetailsPageTabs.ACCESS),
  },
];
