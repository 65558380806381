import React, { useMemo } from 'react';

import { AssetsDataSection } from 'components/asset/assets-data-section';

import { ITitleDividerActionName, Pages } from 'utils/actions';
import { ISearchFilter, ISmartGroup } from 'types';

interface ISmartGroupAssetsTab {
  smartGroup: ISmartGroup;
  onSuccess: (() => void) | undefined;
}

export const SmartGroupAssetsTab: React.FC<ISmartGroupAssetsTab> = ({ smartGroup, onSuccess }) => {
  const assetsFilter = useMemo(() => [['group_ids', 'in', [smartGroup.id]]] as ISearchFilter[], [smartGroup.id]);

  return (
    <AssetsDataSection
      persistentFilters={assetsFilter}
      page={Pages.SMART_GROUP_DETAILS}
      actionsOptions={{
        groupName: ITitleDividerActionName.SMART_GROUP,
        groupParamName: 'smartGroupId',
        page: Pages.SMART_GROUP_DETAILS,
        onSuccess,
      }}
    />
  );
};
