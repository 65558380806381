import React from 'react';
import { Button } from '@mantine/core';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { ArrowLeft } from 'blueprint5-icons';

import { FormActionSection } from 'components/form-submit-section';
import { Wizard, WizardStep, useWizardSteps } from 'components/wizard';
import { FormAssets, FormChecklist, FormSegment, isValid } from 'helpers/form';
import { IconAlias } from 'icons';
import { DataSectionStore } from 'store/data-section-store';
import { IAsset, IContact, IProduct } from 'types';

import { ISmartGroupForm } from 'types/smart-group';
import { MantineIcon } from 'utils/ui/icon';

import { SmartGroupFormAssetFilters } from 'components/smart-group/form-hook/smart-group-asset-filters';
import { SmartGroupProductFilters } from 'components/smart-group/form-hook/smart-group-product-filters';
import { SmartGroupFormGeneral, keysGeneral } from 'components/smart-group/form-hook/smart-group-general';
import { SmartGroupFormProducts } from 'components/smart-group/form-hook/smart-group-products';
import { SmartGroupFormContacts } from 'components/smart-group/form-hook/smart-group-contacts';
import { SmartGroupFormSummary } from 'components/smart-group/form-hook/smart-group-summary';
import { SmartGroupContactFilters } from 'components/smart-group/form-hook/smart-group-contact-filters';
import { Row } from 'components/label-value-pair';

import { useInitiateStores } from './hooks';
import { StepIds } from './utils';

import './styles.scss';

interface ISmartGroupWizard {
  form: ISmartGroupForm;
  fitInParent?: boolean;
  assetDataSectionStore: DataSectionStore<IAsset>;
  productDataSectionStore: DataSectionStore<IProduct>;
  contactDataSectionStore: DataSectionStore<IContact>;
}

const selectionTypeSegments = [
  { value: 'dynamic', label: 'Dynamic' },
  { value: 'static', label: 'Static' },
];

export const SmartGroupWizard: React.FC<ISmartGroupWizard> = observer(
  ({ form, productDataSectionStore, assetDataSectionStore, contactDataSectionStore, fitInParent }) => {
    const { values, formData, handlers, isSending, onSubmit } = form || {};

    const steps = [
      { id: StepIds.General, disabled: !isValid(formData, keysGeneral) },
      {
        id: StepIds.Products,
        disabled: false,
      },
      { id: StepIds.Assets, disabled: false },
      { id: StepIds.Contacts, disabled: false },
      {
        id: StepIds.Summary,
        nextButtonTitle: values?.id ? 'Save' : 'Create',
        action: onSubmit,
      },
    ];
    const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(steps);

    const {
      assetsCount,
      assetCountLoading,
      contactFilterValues,
      contactFilterHandlers,
      productFilterValues,
      productFilterHandlers,
      assetFilterValues,
      assetFilterHandlers,
    } = useInitiateStores({ contactDataSectionStore, productDataSectionStore, assetDataSectionStore, values });

    return (
      <Wizard
        fitInParent
        isLoading={isSending}
        loadingMessage="We are preparing your Smart group now."
        footerPadded={false}
        className={fitInParent ? 'p-3' : undefined}
        selectedStepId={currentStepId}
        onChange={setCurrentStepId}
        footer={
          <FormActionSection className="d-flex justify-content-between w-100">
            <Button
              className={cx('m-0', { invisible: backButton.hide })}
              onClick={backButton.action}
              disabled={backButton.hide || isSending}
              variant="subtle"
              leftSection={<MantineIcon icon={<ArrowLeft />} />}
            >
              {backButton.title}
            </Button>
            <div className="d-flex gap-3">
              <Button
                className="m-0"
                variant="primary"
                onClick={nextButton.action}
                disabled={nextButton.disabled || isSending}
              >
                {nextButton.title}
              </Button>
            </div>
          </FormActionSection>
        }
      >
        <WizardStep
          id={StepIds.General}
          title="General info"
          icon="properties"
          disabled={isStepDisabled(StepIds.General)}
          panel={<SmartGroupFormGeneral form={form} />}
        />
        <WizardStep
          id={StepIds.Products}
          disabled={isStepDisabled(StepIds.Products)}
          title="Products"
          icon={IconAlias.PRODUCT}
          panel={
            <div className="smart-group-wizard__products-tab-container">
              <div className="smart-group-wizard__products-tab-container--header">
                <Row
                  label="Products Count"
                  valueClassName="ms-2"
                  value={
                    productDataSectionStore?.searchStore?.running
                      ? 'Loading...'
                      : values.product_selection_method === 'dynamic'
                      ? productDataSectionStore?.searchStore?.filters?.length &&
                        productDataSectionStore.searchStore.totalCount
                      : values.product_ids?.length
                  }
                  fallback="0"
                />
              </div>
              <FormSegment
                name="product_selection_method"
                label="How would you like to select the Products"
                large
                segments={selectionTypeSegments}
                {...formData.product_selection_method}
                {...handlers}
              />
              {values.product_selection_method === 'dynamic' ? (
                <SmartGroupProductFilters
                  filterValues={productFilterValues}
                  filterHandlers={productFilterHandlers}
                  dataSectionStore={productDataSectionStore}
                />
              ) : (
                <SmartGroupFormProducts form={form} />
              )}
            </div>
          }
        />
        <WizardStep
          id={StepIds.Assets}
          disabled={isStepDisabled(StepIds.Assets)}
          title="Assets"
          icon={IconAlias.ASSET}
          panel={
            <div className="smart-group-wizard__assets-tab-container">
              <div className="smart-group-wizard__assets-tab-container--header">
                <Row
                  label="Assets Count"
                  value={
                    assetDataSectionStore?.searchStore?.running || assetCountLoading
                      ? 'Loading...'
                      : values.asset_selection_method === 'dynamic'
                      ? assetsCount
                      : values.asset_ids?.length
                  }
                  fallback="0"
                />
                <FormChecklist
                  className="d-flex gap-2"
                  label="Permissions"
                  name="permissions"
                  {...formData.permissions}
                  {...handlers}
                />
              </div>
              <FormSegment
                name="asset_selection_method"
                label="How would you like to select the Assets"
                large
                segments={selectionTypeSegments}
                {...formData.asset_selection_method}
                {...handlers}
              />
              {values.asset_selection_method === 'dynamic' ? (
                <SmartGroupFormAssetFilters
                  filterValues={assetFilterValues}
                  filterHandlers={assetFilterHandlers}
                  dataSectionStore={assetDataSectionStore}
                />
              ) : (
                <FormAssets name="asset_ids" fitInParent {...handlers} {...formData.asset_ids} />
              )}
            </div>
          }
        />
        <WizardStep
          id={StepIds.Contacts}
          disabled={isStepDisabled(StepIds.Contacts)}
          title="Contacts"
          icon={IconAlias.CONTACT}
          panel={
            <div className="smart-group-wizard__products-tab-container">
              <div className="smart-group-wizard__contacts-tab-container--header">
                <Row
                  label="Contacts Count"
                  valueClassName="ms-2"
                  value={
                    contactDataSectionStore?.searchStore?.running
                      ? 'Loading...'
                      : values.contact_selection_method === 'dynamic'
                      ? contactDataSectionStore?.searchStore?.filters?.length &&
                        contactDataSectionStore.searchStore.totalCount
                      : values.contact_ids?.length
                  }
                  fallback="0"
                />
              </div>
              <FormSegment
                name="contact_selection_method"
                label="How would you like to select the Contacts"
                large
                segments={selectionTypeSegments}
                {...formData.contact_selection_method}
                {...handlers}
              />
              {values.contact_selection_method === 'dynamic' ? (
                <SmartGroupContactFilters
                  filterValues={contactFilterValues}
                  filterHandlers={contactFilterHandlers}
                  dataSectionStore={contactDataSectionStore}
                />
              ) : (
                <SmartGroupFormContacts form={form} />
              )}
            </div>
          }
        />
        <WizardStep
          id={StepIds.Summary}
          disabled={isStepDisabled(StepIds.Summary)}
          title="Summary"
          icon="menu"
          panel={
            <SmartGroupFormSummary
              productDataSection={productDataSectionStore}
              assetCountLoading={assetCountLoading}
              assetsCount={assetsCount}
              contactDataSection={contactDataSectionStore}
              form={form}
            />
          }
        />
      </Wizard>
    );
  },
);
