import React from 'react';
import { useSessionStore } from 'store/session-store';
import { UserRoles } from 'types';

export const isUserRoleAllowed = ({
  allowedRoles = [],
  prohibitedRoles = [],
}: {
  allowedRoles?: UserRoles[];
  prohibitedRoles?: UserRoles[];
}): boolean => {
  const { role } = useSessionStore.getState().session || {};
  if (!role?.name) return false;

  const isNotInAllowedRoles = allowedRoles?.length && !allowedRoles.includes(role.name as UserRoles);
  const isInProhibitedRoles = prohibitedRoles?.length && prohibitedRoles.includes(role.name as UserRoles);
  return !(isNotInAllowedRoles || isInProhibitedRoles);
};

export const RoleAcl: React.FC<{
  allowedRoles?: UserRoles[];
  prohibitedRoles?: UserRoles[];
  children: React.ReactNode;
}> = ({ children, allowedRoles = [], prohibitedRoles = [] }) => {
  if (!isUserRoleAllowed({ allowedRoles, prohibitedRoles })) {
    return <></>;
  }
  return <>{children}</>;
};
