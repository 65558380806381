import React from 'react';
import GuildsDataSection from 'components/guilds-data-section/guilds';
import { Pages } from 'utils/actions/types';
import { DataSectionPage } from 'components/layout';

const Guilds: React.FC = () => {
  return (
    <DataSectionPage>
      <GuildsDataSection persistFilters page={Pages.GUILDS_LIST} />
    </DataSectionPage>
  );
};

export default Guilds;
