import React from 'react';

import { IUseFormReturn, FormSelect, useFetchFieldOptions, FormCheckbox, FormAccessLevel } from 'helpers/form';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { IContact } from 'types';
import { fetchRoles } from 'utils/apis/role';
import { useSessionStore } from 'store/session-store';
import { CustomContactSettingsRowEdit } from 'components/contact-form/custom-row-edit';

const ContactSettingsSection: React.FC<IUseFormReturn<IContact>> = ({ formData, handlers }) => {
  const user = useSessionStore((state) => state.user);
  const roleOptions = useFetchFieldOptions(fetchRoles);
  const isCurrentUser = formData.id.value === user?.id;

  return (
    <FormSectionWrapperV2
      title="Settings"
      className="entity-detail__infobox-wrapper"
      wrapperClassName="py-3"
      withBorder
    >
      <FormSectionWrapperV2 gridTemplateColumns="1fr" padded={false}>
        <FormSelect
          name="role_id"
          label="Platform Role"
          large
          {...handlers}
          {...formData.role_id}
          options={roleOptions}
          disabled={isCurrentUser}
        />
        <FormAccessLevel name="access_level" label="Access Level" large {...handlers} {...formData.access_level} />
        <CustomContactSettingsRowEdit handlers={handlers} formData={formData} />
      </FormSectionWrapperV2>
      <FormSectionWrapperV2 gridTemplateColumns="1fr" padded={false}>
        <FormCheckbox large name="sso" label="SSO Login Enabled" {...handlers} {...formData.sso} />
        <FormCheckbox large name="mobile_sync" label="Mobile app access" {...handlers} {...formData.mobile_sync} />
        <FormCheckbox large name="mfa_enabled" label="MFA Enabled" {...handlers} {...formData.mfa_enabled} />
        <FormCheckbox large name="bouncing" label="Emails Bouncing" {...handlers} {...formData.bouncing} />
      </FormSectionWrapperV2>
    </FormSectionWrapperV2>
  );
};

export default ContactSettingsSection;
