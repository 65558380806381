export const formatConnectionStatus = (status: string): string => {
  const formatedStatus = status.split('::')[1];
  return formatedStatus ? formatedStatus : status;
};

export const enum DeliveryStatus {
  DRAFT = 'draft',
  DELIVERING = 'delivering',
  SCHEDULED = 'scheduled',
  DELIVERED = 'active',
  FAILED = 'blocked',
}
