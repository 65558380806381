import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IMarketingEvent, DetailsPageTabs } from 'types';
import { formatDate } from 'utils/date';
import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { AssetsMainClassificationLink } from 'components/asset/assets-aggregation/product-assets';
import { EventTiming } from 'components/event-timing';
import { EventLink } from 'components/event-link';
import { ClipboardView } from 'components/clipboard-view';
import { queryEvents } from 'utils/apis/events';
import { formatField } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { DetailCountLink } from 'utils/ui';
import { getAttendeesCount } from 'utils/event-utils';

interface IProps {
  event: IMarketingEvent;
  assets: { classification?: string; type?: string }[];
}

export const EventDetailsOverviewSidebar: React.FC<IProps> = ({ event, assets }) => {
  const { subevent, event_password, pm_statistics_data, created_at, updated_at, group_ids = [] } = event || {};
  const number_products = pm_statistics_data?.count;

  const attendeesCount = useMemo(() => getAttendeesCount(event), [event]);

  const fetchSubEvents = useCallback(() => queryEvents({}, [['id', 'in', group_ids]]), [group_ids]);
  const [subEvents] = useRemote(fetchSubEvents, []);

  const groupNames = subEvents?.map((g) => g.name);

  return (
    <div className="event-overview-sidebar">
      <InfoBoxV2 gridTemplateColumns={'1fr 1.5fr'} title="General info" wrapperClassName="py-4">
        <Row
          label="URL"
          rawValue
          value={<EventLink event={event} className="d-block" />}
          className="text-wrap text-break"
          size="m"
        />
        {!subevent && (
          <Row
            label="Password"
            rawValue
            value={
              <ClipboardView textClassName="text-wrap text-break" className="d-block">
                {event_password}
              </ClipboardView>
            }
            size="m"
          />
        )}
        <EventTiming event={event} />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="1fr 1.5fr" title="About">
        {!subevent && (
          <DetailCountLink entityCount={attendeesCount} link={`../${DetailsPageTabs.ATTENDEES}`} label="Attendees" />
        )}
        <DetailCountLink entityCount={number_products || 0} link={`../${DetailsPageTabs.PRODUCTS}`} label="Products" />
        <Row
          label="Assets"
          value={
            <AssetsMainClassificationLink
              assets={assets}
              muted={false}
              baseUrl={`../${DetailsPageTabs.ASSETS}`}
              fallback="0"
            />
          }
          size="m"
        />
        {!subevent && (
          <Row
            label="Sub-Events"
            rawLabel
            value={
              subEvents?.length ? <Link to={`../${DetailsPageTabs.SUB_EVENTS}`}>{formatField(groupNames)}</Link> : '0'
            }
            size="m"
          />
        )}
        <Row label="Created at" rawLabel value={formatDate(created_at)} size="m" />
        <Row label="Updated at" rawLabel value={formatDate(updated_at)} size="m" />
      </InfoBoxV2>
    </div>
  );
};
