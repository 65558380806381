import React from 'react';
import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';

import { FormInput } from 'helpers/form';
import { IDivision } from 'types';
import { FormSubmitSection } from 'components/form-submit-section';

export interface IDivisionFormData extends Partial<IDivision> {
  parentName?: string;
}

interface IDivisionFormProps {
  division?: IDivision;
  handleSubmit: (division: IDivisionFormData) => Promise<IDivision | void>;
  parentName?: string;
  parentId?: number;
}

const initialValues: IDivisionFormData = {
  name: '',
  parentName: '',
  parent_id: undefined,
};

const DivisionForm: React.FC<IDivisionFormProps> = ({ division, handleSubmit, parentName, parentId }) => {
  const initValues = division
    ? division
    : parentName && parentId
    ? { ...initialValues, parentName: parentName, parent_id: parentId }
    : initialValues;

  const { handlers, formData, onSubmit, valid } = useForm<IDivisionFormData>(initValues, Model.DIVISIONS, handleSubmit);
  const isEditMode = Boolean(division?.id);

  return (
    <form className="d-flex flex-column justify-content-between h-100" onSubmit={onSubmit}>
      <FormInput name="name" label="Division name" {...handlers} {...formData?.name} />
      {parentId && <FormInput name="parent_id" label="Parent" {...formData.parentName} {...handlers} disabled />}
      <FormSubmitSection labels={{ confirm: isEditMode ? 'Save' : 'Create' }} submitDisabled={!valid} />
    </form>
  );
};

export { DivisionForm };
