import React, { useCallback, useEffect } from 'react';
import { Box, Stack, Tooltip } from '@mantine/core';
import { FormProducts, FormCheckbox } from 'helpers/form';
import { IMobileAppSyncProductsForm } from 'components/mobile-app-sync-wizard/types';
import { fetchProductsWithAncestry } from 'utils/apis/product';
import { MantineIcon } from 'utils/ui';
import { disableIncludeDescendants } from 'components/mobile-app-sync-wizard/disable-include-descendants';
import { fetchRelatedProducts } from 'components/mobile-app-sync-wizard/utils';
import { relatedProductTooltipLabel, relatedProductType } from 'components/mobile-app-sync-wizard/initial-values';

import { ItemId } from 'types/index';
import { flags } from 'utils/flags';

export const keysProducts = ['product_ids'];

export const MobileAppSyncFormProducts: React.FC<IMobileAppSyncProductsForm> = ({
  form,
  mobileSyncId,
  fitInParent,
}) => {
  const fetchProductsOverride = useCallback(
    (params, filters) => {
      const defaultFilter = filters || [['parent_id', 'not_exist']];

      if (mobileSyncId) {
        defaultFilter.push(['group_ids', 'ne', mobileSyncId]);
      }
      return fetchProductsWithAncestry(params, defaultFilter);
    },
    [mobileSyncId],
  );

  useEffect(() => {
    const { product_ids, include_related_products } = form.values;
    const fetchProducts = async (): Promise<ItemId[]> => {
      const relatedProducts = await fetchRelatedProducts(product_ids, relatedProductType || 'all');
      return relatedProducts;
    };

    if (include_related_products && product_ids?.length) {
      fetchProducts().then((relatedProducts) => {
        form.handlers.onChange({ relatedProductIds: relatedProducts, include_related_products });
      });
    } else {
      form.handlers.onChange({ relatedProductIds: [], include_related_products });
    }
  }, [form.handlers, form.values]);

  return (
    <div className="product-mobile-selection-form h-100 gap-2">
      <FormProducts
        name="product_ids"
        label="Products"
        omni
        {...form.formData.product_ids}
        {...form.handlers}
        fetchValues={fetchProductsOverride}
        fitInParent={fitInParent}
      />
      <Stack gap="xs">
        <FormCheckbox
          disabled={disableIncludeDescendants}
          name="include_descendants"
          label="Add product(s) including descendants"
          {...form.formData.include_descendants}
          {...form.handlers}
          groupClassName="mb-0"
          large
        />

        {flags.showRelatedProductsFeature && (
          <div className="d-flex justify-content-space-between">
            <FormCheckbox
              name="include_related_products"
              label="Include related products"
              {...form.formData.include_related_products}
              {...form.handlers}
              groupClassName="mb-0"
              large
            />

            <Tooltip label={relatedProductTooltipLabel}>
              <Box ml={3} mt={-2}>
                <MantineIcon icon="info-sign" size={11} />
              </Box>
            </Tooltip>
          </div>
        )}
      </Stack>
    </div>
  );
};
