import React, { useCallback, useState } from 'react';
import { Button } from '@mantine/core';
import cx from 'classnames';

import { AmAmazonapacLocalizationOrderSeriesSeries } from '@mediafellows/mm3-types';
import { ArrowLeft } from 'blueprint5-icons';
import { MantineIcon } from 'utils/ui/icon';

import { ToastError } from 'components/toast';
import { IWizardStepButton } from 'components/wizard/utils';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { useStore } from 'store';

import { StepIds } from './utils';
import { createOrder, sendOrder, updateOrder } from 'utils/apis/localization-order';

import './style.scss';

type IWizardProps<T extends AmAmazonapacLocalizationOrderSeriesSeries> = {
  nextButton: IWizardStepButton;
  backButton: IWizardStepButton;
  currentStepId: number;
  onFinish?: (order: T) => void;
  form: IUseMm3FormReturn<AmAmazonapacLocalizationOrderSeriesSeries>;
};

export function WizardButtons<T extends AmAmazonapacLocalizationOrderSeriesSeries>({
  backButton,
  nextButton,
  currentStepId,
  form,
  onFinish,
}: IWizardProps<T>): React.ReactElement {
  const { toastStore, dialogStore } = useStore();
  const [isSending, setIsSending] = useState<boolean>(false);

  const createLocalizationOrder = useCallback(
    async (e) => {
      try {
        setIsSending(true);
        e.preventDefault();
        let values = form.values;

        const targetDate = values['launch_target_string']
          ? { launch_target: new Date(values['launch_target_string'] as string).toISOString() }
          : {};
        values = { ...values, ...targetDate };

        const order = values.id ? await updateOrder(values) : await createOrder(values);

        setTimeout(async () => {
          await sendOrder({ id: order.id });
        }, 1000);

        onFinish?.(order as T);
        dialogStore.close();
        toastStore.success(`Localization Order was created and sent!`);
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Something went wrong!" />);
      } finally {
        setIsSending(false);
      }
    },
    [dialogStore, form.values, onFinish, toastStore],
  );

  return (
    <div className="d-flex w-100 gap-2">
      <Button
        className={cx('me-auto', { invisible: backButton.hide })}
        onClick={backButton.action}
        disabled={backButton.disabled}
        variant="subtle"
        leftSection={<MantineIcon icon={<ArrowLeft />} />}
      >
        {backButton.title}
      </Button>

      <Button
        variant="primary"
        onClick={nextButton.action}
        disabled={nextButton.disabled || form.isSending || isSending}
      >
        {nextButton.title}
      </Button>

      {currentStepId === StepIds.Summary && (
        <Button
          disabled={nextButton.disabled || form.isSending || isSending}
          variant="primary"
          onClick={createLocalizationOrder}
        >
          Send
        </Button>
      )}
    </div>
  );
}
