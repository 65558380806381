import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { IAssetAggregation, IMm3Asset } from 'types';
import { formatDuration } from 'utils/date';
import { getEntityType, formatAssetType } from 'utils/general';
import { AssetFilters, getMainType } from 'utils/asset';
import { Routes } from 'utils/routes';

import { DescendantsAssetsAggregations } from 'components/asset/assets-aggregation/descendants-assets-links';

export const AssetMm3GeneralTabAboutSection: React.FC<{
  asset: IMm3Asset;
  subAssetsAggregations: IAssetAggregation;
}> = ({ asset, subAssetsAggregations }) => {
  const { classification, id, name, source_meta } = asset || {};
  const type = getMainType(asset);
  const { duration } = source_meta || {};

  return (
    <InfoBoxV2 gridTemplateColumns="minmax(135px, 1fr) 2fr" title="About" wrapperClassName="mt-3">
      <Row label="Name" value={name} />
      <Row label="File Type" value={getEntityType(classification)} />
      <Row label="Asset Type" value={formatAssetType(classification)} />
      <Row
        label="Sub-Assets"
        rawLabel
        value={
          <DescendantsAssetsAggregations
            aggregations={subAssetsAggregations}
            baseUrl={`${Routes.ASSETS}/${id}`}
            muted
          />
        }
        hidden={type !== AssetFilters.VIDEO}
      />
      <Row label="duration" hidden={!duration} value={formatDuration(duration, 'seconds')} />
    </InfoBoxV2>
  );
};
