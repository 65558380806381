import React, { useCallback, useState, useMemo } from 'react';
import { useStore } from 'store';
import { omit, uniq, uniqBy } from 'lodash';

import { Model } from 'helpers/filters/types';
import { useForm } from 'helpers/form';

import { delay } from 'utils/delay';
import { createMobileAppSync } from 'utils/apis/mobile-app-sync';

import { MobileAppSyncWizard } from './mobile-app-sync-wizard';
import { IMobileAppSyncFormData, IMobileAppSyncWizardContainerProps } from 'components/mobile-app-sync-wizard/types';
import { initialValues } from 'components/mobile-app-sync-wizard/initial-values';

import './style.scss';

export const MobileAppSyncWizardContainer: React.FC<IMobileAppSyncWizardContainerProps> = ({
  onSuccess,
  fitInParent,
  productIds = [],
}) => {
  const { toastStore } = useStore();
  const [isCreating, setIsCreating] = useState(false);

  const createAction = useCallback(
    async (values: IMobileAppSyncFormData) => {
      setIsCreating(true);
      toastStore.info('Creating');

      const allAssetIds = uniqBy(
        [
          ...(values.asset_ids || []),
          ...(values.independentAssetIds || []),
          ...(values.subAssetIds || []),
          ...(values.independentAssetSubIds || []),
        ],
        (n) => n.toString(),
      );
      const allProductIds = uniq([...(values.product_ids || []), ...(values.relatedProductIds || [])]);
      const mobileSync = omit(values, 'independentAssetIds', 'relatedProductIds');

      await createMobileAppSync({ ...mobileSync, asset_ids: allAssetIds, product_ids: allProductIds });
      await delay();

      toastStore.clearAll();
      toastStore.success('Mobile Selection was created successfully');
      setIsCreating(false);
      onSuccess?.();
    },
    [onSuccess, toastStore],
  );

  const formInitialValues = useMemo(
    () => (productIds?.length ? { ...initialValues, product_ids: productIds } : initialValues),
    [productIds],
  );

  const form = useForm<IMobileAppSyncFormData>(formInitialValues, Model.MOBILE_APP_SYNC, createAction);

  return <MobileAppSyncWizard fitInParent={fitInParent} form={form} isCreating={isCreating} />;
};
