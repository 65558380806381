export const deliveryPackageSchema = `
  id,
  uuid,
  name,
  created_at,
  organization_id,
  connection_id,
  status,
  organization,
  delivery_items,
  scheduled_at, 
  delivered_at, 
  updated_at,
  recipient_list, 
  owner, 
  percent_transferred,
  delivery_duration_seconds,
  package_size_human_readable
`;

export const deliveryPackageConnectionSchema = `
  id,
  name,
  description,
  type,
  organization_id,
  host,
  port,
  username,
  password,
  created_at,
  updated_at,
  status,
  bucket,
  region,
  aws_access_key_id,
  aws_secret_access_key,
  receiver
`;
