import React from 'react';
import { ActionIcon, Loader, Menu } from '@mantine/core';

import { IProduct } from 'types';
import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';

export interface IBreadcrumbProps {
  text?: string | undefined;
  href?: string;
  icon?: string;
}
interface IAncestryDropdownProps {
  items: IProduct[];
  isLoading: boolean;
  renderItem: (item) => React.ReactNode;
  breadcrumbs: IBreadcrumbProps[];
  onBreadcrumbClick: (e) => void;
}

import './style.scss';

export const AncestryDropdown: React.FC<IAncestryDropdownProps> = ({
  items,
  renderItem,
  breadcrumbs: [root, ...breadcrumbs],
  onBreadcrumbClick,
  isLoading,
}) => {
  const nestedBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1);
  const currentBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

  const renderNestedBreadcrumb = (nestedBreadcrumbs: IBreadcrumbProps[]): React.ReactElement => {
    return (
      <Menu withArrow withinPortal={false} shadow="lg">
        <Menu.Target>
          <div className="d-flex">
            <ActionIcon variant="subtle" color="gray.5">
              <MantineIcon icon="widget-button" />
            </ActionIcon>
            <MantineIcon icon="chevron-right" />
          </div>
        </Menu.Target>
        <Menu.Dropdown className="breadcrumbs-ancestry-dropdown">
          {nestedBreadcrumbs.map((item, index) => (
            <a
              className={`ancestry-element d-block align-items-center ${Classes.MENU_ITEM} bp3-breadcrumb p-0 gap-1`}
              href={item.href}
              key={index}
              onClick={onBreadcrumbClick}
            >
              <Menu.Item>{item.text}</Menu.Item>
            </a>
          ))}
        </Menu.Dropdown>
      </Menu>
    );
  };

  return (
    <Menu closeOnClickOutside={false} closeOnItemClick={false} withinPortal={false}>
      <div className="ancestry-dropdown__sticky">
        <div className="d-flex align-items-center bp3-text-muted pb-1" onClick={onBreadcrumbClick}>
          <a
            className={`ancestry-element d-flex align-items-center ${Classes.MENU_ITEM} bp3-breadcrumb p-0 gap-1`}
            href={root.href}
          >
            <MantineIcon icon="home" />
            {root.text}
          </a>
          <MantineIcon icon="chevron-right" hidden={!breadcrumbs?.length} className="text-muted" />

          <div className="breadcrumbs-container d-flex gap-2 align-items-center">
            {nestedBreadcrumbs.length > 0 && renderNestedBreadcrumb(nestedBreadcrumbs)}

            {nestedBreadcrumbs.length && currentBreadcrumb ? (
              <a
                className={`ancestry-element d-block align-items-center ${Classes.MENU_ITEM} bp3-breadcrumb p-0 gap-1`}
                href={currentBreadcrumb.href}
                key={currentBreadcrumb.text}
                onClick={onBreadcrumbClick}
              >
                {currentBreadcrumb.text}
                <MantineIcon icon="chevron-right" />
              </a>
            ) : (
              breadcrumbs.map((breadcrumb) => {
                return (
                  <a
                    className={`d-block ancestry-element align-items-center ${Classes.MENU_ITEM} bp3-breadcrumb p-0 gap-1`}
                    href={breadcrumb.href}
                    key={breadcrumb.text}
                    onClick={onBreadcrumbClick}
                  >
                    {breadcrumb.text}
                    <MantineIcon icon="chevron-right" />
                  </a>
                );
              })
            )}
          </div>
        </div>
        <Menu.Divider mb="0" />
      </div>
      {isLoading && (
        <Menu.Item disabled leftSection={<Loader size={16} />}>
          Loading...
        </Menu.Item>
      )}
      {!isLoading && !items?.length && (
        <Menu.Item disabled style={{ background: 'var(--mfx-gray-7)' }}>
          Nothing found.
        </Menu.Item>
      )}
      {!isLoading && items.map(renderItem)}
    </Menu>
  );
};
