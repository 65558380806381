import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs } from '@mantine/core';

import { DetailsPageTabs } from 'types';
import { SectionHeader } from 'components/section-header';
import { BodyWrapper } from 'components/layout';

import { useTab } from 'utils/hooks';

import { OrderDetailsOverview } from './order-details-overview';
import { IOrder, OrderDetailTab, OrderTabPanel } from './utils';
import OrderRecipientsTab from './order-recipients-tab/order-recipients';
// import OrderProductsTab from './order-products-tab/order-products';
import detail_view from 'assets/img/detail_view.png';

import './style.scss';

const tabOptions = [DetailsPageTabs.PRODUCTS, DetailsPageTabs.RECIPIENTS];

export const OrderDetails: React.FC<{
  order: IOrder;
}> = observer(({ order }) => {
  const { project_name, product_id, recipient_list } = order || {};
  const [currentTab, setCurrentTab] = useTab(DetailsPageTabs.PRODUCTS, tabOptions);

  const handleTabChange = useCallback(
    (tab): void => {
      setCurrentTab(tab);
    },
    [setCurrentTab],
  );

  return (
    <BodyWrapper>
      <SectionHeader
        title={project_name}
        subtitle={
          <span className="muted-text">
            {[[`${[product_id].length || 0} Product`], `${recipient_list?.length || 0} Recipient(s)`].join(' | ')}
          </span>
        }
        actions={[]}
        useBackground
        dropdownMenu={<></>}
      />
      <div className="delivery-details__content-wrapper">
        <OrderDetailsOverview order={order} />
        <div className="delivery-details__tabs-wrapper">
          <Tabs value={currentTab} onChange={handleTabChange} className={'delivery-details__tabs h-100'}>
            <Tabs.List className="d-flex flex-nowrap">
              <OrderDetailTab value={DetailsPageTabs.PRODUCTS} activeTab={currentTab} />

              <OrderDetailTab value={DetailsPageTabs.RECIPIENTS} activeTab={currentTab} />
            </Tabs.List>
            <OrderTabPanel
              value={DetailsPageTabs.PRODUCTS}
              content={
                <div className="container">
                  <img src={detail_view} className="h-100 w-100" alt="image" style={{ objectFit: 'contain' }} />
                </div>
              }
            />
            {/* <OrderTabPanel value={DetailsPageTabs.PRODUCTS} content={<OrderProductsTab order={order} />} /> */}

            <OrderTabPanel value={DetailsPageTabs.RECIPIENTS} content={<OrderRecipientsTab order={order} />} />
          </Tabs>
        </div>
      </div>
    </BodyWrapper>
  );
});

export default OrderDetails;
