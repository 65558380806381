import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';
import { ISmartGroup } from 'types';
import { useDeleteAction } from './delete-action';
import { useRestoreAction } from './restore-action';
import { useCopyAction } from 'utils/actions/common/copy-action';

import { ISmartGroupActionName } from './types';

import type { IShouldDisplaySmartGroupActionFunction, ISmartGroupActionConfig } from './types';
import { useAddProductsAction, useAddAssetsAction, useAddContactsAction } from './add-entities-actions';
import { useUploadGroupPreviewAction } from 'utils/actions/common/upload-group-preview';
import { useEditAction } from './edit';

export { ISmartGroupActionName, IShouldDisplaySmartGroupActionFunction };

export {
  useAddContactsAction,
  useAddProductsAction,
  useAddAssetsAction,
  useAddContactSelectionsAction,
  contactsToItems,
  productsToItems,
  organizationsToItems,
  contactSelectionsToItems,
} from './add-entities-actions';

export const useSmartGroupActions = (
  entities: ISmartGroup[] = [],
  options: IUseActionsOptionParam,
): IUseActionsResponse<ISmartGroupActionName> => {
  const addProductsAction = useAddProductsAction({
    entities,
    options: { ...options, addSelectedProductAncestors: true },
  });

  const addAssetsAction = useAddAssetsAction({ entities, options });
  const addContactsAction = useAddContactsAction({ entities, options });

  const editAction = useEditAction(entities, options);
  const uploadPreviewAction = useUploadGroupPreviewAction(entities, options);

  const copyAction = useCopyAction(entities, {
    ...options,
    messageSuccess: 'Smart group has been copied',
  });

  const deleteAction = useDeleteAction(entities, options);
  const restoreAction = useRestoreAction(entities, options);

  const actions = [
    editAction,
    uploadPreviewAction,
    addProductsAction,
    addAssetsAction,
    addContactsAction,
    copyAction,
    restoreAction,
    deleteAction,
  ];

  return useActions(actions as ISmartGroupActionConfig[], options, entities);
};
