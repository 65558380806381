import React, { useCallback } from 'react';

import { FormSelect, FormInput } from 'helpers/form';

import { IConnectionForm } from 'components/delivery/connections/types';
import { Button } from '@mantine/core';
import { formatConnectionType } from 'components/connection-list-item/utils';

import { CustomFormFields } from 'components/delivery/connections/utils';

import '../style.scss';

export const ConnectionForm: React.FC<IConnectionForm> = ({ form }) => {
  const onChange = useCallback(
    ({ type }): void => {
      form.handlers.onChange({ type });
    },
    [form.handlers],
  );

  return (
    <div className="connection-form__grid-item">
      <div className="connection-form__grid-item-list">
        <FormSelect
          name="type"
          disabled={form.values.id ? true : false}
          label="Type"
          {...form.formData.type}
          formatLabel={formatConnectionType}
          onChange={onChange}
        />
        <FormInput name="name" label="Server Name" {...form.formData.name} {...form.handlers} />
        <FormInput
          textarea
          name="description"
          label="Description"
          {...form.formData.description}
          {...form.handlers}
          required
        />

        {CustomFormFields({ form })}
      </div>

      <div className="connection-form__grid-item-button save-connection">
        <Button size="xs" variant="success" onClick={form.onSubmit} disabled={!form.valid}>
          {form.values.id ? 'Update' : 'Save'}
        </Button>
      </div>
    </div>
  );
};
