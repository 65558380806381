import { useCallback } from 'react';

import type { IIdentifiable } from 'types';
import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { restoreGroups } from 'utils/apis/groups';
import { pluralEntityInflect } from 'utils/general';
import { IconAlias } from 'icons';

import { shouldDisplayRestore as shouldDisplay } from 'utils/actions/common';

import { IGuildActionConfig, IGuildActionName } from './types';

export const useRestoreAction = (items: IIdentifiable[], options: IUseActionsOptionParam): IGuildActionConfig => {
  const { toastStore, dialogStore } = useStore();
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect('Guild', items.length);

  const executeRestore = useCallback(async () => {
    try {
      await restoreGroups(items);

      options?.onSuccess?.();

      toastStore.success(`${entityWithCount} were restored`);
    } catch (error) {
      toastStore.error(`${entity} restore failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [entity, entityWithCount, items, options, toastStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm restore',
      body: `Are you sure you want to restore ${entityWithDemonstrative}?`,
      onConfirm: executeRestore,
    });
  }, [dialogStore, executeRestore, entityWithDemonstrative]);

  return {
    name: IGuildActionName.RESTORE,
    shouldDisplay,
    icon: IconAlias.ACTION_RESTORE,
    title: 'Restore',
    handler,
  };
};
