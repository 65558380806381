import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';

import { useDialogStore, useSortDataSectionStore, useStore } from 'store';

import { ToastError } from 'components/toast';
import { genreSortItemRenderer } from 'components/genres/genres-data-section/genre-sort-data-section';
import { SortDataSection } from 'components/sort-data-section';

import { sortGenres } from 'utils/apis/genre';
import { IUseActionsOptionParam } from 'utils/actions';
import { delay } from 'utils/delay';
import { Classes } from 'utils/ui';

import { IModalSize } from 'components/dialogs/types';
import { IGenreActionConfig, IGenreActionName } from './types';
import { ICategory, IGenreTreeItem } from 'types';

const SubGenreSort: React.FC<{ isLoading: boolean }> = observer(({ isLoading }) => {
  const { save } = useSortDataSectionStore<IGenreTreeItem>();
  const { close } = useDialogStore();

  return (
    <div className="h-100">
      <SortDataSection itemRenderer={genreSortItemRenderer} className="h-100" hideDivisionAccessLevel />
      <div className={`${Classes.DIALOG_FOOTER_ACTIONS} gap-2 pt-2`}>
        <Button size="xs" variant="default" disabled={isLoading} onClick={close}>
          Cancel
        </Button>
        <Button size="xs" loading={isLoading} disabled={isLoading} onClick={save} variant="primary">
          Save
        </Button>
      </div>
    </div>
  );
});

export const useSortSubGenreAction = (
  genres: IGenreTreeItem[],
  options: IUseActionsOptionParam<ICategory[]>,
): IGenreActionConfig => {
  const { toastStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const { close, openModal } = useDialogStore();
  const { initStore } = useSortDataSectionStore<IGenreTreeItem>();
  const fetcher = useCallback(async (): Promise<IGenreTreeItem[]> => genres[0]?.children, [genres]);

  const onSave = useCallback(
    async (sortedGenres) => {
      try {
        setIsLoading(true);
        const updatedGenres = await sortGenres(sortedGenres);
        toastStore.success('Sub-genres were sorted successfully!');
        await options?.onSuccess?.(IGenreActionName.SORT_SUB_GENRE, updatedGenres);
        await delay();
        setIsLoading(false);
        close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
        await options?.onFailure?.();
        setIsLoading(false);
      }
    },
    [close, options, toastStore],
  );

  initStore({ fetcher, onSave });

  const handler = (): void =>
    openModal({
      title: 'Sort sub-genres',
      className: 'sub-genre-sort-form__container',
      size: IModalSize.XL,
      body: () => <SubGenreSort isLoading={isLoading} />,
    });

  return {
    name: IGenreActionName.SORT_SUB_GENRE,
    shouldDisplay: () => Boolean(genres[0]?.children?.length),
    icon: 'sort',
    title: 'Sort sub-genres',
    handler,
  };
};
