import { AssetStatus, IngestStatus, IAsset, IMm3Asset } from 'types';
import { IShouldDisplayActionFunction, Pages } from 'utils/actions/types';
import { isInMainPage } from 'utils/actions/utils';
import { areAllNotDeleted, areAllDeleted } from 'utils/is-entity-deleted';
import { isAssetRecommendationEnabled } from 'utils/recommendation';
import { flags } from 'utils/flags';

import { IAssetActionName } from './types';
import { hasPower, recommendationBypassSharingRestrictions } from 'utils/powers';
import { getMainType } from 'utils/asset';

export type IShouldDisplayAssetActionFunction = IShouldDisplayActionFunction<IAsset | IMm3Asset, IAssetActionName>;

const availableStatus = new Set([AssetStatus.AVAILABLE, AssetStatus.REVIEW, AssetStatus.CREATED]);
export const isAvailable = (asset: IAsset | IMm3Asset): boolean =>
  availableStatus.has((asset.status || '') as AssetStatus);

const ingestingStatus = new Set([IngestStatus.READY, IngestStatus.INVALID, IngestStatus.AVAILABLE]);
const isNotBeingIngested = (asset: IAsset | IMm3Asset): boolean =>
  ingestingStatus.has((asset.file_status || '') as IngestStatus);

const isMainAsset = (asset: IMm3Asset): boolean => !asset.parent_id;

export const shouldDisplayRemoveFromGroup: IShouldDisplayAssetActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return Boolean(
    entities?.length &&
      [
        Pages.EVENT_ASSETS_TAB,
        Pages.SELECTION_DETAILS,
        Pages.SELECTION_ENTITY_TAB,
        Pages.SCREENING_ROOM_DETAILS,
        Pages.ACCESS_GROUP_DETAILS,
        Pages.MOBILE_APP_SYNC_ASSETS_TAB,
        Pages.GUILD_DETAILS,
      ].includes(options.page as Pages) &&
      areAllNotDeleted(entities),
  );
};

export const shouldDisplayRemoveFromConference: IShouldDisplayAssetActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return Boolean(entities?.length && options.page === Pages.CONFERENCE && areAllNotDeleted(entities));
};

export const shouldDisplaySetUpConference: IShouldDisplayAssetActionFunction = (action, index, user, entities) => {
  return flags.showConferenceFeature && areAllNotDeleted(entities) && entities?.every(isMainAsset);
};

export const shouldDisplayAssetTitleDivider: IShouldDisplayAssetActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return options.page === Pages.EVENT_ASSETS_TAB && areAllNotDeleted(entities);
};

export const shouldDisplayGroupTitleDivider: IShouldDisplayAssetActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return options.page === Pages.EVENT_ASSETS_TAB && areAllNotDeleted(entities);
};

export const shouldDisplayApproveOrReject: IShouldDisplayAssetActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && entities.every(({ status }) => status === 'review');
};

export const shouldDisplayDelete: IShouldDisplayAssetActionFunction = (action, index, user, entities, options) => {
  return entities?.length > 0 && areAllNotDeleted(entities) && isInMainPage(options);
};

export const shouldDisplayUnassign: IShouldDisplayAssetActionFunction = (action, index, user, entities, options) => {
  return Boolean(
    entities?.length &&
      options.page === Pages.PRODUCT_ASSETS_TAB &&
      areAllNotDeleted(entities) &&
      entities.every((asset) => asset?.product_asset?.id),
  );
};

export const shouldDisplayUpdate: IShouldDisplayAssetActionFunction = (action, index, user, entities, options) => {
  return options.page !== Pages.DETAILS && entities?.length > 0 && areAllNotDeleted(entities);
};

export const shouldDisplayDeletePreviewAction: IShouldDisplayAssetActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    entities?.length > 0 &&
    options.context === 'single' &&
    entities[0]?.preview_image !== null &&
    areAllNotDeleted(entities)
  );
};

export const shouldDisplayUploadPreview: IShouldDisplayAssetActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return entities?.length === 1 && options.context === 'single' && areAllNotDeleted(entities);
};

export const shouldDisplayUploadAsset: IShouldDisplayAssetActionFunction = (action, index, user, entities, options) => {
  return entities?.length === 1 && options.context === 'single' && areAllNotDeleted(entities);
};

export const shouldDisplayAssignToProduct: IShouldDisplayAssetActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && areAllNotDeleted(entities) && entities.every(isMainAsset);
};

const subAssetTypes = new Set(['Asset3::Digital::Subtitle', 'Asset3::Digital::Document']);
export const shouldDisplayAssignToAsset: IShouldDisplayActionFunction<IMm3Asset, IAssetActionName> = (
  action,
  index,
  user,
  entities,
) => {
  return (
    entities?.length > 0 &&
    areAllNotDeleted(entities) &&
    entities.every((e) => e.parent_id || subAssetTypes.has(e.type))
  );
};

export const shouldDisplayReingest: IShouldDisplayAssetActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && areAllNotDeleted(entities);
};

export const shouldDisplayUnarchive: IShouldDisplayAssetActionFunction = (action, index, user, entities, options) => {
  // TODO: adjust for mm3 assets
  return (
    (entities?.length > 0 &&
      options.hasRestorableArchive &&
      entities.every((asset) => asset.status === 'offline' && (asset as IAsset).storage?.is_restorable)) ??
    false
  );
};

export const shouldDisplayArchive: IShouldDisplayAssetActionFunction = (action, index, user, entities, options) => {
  return Boolean(
    entities?.length > 0 &&
      options.hasArchive &&
      entities.every((asset) => asset.status === 'available' && isNotBeingIngested(asset)),
  );
};

export const shouldDisplayRestore: IShouldDisplayAssetActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && areAllDeleted(entities);
};

export const shouldDisplayExtractPreviewImage: IShouldDisplayAssetActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return Boolean(
    entities?.length > 0 &&
      options.context === 'single' &&
      entities[0]?.classification?.startsWith('video') &&
      areAllNotDeleted(entities),
  );
};

export const shouldDisplayUploadSubtitle: IShouldDisplayAssetActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  const isMm3 = flags.isMm3Assets;
  if (isMm3) return false;
  const asset = entities[0];
  const assetType = getMainType(asset);
  const isVideo = Boolean(assetType === 'video' || asset?.classification?.startsWith('video'));

  return entities?.length > 0 && isVideo && areAllNotDeleted(entities) && options.context === 'single';
};

export const shouldDisplayMultiNotDeletedMainAssets: IShouldDisplayAssetActionFunction = (
  action,
  index,
  user,
  entities,
) => {
  return entities?.length > 0 && areAllNotDeleted(entities) && entities.every(isMainAsset);
};

export const shouldDisplayCreateScreeningRoom: IShouldDisplayAssetActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    shouldDisplayMultiNotDeletedMainAssets(action, index, user, entities, options) && flags.showScreeningRoomFeature
  );
};
export const shouldDisplayAddToSelection: IShouldDisplayAssetActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && areAllNotDeleted(entities) && entities.every(isMainAsset);
};

export const shouldDisplayChangeAccess: IShouldDisplayAssetActionFunction = (action, index, user, entities) => {
  return entities?.length > 0 && areAllNotDeleted(entities);
};

export const shouldDisplayRecommend: IShouldDisplayAssetActionFunction = (
  action,
  index,
  session,
  entities,
  options,
) => {
  return (
    isAssetRecommendationEnabled() &&
    flags.showAssetRecommendationsFeature &&
    options.page !== Pages.SELECTION_DETAILS &&
    areAllNotDeleted(entities) &&
    (hasPower(recommendationBypassSharingRestrictions) || entities.every((asset) => asset.sharable)) &&
    entities.every(isMainAsset)
  );
};

export const shouldDisplayDelivery: IShouldDisplayAssetActionFunction = (action, index, user, entities, options) => {
  return (
    flags.showDeliveryFeature &&
    options.page !== Pages.SELECTION_DETAILS &&
    entities?.length > 0 &&
    areAllNotDeleted(entities) &&
    entities.every(isMainAsset)
  );
};

export const shouldDisplayTranscode: IShouldDisplayAssetActionFunction = (action, index, user, entities) => {
  return entities.every(
    (asset) => asset.classification?.startsWith('video') && isAvailable(asset) && isNotBeingIngested(asset),
  );
};
