import React from 'react';

import {
  FormRemoteSelect,
  IFormRemoteSelectProps,
  IRemoteSelectorFetcher,
  FormMultiSelect,
  IFormMultiSelectProps,
} from 'helpers/form';
import { fetchProductsWithAncestry } from 'utils/apis/product';

const tagInputProps = { inputProps: { placeholder: 'Add more' } };

export const FormProducts: React.FC<IFormMultiSelectProps> = (props) => {
  const {
    placeholder = 'Search products',
    label,
    name,
    fitInParent = false,
    fetchValues = fetchProductsWithAncestry,
    omni = true,
    hideClearAllButton,
    forceReInitiating,
    ...other
  } = props;

  return (
    <FormMultiSelect
      fitInParent={fitInParent}
      name={name}
      label={label}
      isAncestryMode
      omni={omni}
      fetchValues={fetchValues}
      tagInputProps={tagInputProps}
      placeholder={placeholder}
      {...other}
      hideClearAllButton={hideClearAllButton}
      forceReInitiating={forceReInitiating}
    />
  );
};

const fetchProductOptions: IRemoteSelectorFetcher = (q, id, additionalFilters) => {
  return fetchProductsWithAncestry({ q, ...(id ? { ids: [id] } : {}) }, additionalFilters);
};

export const FormProduct: React.FC<Partial<IFormRemoteSelectProps>> = (props) => {
  const { placeholder = 'Select product', name = 'value', fetchOptions = fetchProductOptions, ...other } = props;

  return <FormRemoteSelect name={name} fetchOptions={fetchOptions} placeholder={placeholder} {...other} />;
};
