import React from 'react';

import { IGroup, IIdentifiable } from 'types';

import { GroupAccessChangeForm } from './group-access-change-form';

import { useGroupAccessChangeForm } from './hooks';

export const GroupAccessChangeTabs: React.FC<{
  items: (IGroup & IIdentifiable)[];
  onSuccess: () => void;
  groupName?: string;
}> = ({ items = [], onSuccess, groupName }) => {
  const form = useGroupAccessChangeForm({ groups: items, onSuccess });
  return <GroupAccessChangeForm form={form} groupName={groupName} />;
};
