export const initialValues = {
  project_name: '',
  type: 'LocalizationOrder::Series::Series',
  vendor: null,
  vendor_order_ref: null,
  vendor_project_manager_id: null,
  organization_project_manager_id: null,
  organization_order_ref: null,
  owner_organization_id: null,
  organization_name: 'Amazon',
  order_template: 'Series',

  recipient_list: [],
  product_id: null,
  asset_ids: [],
  product_type: 'Series',

  launch_target: null,
  launch_territory: null,
  date_to_posthouse: null,
  catalog_count: null,
  release_cycle: null,
  partener_alias: null,
  posthouse_billing: null,
  launch_target_string: null,

  resolution: 'HD',
  trailer: 'N',
  subtitles: [],
  dubbing: [],
  metadata: [],
  artwork_season: [],
  artwork_episode: [],
  localization_artwork: [],
  partner_deliverables: null,
  notes: null,

  product_ids: [],
  recipients: [],
};
