export const groupListSchema = `
  id,
  access_level,
  am_statistics_data,
  pm_statistics_data,
  created_at,
  updated_at,
  deleted_at,
  expires_at,
  scheduled_at,
  name,
  preview_image {
    url
  },
  preview_image_id,
  users_count,
  owner_id,
  owner,
  owner_organization_id,
  description,
`;

export const groupDetailsSchema = `${groupListSchema}, owner_organization`;
