import { useMantineColorScheme } from '@mantine/core';
import { Globals } from 'types';
import { useProfileStore } from 'store';

export const useColorModeToggle = (): (() => void) => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const toggle = (): void => {
    const newScheme = colorScheme === 'dark' ? 'light' : 'dark';

    toggleColorScheme();

    const updateSettings = useProfileStore.getState().updateSettings;
    updateSettings('isDarkMode', newScheme === 'dark'); // Update the profile store

    if (newScheme === 'light') {
      localStorage.setItem(Globals.THEME_LIGHT_LS, '1');
      document.body.classList.remove(Globals.MODE_DARK_CLASS);
    } else {
      localStorage.removeItem(Globals.THEME_LIGHT_LS);
      document.body.classList.add(Globals.MODE_DARK_CLASS);
    }
  };

  return toggle;
};
