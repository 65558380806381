import { useState, useEffect } from 'react';
import { useDataSectionStore } from 'store/hooks';

export const useIsInPersistentFilter = (name: string): boolean => {
  const { searchStore, filters } = useDataSectionStore();
  const [isInPersistentFilter, setIsInPersistentFilter] = useState(false);
  useEffect(() => {
    if (filters?.[name]?.attribute && searchStore?.persistentFilters?.length) {
      const currentFilter = filters[name];
      const { attribute, method } = currentFilter;
      setIsInPersistentFilter(
        Boolean(searchStore?.persistentFilters?.find(([key, val]) => key === attribute && val === method)),
      );
    }
  }, [filters, name, searchStore?.persistentFilters]);
  return isInPersistentFilter;
};
