import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import DateElement from 'components/date-list-element/date-list-element';
import { ContactDivisions } from './contact-divisions';

import { getPhone, placeholder as ph, ResponsibleUser, Classes } from 'utils/ui';
import { useCountryNames } from 'utils/hooks';

import { IContact } from 'types';
import './style.scss';

interface IContactAttributeListProps {
  contact: IContact;
}

export const ContactAttributeList: React.FC<IContactAttributeListProps> = observer(({ contact }) => {
  const { email, created_at, last_login_at, country_id, role_name, phones, division_ids } = contact;
  const [country] = useCountryNames([country_id]);

  const phone = useMemo(() => {
    const p = getPhone(phones);
    if (!p) {
      return;
    }
    return `${p.label} ${p.number}`;
  }, [phones]);

  return (
    <div className={`contact-list-item__attributes ${Classes.TEXT_MUTED}`}>
      <div className="text-truncate contact-list-item__country">{ph(country)}</div>
      <div className="text-truncate contact-list-item__email">{ph(email)}</div>
      <div className="text-truncate contact-list-item__phone">{ph(phone)}</div>
      <div className="text-truncate contact-list-item__contact">{ph(<ResponsibleUser contact={contact} />)}</div>
      <div className="text-truncate contact-list-item__role">{ph(role_name)}</div>
      <div className="text-truncate contact-list-item__division">
        {<ContactDivisions division_ids={division_ids} />}
      </div>
      <div className="text-truncate contact-list-item__created">
        <DateElement date={created_at} mfxIcon="created-at" />
      </div>
      <div className="text-truncate contact-list-item__last">
        <DateElement date={last_login_at} mfxIcon="login-at" customHoverLabel="Last Login at" />
      </div>
    </div>
  );
});
