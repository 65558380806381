import { UmGenericListShowcase, UmGenericListCollection } from '@mediafellows/mm3-types';
import { useCallback } from 'react';

import { useStore } from 'store';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { deleteList } from 'utils/apis/showcase';
import { pluralEntityInflect } from 'utils/general';
import { Intent } from 'utils/ui';
import { IShowcaseActionConfig, IShowcaseActionName } from './types';
import { formatWebsiteTypes } from 'components/showcase-form/utils';
import { shouldDisplayDelete } from './acl';

type IShowcaseOrCollection = UmGenericListShowcase | UmGenericListCollection;
export const useDeleteAction = (
  items: IShowcaseOrCollection[],
  options: IUseActionsOptionParam<IShowcaseOrCollection>,
): IShowcaseActionConfig => {
  const { toastStore, dialogStore } = useStore();

  const type = formatWebsiteTypes(items[0].type);
  const { entityWithDemonstrative, entity, entityWithCount } = pluralEntityInflect(type, items.length);

  const executeDelete = useCallback(async () => {
    try {
      const itemIds = items.reduce((acc, item) => (item?.id ? [...acc, item.id] : acc), []);
      const deleted = await deleteList(itemIds);

      toastStore.success(`${entityWithCount} ${items.length === 1 ? 'was' : 'were'} deleted`);

      await options?.onSuccess?.(IShowcaseActionName.DELETE, deleted.object);
    } catch (error) {
      toastStore.error(`${entity} delete failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [items, toastStore, entityWithCount, options, entity]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: `Are you sure you want to delete ${entityWithDemonstrative}?`,
      onConfirm: executeDelete,
    });
  }, [dialogStore, entityWithDemonstrative, executeDelete]);

  return {
    name: IShowcaseActionName.DELETE,
    shouldDisplay: shouldDisplayDelete,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    requiredPowers: items[0].type === 'List::Showcase' ? ['um/list_delete_showcases'] : undefined,
    handler,
  };
};
