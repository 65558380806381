import React, { useState, useEffect } from 'react';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { SmallLoader, Thumbnail } from 'components/thumbnail';
import { DateListElement } from 'components/date-list-element/date-list-element';
import { IconAlias } from 'icons';

import { Routes } from 'utils/routes';
import { ListItemCountLink } from 'utils/ui';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { isEntityIngesting } from 'utils/hooks/refresh-preview-ingest';
import { Classes } from 'utils/ui';

import { DetailsPageTabs, IGroup } from 'types';
import { useMainAssetCountInGroup } from 'utils/hooks/asset';

import './style.scss';

interface IProducerPortalDataSectionListItemProps {
  contextMenu?: React.ReactElement;
  screeningRoom: IGroup;
  isChecked?: boolean;
  isCheckedDisabled?: boolean;
  handleClick?: (e) => void;
}

export const ScreeningRoomDataSectionListItem: React.FC<IProducerPortalDataSectionListItemProps> = observer(
  ({ screeningRoom, contextMenu, handleClick, isChecked, isCheckedDisabled }) => {
    const { id, created_at, updated_at, preview_image, name, users_count } = screeningRoom;
    const detailsPageLink = `${Routes.SCREENING_ROOMS}/${id}`;
    const [itemChecked, setItemChecked] = useState(isChecked);
    const [assetCount] = useMainAssetCountInGroup(id);

    useEffect(() => {
      setItemChecked(isChecked);
    }, [isChecked]);

    return (
      <div
        className={cx('entity-list-item-row', {
          'entity-list-item--deleted': isEntityDeleted(screeningRoom),
        })}
        onClick={handleClick}
      >
        <div className="entity-list-item-row__checkbox">
          <Checkbox onChange={handleClick} checked={itemChecked} disabled={isCheckedDisabled} />
        </div>
        <div className="entity-list-item-row__thumb">
          <Thumbnail
            fallbackIconName={IconAlias.SCREENING_ROOM}
            image={preview_image?.url}
            placeholder={isEntityIngesting(screeningRoom) && <SmallLoader size={30} />}
          />
        </div>
        <div className="entity-list-item-row__content">
          <div className="entity-list-item-row__title" title={name}>
            <Link to={`${Routes.SCREENING_ROOMS}/${screeningRoom?.id}`}>
              <h4 className="text-nowrap text-truncate">{name}</h4>
            </Link>
            {Boolean(contextMenu) && contextMenu}
          </div>
          <div className={cx('screening-room-list-item-row__attributes', Classes.TEXT_MUTED)}>
            <div className="text-truncate screening-room-list-item__assets">
              <ListItemCountLink
                entityCount={assetCount}
                link={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`}
                linkText="Asset"
              />
            </div>
            <div className="text-truncate screening-room-list-item__products">
              <ListItemCountLink
                entityCount={users_count}
                link={`${detailsPageLink}/${DetailsPageTabs.CONTACTS}`}
                linkText="Contact"
              />
            </div>

            <DateListElement
              date={created_at}
              className="text-truncate screening-room-list-item-row__created"
              mfxIcon="created-at"
            />
            <DateListElement
              className="text-truncate screening-room-list-item-row__updated"
              date={updated_at}
              mfxIcon="updated-at"
            />
          </div>
        </div>
      </div>
    );
  },
);
