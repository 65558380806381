import React, { useCallback } from 'react';
import { startCase } from 'lodash';

import { FormMultiSelect, FormSelect, IUseFormReturn } from 'helpers/form';
import { queryContacts } from 'utils/apis/contacts';
import { IGroup } from 'types';
import { getEntityLabel } from 'utils/general';
import { IAddToGroupForm, IAddToGroupEntityType } from './types';

interface IFormAddToGroupItemsForm {
  form: IUseFormReturn<IAddToGroupForm>;
  entity: IAddToGroupEntityType;
  items?: Array<{ label: string; value: string }>;
  group?: IGroup;
  groupTitle?: string;
  omni?: boolean;
}

const fetchValues: {
  [k in IAddToGroupEntityType];
} = {
  users: queryContacts,
};

export const ShareBasketForm: React.FC<IFormAddToGroupItemsForm> = ({
  items,
  entity,
  groupTitle,
  form,
  omni = false,
}) => {
  const { formData, handlers } = form;
  const itemLabel = startCase(getEntityLabel(entity));

  const fetchItems = useCallback(
    (params) => {
      return fetchValues[entity](params);
    },
    [entity],
  );

  return (
    <>
      <FormMultiSelect
        fetchValues={fetchItems}
        name="item_ids"
        label={itemLabel}
        {...formData.item_ids}
        {...handlers}
        options={items}
        required
        omni={omni}
      />

      <FormSelect name="basket_id" label={groupTitle} {...formData.group_id} {...handlers} disabled required large />
    </>
  );
};
