import React from 'react';

import { DataSectionPage } from 'components/layout';
import { SmartGroupDataSection } from 'components/smart-group/data-section';
import { Button } from '@mantine/core';
import { useSmartGroupDialog } from 'components/smart-group/form-hook';

export const SmartGroups: React.FC = () => {
  const [toggleSortMode] = useSmartGroupDialog();
  return (
    <DataSectionPage>
      <SmartGroupDataSection
        headerRightSection={
          <Button size="xs" className="me-2" onClick={toggleSortMode} variant="primary">
            Create
          </Button>
        }
      />
    </DataSectionPage>
  );
};
