import React from 'react';
import { noop, startCase, uniq } from 'lodash';

import { AssetSearchItem } from 'components/asset/asset-search-item';
import {
  IRawTreeOption,
  IOmniTreeSelectOption,
  IAssetTreeOption,
  ITreeNode,
  IOmniTreeNode,
} from 'helpers/form/fields/form-select';
import { IAsset, ItemId } from 'types';

export type IOmniCheckboxOption = IRawTreeOption & { asset?: IAsset };

export const formatOmniOptionId = ({ childId, parentId }: { childId?: ItemId; parentId?: ItemId }): string => {
  if (!childId && !parentId) return '';
  return parentId && childId ? `${parentId}-${childId}` : String(childId);
};

const formatAssetOmniOption = (
  option: IOmniCheckboxOption,
  parentId?: string,
  showMetadataHover?: boolean,
): IOmniTreeSelectOption => {
  const label = option.asset ? (
    <AssetSearchItem
      key={`asset-${formatOmniOptionId({ ...option, childId: option.id, parentId })}`}
      asset={option.asset}
      handleSelect={noop}
      showMetadata={showMetadataHover}
    />
  ) : (
    option.name
  );

  return {
    label,
    value: parentId ? formatOmniOptionId({ ...option, childId: option.id, parentId }) : option.id,
    parentId,
    id: option.id,
    classification: option?.asset?.classification,
    className: `form-tree-select-option${option.asset ? '--omni' : ''} ${!option.parent_ids && 'empty'}`,
  };
};

export const getAssetsCount = (
  assetOptions?: IOmniTreeNode[],
  value?: ItemId[],
): { count: number; checkedCount: number } => {
  const checkedSet = new Set(value || []);
  const counted = new Set();

  return (assetOptions || []).reduce(
    ({ count, checkedCount }, { id }) => {
      if (counted.has(id)) {
        // case where an asset is assigned to multiple products
        return { count, checkedCount };
      }
      counted.add(id);
      count += 1;
      const isChecked = checkedSet.has(`${id}`);

      checkedCount += isChecked ? 1 : 0;

      return { count, checkedCount };
    },
    { count: 0, checkedCount: 0 },
  );
};

export const parseMainClassificationCount = (optionsTree: IOmniTreeNode[], value?: ItemId[]): IOmniTreeNode[] => {
  return optionsTree.map((productNode) => {
    productNode?.children?.map((mainClassification) => {
      const { count, checkedCount } = getAssetsCount(mainClassification?.children, value);
      mainClassification.label += ` (${checkedCount} / ${count})`;
    });
    return productNode;
  });
};

export const prepareOmniOptions = (
  options?: IOmniCheckboxOption[],
  showMetadataHover?: boolean,
): IOmniTreeSelectOption[] => {
  const classificationAdded = {};
  return (options || []).reduce((acc, option): IOmniTreeSelectOption[] => {
    const mainClassification = option?.asset?.main_classification
      ? option.asset.main_classification
      : 'unclassified_asset';

    if (option.parent_ids?.length) {
      const omniOptions = option.parent_ids.reduce((acc, productId): IOmniTreeSelectOption[] => {
        const parentId = mainClassification + '-' + productId;
        const opts = [formatAssetOmniOption(option, parentId, showMetadataHover)];
        if (!classificationAdded[parentId]) {
          classificationAdded[parentId] = true;
          opts.push({
            label: `${startCase(mainClassification)}s`,
            id: parentId,
            value: parentId,
            parentId: productId,
          });
        }
        return [...acc, ...opts];
      }, [] as IOmniTreeSelectOption[]);

      return [...acc, ...omniOptions];
    }
    if (option.parent_id) {
      const parentId = option.parent_id;
      const omniOptions = [formatAssetOmniOption(option, parentId, showMetadataHover)];
      if (!classificationAdded[parentId]) {
        classificationAdded[parentId] = true;
        const id = mainClassification + '-' + option.parent_id;
        omniOptions.push({ label: mainClassification, id, value: id, parentId: option.id });
      }
      return [...acc, ...omniOptions];
    }
    return [...acc, formatAssetOmniOption(option, undefined, showMetadataHover)];
  }, []);
};

export const parseOmniCheckboxValues = (values: string[]): string[] => {
  return values?.length ? uniq(values?.map((value) => value?.split('-')[1])) : values;
};

export const filterByMainClassification = (
  items: (IAssetTreeOption | IOmniTreeSelectOption)[] | undefined,
  classification: string,
): (IAssetTreeOption | IOmniTreeSelectOption)[] => {
  if (!items) return [];
  return items.filter(({ classification: assetClassification }) => assetClassification?.includes(classification));
};

export const getCountByClassification = (
  assetOptions: IOmniTreeNode[],
  checked: string[],
): { count: number; checkedCount: number } => {
  const checkedSet = new Set(checked);
  const counted = new Set();

  return assetOptions?.reduce(
    ({ count, checkedCount }, { value, id }) => {
      if (counted.has(id)) {
        // case where an asset is assigned to multiple products
        return { count, checkedCount };
      }
      counted.add(id);
      count += 1;
      const isChecked = checkedSet.has(value);

      checkedCount += isChecked ? 1 : 0;

      return { count, checkedCount };
    },
    { count: 0, checkedCount: 0 },
  );
};

export const formatFilterClassification = (classification: string): string => {
  const [mainClassification, ...rest] = classification.split('/');
  return startCase(rest.join('/') || mainClassification);
};

export const getAssetCountByClassification = (assetOptions: IAssetTreeOption[], classification: string): number => {
  return (assetOptions || []).filter(({ classification: c }) => c?.includes(classification)).length;
};

export const formatAssetFilterLabel = (
  classification: string,
  { count, checkedCount }: { count: number; checkedCount: number },
): string => {
  return `All ${formatFilterClassification(classification)} (${checkedCount} / ${count})`;
};

export const formatFilterLabel = (
  classification: string,
  { count, checkedCount }: { count: number; checkedCount: number },
): string => {
  return `${formatFilterClassification(classification)} (${checkedCount} / ${count})`;
};

export const disableChildlessParents = (parentNode: ITreeNode): ITreeNode => ({
  ...parentNode,
  disabled: !parentNode.children?.length,
});
