import { IGroup, IIdentifiable } from 'types';

import { useGroupAccessChangeDialog } from 'components/group-access-change';

import { shouldDisplaySingleNotDeleted as shouldDisplay } from 'utils/actions/common';
import { IGuildActionConfig, IGuildActionName } from './types';
import { ITitleDividerActionName, IUseActionsOptionParam } from 'utils/actions';

export const useChangeAccessAction = (
  items: (IGroup & IIdentifiable)[],
  options: IUseActionsOptionParam,
): IGuildActionConfig => {
  const [openDialog] = useGroupAccessChangeDialog(items, {
    ...options,
    groupName: ITitleDividerActionName.GUILD,
    hideAccessCheckboxes: true,
    dialogTitle: 'Change settings',
  });

  return {
    name: IGuildActionName.CHANGE_ACCESS,
    shouldDisplay,
    icon: 'key',
    title: 'Change settings',
    handler: openDialog,
  };
};
