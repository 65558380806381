import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { IColumn } from 'components/list-table';
import { UserAvatar } from 'components/user-avatar';

import { Routes } from 'utils/routes';

import { IContact, IDeliveryRecipient } from 'types';
import { formatFullName } from 'utils/general';
import { isEntityDeleted } from 'utils/is-entity-deleted';

export interface IOrderRecipientContact extends IDeliveryRecipient, IContact {
  recipient_id: string;
}

const fallback = '—';

const RecipientRow: React.FC<{
  recipient: IOrderRecipientContact;
  value?: string;
  className?: string;
}> = ({ recipient, value, className }) => {
  return recipient['@type'] === 'user' ? (
    <div className={cx(className, { 'entity-list-item--deleted': isEntityDeleted(recipient) })}>
      {value || fallback}
    </div>
  ) : (
    <div className={cx(className, { 'entity-list-item--deleted': isEntityDeleted(recipient) })}>N/A</div>
  );
};

export const DeliveryRecipientAvatar: React.FC<{
  recipient: IOrderRecipientContact;
  className?: string;
}> = ({ recipient, className }) => {
  return (
    <div className={cx('delivery-list-table__contact', className)}>
      <UserAvatar
        className="delivery-list-table__contact-avatar"
        user={{ ...recipient, first_name: recipient['first_name'] || recipient['recipient_id'] }}
      />
      {recipient['@type'] === 'user' ? (
        <Link
          className="entity-list-item-row__title delivery-list-table__contact-name text-truncate"
          to={`${Routes.CONTACTS}/${recipient?.['id']}`}
        >
          {formatFullName(recipient, false)}
        </Link>
      ) : (
        <span className="entity-list-item-row__title delivery-list-table__contact-name text-truncate">
          {recipient['recipient_id']}
        </span>
      )}
    </div>
  );
};

export const orderRecipientColumns: IColumn<IOrderRecipientContact>[] = [
  {
    title: 'Contact',
    renderer: (recipient: IOrderRecipientContact) => (
      <DeliveryRecipientAvatar
        className={isEntityDeleted(recipient) ? 'entity-list-item--deleted' : undefined}
        recipient={recipient}
      />
    ),
    columnClassName: 'delivery-recipient__avatar',
  },
  {
    title: 'Function',
    renderer: (recipient: IOrderRecipientContact) => (
      <RecipientRow recipient={recipient} value={recipient['function']} className="text-truncate" />
    ),
    columnClassName: 'delivery-recipient__function',
  },
  {
    title: 'Organization',
    renderer: (recipient: IOrderRecipientContact) => (
      <RecipientRow recipient={recipient} value={recipient?.['organization']?.['name']} className="text-truncate" />
    ),
    columnClassName: 'delivery-recipient__organization',
  },
  {
    title: 'Country',
    renderer: (recipient: IOrderRecipientContact) => (
      <RecipientRow recipient={recipient} value={recipient?.['country']?.['name']} className="text-truncate" />
    ),
    columnClassName: 'delivery-recipient__country',
  },
];
