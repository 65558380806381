import { startCase } from 'lodash';
import { additionalFilters } from 'components/asset/asset-filters/additional-filters';
import {
  IFiltersDefinition,
  InFilter,
  QFilter,
  RangeFilter,
  EqFilter,
  Model,
  NotExistFilter,
} from 'helpers/filters/types';
import {
  ContextDataProvider,
  DynamicDataProvider,
  dynamicDataExecutorCache,
} from 'helpers/data-provider/option-data-provider';
import { IResult, chipmunk } from 'utils/chipmunk';
import { flags } from 'utils/flags';
import { DynamicTreeDataProvider, unflattenTree } from 'helpers/data-provider/tree-data-provider';
import { IDivision } from 'types';
import { getAssetModel } from 'utils/asset';

export const defaultAssetFilters: IAssetFilters = {
  _: new QFilter(''),
  classification: new EqFilter('classification', ''),
  main_classification: new EqFilter('main_classification', ''),
  type: new EqFilter('type', ''),
  parent_id: new NotExistFilter('parent_id', false),
  protection_levels: new InFilter('protection_levels', []),
  status: new EqFilter('status', ''),
  file_status: new EqFilter('file_status', ''),
  products_count: new EqFilter('products_count', false),
  updated_at: new RangeFilter('updated_at', '', ''),
  created_at: new RangeFilter('created_at', '', ''),
  access_level: new InFilter('access_level', []),
  owner_id: new EqFilter('owner_id', ''),
  owner_organization_id: new EqFilter('owner_organization_id', ''),
  division_ids: new InFilter('division_ids', []),
  ...additionalFilters,
};

export const assetInitFilters = flags.isMm3Assets
  ? {
      parent_id: new NotExistFilter('parent_id', true),
    }
  : undefined;

export const assetStats = [
  'classification',
  'status',
  'file_status',
  'access_level',
  'protection_levels',
  'type',
  'division_ids',
  ...Object.keys(additionalFilters),
].join(',');

export interface IAssetFilters extends IFiltersDefinition {
  _: QFilter;
  classification: EqFilter;
  main_classification: EqFilter;
  type: EqFilter;
  parent_id: NotExistFilter;
  protection_levels: InFilter;
  status: EqFilter;
  file_status: EqFilter;
  products_count: EqFilter;
  updated_at: RangeFilter;
  created_at: RangeFilter;
  access_level: InFilter;
  owner_id: EqFilter;
  owner_organization_id: EqFilter;
  division_ids: InFilter;
}

export const protectionLevelsOptionsProvider = new ContextDataProvider(getAssetModel(), 'protection_levels');
export const statusOptionsProvider = new ContextDataProvider(
  getAssetModel(),
  flags.isMm3Assets ? 'status' : 'statuses',
);
export const fileStatusOptionsProvider = new DynamicDataProvider<{ value: string }>(
  async (): Promise<IResult<{ value: string }>> => ({
    objects: [{ value: 'ready' }, { value: 'invalid' }, { value: 'ingesting' }, { value: 'reingesting' }],
    object: { value: 'ready' },
  }),
  (res: IResult<{ value: string }>) => {
    return (res as IResult<{ value: string }>).objects?.map(({ value }) => ({ value, label: value }));
  },
);

const classificationLabel = flags.isMm3Assets ? 'classification' : 'classifications';
export const classificationsOptionsProvider = new ContextDataProvider(
  getAssetModel(),
  classificationLabel,
  false,
  (classification: string) => {
    const [mainClassification, ...cl] = classification.split('/');
    if (!cl.length) {
      return startCase(mainClassification);
    }

    return `${startCase(mainClassification)}: ${startCase(cl.join('/'))}`;
  },
);

export const accessOptionsProvider = new ContextDataProvider(
  getAssetModel(),
  flags.isMm3Assets ? 'access_level' : 'access_levels',
);

export const defaultParamComponentValues = (queryParams): { include_deleted: boolean } => ({
  include_deleted: !!queryParams['include_deleted'] && queryParams['include_deleted'].toString() === 'true',
});

export const divisionTreeProvider = new DynamicTreeDataProvider(
  dynamicDataExecutorCache('divisions', () => chipmunk.run(({ action }) => action(Model.DIVISIONS, 'query'))),
  (data: IResult<IDivision>) =>
    unflattenTree(data.objects.map((d) => ({ label: d.name, value: d.id, ancestry: d.ancestry || '' }))),
);
