import React, { useMemo } from 'react';
import cx from 'classnames';

import { DetailsPageTabs, IAssetAggregation, IMm3Asset, IMm3AssetType } from 'types';

import { AssetIconLink } from './product-assets';

interface IAssetLinkUIBasicProps {
  baseUrl?: string;
  muted?: boolean;
  disabled?: boolean;
  fallback?: string;
  className?: string;
}

export const DescendantsMm3Assets: React.FC<IAssetLinkUIBasicProps & { assets?: IMm3Asset[] }> = ({
  assets,
  baseUrl,
  muted,
  fallback = '0',
  disabled,
  className,
}) => {
  const assetTypesCount = useMemo<Record<string | IMm3AssetType, number>>(() => {
    const countByType = {} as Record<string | IMm3AssetType, number>;

    assets?.map((asset) => {
      const { type } = asset || {};
      if (type === IMm3AssetType.SUBTITLE) {
        countByType.subtitle = (countByType.subtitle || 0) + 1;
        return;
      }

      if (type === IMm3AssetType.AUDIO) {
        countByType.audio = (countByType.audio || 0) + 1;
        return;
      }

      countByType.ancillary = (countByType.ancillary || 0) + 1;
    });

    return countByType;
  }, [assets]);
  const { subtitle, audio, ancillary } = assetTypesCount;
  const showFallback = Object.values(assetTypesCount).every((e) => !e);
  return (
    <div className={cx(className, { ['d-flex flex-row']: !className?.includes('d-block') })}>
      {subtitle ? (
        <AssetIconLink
          disabled={disabled}
          muted={muted}
          key="subtitle"
          count={subtitle}
          type={IMm3AssetType.SUBTITLE}
          baseUrl={baseUrl + '/' + DetailsPageTabs.SUBTITLES}
          skipTypeParam
        />
      ) : (
        <></>
      )}
      {audio ? (
        <AssetIconLink
          disabled={disabled}
          muted={muted}
          key="audio"
          count={audio}
          type="audio"
          baseUrl={baseUrl + '/' + DetailsPageTabs.AUDIO_TRACKS}
          skipTypeParam
        />
      ) : (
        <></>
      )}

      {ancillary ? (
        <AssetIconLink
          disabled={disabled}
          muted={muted}
          key="ancillary"
          count={ancillary}
          type="ancillary"
          baseUrl={baseUrl + '/' + DetailsPageTabs.SUB_ASSETS}
          skipTypeParam
        />
      ) : (
        <></>
      )}
      {showFallback && fallback}
    </div>
  );
};

interface IAssetTypeAggregationsProps {
  aggregations?: IAssetAggregation;
  baseUrl?: string;
  muted?: boolean;
  disabled?: boolean;
  fallback?: string;
}

export const DescendantsAssetsAggregations: React.FC<IAssetTypeAggregationsProps> = ({
  aggregations,
  baseUrl,
  muted,
  fallback = '0',
  disabled = false,
}) => {
  const buckets = aggregations?.count_by_type;
  const assetTypesCount = useMemo<Record<string | IMm3AssetType, number>>(() => {
    const countByType = {} as Record<string | IMm3AssetType, number>;

    buckets?.map(({ value, count }) => {
      countByType[value] = (countByType[value] || 0) + count;
    });

    return countByType;
  }, [buckets]);
  const { [IMm3AssetType.SUBTITLE]: subtitle, [IMm3AssetType.AUDIO]: audio, report, ...rest } = assetTypesCount;
  const hasAssets = Object.values(rest).some((e) => e > 0);
  return (
    <div className="d-flex flex-row">
      {subtitle ? (
        <AssetIconLink
          disabled={disabled}
          muted={muted}
          key="subtitle"
          count={subtitle}
          type={IMm3AssetType.SUBTITLE}
          baseUrl={baseUrl + '/' + DetailsPageTabs.SUBTITLES}
          skipTypeParam
        />
      ) : (
        <></>
      )}
      {audio ? (
        <AssetIconLink
          disabled={disabled}
          muted={muted}
          key="audio"
          count={audio}
          type="audio"
          baseUrl={baseUrl + '/' + DetailsPageTabs.AUDIO_TRACKS}
          skipTypeParam
        />
      ) : (
        <></>
      )}
      {report ? (
        <AssetIconLink
          disabled={disabled}
          muted={muted}
          key="report"
          count={report}
          type="report"
          baseUrl={baseUrl + '/' + DetailsPageTabs.REPORTS}
          skipTypeParam
        />
      ) : (
        <></>
      )}
      {hasAssets ? (
        Object.entries(rest).reduce((acc, [assetType, count]) => {
          return rest[assetType]
            ? [
                ...acc,
                <AssetIconLink
                  disabled={disabled}
                  muted={muted}
                  key={assetType}
                  count={count}
                  type={assetType}
                  baseUrl={baseUrl + '/' + DetailsPageTabs.SUB_ASSETS}
                />,
              ]
            : acc;
        }, [])
      ) : (
        <></>
      )}
      {Object.values(assetTypesCount).some((e) => e > 0) ? '' : fallback}
    </div>
  );
};
