export const presetKeysToOmit: Array<string> = [
  'id',
  'preview',
  'file_name',
  'name',
  'type',
  'parent_id',
  'uploadURL',
  'fileData',
];
