import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import last from 'lodash/last';

import { IFormMultiSelectOption, useForm } from 'helpers/form';
import { FormMultiSelect, IFormMultiSelectProps } from 'helpers/form/fields';
import { Model } from 'helpers/filters/types';
import { fetchListItemsOptions, IEntitiesToQuery } from 'utils/apis/showcase-item';
import { IQueryParams, ISearchFilter, IStyled, IItem, ICustomItemRendererProps } from 'types';

import { generateSeparatorObject } from './utils';

const itemsInitialValues = {
  items: [],
};

interface IFormMultiEntitiesSelectProps extends Partial<IFormMultiSelectProps>, IStyled {
  withSeparator?: boolean;
  onSelectedItemsChange: (items: IFormMultiSelectOption[]) => void;
  allowedItemTypes?: IEntitiesToQuery[];
  additionalFilters?: { [key in IEntitiesToQuery]?: ISearchFilter[] };
  isAncestryMode?: boolean;
  customRenderItemParser?: (item: IFormMultiSelectOption) => ICustomItemRendererProps;
  hideSelectedItems?: boolean;
  hideClearAllButton?: boolean;
}

const FormMultiEntitiesSelect: React.FC<IFormMultiEntitiesSelectProps> = observer(
  ({
    onSelectedItemsChange,
    withSeparator,
    allowedItemTypes = [],
    additionalFilters,
    isAncestryMode,
    customRenderItemParser,
    ...props
  }) => {
    const [separatorId, setSeparatorId] = useState<number>(1);

    const fetchItems = useCallback(
      async ({ q, ids }: IQueryParams, ancestryFilters: ISearchFilter[]): Promise<IItem[]> => {
        const options = await fetchListItemsOptions({ q, ids }, additionalFilters, allowedItemTypes, ancestryFilters);

        if (withSeparator) {
          return [generateSeparatorObject(separatorId), ...options];
        }
        return options;
      },
      [additionalFilters, allowedItemTypes, separatorId, withSeparator],
    );

    const wasNewSeparatorSelected = useCallback(
      (items: IFormMultiSelectOption[], separatorId: number): boolean => {
        if (!withSeparator) {
          return false;
        }

        const latestSeparatorId = generateSeparatorObject(separatorId).id;
        const lastSelectedItem = last(items);
        return lastSelectedItem?.value === latestSeparatorId;
      },
      [withSeparator],
    );

    const handleSelectedItemsChange = useCallback(
      (items: IFormMultiSelectOption[]): void => {
        setSeparatorId((id) => (wasNewSeparatorSelected(items, id) ? id + 1 : id));
        onSelectedItemsChange(items);
      },
      [onSelectedItemsChange, wasNewSeparatorSelected],
    );

    const { handlers, formData } = useForm<{ items: (number | string)[] }>(itemsInitialValues, Model.ASSETS);

    return (
      <FormMultiSelect
        omni
        name="items"
        label="Items"
        {...handlers}
        {...formData.items}
        fetchValues={fetchItems}
        onSelectedItemsChange={handleSelectedItemsChange}
        isAncestryMode={isAncestryMode}
        customRenderItemParser={customRenderItemParser}
        {...props}
      />
    );
  },
);

export default FormMultiEntitiesSelect;
