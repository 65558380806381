import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch } from '@mantine/core';

import { FilterCheckbox, FilterDateRange, FilterSelect, FilterText } from 'helpers/filters/fields';
import { EqFilter, IFiltersDefinition, InFilter, QFilter, RangeFilter, Model } from 'helpers/filters/types';
import { toggleParam, submitFilters } from 'helpers/filters/helpers';
import { ContextDataProvider } from 'helpers/data-provider/option-data-provider';
import { parseQueryParams } from 'utils/general';
import { useStore } from 'store';
import { ownersOptionsProvider, useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';
import { useLocation } from 'react-router-dom';

export interface IGuildsFilters extends IFiltersDefinition {
  _: QFilter;
  updated_at: RangeFilter;
  created_at: RangeFilter;
  owner_id: EqFilter;
  access_level: InFilter;
}

const accessOptionsProvider = new ContextDataProvider(Model.ACCESS_GROUPS, 'access_levels');

export const customGuildsParam = {
  include_deleted: false,
  sort: 'updated_at',
};

const defaultGuildsParamValues = (queryParams): typeof customGuildsParam => ({
  include_deleted: queryParams['include_deleted'] === true,
  sort: queryParams['sort'],
});

export const GuildsFilters: React.FC = observer(() => {
  const { dataSectionStore } = useStore();
  const location = useLocation();
  const { searchStore } = dataSectionStore;
  const queryParams = parseQueryParams(location.search);
  const [paramComponentValues, setParamComponentValues] = useState(defaultGuildsParamValues(queryParams));

  const handleSubmit = useCallback((action): void => {
    submitFilters({
      action,
      setParamComponentValues,
      customParams: customGuildsParam,
    });
  }, []);

  const [filterValues, filterHandlers] = useFilters<IGuildsFilters>(dataSectionStore, handleSubmit);

  const handleToggleParam = (event): void => {
    toggleParam({
      event,
      paramComponentValues,
      setParamComponentValues,
    });
  };

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />

      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterText
            label="Search List"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Updated at"
            name="updated_at"
            filter={filterValues.updated_at}
            onChange={filterHandlers.onChange}
          />

          <FilterDateRange
            label="Created at"
            name="created_at"
            filter={filterValues.created_at}
            onChange={filterHandlers.onChange}
          />

          <FilterCheckbox
            label="Access"
            name="access_level"
            optionsProvider={accessOptionsProvider}
            filter={filterValues.access_level}
            onChange={filterHandlers.onChange}
            disabled={searchStore.running}
            aggregations={searchStore.aggregationValues('access_level')}
          />

          <FilterSelect
            label="Owner"
            name="owner_id"
            placeholder="Select Owner"
            optionsProvider={ownersOptionsProvider}
            filter={filterValues.owner_id}
            onChange={filterHandlers.onChange}
          />

          <Switch
            label="Include deleted"
            data-param={'include_deleted'}
            onChange={handleToggleParam}
            checked={paramComponentValues.include_deleted}
          />
        </form>
      </div>
    </div>
  );
});
