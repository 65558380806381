import React, { useCallback, useMemo } from 'react';
import { Checkbox } from '@mantine/core';
import { uniq, groupBy } from 'lodash';
import cx from 'classnames';
import { IAssetTreeOption, IOmniTreeNode } from 'helpers/form/fields/form-select';

import { getAllChildrenNodes } from 'helpers/form/fields/form-select/tree-selector-utils';
import { formatFilterLabel, getAssetsCount } from './utils';

import { IFieldData } from 'helpers/form/types';

import { ItemId } from 'types/utility';

import './style.scss';

import { IAssetMainClassification } from 'types/asset';

interface IFiltersProps extends IFieldData<ItemId[], IAssetTreeOption[]> {
  handleChange: (value: ItemId[]) => void;
  optionsTree: IOmniTreeNode[];
  value?: ItemId[];
}

export const AssetClassificationFilters: React.FC<IFiltersProps> = ({ handleChange, optionsTree, value }) => {
  const assetsOptions = useMemo(() => getAllChildrenNodes(optionsTree), [optionsTree]);

  const { assetClassifications, mainClassifications } = useMemo(() => {
    const assetClassifications = uniq(
      assetsOptions.reduce((acc, { classification }) => (classification ? [...acc, classification] : acc), []),
    ).sort();

    const mainClassifications = uniq(
      assetClassifications.reduce((acc: string[], classification) => {
        const mainClassification = classification?.split('/')[0];
        if (mainClassification) {
          acc.push(mainClassification);
        }
        return acc;
      }, []),
    );
    const withMainClassification = assetClassifications.concat(
      mainClassifications.map((mainClassification) => `all_${mainClassification}s`),
    );
    return { assetClassifications: withMainClassification, mainClassifications };
  }, [assetsOptions]);

  const filters = useMemo<Record<string, { count: number; checkedCount: number }>>(() => {
    const assetsByClassification = groupBy(assetsOptions, 'classification');

    assetsOptions.map((asset) => {
      const mainClassification = asset?.classification?.split('/')[0];
      if (!mainClassification) {
        return null;
      }
      if (!assetsByClassification[`all_${mainClassification}s`]) {
        assetsByClassification[`all_${mainClassification}s`] = [];
      }
      return assetsByClassification[`all_${mainClassification}s`].push(asset);
    });

    return Object.keys(assetsByClassification).reduce((acc, classification) => {
      acc[classification] = getAssetsCount(assetsByClassification[classification], value);
      return acc;
    }, {});
  }, [value, assetsOptions]);

  const handleFilterChange = useCallback(
    (e) => {
      const [filterName, isChecked] = [e.target?.name, e.target?.checked];
      const safeValue = value || [];
      const allAssetNodes = getAllChildrenNodes(optionsTree);
      const classificationAssetsById = allAssetNodes.reduce((acc, asset) => {
        if (asset.classification === filterName && asset.id) {
          acc[asset.id] = asset.value;
        }
        return acc;
      }, {});

      const newValue = isChecked
        ? safeValue.concat(Object.keys(classificationAssetsById))
        : safeValue.filter((id) => !classificationAssetsById[id]);

      handleChange(newValue);
    },
    [handleChange, optionsTree, value],
  );

  const handleMainClassificationFilter = useCallback(
    (e) => {
      const [filterName, isChecked] = [e.target?.name, e.target?.checked];
      const safeValue = value || [];
      const allAssetNodes = getAllChildrenNodes(optionsTree);
      let newValue: ItemId[] = [];
      if (isChecked) {
        newValue = allAssetNodes.reduce((acc: string[], e) => {
          if (e?.id && e.classification?.startsWith(filterName)) {
            acc.push(e.id);
          }
          return acc;
        }, safeValue);
      } else {
        const valuesToRemove = new Set();
        allAssetNodes.map((e) => {
          if (e?.id && e.classification?.startsWith(filterName)) {
            valuesToRemove.add(e.id);
          }
        });
        newValue = safeValue.filter((id) => !valuesToRemove.has(id));
      }
      return handleChange(uniq(newValue));
    },
    [handleChange, optionsTree, value],
  );

  const filtersByClassification = useMemo(() => {
    const initialValue = mainClassifications.reduce((acc, classification: IAssetMainClassification) => {
      const name = `all_${classification}s`;
      if (!filters[name]?.count) {
        return acc;
      }

      acc[classification] = [
        <Checkbox
          name={classification}
          label={formatFilterLabel(name, filters[name])}
          key={name}
          checked={filters[name].checkedCount === filters[name].count}
          indeterminate={filters[name].checkedCount !== filters[name].count && filters[name].checkedCount > 0}
          onChange={handleMainClassificationFilter}
        />,
      ];
      return acc;
    }, {} as Record<IAssetMainClassification, React.ReactNode[]>);

    return assetClassifications.reduce((acc, classification) => {
      if (!classification || classification.startsWith('all_')) {
        return acc;
      }
      const mainClassification = classification?.split('/')[0];
      const { count, checkedCount } = filters[classification] || {};
      const isSelected = count === checkedCount;
      if (!acc[mainClassification]) {
        acc[mainClassification] = [];
      }
      acc[mainClassification].push(
        <Checkbox
          key={classification}
          name={classification}
          label={formatFilterLabel(classification, { count, checkedCount })}
          indeterminate={Boolean(!isSelected && checkedCount)}
          checked={isSelected}
          onChange={handleFilterChange}
          className="asset-classification__checkboxes"
        />,
      );
      return acc;
    }, initialValue);
  }, [assetClassifications, filters, handleFilterChange, handleMainClassificationFilter, mainClassifications]);

  return (
    <>
      {Object.entries(filtersByClassification).map(([key, [mainFilter, ...elements]], index) => (
        <div key={key} className="d-flex">
          <div className={cx('form-omni-checkbox__actions ', { ['pt-0']: index > 0 })}>{mainFilter}</div>
          <div className={cx('form-omni-checkbox__actions w-100', { ['pt-0']: index > 0 })}>{elements}</div>
        </div>
      ))}
    </>
  );
};
