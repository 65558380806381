import React, { useState, useEffect, useCallback } from 'react';
import { Switch, ActionIcon, Tooltip } from '@mantine/core';
import { map } from 'lodash';
import { endOfDay } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  AnalyticsFormDateRangeFilter,
  FormGeoSelect,
  FormMultiSelect,
  FormSelect,
  FormProducts,
  FormRemoteSelect,
} from 'helpers/form';
import { today } from 'utils/date';
import { ExportButton } from 'components/analytics/filters/export-buttons';

import { IAnalyticsFiltersProps } from './types';
import { IAnalyticsPageValues, ISearchFilter } from 'types';
import { ContactInteractions, RecommendationDetailsInteractions, sectionToEntity } from 'components/analytics/utils';
import { MantineIcon } from 'utils/ui/icon';
import { Cross } from 'blueprint5-icons';
import { queryVideoAssets } from 'utils/apis/asset';
import { fetchClientContacts, fetchResponsibleContacts, queryContacts } from 'utils/apis/contacts';

const maxDate = endOfDay(today);

enum ExportTitle {
  'product' = 'product views',
  'user' = 'contact logins',
  'asset' = 'video screenings',
}

export const AnalyticsSectionFilters: React.FC<IAnalyticsFiltersProps> = (props) => {
  const {
    dateRange,
    minDate,
    maxDate: propMaxDate,
    handleDateRangeChange,
    onTerritoriesSelect,
    onContactsSelect,
    handleDescendantsChange,
    handleNonScreeningsAssetsChange,
    onProductsSelect,
    territories,
    contactIds,
    productIds,
    includeDescendants,
    resetFilters,
    exportQuery,
    exportGlobalAnalytics,
    assetIds,
    onAssetsSelect,
    onInteractionChange,
    interaction,
    interactionOptions,
    recipientIds,
    onRecipientsSelect,
    senderIds,
    onSendersSelect,
    includeNonScreeningsAssets,
    responsibleUserId,
    onResponsibleUserSelect,
    section,
  } = props;

  const location = useLocation();
  const [customKey, setCustomKey] = useState(uuidv4());
  const showCustomRecommendationFilters =
    (!interactionOptions && section === IAnalyticsPageValues.ANALYTICS_RECOMMENDATION) ||
    interaction === ContactInteractions.RECOMMENDATIONS_DETAILS;

  useEffect(() => {
    setCustomKey(uuidv4());
  }, [
    location,
    productIds,
    contactIds,
    includeDescendants,
    dateRange,
    assetIds,
    interaction,
    includeNonScreeningsAssets,
    responsibleUserId,
  ]);

  const entity = sectionToEntity[section as string];

  const onFilterContactsSelect = useCallback(
    async ({ contactIds }: { contactIds: string[] }) => {
      onResponsibleUserSelect?.({ responsibleUserId: '' });
      onContactsSelect?.({ contactIds });
    },
    [onResponsibleUserSelect, onContactsSelect],
  );

  const onResponsibleContactSelect = useCallback(
    async ({ responsibleUserId }: { responsibleUserId: string }) => {
      const filters: ISearchFilter[] = [['responsible_user_id', 'eq', responsibleUserId]];
      let ids: string[] = responsibleUserId ? map(await queryContacts({}, filters), 'id') : [];
      // pass a fake id, to mimic the original filter behavior
      ids = !ids.length && responsibleUserId ? ['-1'] : ids;

      onContactsSelect?.({ contactIds: ids });
      onResponsibleUserSelect?.({ responsibleUserId });
    },
    [onResponsibleUserSelect, onContactsSelect],
  );

  return (
    <div className="analytics-filters__wrapper" key={customKey}>
      {Boolean(onInteractionChange && interaction && interactionOptions) && (
        <FormSelect
          className="analytics-filters__selector"
          validation={{ valid: true }}
          name="interaction"
          value={interaction}
          onChange={onInteractionChange}
          options={interactionOptions}
          size="xs"
        />
      )}
      <AnalyticsFormDateRangeFilter
        className="align-self-lg-end"
        dateRange={dateRange}
        name="dateRange"
        minDate={minDate}
        maxDate={propMaxDate || maxDate}
        onChange={handleDateRangeChange}
        hideResetButton
      />

      {Boolean(territories && onTerritoriesSelect) && (
        <FormGeoSelect
          placeholder="Select Territories"
          className="analytics-filters__selector"
          key="territories"
          name="territories"
          value={territories}
          onChange={onTerritoriesSelect}
          hideClearAllButton
          size="xs"
          tagsSize="sm"
        />
      )}

      {!showCustomRecommendationFilters && Boolean(contactIds && onContactsSelect) && (
        <FormMultiSelect
          className="analytics-filters__selector contacts"
          placeholder="Select Contacts"
          name="contactIds"
          value={contactIds}
          fetchValues={fetchClientContacts}
          onChange={onFilterContactsSelect}
          hideClearAllButton
          inputSize="xs"
          tagsSize="sm"
        />
      )}

      {!showCustomRecommendationFilters && Boolean(onContactsSelect && onResponsibleUserSelect) && (
        <FormRemoteSelect
          className="analytics-filters__selector contacts responsible_contact"
          placeholder="Select Responsible Contact"
          name="responsibleUserId"
          value={responsibleUserId}
          fetchOptions={fetchResponsibleContacts}
          onChange={onResponsibleContactSelect}
        />
      )}

      {showCustomRecommendationFilters && (
        <>
          <FormMultiSelect
            className="analytics-filters__selector Recipients"
            placeholder="Select Recipients"
            name="recipientIds"
            value={recipientIds}
            fetchValues={queryContacts}
            onChange={onRecipientsSelect}
            hideClearAllButton
            inputSize="xs"
            tagsSize="sm"
          />
          <FormMultiSelect
            className="analytics-filters__selector contacts"
            placeholder="Select Senders"
            name="senderIds"
            value={senderIds}
            fetchValues={queryContacts}
            onChange={onSendersSelect}
            hideClearAllButton
            inputSize="xs"
            tagsSize="sm"
          />
        </>
      )}

      {Boolean(productIds && onProductsSelect) && (
        <FormProducts
          omni={false}
          placeholder="Select Products"
          name="productIds"
          className="analytics-filters__selector"
          value={productIds}
          onChange={onProductsSelect}
          hideClearAllButton
          inputSize="xs"
          tagsSize="sm"
        />
      )}

      {includeDescendants !== undefined && handleDescendantsChange && (
        <Switch
          className="d-flex align-items-center include-descendants__label"
          label="Include Descendants"
          onChange={handleDescendantsChange}
          checked={includeDescendants}
        />
      )}

      {interaction === RecommendationDetailsInteractions.PRODUCT_ASSET && (
        <Switch
          label="Include unscreened Assets"
          onChange={handleNonScreeningsAssetsChange}
          checked={includeNonScreeningsAssets}
          my="auto"
        />
      )}

      {Boolean(assetIds && onAssetsSelect) && (
        <FormMultiSelect
          className="analytics-filters__selector assets"
          placeholder="Select Assets"
          name="assetIds"
          value={assetIds}
          fetchValues={queryVideoAssets}
          onChange={onAssetsSelect}
          hideClearAllButton
          inputSize="xs"
          tagsSize="sm"
        />
      )}

      <ActionIcon
        color="gray.5"
        radius="sm"
        variant="subtle"
        className="analytics-filters__reset-button"
        onClick={resetFilters}
      >
        <MantineIcon icon={<Cross />} />
      </ActionIcon>

      {exportQuery && exportGlobalAnalytics && (
        <Tooltip label={`Export all ${ExportTitle[entity]} for the selected date range`} position={'top'}>
          <div>
            <ExportButton exportGlobalAnalytics={exportGlobalAnalytics} exportQuery={exportQuery} />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
