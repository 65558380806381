import React, { useCallback, useEffect, useMemo } from 'react';
import { FormCheckbox, useForm } from 'helpers/form';
import { observer } from 'mobx-react-lite';
import { Button, Divider, Switch } from '@mantine/core';

import { Model } from 'helpers/filters/types';
import { IAssetFile } from 'types/asset';

import 'components/upload-asset-dialog/index.scss';

interface IApplyPresetFormValues {
  selectedAssets: boolean;
}

const initialValues: IApplyPresetFormValues = {
  selectedAssets: false,
};

interface IAssetsFormHeader {
  openPresetModal: () => void;
  files: IAssetFile[];
  setFiles: (files: IAssetFile[]) => void;
}
export const AssetUploadFormHeader: React.FC<IAssetsFormHeader> = observer(({ openPresetModal, files, setFiles }) => {
  const { formData, handlers } = useForm<IApplyPresetFormValues>(initialValues, Model.ASSETS);
  const { onChange } = handlers;
  const numberOfSelectedFiles = useMemo(() => files.filter(({ selected }) => selected).length, [files]);

  useEffect(() => {
    onChange({ selectedAssets: numberOfSelectedFiles === files.length });
  }, [onChange, numberOfSelectedFiles, files]);

  const onSelectAllAssetsChange = useCallback(
    ({ selectedAssets }) => {
      setFiles(files.map((file) => ({ ...file, selected: selectedAssets })));
    },
    [setFiles, files],
  );
  const allSelected = useMemo(() => files.every(({ selected }) => selected), [files]);
  const onSelectAllChange = useCallback(
    (evt) => {
      const selectAll = evt.target.checked;
      setFiles(files.map((file) => (file.selected === selectAll ? file : { ...file, selected: selectAll })));
    },
    [setFiles, files],
  );
  if (files.length === 0) {
    return null;
  }

  return (
    <div className="d-flex w-100 m-0 align-items-center justify-content-between mb-2">
      <div className="d-flex gap-2">
        <FormCheckbox
          label={`${numberOfSelectedFiles}/${files.length}`}
          name="selectedAssets"
          {...formData.selectedAssets}
          {...handlers}
          onChange={onSelectAllAssetsChange}
          indeterminate={Boolean(numberOfSelectedFiles && numberOfSelectedFiles !== files.length)}
          hasBottomMargin={false}
        />
        <Divider orientation="vertical" mx={10} />
        <Switch checked={allSelected} label="Select All" name="selectAll" onChange={onSelectAllChange} />
      </div>
      <Button variant="outline" color="white" className="flex-end" onClick={openPresetModal}>
        Select Preset
      </Button>
    </div>
  );
});

export default React.memo(AssetUploadFormHeader);
