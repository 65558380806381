import React from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormSelect, FormChecklist, FormCheckbox, FormAccessLevel } from 'helpers/form';
import { filterAssetStatusOptions } from 'helpers/form/fields/form-select';

import { hasPower, amWriteProtectedAttributes } from 'utils/powers';

import { IAssetMm3AboutSectionProps, IAssetOldAboutSectionProps } from './types';
import { withAssetVersion } from 'utils/asset';

export const AssetOldSettingsSection: React.FC<IAssetOldAboutSectionProps> = ({ formData, handlers, formId }) => {
  const statusOptions = filterAssetStatusOptions(formData.status?.options);

  return (
    <FormSectionWrapperV2 gridTemplateColumns="1fr 1fr 1fr" title="Settings">
      <FormAccessLevel name="access_level" label="Access Level" large {...formData.access_level} {...handlers} />
      <FormSelect
        name="status"
        label="Status"
        large
        {...{ ...formData.status, options: statusOptions }}
        {...handlers}
      />

      <FormCheckbox
        name="sharable"
        mainLabel="Sharable"
        label="Allow non-Administrator contacts to share this asset with other contacts"
        formId={formId}
        large
        {...formData.sharable}
        {...handlers}
        disabled={!hasPower(amWriteProtectedAttributes)}
      />

      <FormChecklist
        name="permissions"
        label="Permissions"
        formId={formId}
        large
        {...formData.permissions}
        {...handlers}
      />

      <FormChecklist
        name="protection_levels"
        label="Protection Levels"
        formId={formId}
        large
        {...formData.protection_levels}
        {...handlers}
        className="asset-edit-modal__checklist--inline"
      />
    </FormSectionWrapperV2>
  );
};

export const AssetMm3SettingsSection: React.FC<IAssetMm3AboutSectionProps> = ({ formData, handlers, formId }) => {
  const statusOptions = filterAssetStatusOptions(formData.status?.options);

  return (
    <FormSectionWrapperV2 gridTemplateColumns="1fr 1fr 1fr" title="Settings">
      <FormAccessLevel name="access_level" label="Access Level" large {...formData.access_level} {...handlers} />
      <FormSelect
        name="status"
        label="Status"
        large
        {...{ ...formData.status, options: statusOptions }}
        {...handlers}
      />

      <FormCheckbox
        name="sharable"
        mainLabel="Sharable"
        label="Allow non-Administrator contacts to share this asset with other contacts"
        formId={formId}
        large
        {...formData.sharable}
        {...handlers}
        disabled={!hasPower(amWriteProtectedAttributes)}
      />

      <FormChecklist
        name="protection_levels"
        label="Protection Levels"
        formId={formId}
        large
        {...formData.protection_levels}
        {...handlers}
        className="asset-edit-modal__checklist--inline"
      />
      <FormChecklist
        name="permissions"
        label="Permissions"
        formId={formId}
        large
        {...formData.permissions}
        {...handlers}
      />
    </FormSectionWrapperV2>
  );
};

export default withAssetVersion(AssetOldSettingsSection, AssetMm3SettingsSection);
