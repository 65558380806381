import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { useBasicStore } from 'store/hooks';

import { FormTreeSelect, ITreeSelectOption, IFormTreeSelectProps, IRawTreeOption } from 'helpers/form';
import { byId, formatTreeEntity } from 'utils/general';

export const FormDivisions: React.FC<Omit<IFormTreeSelectProps, 'options'>> = observer((props) => {
  const { divisions } = useBasicStore();

  const formatDivisionLabel = useCallback(
    ({ value }: ITreeSelectOption): string => {
      const divisionsById = byId(divisions);
      return formatTreeEntity(parseInt(value, 10), divisionsById);
    },
    [divisions],
  );

  const divisionOptions = divisions.map((division) => ({
    ...division,
    id: props.isValueNumber ? division.id : division?.id?.toString(),
    parent_id: props.isValueNumber ? division.parent_id : division.parent_id?.toString(),
  }));

  return <FormTreeSelect tagRenderer={formatDivisionLabel} {...props} options={divisionOptions as IRawTreeOption[]} />;
});

export default FormDivisions;
