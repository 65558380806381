import React from 'react';
import { Button } from '@mantine/core';

import { DetailsPageTabs, IIdentifiable, IGuild } from 'types';
import { IUseActionsOptionParam, Pages } from 'utils/actions';
import {
  useAddProductsAction,
  useAddAssetsAction,
  useAddContactsAction,
  useAddOrganizationsAction,
} from 'utils/actions/guilds';
import { useRefreshDataSection } from 'utils/hooks';
import { MantineIcon } from 'utils/ui/icon';

interface IAddItemsToGuildButtonProps {
  currentTab: DetailsPageTabs;
  accessGroup: IGuild & IIdentifiable;
  disabled: boolean;
  onSuccess?: () => void;
  hidden: boolean;
}

export const AddItemsToGuildButton: React.FC<IAddItemsToGuildButtonProps> = ({
  currentTab,
  accessGroup,
  disabled,
  onSuccess,
  hidden,
}) => {
  const refreshDataSection = useRefreshDataSection();

  const options: IUseActionsOptionParam = {
    onSuccess: onSuccess || refreshDataSection,
    context: 'single',
    page: Pages.DETAILS,
  };
  const entities = [accessGroup];
  const addProducts = useAddProductsAction({ entities, options: { ...options, addSelectedProductAncestors: true } });
  const addAssets = useAddAssetsAction({ entities, options: { ...options } });
  const addContacts = useAddContactsAction({ entities, options });
  const addOrganizations = useAddOrganizationsAction({ entities, options });

  let action;

  switch (currentTab) {
    case DetailsPageTabs.PRODUCTS:
      action = addProducts;
      break;

    case DetailsPageTabs.ASSETS:
      action = addAssets;
      break;

    case DetailsPageTabs.CONTACTS:
      action = addContacts;
      break;

    case DetailsPageTabs.ORGANIZATIONS:
      action = addOrganizations;
      break;

    default:
      return <></>;
  }
  const { handler, icon, title } = action;

  return (
    <Button
      size="xs"
      leftSection={<MantineIcon icon={icon} />}
      disabled={disabled}
      onClick={handler}
      variant="primary"
      hidden={hidden}
    >
      {title}
    </Button>
  );
};
