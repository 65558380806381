import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { DetailsPageTabs, IAsset, IConference } from 'types';

import ScrollWrapper from 'components/scroll-wrapper';
import DateListElement from 'components/date-list-element';
import { formatDate } from 'utils/date';
import { AssetsMainClassificationLink } from 'components/asset/assets-aggregation/product-assets';
import { Thumbnail } from 'components/thumbnail';
import { ClipboardView } from 'components/clipboard-view';
import { useBasicStore } from 'store/hooks';
import { parseConferenceUrl } from 'utils/conference';
import { Routes } from 'utils/routes';
import { formatFullName } from 'utils/general';

import './style.scss';

export interface IConferenceOverviewTabProps {
  conference: IConference;
  assets: IAsset[];
}

export const ConferenceOverviewPreviewTab: React.FC<IConferenceOverviewTabProps> = observer(
  ({ assets, conference }) => {
    const {
      user_ids,
      created_at,
      banner_media,
      background_media,
      disable_waitingroom,
      ends_at,
      mode,
      owner,
      starts_at,
      title,
      updated_at,
      awaiting_approval_text,
      awaiting_moderator_text,
      welcome_text,
      farewell_text,
      farewell_alt_text,
      meeting_ended_text,
      meeting_ended_alt_text,
      code,
      status,
    } = conference;
    const { buyerDomain } = useBasicStore();

    const url = parseConferenceUrl(buyerDomain, code);

    return (
      <ScrollWrapper className="conference-details-overview-tab mt-3">
        <InfoBoxV2 gridTemplateColumns="1fr 3fr" title="Settings">
          <Row label="Title" value={title} />
          <Row
            label="URL"
            rawValue
            value={
              <ClipboardView value={url}>
                <a className="d-block" href={url} target="_blank" rel="noreferrer noopener">
                  {url}
                </a>
              </ClipboardView>
            }
          />

          <Row
            label="Assets"
            value={
              <AssetsMainClassificationLink
                assets={assets}
                baseUrl={`${Routes.CONFERENCES}/${code}/${DetailsPageTabs.ASSETS}`}
                muted={false}
              />
            }
          />
          <Row
            label="Contacts"
            value={<Link to={`../${DetailsPageTabs.CONTACTS}`}>{user_ids?.length}</Link>}
            size="m"
          />
          <Row label="Type" value={mode} size="m" />
          <Row label="Code" rawValue value={code} />
          <Row label="Starts at" rawLabel value={<DateListElement date={starts_at} />} size="m" />
          <Row label="Ends at" rawLabel value={<DateListElement date={ends_at} />} size="m" />
          <Row label="Waiting Room" value={disable_waitingroom ? 'Disabled' : 'Enabled'} size="m" />
          <Row label="Created at" value={formatDate(created_at)} rawLabel />
          <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
          <Row label="Moderator" value={formatFullName(owner)} size="m" />
          <Row label="Status" value={status} size="m" />
        </InfoBoxV2>
        <InfoBoxV2 gridTemplateColumns="1fr 3fr" title="Attachments" padded>
          <Row
            label="Preview / Background media"
            rawLabel
            value={<Thumbnail image={background_media} className="conference-details-overview-tab__preview_image" />}
          />
          <Row
            label="Banner media"
            value={<Thumbnail image={banner_media} className="conference-details-overview-tab__preview_image" />}
          />
          <Row label="Awaiting approval text" value={awaiting_approval_text} />
          <Row label="Awaiting moderator text" value={awaiting_moderator_text} />
          <Row html label="Welcome text" value={welcome_text} size="m" />
          <Row html label="Farewell text" value={farewell_text} size="m" />
          <Row html label="Farewell text (public users)" value={farewell_alt_text} size="m" />
          <Row html label="Meeting ended text" value={meeting_ended_text} size="m" />
          <Row html label="Meeting ended text (public users)" value={meeting_ended_alt_text} size="m" />
        </InfoBoxV2>
      </ScrollWrapper>
    );
  },
);
