import React, { useCallback } from 'react';
import { IFormMultiSelectOption } from 'helpers/form';

import { FormProducts } from 'helpers/form';

import { parseProductAncestors } from 'components/delivery/utils';
import { ILocalizationOrderStep } from 'components/localization-orders/types';

export const OrdersProductsStep: React.FC<ILocalizationOrderStep> = ({ orderForm: { formData, handlers } }) => {
  const { onChange } = handlers;
  const setProductAncestry = useCallback(
    (options: IFormMultiSelectOption[]): void => {
      const productAncestry = parseProductAncestors(options);

      onChange({ product_ancestry: productAncestry, product_id: options[0]?.id as number });
    },
    [onChange],
  );

  return (
    <div className="product-recommendation-form h-100">
      <FormProducts
        name="product_ids"
        label="Products"
        fitInParent
        omni
        onSelectedItemsChange={setProductAncestry}
        {...formData.product_ids}
        {...handlers}
        required
      />
    </div>
  );
};
