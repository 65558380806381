import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual, merge } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ActionIcon } from '@mantine/core';
import { Cross } from 'blueprint5-icons';

import { IAssetFields, IAssetFile, IAssetPresetFields } from 'types/asset';
import { Model } from 'helpers/filters/types';
import { Thumbnail } from 'components/thumbnail';
import { FormInput, FormCheckbox, useForm, useMm3Form, IUseFormReturn } from 'helpers/form';

import { MantineIcon } from 'utils/ui/icon';

import AssetPresetFields, {
  IAssetPresetFieldsProps,
} from 'components/asset/asset-upload-components/preset-form/preset-form';
import AssetPresetControls from 'components/asset/asset-upload-components/single-asset-form/preset-control';
import { assetFormCustomContext } from 'components/asset/asset-edit-form/constants';
import { flags } from 'utils/flags';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { getRequiredError, IValidationResult } from 'helpers/form/mm3';
import { AssetType } from 'utils/format-asset-type';

import './index.scss';

export interface IAssetFormProps {
  index: number;
  initialValues: IAssetFields;
  removeFile: (file: IAssetFields) => void;
  onDataChange: (data: Partial<IAssetFile>, index: number) => void;
  setIsValidForm: (fileId: string, valid: boolean) => void;
  applyPreset: (preset: IAssetPresetFields) => void;
  savePreset: (preset: IAssetPresetFields) => void;
  shouldShowErrors: boolean;
  withParentField?: boolean;
}

export const customMm3Context = (
  asset: IAssetFile,
  validations: IValidationResult<IAssetFile>,
): IValidationResult<IAssetFile> => {
  if (!asset) return validations;

  return merge({}, validations, {
    id: {
      isValueNumber: false,
      validation: { valid: true },
    },
    classification: {
      required: true,
      ...(asset.classification ? {} : getRequiredError()),
    },
    'meta.purpose': {
      required: asset.type === AssetType.SUBTITLE ? true : false,
      ...(asset.type === AssetType.SUBTITLE && !asset?.['meta.purpose'] ? getRequiredError() : {}),
    },
    division_ids: {
      required: asset.access_level === 'division' ? true : false,
      ...(asset.access_level === 'division' && !asset?.division_ids?.length ? getRequiredError() : {}),
    },
    'meta.language_id': {
      required: true,
      ...(asset['meta.language_id'] ? {} : getRequiredError()),
    },
  });
};

export const AssetForm: React.FC<IAssetFormProps> = ({
  index,
  initialValues,
  removeFile,
  onDataChange,
  setIsValidForm,
  shouldShowErrors,
  applyPreset,
  savePreset,
  withParentField,
}) => {
  const mm3Form = useMm3Form<IAssetFields>(initialValues, Model.MM3_ASSETS, undefined, customMm3Context);
  const customContext = useMemo(
    () => ({
      properties: {
        name: { required: true },
        classification: { required: true },
        status: { required: true },
        access_level: { required: true },
        ...(assetFormCustomContext ? assetFormCustomContext['properties'] : {}),
      },
    }),
    [],
  );

  const [oldContext, setOldContext] = useState(customContext);

  const oldForm = useForm<IAssetFields>(initialValues, Model.ASSET_DIGITAL_IMAGE, undefined, oldContext);
  const form = flags.isMm3Assets ? mm3Form : oldForm;

  const { formData, handlers: handleChanges, valid, values } = form;

  const handlers = useMemo(() => {
    return {
      ...handleChanges,
      onChange(values: Partial<IAssetFile>): void {
        handleChanges.onChange(values);
        onDataChange(values, index);
      },
    };
  }, [handleChanges, onDataChange, index]);

  useEffect(() => {
    if (!isEqual(initialValues, values)) {
      flags.isMm3Assets
        ? (form as IUseMm3FormReturn<IAssetFields>).onSetFields(initialValues)
        : (form as IUseFormReturn<IAssetFields>).handlers.onSetFields(initialValues);
      onDataChange(initialValues, index);
    }
  }, [initialValues, values, onDataChange, index, form]);

  useEffect(() => {
    setIsValidForm(initialValues.id, valid);
  }, [valid, initialValues, setIsValidForm]);

  useEffect(() => {
    // TODO Adjust preset form for different asset types
    if (formData.type?.value === AssetType.SUBTITLE && formData.classification?.value !== 'document/subtitle') {
      handlers.onChange({ classification: 'document/subtitle' });
    }
  }, [formData.classification, formData.type, handlers]);

  const removeAsset = useCallback(() => {
    removeFile(initialValues);
  }, [removeFile, initialValues]);

  const formId = values.id;

  useEffect(() => {
    if (flags.isMm3Assets) return;
    setOldContext((prevContext) => {
      const updatedProperties = { ...prevContext.properties };
      if (form.values.access_level === 'division') {
        updatedProperties['division_ids'] = { required: true };
      } else {
        updatedProperties['division_ids'] = { required: false };
      }
      return {
        ...prevContext,
        properties: updatedProperties,
      };
    });
  }, [customContext, form.values.access_level]);

  return (
    <form className="asset-upload-components__single-asset-form-container" id={formId}>
      <div className="asset-upload-components__single-asset-form-main-info">
        <FormCheckbox formId={formId} name="selected" {...formData.selected} {...handlers} />
        <Thumbnail
          image={initialValues.preview || initialValues.uploadURL}
          assetType={initialValues.type}
          showType
          noResize={Boolean(initialValues.preview)}
        />
        <div className="asset-upload-components__single-asset-form-fields">
          <ActionIcon className="clear-all__button" color="gray.5" variant="subtle" onClick={removeAsset}>
            <MantineIcon icon={<Cross />} />
          </ActionIcon>
          <FormInput formId={formId} name="file_name" label="File Name" disabled {...formData.file_name} />
          <FormInput formId={formId} name="name" label="Asset Name" {...formData.name} {...handlers} />
        </div>
      </div>
      <AssetPresetFields
        {...({
          formId,
          formData,
          handlers,
          shouldShowErrors,
          values,
          withParentField,
        } as unknown as IAssetPresetFieldsProps)}
      />
      <AssetPresetControls {...{ values, applyPreset, savePreset }} />
    </form>
  );
};

export default React.memo(observer(AssetForm));
