import React, { useCallback } from 'react';

import { IGroup, IIdentifiable } from 'types';
import { useStore } from 'store';
import { useRefreshDataSection } from 'utils/hooks';
import { IUseActionsOptionParam } from 'utils/actions';

import { GroupAccessChangeTabs } from './group-access-change-tabs';
import { IActionName } from 'utils/actions/types';
import { IAccessPrivilege } from 'components/access-privilege-grant';

export interface IUseGroupAccessChangeDialogOptions extends IUseActionsOptionParam {
  dialogTitle?: string;
}

export const useGroupAccessChangeDialog = (
  items: (IGroup & IIdentifiable)[] = [],
  options: IUseGroupAccessChangeDialogOptions,
): [() => void] => {
  const { dialogStore } = useStore();
  const refreshDataSection = useRefreshDataSection();

  const onSuccess = useCallback(
    (action?: IActionName, updatedAccessPrivilege?: IAccessPrivilege) => {
      options?.onSuccess?.(action, updatedAccessPrivilege);
      refreshDataSection();
      dialogStore.close();
    },
    [dialogStore, refreshDataSection, options],
  );

  const body = useCallback((): JSX.Element => {
    return <GroupAccessChangeTabs items={items} onSuccess={onSuccess} groupName={options?.groupName} />;
  }, [items, onSuccess, options?.groupName]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: options?.dialogTitle ?? `Change access`,
      body,
    });
  };

  return [openDialog];
};
