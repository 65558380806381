import React from 'react';

import { AssetVideoPlayer } from 'components/common/asset-video-player';
import AudioPlayer from './custom-audio-player';

import { flags } from 'utils/flags';

import { IAsset, IMm3Asset } from 'types';

interface IAudioPlayerProps {
  asset?: IAsset | IMm3Asset;
}
export const AssetAudioPlayer: React.FC<IAudioPlayerProps> = ({ asset }) => {
  if (!flags.isMm3Assets) return <AudioPlayer asset={asset} />;
  return <AssetVideoPlayer video={asset} />;
};
