import React, { useCallback, useEffect } from 'react';
import { UmGenericListCollection } from '@mediafellows/mm3-types';
import { observer } from 'mobx-react-lite';

import { SortDataSection } from 'components/sort-data-section';
import { IListItem, IProduct } from 'types';
import { ProductDataSectionListItem } from 'components/product-data-section-item/product-data-section-list-item';
import { SeparatorListItem } from 'components/collection-items/data-section-item/data-section-list-item';
import { CollectionDataSectionListItem } from 'components/collection';
import { useSortDataSectionStore } from 'store/hooks';
import { updateListItems } from 'utils/apis/showcase-item';
import { fetchListItems } from 'utils/apis/showcase';
import { delay } from 'utils/delay';
import { ItemTypes } from 'helpers/form/fields/select-helpers';

export interface ICollectionOverviewTabProps {
  collectionId: number;
}

const itemRenderer = (item: IListItem): React.ReactElement => {
  if (item?.entity_type === ItemTypes.PRODUCT && item?.id && item.entity?.id) {
    const product = item.entity as IProduct;
    return (
      <ProductDataSectionListItem
        isChecked={false}
        isAllItemsSelected={false}
        key={item.id}
        active={NaN}
        id={item.id}
        product={product}
        contextMenu={<></>}
      />
    );
  }
  if (item?.entity_type === ItemTypes.SEPARATOR && item?.id) {
    return <SeparatorListItem separator={item} />;
  }
  if (item?.entity_type === ItemTypes.LIST_COLLECTION && item.entity?.id) {
    const collection = item.entity as UmGenericListCollection;
    return (
      <CollectionDataSectionListItem isChecked={false} key={item.id} collection={collection} contextMenu={<></>} />
    );
  }

  return (
    <div key={item?.id}>
      deleted entity of type {item?.entity_type} with id {item?.entity_id}
    </div>
  );
};

const onSave = async (list: IListItem[]): Promise<void> => {
  await updateListItems(
    [list[0].list_id],
    list.map(({ id, list_id }, sequence_number) => ({ id, sequence_number, list_id })),
  );
  await delay(1000);
  return;
};

export const CollectionItemSortTab: React.FC<ICollectionOverviewTabProps> = observer(({ collectionId }) => {
  const { initStore } = useSortDataSectionStore<IListItem>();
  const fetcher = useCallback(async (): Promise<IListItem[]> => {
    const { objects } = await fetchListItems({
      listIds: [collectionId],
    });

    return objects;
  }, [collectionId]);

  useEffect(() => {
    initStore({ fetcher, onSave });
  }, [fetcher, initStore]);

  return (
    <SortDataSection itemRenderer={itemRenderer} className="h-100 collection-items-sort-tab" hideDivisionAccessLevel />
  );
});
