import { create } from 'zustand';
import { IContact } from 'types';
import { get } from 'lodash';
import { cookie, triggerSignOutFromAllOpenTabs } from 'utils/cookie';
import { chipmunk, IResult } from 'utils/chipmunk';
import { STORAGE_KEYS, persistRedirectUrl, setStorageItem } from 'utils/storage';
import { invalidateSession } from 'utils/apis/session';
import { loadAffiliationId, loadAffiliation } from 'utils/apis/affiliation';

import { getRootStore } from 'store/index';
import { analyticsLocalStorageKeysToRemove } from 'store/analytics-store';

import { Model } from 'helpers/filters/types';
import { notifications } from '@mantine/notifications';
import { addPurpose } from 'pages/two-factor-auth/utils';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

interface ISession {
  id?: string;
  user?: IContact;
  affiliation?: { id?: string };
  role?: { id?: number; name?: string };
  powers?: { effect: 'Allow'; module: 'feature'; resource: string }[];
  '@associations'?: Record<string, unknown>;
  mfa_check_required?: boolean;
}

export interface ISessionStore {
  session: ISession | null;
  user: IContact | null;
  initialLoadDone: boolean;
  reset: () => void;
  computed: { affiliationId: string | null };
}

// these types are stubs and when you have better definitions feel free to update them
type ISessionPromise = Promise<ISession>;
type IUserPromise = Promise<IContact>;

interface ILogoutParams {
  allTabs?: boolean;
  remote?: boolean;
  saveRedirectUrl?: boolean;
}

let sessionPromise: ISessionPromise | null = null;
let userPromise: IUserPromise | null = null;

export const useSessionStore = create<ISessionStore>((set, get) => ({
  session: null,
  initialLoadDone: false,
  user: null,
  reset: () => set({ session: null, user: null }),
  computed: {
    get affiliationId() {
      return get().session?.affiliation?.id || null;
    },
  },
}));

export const loadSession = async (force = false, fullSession = false): ISessionPromise => {
  if (!force && sessionPromise) {
    return sessionPromise;
  }

  // wait for the pending request to finish, unfortunately we cannot cancel this
  try {
    await sessionPromise;
  } catch {}

  return (sessionPromise = chipmunk.run(async () => {
    try {
      const affiliation = await loadAffiliation();
      const affiliationId = affiliation?.id || 'mfx';
      setStorageItem(STORAGE_KEYS.WEBSITE_LOGO, affiliation?.logo?.url ?? '');
      setStorageItem(STORAGE_KEYS.WEBSITE_BRAND_HEADER, affiliation?.brand_header ?? '');
      const sesstionType = fullSession ? 'full' : 'slim';
      const session = (
        await chipmunk.action('um.session', sesstionType, {
          raw: true,
        })
      ).object;

      const sessionId = session.id;
      const roleId = session.role?.id;

      cookie.set('sessionId', sessionId);
      cookie.set('roleId', roleId);
      cookie.set('affiliationId', affiliationId);

      chipmunk.updateConfig({
        headers: {
          'Session-Id': sessionId,
          'Role-Id': roleId,
          'Affiliation-Id': affiliationId,
        },
      });

      const isMfaRequred = session.mfa_check_required;
      const purpose = session?.user?.mfa_auth_method;

      if (purpose) {
        addPurpose(purpose);
      }

      if (isMfaRequred && fullSession) {
        return session;
      }

      useSessionStore.setState({ session, initialLoadDone: true });
      return session;
    } catch (e) {
      chipmunk.updateConfig({
        headers: {
          'Session-Id': null,
          'Role-Id': null,
          'Affiliation-Id': null,
        },
      });

      useSessionStore.setState({ session: null, initialLoadDone: true });
    }
  }));
};

export const updateSession = (sessionId: string): void => {
  useSessionStore.getState().reset();
  cookie.set('sessionId', sessionId);
  chipmunk.updateConfig({
    headers: {
      'Session-Id': sessionId,
    },
  });
};

export const ssoLogin = (sessionId: string): ISessionPromise => {
  updateSession(sessionId);
  return loadSession(true);
};

export const login = (email, password): Promise<void> | ISession => {
  return chipmunk.run(async () => {
    chipmunk.updateConfig({
      headers: {
        'Session-Id': null,
        'Role-Id': null,
        'Affiliation-Id': null,
      },
    });

    const affiliationId = await loadAffiliationId();
    const affilaition = await loadAffiliation();
    const device_id = await getFingerprint();

    chipmunk.updateConfig({ headers: { 'Affiliation-Id': affiliationId } });
    cookie.set('affiliationId', affiliationId);

    setStorageItem(STORAGE_KEYS.WEBSITE_LOGO, affilaition?.logo?.url ?? '');
    setStorageItem(STORAGE_KEYS.WEBSITE_BRAND_HEADER, affilaition?.brand_header ?? '');

    const res = await chipmunk.action('um.session', 'create', {
      body: { email, password, device_id },
    });

    cookie.set('sessionId', res.object.id);
    chipmunk.updateConfig({
      headers: { 'Session-Id': res.object.id },
    });

    const isMfaRequred = get(res, 'object.mfa_check_required', false);

    return loadSession(true, isMfaRequred);
  });
};

export const verify2FACode = (auth_code, device_id, trusted, purpose): Promise<void> => {
  return chipmunk.run(async () => {
    await chipmunk.action('um.protection_check', 'verify', {
      body: { auth_code, device_id, trusted },
      params: { purpose },
    });

    return loadSession(true);
  });
};

export const loadUser = (): Promise<IResult> => {
  return chipmunk.run(async (chipmunk) => {
    const session = useSessionStore.getState().session;
    const userId = session?.user?.id;

    if (!userId) return null;
    if (userPromise) return userPromise;

    userPromise = chipmunk
      .action(Model.CONTACTS, 'get', {
        params: { user_ids: userId },
        schema: `first_name, last_name, preview_image, id, role_name, role, organization_id`,
      })
      .then((result) => {
        const user = result.object as IContact;
        useSessionStore.setState({ user });
        return user;
      })
      .catch(() => null) as IUserPromise;
  });
};

export const logout = async ({ allTabs, remote, saveRedirectUrl }: ILogoutParams = {}): Promise<void> => {
  if (saveRedirectUrl) {
    persistRedirectUrl();
  }

  if (remote) {
    await invalidateSession();
  }

  if (allTabs) {
    triggerSignOutFromAllOpenTabs();
  }

  clear();

  const { assetsUploadStore, assetsUploadToSelectionStore, notificationsStore, dialogStore, dataSectionStore } =
    getRootStore();
  dialogStore.closeAllDialogs();
  assetsUploadStore.cleanUp();
  assetsUploadToSelectionStore.cleanUp();
  dataSectionStore.oldFilters = {};
  dataSectionStore.reset();
  notificationsStore.reset();
  notifications.clean();
};

export const clear = (): void => {
  useSessionStore.getState().reset();
  sessionPromise = null;
  userPromise = null;

  cookie.remove('sessionId');
  // cookie.remove(VISIT_KEY);
  analyticsLocalStorageKeysToRemove.forEach((k) => window.localStorage.removeItem(k));
  chipmunk.updateConfig({
    headers: {
      'Session-Id': null,
      'Role-Id': null,
      'Affiliation-Id': null,
    },
  });
};
