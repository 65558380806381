import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { IOrganization } from 'types';
import { formatDate } from 'utils/date';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row, yn } from 'components/label-value-pair';
import {
  MultipleAddressesList,
  GeoScopesRow,
  PhonesList,
  SocialMediaProfilesList,
  WebsitesList,
  TagsList,
} from 'components/entity-list';
import { ScrollWrapper } from 'components/scroll-wrapper';

import './style.scss';
import { CustomRow } from 'pages/organization-details/organization-overview-tab/custom-row';
import { formatFullName } from 'utils/general';

interface IOrganizationOverviewTabProps {
  organization: IOrganization;
}

const OrganizationOverviewTab: React.FC<IOrganizationOverviewTabProps> = observer(({ organization }) => {
  const {
    name,
    email,
    status,
    legal_entity,
    addresses,
    phones,
    country,
    social_media_profiles,
    registration_number,
    responsible_user,
    tags,
    geo_scopes,
    access_level,
    created_at,
    updated_at,
    courier_services,
    key_persons,
    language,
    currency_id,
    urls,
    is_producer,
    description,
  } = organization || {};

  return (
    <ScrollWrapper>
      <InfoBoxV2 title="Business" wrapperClassName="py-3" gridTemplateColumns="repeat(4, 1fr)" withBorder>
        <Row label="Name" value={name} size="m" rawValue />
        <Row label="Registration Number" value={registration_number} size="m" />
        <Row label="Responsible Contact" value={formatFullName(responsible_user)} size="m" />
        <Row label="Legal Entity" value={legal_entity} size="m" />
        <TagsList tags={tags} className="entity-tab__content__tag mr-1" />
        <GeoScopesRow geo_scopes={geo_scopes} />
        <Row label="Access Level" value={access_level} size="m" />
        <CustomRow organization={organization} />
        <Row label="is Producer Company" value={yn(is_producer)} size="m" />
      </InfoBoxV2>
      <InfoBoxV2
        gridTemplateColumns="repeat(4, 1fr)"
        title="organization information"
        wrapperClassName="py-3"
        withBorder
      >
        <Row
          label="Email"
          labelClassName="screen-m muted-text"
          hidden={!email}
          value={
            <Link className="entity-tab__content__email text-lowercase screen-m" to={`mailto:${email}`}>
              {email}
            </Link>
          }
        />

        <MultipleAddressesList addresses={addresses} />
        <Row label="Country" value={country?.name} />
        <PhonesList phones={phones} gridTemplateColumns="1fr 3fr" />
      </InfoBoxV2>
      <InfoBoxV2 title="Description" wrapperClassName="py-3" withBorder gridTemplateColumns="1fr">
        <Row label="" html value={description} size="m" />
      </InfoBoxV2>
      <InfoBoxV2 title="Social Media Profiles" wrapperClassName="py-3" gridTemplateColumns="1fr" withBorder>
        {social_media_profiles && (
          <SocialMediaProfilesList
            social_media_profiles={social_media_profiles}
            showTitle={false}
            gridTemplateColumns="minmax(130px, 1fr) 3fr"
          />
        )}
      </InfoBoxV2>

      <InfoBoxV2 title="Websites" wrapperClassName="py-3" withBorder>
        {urls && <WebsitesList websites={urls} />}
      </InfoBoxV2>

      <InfoBoxV2 title="Basic Information" wrapperClassName="py-3" withBorder>
        <Row label="Status" value={status} size="m" />
        <Row label="Created at" rawLabel value={formatDate(created_at)} size="m" />
        <Row label="Updated at" rawLabel value={formatDate(updated_at)} size="m" />
      </InfoBoxV2>

      <InfoBoxV2 title="Courier" wrapperClassName="py-3" className="entity-detail__infobox-wrapper" withBorder>
        {courier_services?.map(({ company, account }) => {
          return (
            <InfoBoxV2 key={account} gridTemplateColumns="1fr 1fr" padded={false}>
              <Row label="company" value={company} size="m" />
              <Row label="account" value={account} size="m" />
            </InfoBoxV2>
          );
        })}
      </InfoBoxV2>
      <InfoBoxV2 title="Key Personnel" wrapperClassName="py-3" className="entity-detail__infobox-wrapper" withBorder>
        {key_persons?.map(({ label, name }, index) => {
          return (
            <InfoBoxV2 key={label + index} gridTemplateColumns="1fr 1fr" padded={false}>
              <Row label="label" value={label} size="m" />
              <Row label="name" value={name} size="m" />
            </InfoBoxV2>
          );
        })}
      </InfoBoxV2>

      <InfoBoxV2 title="Defaults" wrapperClassName="py-3">
        <Row label="Language" value={language?.name} size="m" />
        <Row label="Currency" value={currency_id} size="m" />
      </InfoBoxV2>
    </ScrollWrapper>
  );
});

export default OrganizationOverviewTab;
