import React from 'react';

import { IDivision } from 'types';
import { useDialogStore } from 'store/hooks';
import { IModalSize } from 'components/dialogs/types';
import { DivisionForm, IDivisionFormData } from './division-form';

interface IUseGenreForm {
  title: string;
  division?: IDivision;
  handleSubmit: (division: IDivisionFormData) => Promise<IDivision | void>;
  parentName?: string;
  parentId?: number;
}
export const useDivisionForm = ({
  title,
  division,
  handleSubmit,
  parentName,
  parentId,
}: IUseGenreForm): (() => void) => {
  const { openModal } = useDialogStore();

  return () =>
    openModal({
      title,
      size: IModalSize.S,
      body: () => (
        <DivisionForm division={division} handleSubmit={handleSubmit} parentName={parentName} parentId={parentId} />
      ),
    });
};
