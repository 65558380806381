import React from 'react';

import { FormInput, FormAccessLevel } from 'helpers/form';
import { IAccessGroupFormComponent } from './types';

export const keysGeneral = ['name', 'access_level'];

export const AccessGroupFormGeneral: React.FC<IAccessGroupFormComponent> = ({ form }) => {
  return (
    <div className="d-flex flex-column">
      <FormInput name="name" label="Name" {...form.formData.name} {...form.handlers} />
      <FormAccessLevel
        name="access_level"
        label="Access Level"
        large
        {...form.formData.access_level}
        {...form.handlers}
      />
    </div>
  );
};
