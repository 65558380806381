import React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu } from '@mantine/core';

import { ParametronSort } from 'helpers/filters/types';
import { ICustomSortMenuProp } from 'components/data-section';
import { useDataSectionStore } from 'store';

import cx from 'classnames';

export const OrderSortMenu: React.FC<{
  sort: ParametronSort;
  handleSort: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}> = observer(({ sort, handleSort }) => {
  const { searchStore } = useDataSectionStore();
  const { paramsAndFiltersCount = 0 } = searchStore || {};
  const hasFiltersApplied = Boolean(paramsAndFiltersCount);

  return (
    <Menu>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.CREATED_AT}
        disabled={hasFiltersApplied}
        className={cx({ active: sort === ParametronSort.CREATED_AT })}
      >
        Order Date
      </Menu.Item>
      <Menu.Item
        onClick={handleSort}
        data-param="sort"
        data-value={ParametronSort.LAUNCH_TARGET}
        className={cx({ active: sort === ParametronSort.LAUNCH_TARGET })}
      >
        Due Date
      </Menu.Item>
    </Menu>
  );
});

export const customSortMenu: ICustomSortMenuProp = (sort, handleSort) => (
  <OrderSortMenu sort={sort} handleSort={handleSort} />
);
