const colorLuminance = (hexColor, lum = 0): string => {
  // validate hex string
  let hex = String(hexColor).replace(/[^0-9a-f]/gi, '');

  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // convert to decimal and change luminosity
  let rgb = '',
    c,
    i;

  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
};

export const stringToColour = (str): string => {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let colour = '';

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }

  const rgb = parseInt(colour, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  if (luma < 70) {
    colour = colorLuminance(colour, 0.2);
  }

  if (luma > 120) {
    colour = colorLuminance(colour, -0.2);
  }

  if (luma > 200) {
    colour = colorLuminance(colour, -0.4);
  }

  return `#${colour}`;
};

export const getTitle = (text?: string): string => {
  const textParts = (text || '')
    .trim()
    .split(' ')
    .map((word) => word.replace(/[^a-zA-Z0-9]/g, '')); // remove special characters

  if (textParts.length === 0) {
    return '';
  } else if (textParts.length === 1) {
    return textParts[0].substring(0, 2);
  } else {
    return textParts[0].substring(0, 1) + textParts[1].substring(0, 1);
  }
};
