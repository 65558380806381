import { IGroupVariety, ItemId, IGroupType } from 'types';
import type { IUseFormReturn } from 'helpers/form';
import { IAccessPrivilegeEmail } from 'components/access-privilege-grant/access-privilege-grant-form-email';
import { IUseActionsOptionParam } from 'utils/actions';

export type IAddToGroupEntityType =
  | 'products'
  | 'assets'
  | 'assets_and_selections'
  | 'users'
  | 'events'
  | 'organizations'
  | 'contacts_and_selections'
  | 'contact_selections';

export enum IAddToGroupEntityTypeEnum {
  PRODUCTS = 'products',
  ASSETS = 'assets',
  ASSETS_AND_SELECTIONS = 'assets_and_selections',
  CONTACTS = 'users',
  EVENTS = 'events',
  ORGNIZATIONS = 'organizations',
  CONACTS_AND_SELECTIONS = 'contacts_and_selections',
  CONTACT_SELECTIONS = 'contact_selections',
}

export interface IAddToGroupForm {
  item_ids: Array<ItemId>;
  contacts?: Array<{ id: ItemId; ['@type']: string }>;
  group_id?: ItemId;
  include_descendants?: boolean;
  delegates_access?: boolean;
  include_future_descendants?: boolean;
  notify_per_email?: boolean;
  permit_download?: boolean;
  email_subject?: string;
  email_message?: string;
}

export interface IAddToGroupFull extends IAddToGroupForm {
  email_subject: string;
  email_message: string;
}

export type IAddToGroupFormReturn = IUseFormReturn<IAddToGroupForm>;

export type IGetDefaultInitialValues = (params?: { groupType?: string }) => Partial<IAddToGroupFull>;

export interface IFormAddToGroupItems {
  entity: IAddToGroupEntityType;
  onConfirm: (values: IAddToGroupForm | IAccessPrivilegeEmail) => Promise<void>;
  onCancel: () => void;
  items?: Array<{ label: string; value: number | string }>;
  group?: IGroupVariety;
  groupType: string;
  hideProductsParams?: boolean;
  groupLabel?: string;
  addSelectedProductAncestors?: boolean;
  withEmailNotification?: boolean;
  omni?: boolean;
}

export interface IUseSelectionDialogProps {
  entityType: IAddToGroupEntityType;
  items?: Array<{ label: string; value: number | string }>;
  group?: IGroupVariety;
  options?: IUseActionsOptionParam;
  groupType: IGroupType;
  title?: string;
  groupLabel?: string;

  passPropsFromExistingItems?: boolean;
  hideProductsParams?: boolean;
  addSelectedProductAncestors?: boolean;
  withEmailNotification?: boolean;
  omni?: boolean;
}
