import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { Page } from 'components/layout';
import { Routes } from 'utils/routes';
import { useNavigate } from 'react-router';
import { SmartGroupWizardContainer } from 'components/smart-group/form-hook/smart-group-container';

export const SmartGroupCreate: React.FC = observer(() => {
  const navigate = useNavigate();
  const onFinish = useCallback((): void => {
    navigate(`${Routes.SMART_GROUP}`);
  }, [navigate]);

  return (
    <Page title="Create Smart Group">
      <SmartGroupWizardContainer onSuccess={onFinish} />
    </Page>
  );
});
