import React, { useCallback, useMemo, useState } from 'react';
import { Tabs } from '@mantine/core';
import { subYears } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { camelCase, startCase } from 'lodash';
import { observer } from 'mobx-react-lite';

import { DetailsPageTabs, IAnalyticsPageValues } from 'types';
import { useAnalytics, useTab } from 'utils/hooks';
import { today } from 'utils/date';

import { useStore } from 'store';

import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { AnalyticsOverviewTab, AnalyticsListTab, AnalyticsDetailedStatsTab } from './tabs';
import { AnalyticsSectionFilters, getSectionInteractionOptions } from 'components/analytics';
import { AnalyticsDetailTab, AnalyticsTabPanel } from 'pages/analytics/tabs/analytics-tabs';
import AnalyticsProductSearchStatsTab from './tabs/analytics-product-search-stats';

import { exportAnalytics } from 'utils/apis/analytics';
import { sectionToEntity, IAnalyticsInteractionOption } from 'components/analytics';
import { Pages } from 'utils/actions/types';

import * as queries from 'pages/analytics/tabs/queries';
import { useSessionStore } from 'store/session-store';

import { Loading } from 'components/loading';
import { LoadingBoundary } from 'components/loading-boundary';

import './style.scss';

const tabOptions = [
  DetailsPageTabs.OVERVIEW,
  DetailsPageTabs.LIST,
  DetailsPageTabs.DETAILED_STATS,
  DetailsPageTabs.PRODUCT_SEARCH_STATS,
];
const getMinDate = async (): Promise<Date> => subYears(today, 10);

const Analytics: React.FC<{ section: IAnalyticsPageValues; page?: Pages }> = ({ section, page }) => {
  const { state } = useLocation();
  const { toastStore } = useStore();
  const user = useSessionStore((state) => state.user);
  const [activeTab, setActiveTab] = useTab(DetailsPageTabs.OVERVIEW, tabOptions);

  const [interaction, setInteraction] = useState<IAnalyticsInteractionOption | undefined>();

  const interactionOptions = useMemo(() => {
    if (activeTab !== DetailsPageTabs.DETAILED_STATS) {
      return;
    }
    const options = getSectionInteractionOptions(section, user, page);
    setInteraction(options[0]);
    if (section === IAnalyticsPageValues.ANALYTICS_RECOMMENDATION) {
      return;
    }
    return options;
  }, [section, activeTab, user, page]);

  const handleTabChange = useCallback(
    (tab: DetailsPageTabs): void => {
      setActiveTab(tab);
    },
    [setActiveTab],
  );

  const onInteractionChange = useCallback(({ interaction }: { interaction: IAnalyticsInteractionOption }) => {
    setInteraction(interaction);
  }, []);

  const {
    dateRange: analyticsDateRange,
    productIds,
    territories,
    includeDescendants,
    contactIds,
    assetIds,
    onDateRangeChange,
    onProductsSelect,
    onContactsSelect,
    onDescendantsChange,
    onTerritoriesSelect,
    onSendersSelect,
    onRecipientsSelect,
    onAssetsSelect,
    onResponsibleUserSelect,
    resetFilters,
    minDate,
    recipientIds,
    senderIds,
    responsibleUserId,
    loading,
  } = useAnalytics({ state, getMinDate, tab: activeTab, section, entity: sectionToEntity[section], interaction });
  const exportQuery = queries?.exportQueries[camelCase(section)];

  const exportGlobalAnalytics = useCallback(async () => {
    try {
      await exportAnalytics({
        exportQuery,
        dateRange: analyticsDateRange,
        geoScopeIds: territories || [],
        contactIds: contactIds || [],
        productIds: productIds || [],
        includeDescendants: includeDescendants || false,
      });
      toastStore.success('Export requested and sent via email.');
    } catch (e) {
      toastStore.error('Failed to export detailed list.');
    }
  }, [analyticsDateRange, exportQuery, territories, contactIds, productIds, includeDescendants, toastStore]);

  const AnalyticsFilters = (
    <AnalyticsSectionFilters
      section={section}
      dateRange={analyticsDateRange}
      minDate={minDate}
      handleDateRangeChange={onDateRangeChange}
      onTerritoriesSelect={onTerritoriesSelect}
      onContactsSelect={onContactsSelect}
      handleDescendantsChange={onDescendantsChange}
      exportGlobalAnalytics={exportGlobalAnalytics}
      onProductsSelect={onProductsSelect}
      territories={territories}
      contactIds={contactIds}
      productIds={productIds}
      includeDescendants={includeDescendants}
      exportQuery={activeTab === DetailsPageTabs.OVERVIEW && exportQuery}
      resetFilters={resetFilters}
      interactionOptions={interactionOptions}
      interaction={interaction}
      onInteractionChange={onInteractionChange}
      assetIds={assetIds}
      onAssetsSelect={onAssetsSelect}
      recipientIds={recipientIds}
      senderIds={senderIds}
      onRecipientsSelect={onRecipientsSelect}
      onSendersSelect={onSendersSelect}
      responsibleUserId={responsibleUserId}
      onResponsibleUserSelect={onResponsibleUserSelect}
    />
  );

  return (
    <LoadingBoundary loading={loading} suspender={<Loading text="Loading Analytics" />}>
      <div className="d-flex flex-column w-100 h-100">
        <div className="d-flex align-items-center justify-content-between">
          <DashboardBreadcrumbs className="d-inline-block mb-3" />
        </div>
        <div className="analytics-details__content-wrapper flex-full">
          <div className="analytics-details__content h-100">
            <Tabs className={'analytics-details__tabs h-100 d-flex'} value={activeTab} onChange={handleTabChange}>
              <Tabs.List className="d-flex flex-nowrap">
                <AnalyticsDetailTab value={DetailsPageTabs.OVERVIEW} activeTab={activeTab} />
                <AnalyticsDetailTab value={DetailsPageTabs.LIST} activeTab={activeTab} valueParser={startCase} />
                <AnalyticsDetailTab
                  value={DetailsPageTabs.DETAILED_STATS}
                  activeTab={activeTab}
                  valueParser={startCase}
                />
                <AnalyticsDetailTab
                  hidden={section !== IAnalyticsPageValues.ANALYTICS_PRODUCTS}
                  value={DetailsPageTabs.PRODUCT_SEARCH_STATS}
                  activeTab={activeTab}
                  valueParser={startCase}
                />
              </Tabs.List>
              <AnalyticsTabPanel
                value={DetailsPageTabs.OVERVIEW}
                content={
                  <AnalyticsOverviewTab
                    section={section}
                    dateRange={analyticsDateRange}
                    filtersComponent={AnalyticsFilters}
                    geoScopeIds={territories}
                    userIds={contactIds}
                    productIds={productIds}
                    recipientIds={recipientIds}
                    senderIds={senderIds}
                    includeDescendants={includeDescendants}
                  />
                }
              />
              <AnalyticsTabPanel
                value={DetailsPageTabs.LIST}
                content={
                  <AnalyticsListTab
                    section={section}
                    dateRange={analyticsDateRange}
                    filtersComponent={AnalyticsFilters}
                    geoScopeIds={territories}
                    userIds={contactIds}
                    productIds={productIds}
                    senderIds={senderIds}
                    recipientIds={recipientIds}
                    includeDescendants={includeDescendants}
                  />
                }
              />

              <AnalyticsTabPanel
                value={DetailsPageTabs.DETAILED_STATS}
                content={
                  <AnalyticsDetailedStatsTab
                    section={section}
                    dateRange={analyticsDateRange}
                    filtersComponent={AnalyticsFilters}
                    geoScopeIds={territories}
                    userIds={contactIds}
                    productIds={productIds}
                    includeDescendants={includeDescendants}
                    interaction={interaction}
                    assetIds={assetIds}
                    recipientIds={recipientIds}
                    senderIds={senderIds}
                  />
                }
              />

              <AnalyticsTabPanel
                value={DetailsPageTabs.PRODUCT_SEARCH_STATS}
                content={
                  <AnalyticsProductSearchStatsTab
                    section={IAnalyticsPageValues.ANALYTICS_PRODUCTS}
                    dateRange={analyticsDateRange}
                    filtersComponent={AnalyticsFilters}
                    geoScopeIds={territories}
                    userIds={contactIds}
                  />
                }
              />
            </Tabs>
          </div>
        </div>
      </div>
    </LoadingBoundary>
  );
};

export default observer(Analytics);
