import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';

import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';
import DateElement from 'components/date-list-element/date-list-element';

import useSelectedOption from 'utils/hooks/selected-option';

import { useRemote } from 'utils/hooks';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { fetchProductThumbnailImages, ListItemCountLink } from 'utils/ui';

import { Routes } from 'utils/routes';
import { DetailsPageTabs, IGuild } from 'types';
import { useMainAssetCountInGroup } from 'utils/hooks/asset';

import './style.scss';

interface IGuildsDataSectionListItemProps {
  contextMenu?: React.ReactElement;
  guild: IGuild;
  isChecked: boolean;
  isCheckedDisabled: boolean;
  handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  isActive: boolean;
}

export const GuildsDataSectionListItem: React.FC<IGuildsDataSectionListItemProps> = ({
  guild,
  contextMenu,
  handleClick,
  isChecked,
  isCheckedDisabled,
  isActive,
}) => {
  const {
    id,
    name,
    requested_codes_count,
    created_at,
    updated_at,
    expires_at,
    pm_statistics_data,
    users_count,
    access_level,
  } = guild;
  const fetcher = useCallback(() => fetchProductThumbnailImages(id), [id]);
  const [thumbnailImages] = useRemote(fetcher, [{}]);
  const [assetCount] = useMainAssetCountInGroup(id);

  const selectedOption = useSelectedOption({ value: access_level });
  const detailsPageLink = `${Routes.GUILDS}/${id}`;
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);

  return (
    <div
      className={cx('entity-list-item', {
        'entity-list-item--active': isActive,
        'entity-list-item--deleted': isEntityDeleted(guild),
      })}
      onClick={handleClick}
    >
      <div className="entity-list-item-row">
        <div
          className={cx('entity-list-item-row__checkbox', {
            [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
          })}
        >
          <Checkbox onClick={handleClick} checked={itemChecked} disabled={isCheckedDisabled} />
        </div>
        <div className="entity-list-item-row__multi-thumb">
          <MemoizedMultiThumbnail thumbnailImages={thumbnailImages || [{}]} />
        </div>
        <div className="entity-list-item-row__content">
          <div className="entity-list-item-row__title" title={name}>
            <Link to={detailsPageLink}>
              <h4 className="text-nowrap text-truncate">{name}</h4>
            </Link>
            {Boolean(contextMenu) && contextMenu}
          </div>
          <div className="guild-list-item__attributes muted-text">
            <div className="text-truncate guild-list-item__products">
              <ListItemCountLink
                entityCount={pm_statistics_data?.count}
                link={`${detailsPageLink}/${DetailsPageTabs.PRODUCTS}`}
                linkText="Product"
              />
            </div>

            <div className="text-truncate guild-list-item__assets">
              <ListItemCountLink
                entityCount={assetCount}
                link={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`}
                linkText="Asset"
              />
            </div>

            <div className="text-truncate guild-list-item__owner">
              <ListItemCountLink
                entityCount={users_count}
                link={`${detailsPageLink}/${DetailsPageTabs.CONTACTS}`}
                linkText="Contact"
              />
            </div>

            <div className="text-truncate guild-list-item__requested-codes-count">{`${
              requested_codes_count || 0
            } Available Codes`}</div>

            <div className="text-truncate guild-list-item__created">
              <DateElement date={created_at} mfxIcon="created-at" />
            </div>
            <div className="text-truncate guild-list-item__updated">
              <DateElement date={updated_at} mfxIcon="updated-at" />
            </div>
            <div className="text-truncate guild-list-item__expires">
              <DateElement date={expires_at} mfxIcon="expires-at" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
