import React from 'react';

import {
  FormGeoSelect,
  FormMultiSelect,
  FieldListV2,
  FormInput,
  FormSelect,
  FormMultiInput,
  FormHtmlInput,
  FormCheckbox,
  FormAccessLevel,
} from 'helpers/form';
import { CustomField } from 'pages/upsert-organization/custom-field';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormResponsibleContacts } from 'helpers/form/custom-fields';

import type { IOrganizationFormPart } from './types';

export const keyDataKeys = [
  'name',
  'access_level',
  'registration_number',
  'responsible_user_id',
  'legal_entity',
  'classification',
  'tags',
  'geo_scope_ids',
  'key_persons',
  'is_producer',
];

interface IOrganizationKeyDataProps extends IOrganizationFormPart {
  large?: boolean;
}
export const FormKeyData: React.FC<IOrganizationKeyDataProps> = ({ formData, formHandlers, formValues }) => {
  return (
    <>
      <FormSectionWrapperV2
        title="Business"
        wrapperClassName="py-3"
        className="entity-detail__infobox-wrapper"
        withBorder
      >
        <FormInput name="name" type="text" label="Organization Name" {...formHandlers} {...formData.name} />
        <FormInput
          name="registration_number"
          type="text"
          label="Registration Number"
          {...formHandlers}
          {...formData.registration_number}
        />
        <FormResponsibleContacts {...formHandlers} {...formData.responsible_user_id} />

        <FormSelect name="legal_entity" label="Legal Entity" large {...formHandlers} {...formData.legal_entity} />

        <FormMultiSelect
          name="tags"
          label="Tags"
          allowNewItems
          {...formHandlers}
          {...formData.tags}
          options={formValues.tags}
        />

        <FormGeoSelect
          large
          key="geo_scope"
          name="geo_scope_ids"
          label="Territories of Responsibility"
          {...formData.geo_scope_ids}
          {...formHandlers}
        />
        <div className="d-flex flex-column">
          <FormAccessLevel
            name="access_level"
            label="Access Level"
            large
            {...formHandlers}
            {...formData.access_level}
            withinPortal
          />
          <FormCheckbox label="is Producer Company" name="is_producer" {...formHandlers} {...formData.is_producer} />
        </div>
        <CustomField formData={formData} formValues={formValues} formHandlers={formHandlers} />
      </FormSectionWrapperV2>

      <FormSectionWrapperV2 wrapperClassName="py-3" gridTemplateColumns="1fr" withBorder>
        <FormHtmlInput
          name="description"
          type="text"
          label="Description"
          large
          {...formHandlers}
          {...formData.description}
        />
      </FormSectionWrapperV2>
      <FormSectionWrapperV2 wrapperClassName="py-3" gridTemplateColumns="1fr" withBorder padded={false}>
        <FieldListV2
          prefix="key_persons"
          label="Key Personnel"
          component={FormMultiInput}
          fieldProps={() => ({
            form: { data: formData, handlers: formHandlers },
            large: true,
            fields: [
              { key: 'label', label: 'Label' },
              { key: 'name', label: 'Name' },
            ],
          })}
          formValues={formValues}
          formHandlers={formHandlers}
        />
      </FormSectionWrapperV2>
    </>
  );
};
