import React, { useCallback, useEffect } from 'react';
import { UmGenericListItem } from '@mediafellows/mm3-types';

import { Model } from 'helpers/filters/types';
import { useMm3Form } from 'helpers/form/use-mm3-form';
import { FormInput, FormRemoteSelect, IRemoteSelectorFetcher } from 'helpers/form';
import { Thumbnail } from 'components/thumbnail';
import { SectionHeader } from 'components/section-header';
import { formatWebsiteTypes } from 'components/showcase-form/utils';
import { IAsset, IListItem, IMm3Asset, IPreviewImage } from 'types';
import { getPreviewImage, typeParser } from 'utils/list-item';

import { parseInitialValue } from './utils';

import { queryAssets, loadAsset } from 'utils/apis/asset';
import { parseKeyPathToObject } from 'utils/payload';
import { queryProductAssets } from 'utils/apis/product-asset';
import './style.scss';

export const ShowcaseItemForm: React.FC<{
  item: IListItem;
  previewImage?: IPreviewImage | null;
  syncData: (item: IListItem) => void;
  type?: string;
}> = ({ item, syncData, previewImage, type }) => {
  const { formData, handlers, values } = useMm3Form<UmGenericListItem>(item, Model.LIST_ITEMS);

  useEffect(() => {
    if (item.id === values.id) {
      return;
    }
    handlers.onChange(parseInitialValue(item));
  }, [item, handlers, values.id]);

  const fetchOptions = useCallback<IRemoteSelectorFetcher>(
    async (q: string, id: number): Promise<(IAsset | IMm3Asset)[]> => {
      if (id) {
        return queryAssets({
          ids: [id],
        });
      }
      const marketingAssets = await queryProductAssets({
        product_ids: item.entity_id,
        marketing_use: true,
      });

      return marketingAssets.reduce((acc, { asset }) => (asset ? [...acc, asset] : acc), []);
    },
    [item.entity_id],
  );

  const previewAssetId = getPreviewImage(item)?.asset_id;
  useEffect(() => {
    if (values.preview_asset_id || !previewAssetId) {
      return;
    }

    handlers.onChange({ preview_asset_id: previewAssetId });
  }, [handlers, item.id, previewAssetId, values.preview_asset_id]);

  const onChange = useCallback(
    async (values: Partial<IListItem>): Promise<void> => {
      handlers.onChange(values);

      if ('preview_asset_id' in values) {
        const { preview_image } = (values?.preview_asset_id && (await loadAsset(values?.preview_asset_id))) || {};

        syncData({ ...item, ...values, preview_image });
        return;
      }

      const { meta } = (parseKeyPathToObject(values) || {}) as UmGenericListItem;
      syncData({ ...item, meta: { ...(item.meta as object), ...(meta as object) } });
    },
    [handlers, item, syncData],
  );
  const title = `Edit item display within ${formatWebsiteTypes(type)}`;
  return (
    <div className="showcase-item-form">
      <SectionHeader title={title} />
      <Thumbnail
        image={previewImage?.url}
        customTag={typeParser?.(item?.entity)}
        showType
        assetType={item?.entity_type}
        className="item-preview-image"
      />
      {item.entity_type === 'product' && (
        <FormRemoteSelect
          name="preview_asset_id"
          label="Preview image"
          {...formData.preview_asset_id}
          {...handlers}
          fetchOptions={fetchOptions}
          emptyValue={null}
          onChange={onChange}
        />
      )}
      <FormInput label="Title" name="meta.title" {...handlers} {...formData.meta?.title} onChange={onChange} />
      <FormInput label="Text" textarea name="meta.text" {...handlers} {...formData.meta?.text} onChange={onChange} />
      <FormInput label="Link" name="meta.url" {...handlers} {...formData.meta?.url} onChange={onChange} />
      <FormInput
        label="Link text"
        name="meta.url_text"
        className="showcase-item-form__url_text_field"
        {...handlers}
        {...formData.meta?.url_text}
        onChange={onChange}
      />
    </div>
  );
};
