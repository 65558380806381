import React, { useCallback } from 'react';

import { FormProducts, FormCheckbox } from 'helpers/form';
import { ISmartGroupFormComponent } from 'types/smart-group';

export const keysProducts = ['product_ids', 'include_descendants'];

export const SmartGroupFormProducts: React.FC<ISmartGroupFormComponent> = ({ form }) => {
  const { handlers } = form;
  const handleChangeDescendants = useCallback(
    ({ include_descendants }) => {
      handlers.onChange({ include_descendants, ...(include_descendants ? {} : { include_future_descendants: false }) });
    },
    [handlers],
  );

  return (
    <div className="smart-group-wizard__products-tab">
      <FormProducts name="product_ids" label="Products" fitInParent {...form.formData.product_ids} {...form.handlers} />
      <FormCheckbox
        name="include_descendants"
        label="Add product(s) including descendants"
        {...form.formData.include_descendants}
        {...form.handlers}
        onChange={handleChangeDescendants}
      />
      <FormCheckbox
        name="include_future_descendants"
        label="Automatically include all future descendants"
        {...form.formData.include_descendants}
        {...form.handlers}
      />
    </div>
  );
};
