import { getRootStore } from 'store';
import { isUserRoleAllowed } from 'components/role-acl/role-acl';

import { IOption, IOptionColor, UserRoles } from 'types';
export const allowedDivisionWriteRoles = [UserRoles.ADMIN, UserRoles.SUPER_ADMIN];

export const affiliationHasNoDivisions = (): boolean => {
  const rootStore = getRootStore();
  const {
    basicStore: { divisions },
  } = rootStore;
  return !divisions?.length;
};
export const isUserAllowedToSetDivisions = (): boolean =>
  isUserRoleAllowed({ allowedRoles: allowedDivisionWriteRoles });

export const isDivisionAcessLevelNotAllowed = (): boolean => {
  return affiliationHasNoDivisions() || !isUserAllowedToSetDivisions();
};

export const ACCESS_LEVELS_OPTIONS: IOption[] = [
  {
    label: 'private',
    value: 'private',
    color: IOptionColor.red,
  },
  {
    label: 'company',
    value: 'company',
    color: IOptionColor.yellow,
  },
  {
    label: 'viewable',
    value: 'viewable',
    color: IOptionColor.green,
  },
  {
    label: 'public',
    value: 'public',
    color: IOptionColor.white,
  },
];

const divisionAccessLevel: IOption = {
  label: 'division',
  value: 'division',
  color: IOptionColor.orange,
};

export const getAccessLevelOptions = (hideDivisionAccessLevel = true): IOption[] => {
  return affiliationHasNoDivisions() || hideDivisionAccessLevel
    ? ACCESS_LEVELS_OPTIONS
    : [...ACCESS_LEVELS_OPTIONS.slice(0, 1), divisionAccessLevel, ...ACCESS_LEVELS_OPTIONS.slice(1)];
};
