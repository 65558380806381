import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import map from 'lodash/map';

import { Routes } from 'utils/routes';
import { Thumbnail } from 'components/thumbnail';
import { ProductAncestryBreadcrumbs as AncestryBreadcrumbs } from 'components/breadcrumb-product-ancestry';
import DateElement from 'components/date-list-element/date-list-element';
import { AssetAttributes } from 'components/asset/asset-data-section-item';
import CheckboxTree from 'components/checkbox-tree/checkbox-tree';
import { ICheckboxIcons, IOnCheckNode } from 'components/checkbox-tree/types';

import { IOmniTreeNode } from 'helpers/form';
import { IAsset, IProduct } from 'types';

import useSelectedOption from 'utils/hooks/selected-option';
import { getEntityType } from 'utils/general';
import { CategoriesInfo, placeholder } from 'utils/ui';
import { Classes } from 'utils/ui';

import './style.scss';

export interface IProductListItem {
  product: IProduct & { assets: IAsset[] };
  readOnly?: boolean;
}

const ProductListItem: React.FC<IProductListItem> = ({ product }) => {
  const selectedOption = useSelectedOption({ value: product.access_level, hideDivisionAccessLevel: false });

  return (
    <div
      className={cx('list-item__wrapper list-item__product-wrapper', {
        [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
      })}
    >
      <Thumbnail showType assetType={product.type} image={product.inherited_preview_image?.url} />
      <div className="list-item__rows">
        <div className="d-flex align-items-center justify-content-between">
          <AncestryBreadcrumbs ancestryInfo={product.ancestry_info} layout="list" productId={product.id} />
        </div>
        <div className="entity-list-item-row__title d-flex align-items-center">
          <Link className="text-truncate" to={`${Routes.PRODUCTS}/${product?.id}`}>
            <h4 className="text-truncate">{product.title}</h4>
          </Link>
        </div>
        <div className={cx('list-item__product-info', Classes.TEXT_MUTED)}>
          <div>{product.default_layer?.meta?.year_of_production || '—'}</div>
          <div className="text-truncate text-nowrap">
            <CategoriesInfo category_ids={product.default_layer?.meta?.category_ids} />
          </div>
          <DateElement date={product.created_at} mfxIcon="created-at" className="text-nowrap text-truncate" />
          <DateElement date={product.published_at} mfxIcon="published-at" className="text-nowrap text-truncate" />
        </div>
      </div>
    </div>
  );
};

export interface IAssetListItem {
  asset: IAsset;
}

const AssetListItem: React.FC<IAssetListItem> = ({ asset }) => {
  const assetType = getEntityType(asset.classification);
  const selectedOption = useSelectedOption({ value: asset.access_level, hideDivisionAccessLevel: false });

  return (
    <div
      className={cx('list-item__wrapper list-item__asset-wrapper', {
        [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
      })}
    >
      <Thumbnail showType assetType={assetType} image={asset.preview_image?.url} />
      <div className="list-item__rows">
        <div className="entity-list-item-row__title d-flex align-items-center">
          <Link className="text-truncate" to={`${Routes.ASSETS}/${asset?.id}`}>
            <h4 className="text-truncate">{asset.name}</h4>
          </Link>
        </div>
        <div className={cx('list-item__asset-info', Classes.TEXT_MUTED)}>
          <AssetAttributes asset={asset} />
          <div>{placeholder(asset.format)}</div>
          <DateElement date={asset.updated_at} mfxIcon="updated-at" className="text-nowrap text-truncate" />
          <DateElement date={asset.published_at} mfxIcon="published-at" className="text-nowrap text-truncate" />
        </div>
      </div>
    </div>
  );
};

interface ITreeWrapperProps {
  optionsTree: IOmniTreeNode[][];
  checked: string[][];
  disabled?: boolean;
  onCheck?: (currentBranch: string[], node: IOnCheckNode) => Promise<void>;
  customIcons?: ICheckboxIcons;
}

export const TreeWrapper: React.FC<ITreeWrapperProps> = ({
  optionsTree,
  checked,
  disabled = false,
  onCheck,
  customIcons,
}) => {
  return (
    <div
      className={cx('marketing-entity-products__wrapper', {
        ['marketing-entity-products__wrapper--disabled']: disabled,
      })}
    >
      {optionsTree?.map((nodes, index) => {
        const expanded = map(nodes, 'value');
        const checkedForCurrent = checked[index] || [];
        return (
          <div
            key={index}
            className={cx('marketing-entity-products__content', {
              ['marketing-entity-products__content--active']: checkedForCurrent.length,
            })}
          >
            <CheckboxTree
              disabled={disabled}
              checked={checkedForCurrent}
              nodes={nodes}
              onCheck={onCheck}
              expanded={expanded}
              withExpanded
              icons={customIcons}
            />
          </div>
        );
      })}
    </div>
  );
};

export { ProductListItem, AssetListItem };
