import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Tree } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { TreeController } from '@mantine/core/lib/components/Tree/use-tree';

import { useStore } from 'store';

import { SectionHeader } from 'components/section-header';
import { Loading } from 'components/loading';
import { SectionMessage, SectionMessageType } from 'components/section-message';
import { DataSectionContentWrapper } from 'components/data-section-content-wrapper';
import { CreateDivisionButton } from 'components/divisions/division-form/create-division-button';

import { parseQueryParams } from 'utils/general';

import { IDivisionTreeItem } from 'types';

export const DivisionDataSectionItems: React.FC<{
  className: string;
  itemRenderer: (item) => React.ReactNode | JSX.Element;
  title: string;
  divisionsTree: IDivisionTreeItem[];
  tree: TreeController;
}> = observer(({ className, itemRenderer, title, divisionsTree, tree }) => {
  const {
    dataSectionStore: { searchStore, setParams, reset },
  } = useStore();

  const [allowRender, setAllowRender] = useState(false);
  const location = useLocation();
  const { running } = searchStore || {};
  useEffect(() => {
    reset();
    setAllowRender(true);
  }, [reset]);

  useEffect(() => {
    if (!searchStore?.parametron || !allowRender) {
      return;
    }
    const searchData = parseQueryParams(location.search);
    setParams(searchData).triggerSearch();
  }, [location, searchStore, allowRender, setParams]);

  return (
    <div className="data-section-layout">
      <div className={className}>
        <SectionHeader
          useBorder
          className="website-data-section__section-header"
          title={title}
          useBackground
          customRightRenderer={() => <CreateDivisionButton />}
        />
        {running && <Loading text="Loading Data" />}
        {!(running || divisionsTree.length) && <SectionMessage intent={SectionMessageType.EMPTY} />}
        {!running && Boolean(divisionsTree.length) && (
          <div className="data-section__content-wrapper website-data-section__items-list h-100">
            <DataSectionContentWrapper className="h-100" layout="list">
              <Tree
                selectOnClick
                clearSelectionOnOutsideClick
                data={divisionsTree}
                renderNode={itemRenderer}
                tree={tree}
              />
            </DataSectionContentWrapper>
          </div>
        )}
      </div>
    </div>
  );
});

export default DivisionDataSectionItems;
