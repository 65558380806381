import React, { useMemo, useCallback, useState, useRef } from 'react';

import AudioProgressBar from './audio-progress-bar';
import VolumeInput from './volume-input';
import { SmallLoader } from 'components/thumbnail';
import { IAsset, IMm3Asset } from 'types';
import { getPreviewImageUrl } from 'utils/asset';
import { MantineIcon } from 'utils/ui';
import { IconSize } from 'utils/ui/icon-size';

import './style.scss';

function formatDurationDisplay(duration: number): string {
  const min = Math.floor(duration / 60);
  const sec = Math.floor(duration - min * 60);

  const formatted = [min, sec].map((n) => (n < 10 ? '0' + n : n)).join(':');

  return formatted;
}

interface IAudioPlayerProps {
  asset?: IAsset | IMm3Asset;
}

const AudioPlayer: React.FC<IAudioPlayerProps> = ({ asset }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [isReady, setIsReady] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [buffered, setBuffered] = useState(0);
  const [volume, setVolume] = useState(0.2);
  const [isPlaying, setIsPlaying] = useState(false);

  const durationDisplay = useMemo(() => formatDurationDisplay(duration), [duration]);
  const elapsedDisplay = useMemo(() => formatDurationDisplay(currentProgress), [currentProgress]);

  const togglePlayPause = useCallback((): void => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  }, [isPlaying]);

  const handleBufferProgress: React.ReactEventHandler<HTMLAudioElement> = useCallback((e) => {
    const audio = e.currentTarget;
    const dur = audio.duration;
    if (dur > 0) {
      for (let i = 0; i < audio.buffered.length; i++) {
        if (audio.buffered.start(audio.buffered.length - 1 - i) < audio.currentTime) {
          const bufferedLength = audio.buffered.end(audio.buffered.length - 1 - i);
          setBuffered(bufferedLength);
          break;
        }
      }
    }
  }, []);

  const handleMuteUnmute = useCallback((): void => {
    if (!audioRef.current) return;

    if (audioRef.current.volume !== 0) {
      audioRef.current.volume = 0;
    } else {
      audioRef.current.volume = 1;
    }
  }, []);

  const handleVolumeChange = useCallback((volumeValue: number): void => {
    if (!audioRef.current) return;
    audioRef.current.volume = volumeValue;
    setVolume(volumeValue);
  }, []);

  const handleTimeUpdate = useCallback(
    (e) => {
      setCurrentProgress(e.currentTarget.currentTime);
      handleBufferProgress(e);
    },
    [handleBufferProgress],
  );

  const handleDurationChange = useCallback((e) => setDuration(e.currentTarget.duration), []);

  const handleCanPlay = useCallback(
    (e) => {
      e.currentTarget.volume = volume;
      setIsReady(true);
    },
    [volume],
  );

  const onProgressBarChange = useCallback((e) => {
    if (!audioRef.current) return;
    audioRef.current.currentTime = e.currentTarget.valueAsNumber;
    setCurrentProgress(e.currentTarget.valueAsNumber);
  }, []);

  const format = (asset as IAsset)?.format || (asset as IMm3Asset)?.source_meta?.format;
  const audioFormat = format?.charAt(0) === '.' ? format.slice(1) : 'mp3';
  return (
    <div className="mt-auto h-100">
      <div className="p-2 h-100 position-relative d-flex flex-column align-items-center">
        {asset && (
          <audio
            ref={audioRef}
            preload="metadata"
            onDurationChange={handleDurationChange}
            onPlaying={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onCanPlay={handleCanPlay}
            onTimeUpdate={handleTimeUpdate}
            onProgress={handleBufferProgress}
            onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
          >
            <source type={`audio/${audioFormat}`} src={asset?.stream_url || ''} />
          </audio>
        )}
        <div
          className="w-100 audio-player__bg-img"
          style={{
            backgroundImage: `url(${getPreviewImageUrl(asset?.preview_image)})`,
          }}
        >
          <button
            disabled={!isReady}
            onClick={togglePlayPause}
            aria-label={isPlaying ? 'Pause' : 'Play'}
            className="d-flex align-items-center justify-content-center audio-player__icon-button"
          >
            {!isReady && !asset?.stream_url && (
              <MantineIcon fill="var(--mfx-main)" icon="high-priority" size={IconSize.LARGE} />
            )}
            {!isReady && asset?.stream_url && <SmallLoader />}
            {isReady && asset?.stream_url && isPlaying && (
              <MantineIcon fill="var(--mfx-main)" icon="pause" size={IconSize.LARGE} />
            )}
            {isReady && asset?.stream_url && !isPlaying && (
              <MantineIcon fill="var(--mfx-main)" icon="play" size={IconSize.LARGE} />
            )}
          </button>
        </div>
        <div className="d-flex flex-column w-100 mt-1">
          <div className="d-flex align-items-center justify-content-between">
            <span>
              {elapsedDisplay} / {durationDisplay}
            </span>
            <div className="d-flex align-items-center ">
              {volume === 0 ? (
                <MantineIcon icon="volume-off" onClick={handleMuteUnmute} />
              ) : (
                <MantineIcon icon="volume-up" onClick={handleMuteUnmute} className="me-1" />
              )}

              <VolumeInput volume={volume} onVolumeChange={handleVolumeChange} />
            </div>
          </div>
          <AudioProgressBar
            duration={duration}
            currentProgress={currentProgress}
            buffered={buffered}
            onChange={onProgressBarChange}
          />
        </div>
      </div>
    </div>
  );
};
export default AudioPlayer;
