import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  FormInput,
  FormDate,
  FormSelect,
  useFetchFieldOptions,
  FormCountry,
  IUseFormReturn,
  FormCheckbox,
  FormDivisions,
} from 'helpers/form';
import { FormOrganization } from 'helpers/form/fields/form-organization';
import { FormResponsibleContacts } from 'helpers/form/custom-fields';
import { minBirthDate, maxBirthDate } from 'utils/constants';
import { Classes } from 'utils/ui';
import { fetchPlatformRoles } from 'pages/upsert-contact/step-about/api';
import { IContactFields } from 'pages/upsert-contact';
import { CustomFields } from 'pages/upsert-contact/step-about/custom-fields';
import { ItemId } from 'types/utility';

export interface IStepAboutProps {
  form: IUseFormReturn<IContactFields>;
  modalView?: boolean;
  organizationId?: ItemId;
}

const StepAbout: React.FC<IStepAboutProps> = observer((props) => {
  const { form, modalView, organizationId } = props;

  const roleOptions = useFetchFieldOptions(fetchPlatformRoles);

  const { formData, handlers } = form;

  const isDisabledOrganization = modalView && organizationId ? true : false;

  return (
    <>
      <div className={`form-dynamic-fields__title ${Classes.TEXT_MUTED}`}>Personal & Business Data</div>
      <div className="upsert-contact__form">
        <FormInput name="first_name" type="text" label="First Name" {...formData.first_name} {...handlers} />
        <FormInput name="last_name" type="text" label="Last Name" {...formData.last_name} {...handlers} />
        <FormOrganization
          name="organization_id"
          {...formData.organization_id}
          {...handlers}
          disabled={isDisabledOrganization}
        />
        <FormDivisions name="division_ids" label="Divisions" large {...formData.division_ids} {...handlers} />
        <FormInput
          name="function"
          type="text"
          label="Function"
          placeholder="Type Function e.g. CEO"
          {...formData.function}
          {...handlers}
        />
        <FormInput name="email" type="email" label="Email" {...formData.email} {...handlers} />
        <FormCountry name="country_id" label="Country" large {...formData.country_id} {...handlers} />
        <FormSelect name="gender" label="Gender" large {...formData.gender} {...handlers} />
        <FormDate
          className="registration__date"
          large
          name="date_of_birth"
          label="Birthday"
          minDate={minBirthDate}
          maxDate={maxBirthDate}
          {...formData.date_of_birth}
          {...handlers}
          hideTodayButton
        />
        <CustomFields formData={formData} handlers={handlers} />
        <FormSelect
          name="role_id"
          label="Platform Role"
          large
          {...formData.role_id}
          {...handlers}
          options={roleOptions}
        />
        <FormCheckbox
          large
          name="sso"
          label="SSO Login Enabled"
          {...handlers}
          {...formData.sso}
          groupClassName="align-self-center"
        />
        <FormResponsibleContacts {...handlers} {...formData.responsible_user_id} />
      </div>
    </>
  );
});

export default StepAbout;
