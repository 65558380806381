import React from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { Avatar, Indicator } from '@mantine/core';

import { stringToColour } from 'components/avatar';
import { SmallLoader } from 'components/thumbnail';

import { useFetchPreviewWithRefreshForIngest } from 'utils/hooks';
import { isProcessingPreview } from 'utils/general';

import { IContact } from 'types';

import './style.scss';

export interface IUserAvatarProps {
  className?: string;
  user?: IContact | null;
  showTag?: boolean;
  onClick?: () => void;
  shouldRefresh?: boolean;
  disabled?: boolean;
  size?: number;
  offset?: number;
}

const getFirstLastName = (user?: IContact): { first_name: string; last_name: string } => {
  if (!user) {
    return { first_name: '', last_name: '' };
  }

  const { first_name, last_name } = user;

  if (first_name || last_name) {
    return { first_name, last_name };
  }

  const { full_name = '' } = user;

  const parts = full_name.split(' ');

  return { first_name: parts.shift() || '', last_name: parts.join(' ') };
};

export const getStatusColor = (status: string | undefined): string => {
  switch (status) {
    case 'active':
      return '#38a169';
    case 'blocked':
      return '#e53e3e';
    case 'deleted':
      return '#c53030';
    case 'inactive':
      return '#a0aec0';
    case 'invited':
      return '#3182ce';
    case 'pending':
      return '#d69e2e';
    case 'rejected':
      return '#c53030';
    case 'review':
      return '#d69e2e';
    default:
      return '';
  }
};

const UserAvatar: React.FC<IUserAvatarProps> = observer((props) => {
  const {
    className,
    user,
    onClick,
    showTag = false,
    shouldRefresh = false,
    disabled = false,
    size = 16,
    offset = 7,
  } = props;

  const { preview_image: user_preview_image, preview_image_id } = user || {};
  const [preview_image] = useFetchPreviewWithRefreshForIngest(
    { preview_image_id, preview_image: user_preview_image },
    shouldRefresh,
  );

  if (!user) return null;

  const { status, role_name } = user;
  const url = preview_image?.url;

  const { first_name, last_name } = getFirstLastName(user);

  return (
    <div className={cx('user-avatar', className, { 'user-avatar--clickable': !!onClick })} onClick={onClick}>
      <Indicator
        disabled={disabled}
        inline
        size={size}
        offset={offset}
        position="bottom-end"
        color={getStatusColor(status)}
        withBorder
        className="user-avatar__content"
        zIndex="1"
      >
        <Avatar
          src={url}
          className="user-avatar__content user-avatar__content--image"
          color={stringToColour(`${first_name}${last_name}`)}
          radius="xl"
          alt={`${first_name} ${last_name}`}
        >
          {!url && `${first_name?.[0]}${last_name?.[0]}`}
        </Avatar>
      </Indicator>

      {showTag && role_name && <div className="user-avatar__tag text-uppercase p-1">{role_name}</div>}

      {isProcessingPreview({ preview_image_id, preview_image }) && (
        <div className="user-avatar__content user-avatar__content--fallback user-avatar__content--pending">
          <SmallLoader size={30} />
        </div>
      )}

      {!url && !preview_image_id && (
        <div
          style={{ backgroundColor: stringToColour(`${first_name}${last_name}`) }}
          className="user-avatar__content user-avatar__content--fallback"
        >
          {first_name?.[0]}
          {last_name?.[0]}
        </div>
      )}
    </div>
  );
});

export default UserAvatar;
