import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { Routes } from 'utils/routes';
import { Thumbnail } from 'components/thumbnail';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { PreviewTab, PreviewInfo } from 'components/preview-tab';

import { DetailsPageTabs, IAsset, IConference } from 'types';
import { useBasicStore, useDataSectionStore } from 'store';
import { AssetsMainClassificationLink } from 'components/asset/assets-aggregation/product-assets';
import DateListElement from 'components/date-list-element';
import { parseConferenceUrl } from 'utils/conference';
import { ClipboardView } from 'components/clipboard-view';
import { IconAlias } from 'icons';
import { formatDate } from 'utils/date';
import { queryMainAssets } from 'utils/apis/asset';
import { formatFullName } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { DetailCountLink } from 'utils/ui';

export const ConferencePreview: React.FC<{}> = observer(() => {
  const { activeItem: conference } = useDataSectionStore<IConference>();

  const {
    asset_ids,
    user_ids,
    background_media,
    code,
    created_at,
    disable_waitingroom,
    ends_at,
    mode,
    owner,
    starts_at,
    status,
    title,
    updated_at,
  } = conference || {};
  const { buyerDomain } = useBasicStore();

  const fetchAssets = useCallback(
    async (): Promise<IAsset[]> => (asset_ids?.length ? queryMainAssets({ ids: asset_ids }) : []),
    [asset_ids],
  );
  const [assets = [], assetsLoading] = useRemote<IAsset[]>(fetchAssets, []);
  const conferenceUrl = parseConferenceUrl(buyerDomain, code);

  if (!conference?.id) {
    return <span className="entity-preview-tab__no-preview">No preview</span>;
  }

  const detailPageLink = `${Routes.CONFERENCES}/${code}`;
  const showAssets = !assetsLoading && assets.length;
  return (
    <PreviewTab
      title={title}
      subtitle={
        <ClipboardView value={conferenceUrl} className="text-center d-block">
          <a className="text-wrap text-break" href={conferenceUrl} target="_blank" rel="noreferrer noopener">
            {code}
          </a>
        </ClipboardView>
      }
      detailsLink={`${detailPageLink}`}
      customThumbnail={
        <Thumbnail image={background_media} showType customTag={mode} fallbackIconName={IconAlias.CONFERENCE} />
      }
    >
      <PreviewInfo>
        <Row label="Title" value={title} />
        <Row
          label="Assets"
          hideIfNoValue
          value={
            showAssets && (
              <AssetsMainClassificationLink
                assets={assets}
                baseUrl={`${detailPageLink}/${DetailsPageTabs.ASSETS}`}
                muted={false}
              />
            )
          }
        />
        <DetailCountLink
          entityCount={user_ids && user_ids.length}
          link={`${detailPageLink}/${DetailsPageTabs.CONTACTS}`}
          label="Contacts"
        />
        <Row label="Conference type" value={mode} size="m" />
        <Row label="Status" value={status} size="m" />
        <Row label="Starts at" rawLabel value={<DateListElement date={starts_at} />} size="m" />
        <Row label="Ends at" rawLabel value={<DateListElement date={ends_at} />} size="m" />
        <Row label="Moderator" value={formatFullName(owner)} size="m" />
        <Row label="Waiting Room" value={disable_waitingroom ? 'Disabled' : 'Enabled'} size="m" />
        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
      </PreviewInfo>
    </PreviewTab>
  );
});
