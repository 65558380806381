import { GroupTypes, IAsset, IMm3Asset } from 'types';

import { useAddToGroupDialog } from 'components/add-to-group-dialog';
import { shouldDisplayAddToSelection as shouldDisplay } from './actions-acl';
import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';

export const useAddToSelectionAction = (items: (IAsset | IMm3Asset)[]): IAssetActionConfig => {
  const [openSelectionDialog] = useAddToGroupDialog({
    entityType: 'assets',
    items: items.map(({ id, name }) => ({ label: name || `-${id}-`, value: id || 0 })),
    groupType: GroupTypes.SELECTION,
    omni: true,
  });

  return {
    name: IAssetActionName.ADD_TO_SELECTION,
    shouldDisplay,
    icon: 'add-to-artifact',
    title: `Add to Selection`,
    handler: openSelectionDialog,
  };
};
