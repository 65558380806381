import React, { FC, useState, useCallback } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { ActionIcon } from '@mantine/core';
import { Export } from 'blueprint5-icons';

import { SectionHeader } from 'components/section-header';
import LoadingBoundary from 'components/loading-boundary/loading-boundary';
import { Loading } from 'components/loading';
import { CardPagination } from 'components/card-pagination';
import { exportAnalytics } from 'utils/apis/analytics';

import { useRemote, useResetPagination, useWindowSize } from 'utils/hooks';
import { formatDate } from 'utils/date';
import { LinkWithArrow } from 'utils/ui';
import { IUseTopItemData } from 'types/analytics';
import { IAnalyticsProps } from 'types';
import { TopCardAssignedProducts, TopCardItemImage, LinkOrText, TopCardItemTitle } from './utils';
import { useStore } from 'store';

import './style.scss';
import { MantineIcon } from 'utils/ui/icon';

interface IStatisticTopCardProps {
  title: string;
  fetcher: (params) => Promise<IUseTopItemData>;
  statisticName: string;
  url?: string | { pathname: string; state: unknown };
  entity?: string;
  dateRange?: [Date, Date];
  geoScopeIds?: Array<string>;
  userIds?: Array<string>;
  productIds?: Array<string>;
  includeDescendants?: boolean;
  assetIds?: Array<string>;
  senderIds?: string[];
  recipientIds?: string[];
  showPagination?: boolean;
  interaction?: string;
  query?: string;
  exportAction?: boolean;
}

const StatisticsTopCard: FC<IStatisticTopCardProps> = ({
  title,
  statisticName,
  url,
  fetcher,
  dateRange,
  userIds,
  geoScopeIds,
  interaction,
  productIds,
  assetIds,
  senderIds,
  recipientIds,
  includeDescendants,
  showPagination = false,
  exportAction = true,
  query,
}) => {
  const [page, setPage] = useState(1);

  const [per] = useState(20);
  const state =
    (url as { pathname: string; state: IAnalyticsProps })?.state ||
    ({
      dateRange,
      userIds,
      geoScopeIds,
      interaction,
      productIds,
      includeDescendants,
    } as IAnalyticsProps);

  useResetPagination(setPage);

  const fetchWithParams = useCallback(
    (newPage?: number) => {
      if (newPage) {
        setPage(newPage);
      }
      const params = {
        dateRange,
        userIds,
        geoScopeIds,
        productIds,
        includeDescendants,
        assetIds,
        senderIds,
        recipientIds,
      };

      return fetcher(showPagination ? { page, per, ...params } : params);
    },
    [
      assetIds,
      dateRange,
      fetcher,
      geoScopeIds,
      includeDescendants,
      page,
      per,
      productIds,
      recipientIds,
      senderIds,
      showPagination,
      userIds,
    ],
  );

  const [result, isLoading] = useRemote<IUseTopItemData>(fetchWithParams, { data: [] });
  const { data, pagination } = result as IUseTopItemData;

  const totalPages = Number(Math.ceil((pagination?.total_count || 0) / per));
  const size = useWindowSize();
  const { toastStore } = useStore();

  const exportTopCardAnalytics = useCallback(async () => {
    try {
      await exportAnalytics({
        exportQuery: query + '_export',
        dateRange: dateRange as [Date, Date],
        geoScopeIds: geoScopeIds ?? [],
        contactIds: userIds ?? [],
        productIds: productIds ?? [],
        includeDescendants: includeDescendants || false,
        recipientIds,
        senderIds,
      });
      toastStore.success('Export requested and sent via email.');
    } catch (e) {
      toastStore.error('Failed to export detailed list.');
    }
  }, [dateRange, query, geoScopeIds, recipientIds, senderIds, userIds, productIds, includeDescendants, toastStore]);

  const CustomRightRenderer = (): JSX.Element => (
    <>
      {data.length && !url && exportAction ? (
        <div className="list-table__actions">
          <ActionIcon
            title="Export Detailed List data"
            variant="subtle"
            color="gray.5"
            onClick={exportTopCardAnalytics}
          >
            <MantineIcon icon={<Export />} />
          </ActionIcon>
        </div>
      ) : null}
      {url ? <LinkWithArrow className="view-all__card--text" to={url} state={state} text="View All" /> : <></>}
    </>
  );

  return (
    <div className="statistic-top-card-wrapper">
      <SectionHeader title={title} useBackground useBorder customRightRenderer={CustomRightRenderer} />
      <div className="statistic-top-card">
        <div className="statistic-top-card__content">
          <LoadingBoundary loading={isLoading} suspender={<Loading text={'Loading...'} />}>
            {data?.map(({ id, title, thumbnail, lastViewed, count, url, contact, products }, index) => {
              url = url.replace('overview', 'analytics');
              return (
                <div key={id + title + index}>
                  <div
                    className={cx('statistic-top-card__content-wrapper', {
                      'statistic-top-card__content-wrapper--last': index === data.length - 1,
                    })}
                  >
                    <TopCardItemImage contact={contact} thumbnail={thumbnail} />
                    <div className="statistic-top-card__item-left">
                      <TopCardItemTitle contact={contact} title={title} state={state} url={url} />
                      <TopCardAssignedProducts products={products} assetId={id} />
                      {lastViewed && (
                        <div className="statistic-top-card__content__lastview">
                          <span className="text-muted">Last View: </span>
                          <span>{formatDate(lastViewed)}</span>
                        </div>
                      )}
                    </div>
                    <LinkOrText url={url} state={state} className="statistic-top-card__content__statistic">
                      <div className="statistic-top-card__content__statistic__value">{count}</div>
                      <div className="statistic-top-card__content__statistic__title">{statisticName.toUpperCase()}</div>
                    </LinkOrText>
                  </div>
                </div>
              );
            })}
          </LoadingBoundary>
        </div>
        {data?.length && showPagination ? (
          <div className="list-table__pagination">
            <CardPagination
              page={page || 1}
              onPageChange={fetchWithParams}
              totalCount={Number(pagination?.total_count)}
              totalPages={totalPages}
              pageRangeDisplayed={Math.floor(size.width / 200)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default observer(StatisticsTopCard);
