import React from 'react';

import { withAssetVersion } from 'utils/asset';
import { FilterSwitch, IFiltersHandlers } from 'helpers/filters';

import { IAssetFilters } from './utils';

interface IAssetFiltersProps {
  filterValues: IAssetFilters;
  filterHandlers: IFiltersHandlers<IAssetFilters>;
  withMainAssetsFilter?: boolean;
}

export const AssetOldParentFilter: React.FC<IAssetFiltersProps> = ({}) => {
  return <></>;
};

export const AssetMm3ParentFilter: React.FC<IAssetFiltersProps> = (props) => {
  const { filterValues, filterHandlers, withMainAssetsFilter } = props;

  return (
    <>
      {withMainAssetsFilter && (
        <FilterSwitch
          label="Show main assets only"
          name="parent_id"
          filter={filterValues.parent_id}
          onChange={filterHandlers.onChange}
          checkedValue
          notCheckedValue={false}
        />
      )}
    </>
  );
};

export const AssetParentFilter = withAssetVersion(AssetOldParentFilter, AssetMm3ParentFilter);
