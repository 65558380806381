import React from 'react';
import { observer } from 'mobx-react-lite';

import { FormSelect, IFormSelectProps } from 'helpers/form';
import { affiliationHasNoDivisions, isUserAllowedToSetDivisions } from 'utils/access-level';

import { AccessLevelLabel } from 'utils/ui';

interface IFormAccessLevelProps extends IFormSelectProps {
  filterOptionsByUserRole?: boolean;
}
export const FormAccessLevel: React.FC<IFormAccessLevelProps> = observer((props) => {
  const { options, filterOptionsByUserRole = false, label, required } = props;

  let modifiedOptions = options;

  if (filterOptionsByUserRole && !isUserAllowedToSetDivisions()) {
    modifiedOptions = (options as string[])?.filter((option) => option !== 'division');
  } else if (affiliationHasNoDivisions()) {
    modifiedOptions = (options as string[])?.map((value) => ({
      label: value,
      value: value || '',
      disabled: value === 'division',
    }));
  }

  return (
    <FormSelect
      large
      {...props}
      options={modifiedOptions}
      labelComponent={label && <AccessLevelLabel label={label} required={required} options={modifiedOptions} />}
    />
  );
});

export default FormAccessLevel;
