import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';
import cx from 'classnames';
import { map } from 'lodash';

import { Routes } from 'utils/routes';
import { useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IConference, ItemId } from 'types';
import { createConference } from 'utils/apis/conference';
import { useStore } from 'store';
import { Wizard, WizardStep, useWizardSteps } from 'components/wizard';
import { ToastError } from 'components/toast';

import { FormActionSection } from 'components/form-submit-section';
import { ConferenceCreateSummary } from 'components/conference/conference-create-summary';
import { initialValues } from 'utils/conference';
import { ConferenceAttachmentsSection } from 'components/conference/conference-form/attachments-section';
import { ConferenceSettingsSection } from 'components/conference/conference-form/settings-section';
import { ConferenceAssetsSection } from 'components/conference/conference-form/assets-section';
import { customContext } from 'components/conference/conference-form/utils';
import { useNavigate } from 'react-router';
import { MantineIcon } from 'utils/ui/icon';
import { ArrowLeft } from 'blueprint5-icons';

import { StepIds } from './utils';
import { IconAlias } from 'icons/icon-aliases';
import { ConferenceUsersSection } from './users-section';
import { useSessionStore } from 'store/session-store';
import { querySubAssets } from 'utils/apis/asset';

export const CreateConferenceWizard: React.FC<{
  conference?: Partial<IConference>;
  onSuccess?: VoidFunction;
}> = observer(({ conference = initialValues, onSuccess }) => {
  const { toastStore } = useStore();
  const user = useSessionStore((state) => state.user);
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (conference: Partial<IConference>): Promise<void> => {
      try {
        await createConference({
          ...conference,
          asset_ids: [...(conference.asset_ids || []), ...(conference.subAssetIds || [])],
        });
        toastStore.success('Conference was created successfully!');
        if (onSuccess) {
          onSuccess();
        } else {
          navigate(Routes.CONFERENCES);
        }
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [onSuccess, navigate, toastStore],
  );

  const conferenceForm = useForm<Partial<IConference>>(conference, Model.CONFERENCE, handleSubmit, customContext);

  if (user?.id && !conferenceForm.values.owner_id) {
    conferenceForm.handlers.onChange({ owner_id: parseInt(user?.id as string, 10) });
  }

  const steps = [
    {
      id: StepIds.Settings,
      disabled: !conferenceForm.valid,
    },
    {
      id: StepIds.Assets,
      disabled: !conferenceForm.valid,
    },
    {
      id: StepIds.Users,
      disabled: !conferenceForm.valid,
    },
    {
      id: StepIds.Layout,
      disabled: !conferenceForm.valid,
    },
    {
      id: StepIds.Summary,
      nextButtonTitle: 'Create',
      action: conferenceForm.onSubmit,
    },
  ];

  const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(steps);

  useEffect(() => {
    if (currentStepId === StepIds.Summary) {
      const fetchSubAssets = async (): Promise<void> => {
        const subAssets = await querySubAssets(conferenceForm.values.asset_ids as ItemId[]);
        const subAssetIds = map(subAssets, 'id');
        conferenceForm.handlers.onChange({ subAssetIds: subAssetIds || [] });
      };

      fetchSubAssets();
    }
  }, [currentStepId, conferenceForm.values.asset_ids, conferenceForm.handlers]);

  return (
    <Wizard
      fitInParent={true}
      selectedStepId={currentStepId}
      onChange={setCurrentStepId}
      footerPadded={false}
      footer={
        <FormActionSection className="d-flex justify-content-between w-100">
          <Button
            className={cx('m-0', { invisible: backButton.hide })}
            onClick={backButton.action}
            disabled={backButton.hide}
            variant="subtle"
            leftSection={<MantineIcon icon={<ArrowLeft />} />}
          >
            {backButton.title}
          </Button>
          <Button className="m-0" variant="primary" onClick={nextButton.action} disabled={nextButton.disabled}>
            {nextButton.title}
          </Button>
        </FormActionSection>
      }
    >
      <WizardStep
        id={StepIds.Settings}
        disabled={isStepDisabled(StepIds.Settings)}
        title="Settings"
        icon="settings"
        panel={<ConferenceSettingsSection conferenceForm={conferenceForm} padded={false} />}
      />

      <WizardStep
        id={StepIds.Assets}
        disabled={isStepDisabled(StepIds.Assets)}
        title="Assets"
        icon="media"
        panel={<ConferenceAssetsSection conferenceForm={conferenceForm} />}
      />

      <WizardStep
        id={StepIds.Users}
        disabled={isStepDisabled(StepIds.Users)}
        title="Contacts"
        icon={IconAlias.CONTACT}
        panel={<ConferenceUsersSection conferenceForm={conferenceForm} />}
      />

      <WizardStep
        id={StepIds.Layout}
        disabled={isStepDisabled(StepIds.Layout)}
        title="Layout"
        icon="document"
        panel={<ConferenceAttachmentsSection conferenceForm={conferenceForm} />}
      />
      <WizardStep
        id={StepIds.Settings}
        disabled={isStepDisabled(StepIds.Summary)}
        title="Summary"
        icon="menu"
        panel={<ConferenceCreateSummary form={conferenceForm} />}
      />
    </Wizard>
  );
});
