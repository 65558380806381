import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Routes } from 'utils/routes';
import { IContact, IAnalyticsProps } from 'types';
import { getContactName } from 'utils/ui';

export const renderContactNamePart = (
  contact: IContact,
  customLink?: string,
  state?: IAnalyticsProps,
  textClassName?: string,
): JSX.Element => {
  const { organization_name, ['function']: contact_function } = contact;
  const cleanFunction = contact_function === 'n/a' ? undefined : contact_function;
  const contactId = contact?.id;
  const pipe = organization_name || cleanFunction ? ' | ' : '';
  const orgPrefix = organization_name && cleanFunction ? ' at ' : '';
  const contactName = getContactName(contact);
  return (
    <div className="screen-m text-truncate">
      <h4 className={cx('d-inline', textClassName)}>
        {contactId && contactId !== '0' ? (
          <Link to={customLink || `${Routes.CONTACTS}/${contactId}`} state={state}>
            {contactName}
          </Link>
        ) : (
          <span className="text-break">{contact.title || contact.full_name || contactName}</span>
        )}
        <span className="contact-list__pipe">{pipe}</span>
        {cleanFunction && <span className="contact-list-item__subtitle">{cleanFunction}</span>}
        {organization_name && <span className="contact-list-item__subtitle">{`${orgPrefix}${organization_name}`}</span>}
      </h4>
    </div>
  );
};
