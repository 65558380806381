import React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from '@mantine/core';
import { useBasicStore } from 'store/hooks';

import { IColumn } from 'components/list-table';
import { SubtitleActionsList } from 'components/action';
import { yn } from 'components/label-value-pair';

import { IActionName } from 'utils/actions/types';
import { ILanguage, ISubtitle, ItemId } from 'types';
import { ActionsMenu } from 'components/action/utils';

const SubtitleLanguage: React.FC<{ languageId?: string; language?: ILanguage }> = observer(
  ({ language, languageId }) => {
    const { languagesNamesById } = useBasicStore();
    const languageName = language?.name || (languageId && languagesNamesById([languageId])?.[0]);
    return (
      <Tooltip key={language?.id} label={languageName} position={'top'}>
        <div className="text-truncate">{languageName}</div>
      </Tooltip>
    );
  },
);

export const getSubtitlesTableColumns = (
  onSuccess: (action?: IActionName, entity?: ISubtitle) => void | Promise<void>,
  assetId: ItemId,
): IColumn<ISubtitle>[] => {
  const columns: IColumn<ISubtitle>[] = [
    {
      title: 'Language',
      renderer: ({ language, language_id }) => <SubtitleLanguage language={language} languageId={language_id} />,
      columnClassName: 'asset-subtitles__langauge',
    },
    {
      title: 'Type',
      renderer: ({ purpose }) => purpose || '—',
      columnClassName: 'asset-subtitles__type',
    },
    {
      title: 'Creation Type',
      renderer: ({ creation_type }) => creation_type || '—',
      columnClassName: 'asset-subtitles__label',
    },
    {
      title: 'File Name',
      renderer: ({ file_attachment }) => {
        const fileName = file_attachment?.split('/').pop();
        return (
          <Tooltip key={file_attachment} label={fileName} position={'top'}>
            <div className="text-truncate">{fileName}</div>
          </Tooltip>
        );
      },
      columnClassName: 'asset-subtitles__filename',
    },
    {
      title: 'Format',
      renderer: ({ storage_url }) => storage_url?.split('.').pop(),
      columnClassName: 'asset-subtitles__format',
    },
    {
      title: 'Label',
      renderer: ({ label }) => (
        <Tooltip key={label} label={label} position={'top'}>
          <div className="text-truncate">{label || '—'}</div>
        </Tooltip>
      ),
      columnClassName: 'asset-subtitles__label',
    },
    {
      title: 'Available in player',
      renderer: ({ show_in_player }) => yn(show_in_player) || '—',
      columnClassName: 'asset-subtitles__player',
    },
    {
      title: '',
      renderer: (subtitle: ISubtitle) => (
        <ActionsMenu
          component={SubtitleActionsList}
          items={[subtitle]}
          options={{ onSuccess, context: 'single', assetId }}
        />
      ),
      columnClassName: 'asset-subtitles__actions',
    },
  ];
  return columns;
};
