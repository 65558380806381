import React from 'react';
import { InfoBoxV2 } from 'components/info-box-v2';

interface IPreviewInfo {
  title?: string;
  children?: JSX.Element | JSX.Element[];
  gridTemplateColumns?: string;
}

export const PreviewInfo: React.FC<IPreviewInfo> = ({ children, title, gridTemplateColumns = '1fr 1fr' }) => {
  return (
    <div className="p-3 text-break">
      <InfoBoxV2 gridTemplateColumns={gridTemplateColumns} title={title} padded={false}>
        {children}
      </InfoBoxV2>
    </div>
  );
};
