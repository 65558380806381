import { flags, mbImportAllowedRoles } from 'utils/flags';
import { areAllNotDeleted } from 'utils/is-entity-deleted';
import { IAsset, IMm3Asset, UserRoles } from 'types';
import { IShouldDisplayAssetActionFunction } from 'utils/actions/asset/actions-acl';

export const shouldDisplayImportToMB: IShouldDisplayAssetActionFunction = (action, index, user, entities) => {
  const role = user?.role_name;
  if (!role) return false;
  const userHasAppropriateRole = mbImportAllowedRoles.includes(role as UserRoles);
  return flags.showMediaBenchFeature && userHasAppropriateRole && areAllNotDeleted(entities);
};

export const isDisabledExportToMB = (entities: (IAsset | IMm3Asset)[]): boolean => {
  return !!entities.find((entity) => {
    const c = entity.classification;
    return !c?.includes('image') && !c?.includes('video');
  });
};
