import { useCallback } from 'react';
import { compact } from 'lodash';

import { IContact, IOrganization, ItemId } from 'types';
import { useBasicStore } from 'store/hooks';
import { byId, formatField, formatTreeEntities } from 'utils/general';
import { getOrganizations } from 'utils/apis/organization';
import { useRemote } from '.';
import { getContacts } from 'utils/apis/contacts';

export function useDivisionsInfo(ids?: number[] | null, full = false): string | undefined {
  const { divisions } = useBasicStore();
  if (!ids || !ids.length) {
    return;
  }
  if (full) {
    return formatTreeEntities(ids, divisions);
  }
  const divisionsById = byId(divisions);
  return formatField(ids.map((divisionId) => divisionsById[divisionId]?.name)) as string;
}

export const useEntityOwnership = (
  entity?: {
    owner?: IContact;
    owner_organization?: Partial<IOrganization>;
    owner_organization_id?: ItemId | null;
    owner_id?: ItemId | null;
    division_ids?: number[] | null;
  },
  full = false,
  showOrg = true,
): { ownershipInfo: string; loadingOwnership: boolean } => {
  const { owner, owner_organization, owner_organization_id, owner_id, division_ids } = entity || {};
  const divisionsNames = useDivisionsInfo(division_ids, full);

  const fetchOwnerOrg = useCallback((): Promise<Partial<IOrganization>[]> => {
    if (!showOrg) return Promise.resolve([]);
    if (owner_organization) return Promise.resolve([owner_organization]);
    return getOrganizations(owner_organization_id ? [owner_organization_id] : []);
  }, [owner_organization, owner_organization_id, showOrg]);

  const [org, loading] = useRemote(fetchOwnerOrg);

  const fetchOwner = useCallback(async () => {
    if (owner) return Promise.resolve([owner]);
    return owner_id
      ? await getContacts({ contactIds: [owner_id], schema: `first_name, last_name, id, division_ids` })
      : Promise.resolve([]);
  }, [owner, owner_id]);

  const [entityOwner = [], ownerLoading] = useRemote(fetchOwner);

  const ownerOrganization = org?.[0];
  const ownerContact = entityOwner?.[0];

  const ownerFields = [
    ownerContact?.id && `${ownerContact?.first_name} ${ownerContact?.last_name}`,
    divisionsNames,
  ].concat(showOrg && ownerOrganization ? [ownerOrganization?.name] : []);

  return { ownershipInfo: compact(ownerFields).join(', '), loadingOwnership: loading || ownerLoading };
};
