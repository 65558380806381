import React from 'react';

import { useWorkflowActions } from 'utils/actions';
import { IWorkflow } from 'types';
import { IItemActionsMenuProps } from './utils';
import { ActionsMenu } from 'components/action/utils';
import { EntityActionListWrapper } from '.';

const WorkflowActions: React.FC<IItemActionsMenuProps<IWorkflow>> = (props) => {
  const { items, options } = props;
  const actions = useWorkflowActions(items, options);
  return <EntityActionListWrapper actions={actions} />;
};

export const WorkflowActionsList: React.FC<IItemActionsMenuProps<IWorkflow>> = (props) => {
  const { items, options } = props;
  return <ActionsMenu component={WorkflowActions} items={items} options={options} />;
};
