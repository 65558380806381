import { ConnectionType } from 'components/delivery/connections';
import { IShouldDisplayConnectionActionFunction } from './types';

export const shouldDisplayActivation: IShouldDisplayConnectionActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    options.context === 'single' && entities[0]?.status === 'inactive' && entities[0]?.type !== ConnectionType.WYVERN
  );
};

export const shouldDisplayDeactivation: IShouldDisplayConnectionActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return (
    options.context === 'single' && entities[0]?.status === 'active' && entities[0]?.type !== ConnectionType.WYVERN
  );
};

export const shouldDisplayValidation: IShouldDisplayConnectionActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return options.context === 'single' && entities[0]?.type !== ConnectionType.WYVERN;
};
