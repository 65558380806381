import { IFilters as IProductFilters } from 'components/product-filters/types';
import { ISmartGroupFormFields } from 'types/smart-group';
import { useCallback, useMemo } from 'react';
import { Model } from 'helpers/filters/types';

import { IProduct, IAsset, IContact, ISmartGroup, ISearchFilter } from 'types';
import { useControlDataSection, useRemote } from 'utils/hooks';

import { IAssetFilters } from 'components/asset/asset-filters';
import { defaultAssetFilters, defaultProductFilters } from 'components/smart-group/form-hook/default-filters';
import { getAssetModel } from 'utils/asset';
import { convertPresetsToFilters } from 'helpers/filters/helpers';
import { defaultContactFilters } from 'components/contact-filters/utils';
import { IContactFilters } from 'components/contact-filters/contact-filters';
import { DataSectionStore } from 'store/data-section-store';
import { noParentFilter } from 'components/product-filters/utils';

import { IFiltersHandlers, useFilters } from 'helpers/filters';
import { queryAllProducts } from 'utils/apis/product';
import { getValidIds } from 'utils/general';
import { searchAssets } from 'utils/apis/asset';

const params = { per: 1 };
const productDefaultInitFilters = noParentFilter(true);

export const useCreateFiltersStores = ({
  group,
}: {
  group?: ISmartGroup;
}): {
  productDataSectionStore: DataSectionStore<IProduct>;
  assetDataSectionStore: DataSectionStore<IAsset>;
  contactDataSectionStore: DataSectionStore<IContact>;
} => {
  const { productDataSectionStore, assetDataSectionStore, contactDataSectionStore } = useMemo(() => {
    return {
      productDataSectionStore: new DataSectionStore<IProduct>(),
      assetDataSectionStore: new DataSectionStore<IAsset>(),
      contactDataSectionStore: new DataSectionStore<IContact>(),
    };
  }, []);

  const productInitFilters = useMemo(() => {
    if (!group?.product_filters?.length) {
      return productDefaultInitFilters;
    }
    return convertPresetsToFilters(defaultProductFilters, group.product_filters);
  }, [group?.product_filters]);

  useControlDataSection({
    model: Model.PRODUCTS,
    customDataSectionStore: productDataSectionStore,
    defaultFilters: defaultProductFilters,
    initFilters: productInitFilters,
    schema: 'id',
    stats: '',
    params,
    defaultParams: params,
  });

  const assetInitFilters = useMemo(() => {
    if (!group?.asset_filters?.length) {
      return;
    }
    return convertPresetsToFilters(defaultAssetFilters, group.asset_filters);
  }, [group?.asset_filters]);

  useControlDataSection<IAsset, IAssetFilters>({
    model: getAssetModel(),
    customDataSectionStore: assetDataSectionStore,
    defaultFilters: defaultAssetFilters,
    initFilters: assetInitFilters,
    schema: 'id',
    stats: '',
    defaultParams: params,
    params,
  });

  const contactInitFilters = useMemo(() => {
    if (!group?.user_filters) {
      return;
    }
    return convertPresetsToFilters(defaultContactFilters, group.user_filters);
  }, [group?.user_filters]);

  useControlDataSection<IContact, IContactFilters>({
    model: Model.CONTACTS,
    customDataSectionStore: contactDataSectionStore,
    defaultFilters: defaultContactFilters,
    initFilters: contactInitFilters,
    schema: 'id',
    stats: '',
    defaultParams: params,
    params,
  });

  return { productDataSectionStore, assetDataSectionStore, contactDataSectionStore };
};

export const useInitiateStores = ({
  values,
  assetDataSectionStore,
  productDataSectionStore,
  contactDataSectionStore,
}: {
  values: ISmartGroupFormFields;
  assetDataSectionStore: DataSectionStore<IAsset>;
  productDataSectionStore: DataSectionStore<IProduct>;
  contactDataSectionStore: DataSectionStore<IContact>;
}): {
  assetCountLoading: boolean;
  assetFilterHandlers: IFiltersHandlers<IAssetFilters>;
  assetFilterValues: IAssetFilters;
  assetsCount: number;
  contactFilterHandlers: IFiltersHandlers<IContactFilters>;
  contactFilterValues: IContactFilters;
  productFilterHandlers: IFiltersHandlers<IProductFilters>;
  productFilterValues: IProductFilters;
} => {
  const fetchAssetsCounts = useCallback(async () => {
    if (
      values.asset_selection_method === 'static' ||
      !assetDataSectionStore?.searchStore?.filters?.length ||
      !productDataSectionStore?.searchStore?.filters?.length
    ) {
      return 0;
    }

    let productIds = values.product_ids;
    if (values.product_selection_method === 'dynamic') {
      const products = await queryAllProducts({}, productDataSectionStore?.searchStore?.filters || [], 'id');
      productIds = getValidIds(products);
    }

    const filters: ISearchFilter[] = [
      ['products.product_id', 'in', productIds],
      ...(assetDataSectionStore.searchStore.filters || []),
    ];

    const result = await searchAssets({ per: 1, page: 1 }, filters, 'id');
    return result.pagination?.total_count || 0;
  }, [
    assetDataSectionStore?.searchStore?.filters,
    productDataSectionStore?.searchStore?.filters,
    values.asset_selection_method,
    values.product_ids,
    values.product_selection_method,
  ]);

  const [assetFilterValues, assetFilterHandlers] = useFilters<IAssetFilters>(
    assetDataSectionStore,
    assetDataSectionStore.triggerSearch,
  );
  const [productFilterValues, productFilterHandlers] = useFilters<IProductFilters>(
    productDataSectionStore,
    productDataSectionStore.triggerSearch,
  );
  const [contactFilterValues, contactFilterHandlers] = useFilters<IContactFilters>(
    contactDataSectionStore,
    contactDataSectionStore.triggerSearch,
  );
  const [assetsCount = 0, assetCountLoading] = useRemote(fetchAssetsCounts);
  return {
    assetCountLoading,
    assetFilterHandlers,
    assetFilterValues,
    assetsCount,
    contactFilterHandlers,
    contactFilterValues,
    productFilterHandlers,
    productFilterValues,
  };
};
