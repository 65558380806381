import React, { useEffect, useState, useCallback } from 'react';
import { map, uniq, remove } from 'lodash';
import { Link } from 'react-router-dom';
import { pigeon } from 'utils/pigeon';
import { addListener, leaveRoom } from '@mediafellows/pigeon2-client';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';

import { DetailsPageTabs, IGenericMessage } from 'types';
import { Intent } from 'utils/ui/intent';
import { extendedFormatDuration, formatDate } from 'utils/date';
import { formatFullName } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { fetchConnectionById } from 'utils/apis/delivery-connections';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import { DeliveryProgressBar } from 'components/progress-bar';
import { ConnectionType } from 'components/delivery/connections';
import { DeliveryStatus } from 'components/delivery-list-item/utils';

export const DeliveryDetailsOverview: React.FC<{
  delivery: DeliveriesGenericDeliveryPackage;
  assetsCount?: number;
}> = ({ delivery, assetsCount }) => {
  const {
    name,
    uuid,
    created_at,
    scheduled_at,
    delivered_at,
    updated_at,
    delivery_items,
    recipient_list,
    status,
    connection_id,
    package_size_human_readable,
    // percent_transferred,
    delivery_duration_seconds,
    owner,
  } = delivery || {};
  const [percentageDelivered, setPrecentageDelivered] = useState(0);
  const product_ids = uniq(remove(map(delivery_items, 'product_id'), null));

  const fetchConnection = useCallback(async () => fetchConnectionById(connection_id), [connection_id]);
  const [connection] = useRemote(fetchConnection);

  const showProgressBar =
    connection?.type !== ConnectionType.WYVERN &&
    [DeliveryStatus.DELIVERING, DeliveryStatus.SCHEDULED].includes(status as DeliveryStatus) &&
    percentageDelivered !== 100;

  useEffect(() => {
    if (status === 'delivering' || status === 'scheduled') {
      pigeon(uuid as string);
    }

    return () => {
      leaveRoom(`delivery-package-${uuid}`);
    };
  });

  addListener(
    'room.message',
    ({ message, type }: { message: IGenericMessage<IGenericMessage<string | number>>; type: string }) => {
      if (type === 'delivery_package.transfer.update' && message?.delivery_package.uuid === uuid) {
        setPrecentageDelivered(message?.delivery_package?.percentage as number);
      }
    },
  );

  return (
    <div className="delivery-details__overview-wrapper">
      <InfoBoxV2 gridTemplateColumns="2fr 3fr" useBackground padded={false} wrapperClassName="p-3 m-3">
        <Row label="Name" value={name} size="m" />
        <Row label="Status" value={percentageDelivered === 100 ? 'delivered' : status} size="m" />
        <Row label="Scheduled at" value={formatDate(scheduled_at)} rawLabel />
        <Row label="Sent at" value={formatDate(delivered_at)} rawLabel />
        <Row label="Sender" value={formatFullName(owner)} size="m" />
        <Row label="Created at" value={formatDate(created_at)} size="m" rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} size="m" rawLabel />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="2fr 3fr" title="About">
        {showProgressBar && (
          <div className="progress-block">
            <div className="muted-text screen-m">Delivery Progress</div>
            <DeliveryProgressBar className="delivery-bar" variant={Intent.SUCCESS} value={percentageDelivered} />
          </div>
        )}
        <Row label="Products" value={product_ids?.length} size="m" />
        <Row
          label="Assets"
          value={assetsCount ? <Link to={`../${DetailsPageTabs.ASSETS}`}>{assetsCount}</Link> : 0}
          size="m"
        />
        <Row
          label="Recipients"
          value={<Link to={`../${DetailsPageTabs.RECIPIENTS}`}>{recipient_list?.length}</Link>}
          size="m"
        />
        {/* <Row label="Percentage Transferred" value={`${percent_transferred || 0}%`} /> */}
        <Row
          label="Delivery Duration"
          value={`${extendedFormatDuration(delivery_duration_seconds || 0)}`}
          hidden={connection?.type === ConnectionType.WYVERN}
        />
        <Row
          label="Size"
          value={status !== 'draft' ? package_size_human_readable : '-'}
          size="m"
          hidden={connection?.type === ConnectionType.WYVERN}
        />
      </InfoBoxV2>
    </div>
  );
};
