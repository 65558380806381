import { IAction, IUseActionsOptionParam, Pages } from 'utils/actions/types';
import { IShouldDisplaySmartGroupActionFunction, ISmartGroupActionName } from './types';
import { areAllNotDeleted } from 'utils/is-entity-deleted';
import { IContact } from 'types/contact';
import { ISmartGroup } from 'types';

export const shouldDisplaySmartGroup: IShouldDisplaySmartGroupActionFunction = (
  action,
  index,
  user,
  items,
  options,
) => {
  return (
    areAllNotDeleted(items) &&
    options?.page !== undefined &&
    [Pages.SMART_GROUP_DETAILS, Pages.SMART_GROUPS_LIST].includes(options?.page)
  );
};

export const shouldDisplayForStaticSmartGroup = (
  action: IAction<ISmartGroupActionName>,
  index: number,
  user: IContact | null,
  items: ISmartGroup[],
  options: IUseActionsOptionParam<ISmartGroup>,
  attribute: 'user_filters' | 'asset_filters' | 'product_filters' = 'user_filters',
): boolean => {
  return (
    areAllNotDeleted(items) &&
    options?.page !== undefined &&
    [Pages.SMART_GROUP_DETAILS, Pages.SMART_GROUPS_LIST].includes(options?.page) &&
    !items[0]?.[attribute]?.length
  );
};

export const shouldDisplayForStaticContactSmartGroup: IShouldDisplaySmartGroupActionFunction = (
  action,
  index,
  user,
  items,
  options,
) => {
  return shouldDisplayForStaticSmartGroup(action, index, user, items, options, 'user_filters');
};

export const shouldDisplayForStaticAssetSmartGroup: IShouldDisplaySmartGroupActionFunction = (
  action,
  index,
  user,
  items,
  options,
) => {
  return shouldDisplayForStaticSmartGroup(action, index, user, items, options, 'asset_filters');
};

export const shouldDisplayForStaticProductSmartGroup: IShouldDisplaySmartGroupActionFunction = (
  action,
  index,
  user,
  items,
  options,
) => {
  return shouldDisplayForStaticSmartGroup(action, index, user, items, options, 'product_filters');
};
