import React from 'react';
import { Tooltip } from '@mantine/core';

import { AnalyticsContact, AnalyticsOrganization, UserTerritory } from 'utils/ui';
import { pluralWordInflect } from 'utils/general';
import DateElement from 'components/date-list-element/date-list-element';
import { IProductSearchStatsTableItem } from 'components/product-views-list-table/api';
import { pluralizeTooltipLabel } from 'components/analytics';
import { listRenderer } from './utils';

import '../style.scss';

export const SearchTermColumns: { title: string; renderer: (data: IProductSearchStatsTableItem) => React.ReactNode }[] =
  [
    {
      title: 'Contact',
      renderer: ({ user }) => <AnalyticsContact user={user} />,
    },
    {
      title: 'Organization',
      renderer: ({ user }) => (
        <AnalyticsOrganization user={user} organization={{ id: user.organization_id, name: user.organization_name }} />
      ),
    },
    {
      title: 'Country',
      renderer: ({ user }) => {
        return user.country_name ? <span>{user.country_name}</span> : <UserTerritory countryId={user.country_id} />;
      },
    },
    {
      title: 'Search Term',
      renderer: ({ search_terms }) =>
        search_terms ? (
          <Tooltip label={search_terms} position="top">
            <div className="search-terms-list__search-terms text-truncate">{search_terms}</div>
          </Tooltip>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'Filters applied',
      renderer: ({ applied_filters_count, search_filters }) => {
        return !applied_filters_count || applied_filters_count === 0 ? (
          'N/A'
        ) : (
          <Tooltip label={listRenderer(search_filters, 'dynamic')} withArrow={false} position="top">
            <div className="search-terms-list-table__search-filters">
              <span>{pluralizeTooltipLabel(applied_filters_count, 'filter')}</span>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Products found',
      renderer: ({ products }) => {
        return !products.length ? (
          'N/A'
        ) : (
          <Tooltip label={listRenderer(products, 'products')} withArrow={false} position="top">
            <div className="search-terms-list-table__search-filters">
              <span>{pluralWordInflect('product', products.length)}</span>
            </div>
          </Tooltip>
        );
      },
    },
    { title: 'Searched at', renderer: ({ searched_at }) => <DateElement date={searched_at} /> },
  ];
