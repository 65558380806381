import React, { useCallback } from 'react';
import { McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import { addMonths } from 'date-fns';
import { merge, isEmpty, isEqual, uniq } from 'lodash';

import { IProduct, ItemId } from 'types';
import { useMm3Form } from 'helpers/form/use-mm3-form';
import { Model } from 'helpers/filters/types';
import { useStore } from 'store';
import { formatDate, ISODateFormat, today } from 'utils/date';
import { ToastError } from 'components/toast';
import type { ICreateWorkFlowPayload } from 'utils/actions/types';
import { getInitialValues } from 'components/recommendation/recommend-products/initial-values';
import { RecommendProductsWizard } from './recommend-products-wizard';
import { createGenericRecommendationDraft } from 'components/recommendation/recommendation-shared/ui';
import { IValidationResult, getRequiredError } from 'helpers/form/mm3';
import { useSessionStore } from 'store/session-store';

const defaultExpires = addMonths(today, 1);

interface IRecommendProductsWizardContainer {
  products?: IProduct[];
  onFinish: (recommendation: McGenericRecommendationProduct) => void;
  groupId?: ItemId;
  recommendation?: Partial<ICreateWorkFlowPayload<McGenericRecommendationProduct>>;
  tab?: number;
}

const customValidator = (
  values: ICreateWorkFlowPayload<McGenericRecommendationProduct>,
  validation: IValidationResult<ICreateWorkFlowPayload<McGenericRecommendationProduct>>,
): IValidationResult<ICreateWorkFlowPayload<McGenericRecommendationProduct>> => {
  const recipientsValidation = {
    recipients: { required: true, ...(!values?.recipients.length && { ...getRequiredError() }) },
  };

  const productsValidation = {
    productList: { required: true, ...(!values?.productList.length && { ...getRequiredError() }) },
  };

  return merge({}, validation, recipientsValidation, productsValidation) as IValidationResult<
    ICreateWorkFlowPayload<McGenericRecommendationProduct>
  >;
};

export const RecommendProductsWizardContainer: React.FC<IRecommendProductsWizardContainer> = ({
  onFinish,
  products,
  tab,
  recommendation,
}) => {
  const { toastStore } = useStore();
  const user = useSessionStore.getState().user;

  const handleConfirm = useCallback(
    async (values: ICreateWorkFlowPayload<McGenericRecommendationProduct>): Promise<void> => {
      try {
        if (!values) {
          return;
        }

        // temporary implementation
        values = {
          ...values,
          asset_ids: uniq([...(values.asset_ids || []), ...(values?.subAssetIds || [])] as number[]),
        };

        const recommendation = await createGenericRecommendationDraft<McGenericRecommendationProduct>(values);
        toastStore.success('Recommendation draft was successfully saved!');
        onFinish(recommendation);
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [toastStore, onFinish],
  );
  const initialValues = getInitialValues(user);
  const isRecommendationCreated = Boolean(recommendation?.id);
  const form = useMm3Form<ICreateWorkFlowPayload<McGenericRecommendationProduct>>(
    {
      ...initialValues,
      ...recommendation,
      cc_recipients: recommendation?.cc_list || [],
      bcc_recipients: recommendation?.bcc_list || [],
      product_ids: products?.map(({ id }) => id) || recommendation?.product_ids || [],
      expires_at: formatDate(recommendation?.expires_at || defaultExpires, ISODateFormat),
      message: isRecommendationCreated ? recommendation?.message : initialValues?.message,
      allows_asset_download: Boolean(recommendation?.allows_asset_download),
      requires_login: isRecommendationCreated ? Boolean(recommendation?.requires_login) : initialValues.requires_login,
      asset_ids: recommendation?.asset_ids || [],
      recipients: recommendation?.recipient_list?.map((e) => e.recipient_id) || recommendation?.recipients || [],
      productList:
        recommendation?.productList || recommendation?.product_ids || (products || []).map((e) => e.id) || [],
      includeFutureEpisodes:
        !isEmpty(recommendation?.product_ids) &&
        isEqual(recommendation?.include_future_episodes_of_product_ids, recommendation?.product_ids),
      visible: isRecommendationCreated ? Boolean(recommendation?.visible) : initialValues.visible,
    } as ICreateWorkFlowPayload<McGenericRecommendationProduct>,
    Model.RECOMMENDATIONS_PRODUCT,
    handleConfirm,
    customValidator,
  );

  return (
    <RecommendProductsWizard
      form={form}
      tab={tab}
      onFinish={onFinish}
      allAssetsSelected={!('asset_ids' in (recommendation || {}))}
    />
  );
};
