import React from 'react';

import { IAsset, IMm3Asset } from 'types';

import { AssetMetadataSpecificationsSection } from 'pages/asset-details/asset-metadata-tab/sections/specifications';
import { AssetMetadataAboutSection } from 'pages/asset-details/asset-metadata-tab/sections/about';
import { AssetMetadataDocumentDataSection } from 'pages/asset-details/asset-metadata-tab/sections/document-data';
import { AssetMetadataSettingsSection } from 'pages/asset-details/asset-metadata-tab/sections/settings';
import { AssetMetadataTimestamps } from 'pages/asset-details/asset-metadata-tab/sections/timestamps';
import { AssetMetadataCreditsSection } from 'pages/asset-details/asset-metadata-tab/sections/credits';
import { AssetMetadataIdentifiersSection } from 'pages/asset-details/asset-metadata-tab/sections/identifiers';
import { AssetMetadataMediaInfoSection } from 'pages/asset-details/asset-metadata-tab/sections/media-info';
import { AudioMetadaSection } from 'pages/asset-details/asset-metadata-tab/sections/audio';

import './style.scss';

export const AssetMetadataTab: React.FC<{ asset: IAsset | IMm3Asset }> = ({ asset }) => {
  return (
    <div className="asset-metadata-tab h-100">
      <div className="asset-metadata-tab__infoboxes">
        <AssetMetadataSpecificationsSection asset={asset} />
        <AssetMetadataAboutSection asset={asset} />
        <AudioMetadaSection asset={asset as IMm3Asset} />
        <AssetMetadataDocumentDataSection asset={asset} />
        <AssetMetadataSettingsSection asset={asset} />
        <AssetMetadataTimestamps asset={asset} />
        <AssetMetadataCreditsSection asset={asset} />
        <AssetMetadataIdentifiersSection asset={asset} />
        <AssetMetadataMediaInfoSection asset={asset} />
      </div>
    </div>
  );
};

export default AssetMetadataTab;
