import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { IAsset, IMm3Asset } from 'types';

import { AssetFilters, withAssetVersion } from 'utils/asset';

export const AssetOldMetadataCreditsSection: React.FC<{ asset?: IAsset }> = ({ asset }) => {
  const { classification, default_layer } = asset || {};

  const { copyright_notice } = default_layer || {};

  const isImage = classification?.startsWith(AssetFilters.IMAGE);

  return (
    <InfoBoxV2 gridTemplateColumns="160px 1fr" title="Credits" hidden={!isImage}>
      <Row label="copyright_notice" value={copyright_notice} size="m" hideIfNoValue />
    </InfoBoxV2>
  );
};

export const AssetMm3MetadataCreditsSection: React.FC<{ asset?: IMm3Asset }> = ({ asset }) => {
  const { classification, meta } = asset || {};

  const { copyright_notice } = meta || {};

  const isImage = classification?.startsWith(AssetFilters.IMAGE);

  return (
    <InfoBoxV2 gridTemplateColumns="160px 1fr" title="Credits" hidden={!isImage}>
      <Row label="copyright_notice" value={copyright_notice} size="m" hideIfNoValue />
    </InfoBoxV2>
  );
};

export const AssetMetadataCreditsSection = withAssetVersion(
  AssetOldMetadataCreditsSection,
  AssetMm3MetadataCreditsSection,
) as React.FC<{ asset: IMm3Asset | IAsset }>;
