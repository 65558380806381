import { chipmunk, IResult } from 'utils/chipmunk';

import {
  addProductToGroup,
  addDifferentContactEntitiesToGroup,
  addAssetToGroup,
  queryProductGroupItems,
  removeProductFromGroup,
  queryAssetGroupItems,
  removeAssetFromGroup,
  removeContactsFromGroup,
  updateGroup,
} from 'utils/apis/groups';

import { GroupTypes, ISmartGroup } from 'types';
import { Model } from 'helpers/filters/types';
import { ISmartGroupFormFields } from 'types/smart-group';
import { queryAllProducts } from './product';
import { getValidIds } from 'utils/general';
import { queryAllAssets } from './asset';
import { queryContacts } from './contacts';
import { IFilterOption } from 'utils/hooks';

export const createSmartGroup = async (values: ISmartGroupFormFields): Promise<IResult<ISmartGroup>> => {
  return chipmunk.run(async ({ action }) => {
    const {
      asset_filters,
      asset_ids,
      asset_selection_method,
      contacts,
      contact_selection_method,
      expires_at,
      include_descendants,
      include_future_descendants,
      name,
      product_filters,
      product_ids,
      product_selection_method,
      permissions,
      user_filters,
    } = values;
    const result = await action(Model.SMART_GROUP, 'create', {
      body: {
        asset_filters,
        expires_at,
        name,
        product_filters,
        type: GroupTypes.SMART_GROUPS,
        user_filters,
      },
    });

    const { object: group } = result;
    const group_id = group.id;

    let productsPromise;
    if (product_selection_method === 'static') {
      productsPromise = addProductToGroup({
        group_id,
        item_ids: product_ids,
        include_descendants,
        include_future_descendants,
      });
    }
    let assetsPromise;
    if (asset_selection_method === 'static') {
      assetsPromise = addAssetToGroup({
        group_id,
        item_ids: asset_ids,
        include_descendants,
        include_future_descendants,
        permissions,
      });
    }

    let contactsPromise;
    if (contact_selection_method === 'static') {
      contactsPromise = addDifferentContactEntitiesToGroup({ group_id, contacts });
    }

    await Promise.all([productsPromise, assetsPromise, contactsPromise]);

    return group;
  });
};

export const updateSmartGroup = async (values: ISmartGroupFormFields): Promise<ISmartGroup | undefined> => {
  const {
    access_level,
    asset_filters,
    asset_ids,
    asset_selection_method,
    contacts,
    contact_selection_method,
    expires_at,
    id,
    include_descendants,
    include_future_descendants,
    name,
    product_filters,
    user_filters,
    product_ids,
    product_selection_method,
    settings,
    permissions,
  } = values;
  const group = await updateGroup<ISmartGroup>({
    access_level,
    asset_filters,
    expires_at,
    id,
    name,
    product_filters,
    settings,
    type: GroupTypes.SMART_GROUPS,
    user_filters,
  });

  const group_id = group?.id;
  if (!group_id) {
    return;
  }

  let productsToRemovePromise;
  let productsToAddPromise;
  let productsPromise;
  if (product_selection_method === 'static') {
    productsToRemovePromise = queryAllProducts(
      {},
      [
        ['group_ids', 'in', [group_id]],
        ...(product_ids?.length ? [['id', 'not_in', product_ids] as IFilterOption] : []),
      ],
      'id',
    );
    if (product_ids?.length) {
      productsToAddPromise = queryAllProducts(
        {},
        [
          ['group_ids', 'not_in', [group_id]],
          ['id', 'in', product_ids],
        ],
        'id',
      );
    }
    productsPromise = Promise.all([productsToAddPromise, productsToRemovePromise]).then(
      ([productsToAdd, productsToRemove]) => {
        const productIdsToRemove = getValidIds(productsToRemove);
        const removePromise = queryProductGroupItems(group.id, productIdsToRemove).then(({ objects: groupItems }) => {
          if (groupItems?.length) {
            return removeProductFromGroup({ group_ids: [group_id], item_ids: getValidIds(groupItems) });
          }
        });

        const addPromise = addProductToGroup({
          group_id,
          item_ids: getValidIds(productsToAdd),
          include_descendants,
          include_future_descendants,
        });

        return Promise.all([addPromise, removePromise]);
      },
    );
  }

  let assetsToRemovePromise;
  let assetsToAddPromise;
  let assetsPromise;
  if (asset_selection_method === 'static') {
    assetsToRemovePromise = queryAllAssets(
      {},
      [['group_ids', 'in', [group_id]], ...(asset_ids?.length ? [['id', 'not_in', asset_ids] as IFilterOption] : [])],
      'id',
    );
    if (asset_ids?.length) {
      assetsToAddPromise = queryAllAssets(
        {},
        [
          ['group_ids', 'not_in', [group_id]],
          ['id', 'in', asset_ids],
        ],
        'id',
      );
    }
    assetsPromise = Promise.all([assetsToAddPromise, assetsToRemovePromise]).then(
      async ([assetsToAdd, assetsToBeRemoved]) => {
        const addPromise = assetsToAdd?.length
          ? addAssetToGroup({
              group_id,
              item_ids: getValidIds(assetsToAdd),
              include_descendants,
              permissions,
              include_future_descendants,
            })
          : undefined;

        const removePromise = assetsToBeRemoved?.length
          ? queryAssetGroupItems(group.id, getValidIds(assetsToBeRemoved)).then(({ objects: groupItems }) => {
              if (groupItems?.length) {
                removeAssetFromGroup({ group_ids: [group_id], item_ids: getValidIds(groupItems) });
              }
            })
          : undefined;
        return Promise.all([addPromise, removePromise]);
      },
    );
  }

  let contactsPromise;
  if (contact_selection_method === 'static') {
    queryContacts(
      {},
      [
        ['group_ids', 'in', [group_id]],
        ['id', 'not_in', getValidIds(contacts)],
      ],
      'id',
      'unfurl',
    ).then(async (contactsToRemove) => {
      contactsPromise = addDifferentContactEntitiesToGroup({ group_id, contacts });
      if (contactsToRemove?.length) {
        removeContactsFromGroup(group_id, getValidIds(contactsToRemove));
      }
    });
  }

  await Promise.all([productsPromise, assetsPromise, contactsPromise]);

  return group;
};
