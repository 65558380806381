import { ISimpleTreeNode } from './dynamic-tree-data-provider';
import { ITreeFilterNode } from 'types';

export enum TreeSelectedState {
  NOT_SELECTED = 'circle',
  SELECTED = 'tick-circle',
}

export const unflattenTree = (nodes: ISimpleTreeNode[]): ITreeFilterNode[] => {
  const orderedData = nodes.sort((a, b) => nodeLevel(a) - nodeLevel(b));

  const result: ITreeFilterNode[] = [];
  const nodeMap: { [id: number]: ITreeFilterNode } = {};

  orderedData.map((node) => {
    if (!node.ancestry) {
      const newNode = convertNode(node);
      result.push(newNode);
      nodeMap[newNode.id] = newNode;
    } else {
      const parentId = node.ancestry.split('/').pop() || '';
      const parent = nodeMap[parentId];
      const newNode = convertNode(node);
      parent.children = parent.children || [];
      parent.children.push(newNode);
      nodeMap[newNode.id] = newNode;
    }
  });

  return result;
};

const convertNode = (node: ISimpleTreeNode): ITreeFilterNode => {
  return {
    id: node.value?.toString(),
    label: node.label,
    secondaryLabel: '',
    value: node.value?.toString(),
  };
};

const nodeLevel = (node: ISimpleTreeNode): number => {
  return !node.ancestry ? 0 : node.ancestry.split('/').length + 1;
};
