import React, { useCallback } from 'react';

import { IContact, ISearchFilter, IStyled, ItemId } from 'types';
import { FormRemoteSelect, IFieldData, IFieldHandlers } from 'helpers/form';
import { contactListSchema } from 'utils/schemas';
import { chipmunk } from 'utils/chipmunk';
import { parseToSearchParams } from 'utils/general';
import { Model } from 'helpers/filters/types';
import { SearchExternalInvitee } from './ui';
import { emailValidation } from 'utils/validations';
import { queryContacts } from 'utils/apis/contacts';

export interface IFormInvitees extends IFieldData<string | number | null>, IFieldHandlers<string | number>, IStyled {
  name: string;
  label: string;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;

  placeholder?: string;
  showPositiveValidation?: boolean;
  ids?: ItemId[] | null;
}

export const queryInvitees = (
  { ids, q, ...params }: { ids?: ItemId[]; q?: string; per?: number },
  additionalFilters: ISearchFilter[] = [],
  schema = contactListSchema,
): Promise<IContact[]> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(Model.CONTACTS, 'search', {
      body: parseToSearchParams({ ids, q }, additionalFilters),
      params: { per: Math.max(25, ids?.length || 0), ...params },
      schema,
    });

    return objects;
  });
};

export const FormInvitees: React.FC<IFormInvitees> = ({ name, label, onChange, ids, ...rest }) => {
  const fetchInvitees = useCallback<(query: string, id?: ItemId) => Promise<IContact[]>>(
    async (query, id) => {
      const params: { q?: string; ids?: ItemId[] } = { q: query };
      if (ids?.length) {
        params.ids = ids;
      }
      if (id) {
        params.ids = [id];
      }

      if (!params.ids) return [];

      return queryContacts(params);
    },
    [ids],
  );

  return (
    <FormRemoteSelect
      name={name}
      label={label}
      {...rest}
      fetchOptions={fetchInvitees}
      onChange={onChange}
      allowNewItems
      validateNewItem={(text) => emailValidation({}, text)[0]}
      createTagElement={SearchExternalInvitee}
    />
  );
};
