import React from 'react';
import { Row } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';
import { Routes } from 'utils/routes';
import { IAccessGroup, DetailsPageTabs } from 'types';
import { FormInput, IUseFormReturn } from 'helpers/form';
import { formatDate } from 'utils/date';
import { IGroupNameEditFormFields } from 'utils/apis/groups';
import { formatOwnership, getStatus } from 'utils/general';
import { useOrganizationCountInGroup } from 'utils/hooks';
import { DetailCountLink } from 'utils/ui';
import { useMainAssetCountInGroup } from 'utils/hooks/asset';

const inputStyle = { gridTemplateColumns: '3fr' };

const AccessGroupDetailsOverview: React.FC<{
  group?: IAccessGroup;
  form: IUseFormReturn<IGroupNameEditFormFields>;
  editModeEnabled: boolean;
}> = ({ group, form, editModeEnabled }) => {
  const { id, deleted_at, created_at, updated_at, pm_statistics_data, users_count, expires_at, name } = group || {};

  const [organization_count] = useOrganizationCountInGroup(id);
  const [assetCount] = useMainAssetCountInGroup(id);

  const ownershipInfo = formatOwnership(group);
  return (
    <div className="recommendation-details__overview-wrapper">
      <InfoBoxV2 gridTemplateColumns="2fr 3fr" useBackground padded={false} wrapperClassName="p-3 m-3">
        <Row
          label="Name"
          value={
            editModeEnabled ? (
              <FormInput name="name" {...form.formData.name} {...form.handlers} required={false} style={inputStyle} />
            ) : (
              name
            )
          }
        />

        <Row label="Ownership" value={ownershipInfo} />
        <Row label="Status" value={getStatus(deleted_at)} />

        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
        <Row label="Expires at" value={formatDate(expires_at)} rawLabel />
        <Row label="Deleted at" value={formatDate(deleted_at)} rawLabel hideIfNoValue />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="2fr 3fr" title="About">
        <DetailCountLink
          entityCount={pm_statistics_data?.count}
          link={`${Routes.ACCESS_GROUPS}/${id}/${DetailsPageTabs.PRODUCTS}`}
          label={DetailsPageTabs.PRODUCTS}
        />
        <DetailCountLink
          entityCount={assetCount}
          link={`${Routes.ACCESS_GROUPS}/${id}/${DetailsPageTabs.ASSETS}`}
          label={DetailsPageTabs.ASSETS}
        />
        <DetailCountLink
          entityCount={users_count}
          link={`${Routes.ACCESS_GROUPS}/${id}/${DetailsPageTabs.CONTACTS}`}
          label={DetailsPageTabs.CONTACTS}
        />

        <DetailCountLink
          entityCount={organization_count}
          link={`${Routes.ACCESS_GROUPS}/${id}/${DetailsPageTabs.ORGANIZATIONS}`}
          label={DetailsPageTabs.ORGANIZATIONS}
        />
      </InfoBoxV2>
    </div>
  );
};

export default AccessGroupDetailsOverview;
