import React, { useCallback, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Loader, Menu, ActionIcon, Combobox, useCombobox, InputBase } from '@mantine/core';
import { EqFilter, IAggregation, InFilter, isInFilter, NeFilter } from 'helpers/filters/types';
import { highlightText } from 'helpers/form/fields/helpers';
import { useIsInPersistentFilter } from 'helpers/filters/fields/utils';
import { IDataProvider, IDataProviderItem } from 'helpers/data-provider/option-data-provider';
import { FormGroup } from 'helpers/form/fields/form-group';
import { useDebounce } from 'utils/hooks';
import { MantineIcon } from 'utils/ui/icon';

import './style.scss';

type ISelectFilterDefinition = EqFilter | NeFilter | InFilter;

interface IFilterSelectProps {
  name: string;
  label: string;
  placeholder?: string;
  optionsProvider: IDataProvider;
  large?: boolean;
  disabled?: boolean;
  className?: string;

  aggregations?: IAggregation[];
  filter: ISelectFilterDefinition;
  onChange?: (newValue: { [key: string]: ISelectFilterDefinition }) => void;
}

const FilterSelect: React.FC<IFilterSelectProps> = observer((props) => {
  const { name, placeholder, label, filter, aggregations, onChange, optionsProvider, className, large, disabled } =
    props;
  const { value } = filter || {};
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex('active');
      combobox.focusSearchInput();
    },
  });

  const [selectedItem, setSelectedItem] = useState<{ value: string | number; label: string } | null>(null);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);
  const options = useMemo<IDataProviderItem[]>(() => optionsProvider.data, [optionsProvider.data]);

  const dataLoading = useMemo(() => optionsProvider.loading, [optionsProvider.loading]);

  const isInPersistentFilter = useIsInPersistentFilter(name);
  const hasAggregations = useMemo(() => typeof aggregations !== 'undefined', [aggregations]);
  const isDisabled = (hasAggregations && !aggregations?.length) || isInPersistentFilter;
  const isEmpty = !value || (Array.isArray(value) && !value?.length);

  useEffect(() => {
    optionsProvider.init();
  }, [optionsProvider]);

  useEffect(() => {
    if (isEmpty) {
      setSelectedItem(null);
      return;
    }
    const formattedValue = Array.isArray(value) ? value[0] : value;
    const selected = options?.find((o) => o.value === formattedValue);
    const v = `${formattedValue || ''}`;
    setSelectedItem(selected || optionsProvider.cachedSelectedValue || (v ? { value: v, label: v } : null));
  }, [isEmpty, options, optionsProvider, hasAggregations, name, value]);

  useEffect(() => {
    optionsProvider.filter(debouncedQuery);
  }, [debouncedQuery, optionsProvider]);

  const handleChange = useCallback(
    (selectedValue: string | number | null): void => {
      const item = options.find((o) => o.value == selectedValue);
      if (!item) return;
      setSelectedItem({ ...item });
      optionsProvider?.cacheSelectedValue({ ...item });
      const newValue = Number.isNaN(Number(item.value)) ? item.value : Number(item.value);
      if (onChange) {
        isInFilter(filter)
          ? onChange({ [name]: { ...filter, value: [newValue] } })
          : onChange({ [name]: { ...filter, value: newValue } });
      }
      setQuery('');
      combobox.closeDropdown();
    },
    [options, optionsProvider, onChange, combobox, filter, name],
  );

  const itemRenderer = useCallback(() => {
    const items = options.filter((item) => item !== null);
    const hasItems = items.length;
    if (dataLoading) {
      return <Combobox.Empty>Loading...</Combobox.Empty>;
    }

    if (hasItems) {
      return (
        <Menu>
          {items.map((item) => {
            const aggr = aggregations?.find((a) => a.value === item.value);
            const hideItem = hasAggregations && !aggr?.count;
            const isActiveItem = item.value == selectedItem?.value;
            if (hideItem) return null;
            return (
              <Menu.Item
                rightSection={aggr && <span className="filter__item-aggregation">{`(${aggr?.count})`}</span>}
                className={classNames({ active: isActiveItem }, 'filter-select__menu-item')}
                key={item.value}
                onClick={() => handleChange(item.value)}
              >
                {highlightText(item.label, query)}
              </Menu.Item>
            );
          })}
        </Menu>
      );
    }

    return <Combobox.Empty> Nothing found.</Combobox.Empty>;
  }, [aggregations, dataLoading, handleChange, hasAggregations, options, query, selectedItem?.value]);

  const handleQueryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value);
  }, []);

  const handlePopoverOpen = useCallback(() => {
    optionsProvider.filter(debouncedQuery);
  }, [optionsProvider, debouncedQuery]);

  const handleReset = useCallback((): void => {
    setSelectedItem(null);
    if (onChange) {
      isInFilter(filter)
        ? onChange({ [name]: { ...filter, value: [] } })
        : onChange({ [name]: { ...filter, value: '' } });
    }
  }, [filter, name, onChange]);

  return (
    <div className={classNames('d-flex align-items-center filter-select__container', className)}>
      <div className="flex-fill filter-select__wrap">
        <FormGroup label={label} labelFor={name}>
          <Combobox
            store={combobox}
            onOptionSubmit={handleChange}
            portalProps={{
              className: 'filter-select-popover__portal',
            }}
            withinPortal
            position="bottom-start"
          >
            <Combobox.Target>
              <InputBase
                rightSection={dataLoading ? <Loader size={18} /> : <MantineIcon icon="caret-down" />}
                onClick={(e) => {
                  e.preventDefault();
                  combobox.toggleDropdown();
                }}
                onFocus={(e) => {
                  e.preventDefault();
                  handlePopoverOpen();
                }}
                placeholder={placeholder}
                size={large ? 'md' : 'sm'}
                component="button"
                pointer
                className={classNames('filter-select__button', {
                  'filter-select__placeholder': !selectedItem,
                })}
                disabled={disabled || isDisabled}
              >
                {selectedItem?.label || placeholder}
              </InputBase>
            </Combobox.Target>

            <Combobox.Dropdown hidden={disabled || isDisabled}>
              <Combobox.Options>
                <Combobox.Search
                  value={query}
                  onChange={handleQueryChange}
                  placeholder={'Filter ...'}
                  leftSection={<MantineIcon icon="search" />}
                  pos="sticky"
                  className="filter-select__search-wrapper"
                />
                {itemRenderer()}
              </Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
        </FormGroup>
      </div>

      <ActionIcon
        variant="subtle"
        color="gray.5"
        radius="sm"
        className="mt-3"
        onClick={handleReset}
        disabled={isDisabled || isEmpty}
      >
        <MantineIcon icon="cross" />
      </ActionIcon>
    </div>
  );
});

export default FilterSelect;
