import { useCallback, useMemo } from 'react';
import { IAsset, ItemId } from 'types';
import { tuco } from 'utils/chipmunk';
import { useRemote } from 'utils/hooks';

export const useMainAssetCountInGroup = (group_id?: ItemId): [number] => {
  const filters = useMemo(
    () => [
      ['group_ids', 'eq', group_id],
      ['parent_id', 'not_exist'],
    ],
    [group_id],
  );

  const loadAssets = useCallback(
    () =>
      tuco<IAsset>('getGroupAssets', {
        body: {
          search: {
            filters,
          },
        },
      }),
    [filters],
  );

  const [result] = useRemote(loadAssets);

  return [result?.objects?.length || 0];
};
