import React from 'react';
import isString from 'lodash/isString';

import { hasPower } from 'utils/powers';

interface ICheckPowersProps {
  anyOf?: boolean;
  requiredPowers?: string | string[];
  children?: React.ReactNode;
  hideAnyway?: boolean;
}
export const hasRequiredPowers = (requiredPowers?: string | string[], anyOf?: boolean): boolean => {
  const powers = isString(requiredPowers) ? [requiredPowers] : requiredPowers;
  return !powers || powers[anyOf ? 'some' : 'every'](hasPower);
};

export const CheckPowers: React.FC<ICheckPowersProps> = ({ anyOf, requiredPowers, children, hideAnyway }) => {
  const hasNeededPowers = hasRequiredPowers(requiredPowers, anyOf);
  if (!hideAnyway && hasNeededPowers) {
    return <>{children}</>;
  }

  return <></>;
};
