import { Pages } from 'utils/actions/types';
import { IShouldDisplayGuildActionFunction } from './types';
import { areAllNotDeleted } from 'utils/is-entity-deleted';

export const shouldDisplay: IShouldDisplayGuildActionFunction = (action, index, user, items, options) => {
  return (
    areAllNotDeleted(items) &&
    options?.page !== undefined &&
    [Pages.GUILD_DETAILS, Pages.GUILDS_LIST].includes(options?.page)
  );
};

export const shouldDisplayGenerate: IShouldDisplayGuildActionFunction = (action, index, user, items, options) => {
  return (
    areAllNotDeleted(items) &&
    items.length === 1 &&
    options?.page !== undefined &&
    [Pages.GUILD_DETAILS, Pages.GUILDS_LIST].includes(options?.page)
  );
};
