import React from 'react';

import { FormProducts, FormAssets } from 'helpers/form';
import { ItemId } from 'types';
import { ICreateEventStep } from './types';

export const keysProductsAssets = ['asset_ids', 'products_ids'];

interface IStepProductsAssets extends ICreateEventStep {
  productIds?: ItemId[];
  assetIds?: ItemId[];
  hideProductsParams?: boolean;
}

export const StepProductsAssets: React.FC<IStepProductsAssets> = ({ form }) => {
  return (
    <div className="event-form__product-asset-step">
      <FormProducts
        label="Products"
        name="products_item_ids"
        {...form.formData.products_item_ids}
        {...form.handlers}
        fitInParent
      />
      <FormAssets
        label="Assets"
        name="assets_item_ids"
        {...form.formData.assets_item_ids}
        {...form.handlers}
        fitInParent
      />
    </div>
  );
};
