import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { IUseFormReturn } from 'helpers/form';
import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { ActionMenuItem } from 'components/action';
import { IContact } from 'types';
import { useContactActions } from 'utils/actions';
import { Pages } from 'utils/actions/types';
import { IContactActionName } from 'utils/actions';

import { useHeaderActions } from './utils';
import { useNavigate } from 'react-router';
import { Routes } from 'utils/routes';
import { Menu } from '@mantine/core';

interface IContactDetailsHeaderProps {
  contact: IContact;
  refresh: () => Promise<void>;
  contactForm: IUseFormReturn<IContact>;
  editModeEnabled: boolean;
  setEditModeEnabled: (enabled: boolean) => void;
}

const page = Pages.DETAILS;

export const ContactDetailsHeader: React.FC<IContactDetailsHeaderProps> = observer(
  ({ contact, refresh, contactForm, editModeEnabled, setEditModeEnabled }) => {
    const { first_name, last_name, organization_name, organization_id } = contact || {};

    const navigate = useNavigate();

    const onSuccess = useCallback(
      async (action?: string) => {
        switch (action) {
          case IContactActionName.DELETE:
            navigate(`${Routes.CONTACTS}`);
            break;
          default:
            await refresh();
        }
      },
      [refresh, navigate],
    );

    const singleActions = useContactActions([contact], { onSuccess, page, context: 'single' });

    const { headerActions } = useHeaderActions({
      contact,
      contactForm,
      editModeEnabled,
      setEditModeEnabled,
    });

    const actions = useMemo((): ISectionHeaderAction[] => {
      const deleteAction = singleActions.find((action) => action.name === IContactActionName.DELETE);
      return [
        ...headerActions,
        {
          type: 'delete',
          handler: deleteAction?.handler,
        },
        {
          type: 'more',
          submenu: singleActions.map((action, index) => (
            <Menu key={index}>
              <ActionMenuItem action={action} key={`${action.name}_${index}`} />
            </Menu>
          )),
        },
      ];
    }, [headerActions, singleActions]);

    return (
      <SectionHeader
        title={`${first_name} ${last_name}`}
        subtitle={organization_name}
        useBackground
        actions={actions}
        customLeftRenderer={() => (
          <>
            <h3 className={'text-truncate text-nowrap mb-0'}>{`${first_name} ${last_name}`}</h3>
            <Link to={`/organizations/${organization_id}`}>{organization_name}</Link>
          </>
        )}
      />
    );
  },
);

export default ContactDetailsHeader;
