import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { FormGroup, Intent } from '@blueprintjs/core';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Placeholder from '@tiptap/extension-placeholder';
import { ExitFullScreenButton, GoFullScreenButton } from './full-screen-mode-buttons';
import { formatFormLabel } from 'helpers/form';
import { IElementDiv, IFormHtmlInputProps } from './types';
import { ActionIcon } from '@mantine/core';
import { MantineIcon } from 'utils/ui';

import './style.scss';

const getElementClassname = (name: string): string => {
  const normalized = name.replace(/[^a-zA-Z0-9\-]/g, '_');
  return `html-edit-${normalized}`;
};

// removes added paragraphs by editor
const validateHtml = (value: string): string => {
  const cleanParagraphs = value.replace(/^<p>\s*<\/p>/, '').replace(/<p>\s*<\/p>$/, '');

  //TODO - fix for nexted lists
  const cleanLists = cleanParagraphs.replace(/<li>\s*<p>(.*?)<\/p>\s*<\/li>/g, '<li>$1</li>');

  return cleanLists;
};

const removeFirstParagraph = (input: string): string => {
  return input.replace(/^<p>/, '').replace(/<\/p>$/, '');
};

function decodeHtmlEntities(input): string {
  // Replace HTML entities &lt; and &gt; with actual < and > for tags
  return input.replace(/&lt;(\/?[a-zA-Z0-9]+)&gt;/g, '<$1>');
}

const HtmlTextEditorForm: React.FC<IFormHtmlInputProps> = (props) => {
  const {
    label = '',
    name,
    placeholder,
    onChange,
    onBlur,
    validation,
    touched,
    value,
    required,
    inline = false,
    style,
    className,
    disabled,
    ...rest
  } = props;
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [codeView, setCodeView] = useState(false);
  const [initialRun, setInitialRun] = useState(false);

  const showError = touched && !validation?.valid;
  const intent = showError ? Intent.DANGER : 'none';

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Underline,
        Link,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
        Placeholder.configure({
          placeholder: placeholder || 'Write something …',
        }),
      ],
      content: value,
      editable: !disabled,
      onUpdate({ editor }) {
        let htmlValue = editor.getHTML();
        const editorElement = editorRef?.current?.querySelector('div.tiptap.ProseMirror');
        const currentContent = validateHtml(editorElement?.textContent as string);
        const value = editor.isEmpty ? '' : currentContent;
        const cleanedValue = removeFirstParagraph(value);

        if (editor?.isEmpty) {
          editor.commands.setContent(cleanedValue, false);
        }

        htmlValue = decodeHtmlEntities(htmlValue);
        onChange?.({ [name]: removeFirstParagraph(htmlValue) });
      },
      onBlur() {
        onBlur?.(name);
      },
    },
    [name, onChange, onBlur],
  );

  const editorRef = useRef<IElementDiv>(null);
  const groupClassName = inline ? 'form-input-inline__container' : className;
  const elementClassname = getElementClassname(name);

  useEffect(() => {
    if (editor) {
      const value = editor?.getHTML();
      const textValue = editor?.getText();
      const modifiedHtml = removeFirstParagraph(validateHtml(value));

      if (codeView) {
        editor?.commands.clearContent();
        editor?.commands.setContent(`<textarea>${modifiedHtml}</textarea>`, true);
        setInitialRun(true);
      } else {
        if (initialRun) {
          const cleanText = removeFirstParagraph(textValue);
          editor?.commands.setContent(cleanText, true);
        }
      }
    }
  }, [codeView, editor, initialRun]);

  return (
    <FormGroup
      label={formatFormLabel(label, required)}
      labelFor={name}
      intent={intent}
      inline={inline}
      helperText={showError ? validation?.errorMessage : ''}
      className={cx([groupClassName, elementClassname])}
      style={{ ...style, minWidth: 'fit-content' }}
    >
      <RichTextEditor
        editor={editor}
        ref={editorRef}
        styles={{
          ...(isFullScreen && { content: { height: '100%' } }),
          content: { overflow: 'auto', height: isFullScreen ? '100%' : '139px', resize: 'vertical' },
          ...(isFullScreen && { typographyStylesProvider: { height: 'calc(100% - 48px)' } }),
        }}
        {...rest}
      >
        <RichTextEditor.Toolbar sticky={isFullScreen}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Undo />
            <RichTextEditor.Redo />
            {!isFullScreen ? (
              <GoFullScreenButton editorRef={editorRef} setIsFullScreen={setIsFullScreen} />
            ) : (
              <ExitFullScreenButton setIsFullScreen={setIsFullScreen} />
            )}
          </RichTextEditor.ControlsGroup>
          <ActionIcon
            data-active={codeView}
            className="html-editor__code-view-icon"
            variant="subtle"
            onClick={() => setCodeView(!codeView)}
          >
            <MantineIcon icon="console" />
          </ActionIcon>
        </RichTextEditor.Toolbar>
        <RichTextEditor.Content className="html-editor__content" />
      </RichTextEditor>
    </FormGroup>
  );
};

export default HtmlTextEditorForm;
