import { filter } from 'lodash';
import { AssetType } from 'utils/format-asset-type';

export enum MainClassification {
  Video = 'video',
  Audio = 'audio',
  Document = 'document',
  Image = 'image',
}

export const filterClassificationsByType = (type: string, classifications?: string[] | null): string[] => {
  const filterFn = (mainCl: string): string[] => filter(classifications, (cl) => cl.includes(mainCl));

  if (!type) {
    return classifications || [];
  }

  switch (type) {
    case AssetType.AUDIO:
      return filterFn(MainClassification.Audio);
    case AssetType.VIDEO:
      return filterFn(MainClassification.Video);
    case AssetType.IMAGE:
      return filterFn(MainClassification.Image);
    case AssetType.DOCUMENT:
      return filterFn(MainClassification.Document);
    case AssetType.SUBTITLE:
      return filterFn(MainClassification.Document);
    default:
      return classifications || [];
  }
};
