import React from 'react';
import { toNumber } from 'lodash';

import { Row } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';

import { Routes } from 'utils/routes';
import { DetailsPageTabs, ISmartGroup } from 'types';
import { formatDate } from 'utils/date';
import { formatOwnership, parseDataSize } from 'utils/general';
import { useMainAssetCountInGroup } from 'utils/hooks/asset';

const SmartGroupDetailsOverview: React.FC<{
  smartGroup?: ISmartGroup;
}> = ({ smartGroup }) => {
  const {
    am_statistics_data,
    asset_filters,
    user_filters,
    created_at,
    deleted_at,
    expires_at,
    id,
    name,
    pm_statistics_data,
    product_filters,
    updated_at,
    users_count,
  } = smartGroup || {};

  const mobile_sync_size = toNumber(am_statistics_data?.mobile_sync_size);
  const ownerField = formatOwnership(smartGroup);
  const detailsLink = `${Routes.SMART_GROUP}/${id}`;
  const [assetCount] = useMainAssetCountInGroup(id);

  return (
    <div className="recommendation-details__overview-wrapper">
      <InfoBoxV2 gridTemplateColumns="90px 1fr" useBackground={true} padded={false} wrapperClassName="p-3 m-3">
        <Row label="Name" value={name} />
        <Row label="Owner" value={ownerField} />
        <Row label="Expires at" value={formatDate(expires_at)} rawLabel />
        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
        <Row label="Deleted at" value={formatDate(deleted_at)} rawLabel hideIfNoValue />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="90px 1fr" title="About">
        <Row label="Sync size" value={parseDataSize(mobile_sync_size, '0 B')} rawLabel />
        <Row
          value={`${pm_statistics_data?.count} (${product_filters?.length ? 'dynamic' : 'static'})`}
          link={`${detailsLink}/${DetailsPageTabs.PRODUCTS}`}
          label={DetailsPageTabs.PRODUCTS}
        />
        <Row
          value={`${assetCount} (${asset_filters?.length ? 'dynamic' : 'static'})`}
          link={`${detailsLink}/${DetailsPageTabs.ASSETS}`}
          label={DetailsPageTabs.ASSETS}
        />
        <Row
          value={`${users_count} (${user_filters?.length ? 'dynamic' : 'static'})`}
          link={`${detailsLink}/${DetailsPageTabs.CONTACTS}`}
          label={DetailsPageTabs.CONTACTS}
        />
      </InfoBoxV2>
    </div>
  );
};

export default SmartGroupDetailsOverview;
