import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';
import { Loading } from 'components/loading';
import { BodyWrapper } from 'components/layout';
import { useRemote } from 'utils/hooks';
import { ISmartGroup } from 'types';
import { loadGroup } from 'utils/apis/groups';
import { SmartGroupListSchema } from 'utils/schemas/smart-group';

import { SmartGroupDetailsLoaded } from './smart-group-details-loaded';

import 'components/marketing-entity-detail/style.scss';

const EmptySection: React.FC = () => {
  return (
    <BodyWrapper>
      <SectionMessage intent={SectionMessageType.EMPTY}>No Access Privilege</SectionMessage>
    </BodyWrapper>
  );
};

const SmartGroupDetails: React.FC = observer(() => {
  const { smartGroupId = '' } = useParams<{ smartGroupId: string }>();

  const fetchSmartGroup = useCallback(async () => {
    if (!smartGroupId) {
      return;
    }
    return loadGroup<ISmartGroup>(smartGroupId, SmartGroupListSchema);
  }, [smartGroupId]);

  const [smartGroup, loading, setSmartGroup, refreshSmartGroup] = useRemote<ISmartGroup | undefined>(fetchSmartGroup);

  if (loading) {
    return (
      <BodyWrapper>
        <Loading text="Loading Mobile Selection details" />
      </BodyWrapper>
    );
  }

  if (!smartGroup?.id) {
    return <EmptySection />;
  }

  return (
    <SmartGroupDetailsLoaded
      smartGroup={smartGroup}
      setSmartGroup={setSmartGroup}
      refreshSmartGroup={refreshSmartGroup}
    />
  );
});

export default SmartGroupDetails;
