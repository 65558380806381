import React from 'react';
import { Badge } from '@mantine/core';

import { DeliveriesGenericConnection } from '@mediafellows/mm3-types';
import { Avatar } from 'components/avatar/avatar';
import {
  formatConnectionType,
  AvatarType,
  ConnectionStatus,
  toPascalCase,
} from 'components/connection-list-item/utils';
import { Classes } from 'utils/ui';
import { ConnectionType } from 'components/delivery/connections';
import { UserStatus } from 'types';

export const ConnectionPreviewHeader: React.FC<{ connection: DeliveriesGenericConnection }> = ({ connection }) => {
  const { name, status, type } = connection || {};
  const formatedType = toPascalCase(formatConnectionType(type as string));

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="entity-preview__title">
          <h3>{name}</h3>
        </div>

        {connection.type !== ConnectionType.WYVERN && (
          <div className={`entity-preview__subtitle ${Classes.TEXT_MUTED}`}>
            <Badge variant={ConnectionStatus[status as string]}>{status?.toUpperCase()}</Badge>
          </div>
        )}
      </div>

      <div className="connection-preview__thumb">
        <Avatar image={AvatarType[formatedType]} status={connection.status as UserStatus} />
      </div>
    </div>
  );
};
