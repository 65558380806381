import { UmGenericListItem } from '@mediafellows/mm3-types';
import { IResult } from '@mediafellows/chipmunk';
import { chunk } from 'lodash';

import { Model, ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { chipmunk } from 'utils/chipmunk';
import {
  IQueryParams,
  IAsset,
  IProduct,
  IOrganization,
  IGroup,
  IContact,
  ItemId,
  ICategory,
  ISearchFilter,
} from 'types';
import { parseToSearchParams } from 'utils/general';
import { IFormMultiSelectOption } from 'helpers/form';

import { queryOrganizations } from './organization';
import { queryGroups } from './groups';
import { queryAssets } from './asset';
import { fetchProductsWithAncestry } from './product';
import { queryContacts } from './contacts';
import { fetchLists } from './showcase';
import { queryGenres } from './genre';
import { fetchNews } from './news';
import { ItemTypes } from 'helpers/form/fields/select-helpers';

type IItem = IProduct | IAsset | IContact | IOrganization | IGroup | ICategory;

export type IEntitiesToQuery =
  | 'user'
  | 'product'
  | 'asset'
  | 'group/access_privilege'
  | 'group/event'
  | 'organization'
  | 'category'
  | 'list/collection'
  | 'news'
  | 'separator'
  | 'asset3';
export const fetchListItemsOptions = async (
  { ids, q }: IQueryParams,
  additionalFilters: {
    [key in IEntitiesToQuery]?: ISearchFilter[];
  } = {},
  queryEntities: IEntitiesToQuery[] = [],
  ancestryFilters?: ISearchFilter[],
): Promise<IItem[]> => {
  const params = { per: 10, page: 1, ids, q };
  const productFilter = additionalFilters['product'] || [];
  const productParentFilter = ancestryFilters || [['parent_id', 'not_exist']];

  const productAncestryPromise = queryEntities.includes('product')
    ? fetchProductsWithAncestry({ q, ids }, [...productFilter, ...productParentFilter])
    : [];

  // do not show other entities when navigating into a product's descendants tree
  if (ancestryFilters) {
    const result = await productAncestryPromise;
    return result;
  }

  const contactsPromise = queryEntities.includes('user') ? queryContacts(params, additionalFilters.user) : [];
  const newsPromise = queryEntities.includes(ItemTypes.NEWS) ? fetchNews(params, additionalFilters.news) : [];
  const assetsPromise = queryEntities.includes('asset')
    ? queryAssets(params, [['status', 'eq', 'available'], ...(additionalFilters.asset || [])])
    : [];
  const eventsPromise = queryEntities.includes('group/event')
    ? queryGroups(params, [['type', 'eq', 'group/event']])
    : [];
  const collectionsPromise = queryEntities.includes('list/collection')
    ? fetchLists(
        parseToSearchParams(params, [
          ['type', 'eq', 'List::Collection'],
          ...(additionalFilters['list/collection'] || []),
        ]),
      )
    : [];
  const selectionsPromise = queryEntities.includes('group/access_privilege')
    ? queryGroups(params, [['type', 'eq', 'group/access_privilege']])
    : [];
  const genres = queryEntities.includes('category') ? queryGenres(params) : [];
  const orgsPromise = queryEntities.includes('organization')
    ? queryOrganizations(params, additionalFilters.organization)
    : [];

  const result = (await Promise.all([
    productAncestryPromise,
    assetsPromise,
    newsPromise,
    contactsPromise,
    eventsPromise,
    selectionsPromise,
    collectionsPromise,
    orgsPromise,
    genres,
  ])) as IItem[][];

  return ([] as IItem[]).concat(...result);
};

export const createListItems = async (
  items: IFormMultiSelectOption[],
  listId?: number | null,
): Promise<UmGenericListItem[]> => {
  if (!listId || !items?.length) return [];

  return chipmunk.run(async ({ action }) => {
    const { object: firstItem } = await action<UmGenericListItem>(Model.LIST_ITEMS, 'search', {
      params: { lists_ids: [listId] },
      body: {
        sort: ParametronSort.SEQUENCE_NUMBER,
        order: ParametronOrder.ASCENDING,
        per: 1,
        page: 1,
      },
    });

    const sequenceNumber = firstItem?.sequence_number || 0;
    const parseItem = (item: IFormMultiSelectOption, index: number): Omit<UmGenericListItem, 'id'> => ({
      list_id: listId,
      entity_id: item.itemType === 'separator' ? 0 : Number(item?.value),
      entity_type: item.itemType === 'group' ? item['@type'] : item.itemType,
      sequence_number: firstItem?.id ? sequenceNumber - (index + 1) : index + 1,
    });

    return action<UmGenericListItem>(Model.LIST_ITEMS, 'create', {
      body: items.map(parseItem),
      params: { lists_ids: [listId] },
    });
  });
};

export const deleteListItems = async (listId?: number | null, itemsIds?: ItemId[]): Promise<IResult | null> => {
  if (!itemsIds?.length || !listId) {
    return null;
  }

  return chipmunk.run(({ action }) => {
    return action(Model.LIST_ITEMS, 'destroy', {
      params: { lists_ids: listId, items_ids: itemsIds },
    });
  });
};

export const searchListItems = async (
  listId?: ItemId,
  filters: ISearchFilter[] = [],
  schema?: string,
  allItems?: boolean,
): Promise<UmGenericListItem[]> => {
  if (!listId) {
    return [];
  }

  return chipmunk.run(async (chpmnk) => {
    const { objects } = await chpmnk[allItems ? 'unfurl' : 'action']<UmGenericListItem>(Model.LIST_ITEMS, 'search', {
      body: { search: { filters: [...filters, ['list_id', 'eq', listId]] } },
      params: { lists_ids: listId },
      schema,
    });

    return objects;
  });
};

export const updateListItems = async (
  lists_ids: number[],
  items: Partial<UmGenericListItem>[],
  withSequenceNumber?: boolean,
): Promise<UmGenericListItem[]> => {
  if (!items?.length) {
    return [];
  }
  const itemsPerChunk = 100;
  const batches = chunk(items, itemsPerChunk);

  const promises = batches.map((batch, index) => {
    return chipmunk.run(async ({ action }) => {
      const { objects } = await action(Model.LIST_ITEMS, 'update', {
        params: { lists_ids, items_ids: batch.map(({ id }) => id) },
        body: batch.map((item, sequence_number) => ({
          ...item,
          ...(withSequenceNumber ? { sequence_number: itemsPerChunk * index + sequence_number } : {}),
        })),
      });

      return objects;
    });
  });

  const results = await Promise.all(promises);
  return results.flat(1);
};

export const getAllListItems = async (
  listId?: ItemId | null,
  schema = 'id, entity_id, entity_type',
): Promise<UmGenericListItem[]> => {
  if (!listId) {
    return [];
  }

  return chipmunk.run(async ({ unfurl }) => {
    const { objects } = await unfurl(Model.LIST_ITEMS, 'search', {
      params: { lists_ids: [listId] },
      body: {
        search: {
          filters: [['list_id', 'eq', listId]],
        },
      },
      schema,
    });
    return objects;
  });
};
