import React from 'react';
import { omit } from 'lodash';

import { useDialogStore, useStore } from 'store';

import { ToastError } from 'components/toast';
import { useDivisionForm } from 'components/divisions/division-form/use-division-form';

import { createDivision } from 'utils/apis/divisions';
import { IUseActionsOptionParam } from 'utils/actions';
import { delay } from 'utils/delay';

import { IDivisionActionConfig, IDivisionActionName } from './types';
import { IDivision } from 'types';
import { IDivisionFormData } from 'components/divisions/division-form/division-form';

export const useAddSubDivisionAction = (
  divisions: IDivision[],
  options: IUseActionsOptionParam<IDivision>,
): IDivisionActionConfig => {
  const { toastStore, basicStore } = useStore();
  const { close } = useDialogStore();
  const handleSubmit = async (values: IDivisionFormData): Promise<IDivision | void> => {
    try {
      const valuesToSubmit = omit(values, ['parentName']);
      const division = await createDivision(valuesToSubmit);
      toastStore.success('Sub-division has been added successfully!');
      await delay();
      await options?.onSuccess?.(IDivisionActionName.ADD_SUB_DIVISION, division);
      basicStore.updateBasicStore('divisions', [division, ...(basicStore.divisions || [])]);
      close();
      return division;
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
      await options?.onFailure?.();
    }
  };

  const handler = useDivisionForm({
    title: 'Add sub-division',
    handleSubmit,
    parentName: divisions[0].name,
    parentId: options?.parentId as number,
  });

  return {
    name: IDivisionActionName.ADD_SUB_DIVISION,
    shouldDisplay: () => Boolean(options?.parentId),
    icon: 'add',
    title: 'Add sub-division',
    handler,
  };
};
