import React, { useCallback } from 'react';

import { Page } from 'components/layout';
import { GuildWizard } from 'components/guilds';
import { Routes } from 'utils/routes';
import { useNavigate } from 'react-router';

export const UpsertGuild: React.FC = () => {
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    navigate(`${Routes.GUILDS}`);
  }, [navigate]);

  return (
    <Page title="Create Guild">
      <GuildWizard onSuccess={onSuccess} fitInParent />
    </Page>
  );
};
