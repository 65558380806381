import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { Routes } from 'utils/routes';
import { useRemote } from 'utils/hooks';
import { useDataSectionStore } from 'store';
import { OrderPreviewAttributes } from './order-list-attributes';
import { AmAmazonapacLocalizationOrderSeriesSeries } from '@mediafellows/mm3-types';
import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';
import { Thumbnail } from 'components/thumbnail';
import { PreviewTab } from 'components/preview-tab';
import { queryProducts } from 'utils/apis/product';
import { IProduct, ItemId } from 'types';
import { loadOrderThumbnails } from 'utils/apis/localization-order';

import './style.scss';

const OrderPreviewTab = observer(() => {
  const { activeItem: order } = useDataSectionStore<AmAmazonapacLocalizationOrderSeriesSeries>();

  const fetchThumbnails = useCallback(() => {
    return loadOrderThumbnails(order?.product_id as ItemId);
  }, [order?.product_id]);

  const getProductById = useCallback(async () => {
    return queryProducts({ ids: [order?.product_id as ItemId] });
  }, [order?.product_id]);

  const [thumbnails = []] = useRemote(fetchThumbnails);
  const [products] = useRemote(getProductById);

  return (
    <PreviewTab
      title={order?.project_name}
      detailsLink={`/${Routes.ORDER_MANAGEMENT}/${order?.id}`}
      customThumbnail={
        thumbnails.length ? (
          <MemoizedMultiThumbnail
            height={172}
            width={308}
            className="entity-preview__thumb"
            thumbnailImages={thumbnails || [{}]}
          />
        ) : (
          <Thumbnail showType image="" />
        )
      }
    >
      <OrderPreviewAttributes product={products?.[0] as IProduct} />
    </PreviewTab>
  );
});
export default OrderPreviewTab;
