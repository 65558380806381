import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { Routes } from 'utils/routes';
import { LabelValuePair as Row, yn } from 'components/label-value-pair';
import { AssetFilters, withAssetVersion } from 'utils/asset';
import { PreviewInfo } from 'components/preview-tab';
import { ScheduledChangeIndicator } from 'components/common/scheduled-change-info';
import { AssetStatusIndicator } from 'components/asset';
import { AssetMm3PreviewAttributes } from 'components/asset/asset-preview-tab/asset-preview-attributes-mm3';
import { getAssetArtifacts } from 'utils/apis/asset';

import { useDataSectionStore } from 'store';
import { useBasicStore } from 'store/hooks';

import { IAsset, DetailsPageTabs } from 'types';
import { formatDate, formatDuration } from 'utils/date';
import {
  getEntityType,
  formatAssetType,
  formatResolution,
  parseDataSize,
  joinLanguages,
  formatField,
  getLanguageById,
} from 'utils/general';
import { useRemote } from 'utils/hooks';
import { parseSubtitles } from './utils';
import { useEntityOwnership } from 'utils/hooks/use-divisions';

import './style.scss';

export const AssetOldPreviewAttributes: React.FC = observer(() => {
  const { languages } = useBasicStore();
  const { activeItem: asset } = useDataSectionStore<IAsset>();

  const {
    id,
    access_level,
    height,
    width,
    classification,
    language_ids,
    duration,
    status,
    permissions,
    protection_levels,
    published_at,
    created_at,
    updated_at,
    deleted_at,
    subtitles,
    products,
    file_size,
    sharable,
    dubbed_language_id,
  } = asset || {};

  const fetchAssetAudioTracks = useCallback(async () => {
    return await getAssetArtifacts(id);
  }, [id]);

  const [audioTracks = []] = useRemote(fetchAssetAudioTracks);

  const detailsPageLink = `${Routes.ASSETS}/${id}`;
  const productsCount = products?.length ? (
    <Link to={`${detailsPageLink}/${DetailsPageTabs.PRODUCTS}`}>{products.length}</Link>
  ) : (
    '0'
  );

  const audioCount = audioTracks?.length ? (
    <Link to={`${detailsPageLink}/${DetailsPageTabs.AUDIO_TRACKS}`}>{audioTracks.length}</Link>
  ) : (
    '—'
  );

  const assetItemType = getEntityType(classification as string);
  const subs = parseSubtitles('subtitle', languages, subtitles);
  const captions = parseSubtitles('caption', languages, subtitles);
  const isVideo = assetItemType === AssetFilters.VIDEO;
  const isImage = assetItemType === AssetFilters.IMAGE;
  const isAudio = assetItemType === AssetFilters.AUDIO;
  const hasResolution = isImage || isVideo;
  const hasDuration = isVideo || isAudio;

  const { ownershipInfo, loadingOwnership } = useEntityOwnership(asset);

  return (
    <PreviewInfo>
      <Row label="File type" value={assetItemType} size="m" rawLabel />
      <Row label="Asset Type" value={formatAssetType(classification as string)} size="m" rawLabel />
      <Row label="Resolution" value={formatResolution({ width, height })} size="m" hidden={!hasResolution} />
      <Row label="File size" hideIfNoValue value={parseDataSize(file_size)} size="m" rawLabel />
      <Row label="Runtime" value={formatDuration(duration, 'seconds')} size="m" hidden={!hasDuration} />
      <Row
        label="Status"
        value={
          <>
            {status} <ScheduledChangeIndicator entity={asset} type="status" />
            {asset && <AssetStatusIndicator asset={asset} />}
          </>
        }
        size="m"
      />
      <Row
        label="Access level"
        value={
          <>
            {access_level} <ScheduledChangeIndicator entity={asset} type="access" />
          </>
        }
        size="m"
        rawLabel
      />
      <Row label="Sharable" value={yn(sharable)} size="m" />
      <Row
        label="Permissions"
        value={
          <>
            {permissions?.includes('download') ? 'Download' : '[N/A]'}
            <ScheduledChangeIndicator entity={asset} type="permissions" />
          </>
        }
        size="m"
      />
      <Row
        label="Protection levels"
        value={
          <>
            {formatField(protection_levels, '[N/A]')} <ScheduledChangeIndicator entity={asset} type="protection" />
          </>
        }
        rawLabel
      />
      <Row label="Ownership" value={ownershipInfo} loading={loadingOwnership} />
      <Row label="Languages" value={joinLanguages(languages, language_ids) || 'No languages'} size="m" />
      <Row label="Dubbed Languages" value={getLanguageById(languages, dubbed_language_id) || 'No language'} size="m" />
      <Row label="Products" value={productsCount} size="m" />
      <Row label="Created at" value={formatDate(created_at)} rawLabel />
      <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
      <Row label="Published at" value={formatDate(published_at) || '[N/A]'} rawLabel />
      <Row label="Deleted at" value={formatDate(deleted_at)} hideIfNoValue rawLabel />
      <>
        {isVideo && (
          <>
            <Row label="Subtitles" value={subs} size="m" />
            <Row label="captions" value={captions} size="m" />
            <Row label="Audio Tracks" value={audioCount} size="m" />
          </>
        )}
      </>
    </PreviewInfo>
  );
});

export const AssetPreviewAttributes = withAssetVersion(AssetOldPreviewAttributes, AssetMm3PreviewAttributes);
