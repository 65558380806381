import { IShouldDisplayRecommendationActionFunction } from './types';

export const isRecommendationDraftAndSingleContext: IShouldDisplayRecommendationActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  return entities[0]?.status === 'draft' && options.context === 'single';
};
export const shouldShowDeliver: IShouldDisplayRecommendationActionFunction = (action, index, user, entities) => {
  return (
    entities[0]?.status === 'draft' &&
    (entities.every((recommendation) => recommendation.generate_links_only) ||
      entities.every((recommendation) => !recommendation.generate_links_only))
  );
};
export const isRecommendationSentAndSingleContext: IShouldDisplayRecommendationActionFunction = (
  action,
  index,
  user,
  entities,
) => {
  return entities[0]?.status === 'sent';
};
