import { useCallback } from 'react';
import type { ISmartGroup } from 'types';

import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplaySmartGroup as shouldDisplay } from './actions-acl';

import { ISmartGroupActionConfig, ISmartGroupActionName } from './types';
import { useSmartGroupDialog } from 'components/smart-group/form-hook';

import { getValidIds } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { queryContacts } from 'utils/apis/contacts';
import { queryAllProducts } from 'utils/apis/product';
import { queryAllAssets } from 'utils/apis/asset';

export const useEditAction = (items: ISmartGroup[], options: IUseActionsOptionParam): ISmartGroupActionConfig => {
  const group = items[0];

  const fetchContacts = useCallback(async () => {
    if (group?.user_filters?.length || !group?.id) {
      return [];
    }
    return queryContacts({}, [['group_ids', 'in', [group?.id]]], 'id', 'unfurl');
  }, [group?.id, group?.user_filters?.length]);

  const [contacts, loadingContacts] = useRemote(fetchContacts);

  const fetchProductIds = useCallback(async () => {
    if (!group?.id || group.product_filters?.length) {
      return [];
    }
    const products = await queryAllProducts({}, [['group_ids', 'in', [group.id]]], 'id');
    return getValidIds(products);
  }, [group?.id, group?.product_filters?.length]);

  const [productIds, loadingProducts] = useRemote(fetchProductIds);

  const fetchAssetIds = useCallback(async () => {
    if (!group?.id || group.asset_filters?.length) {
      return [];
    }
    const assets = await queryAllAssets({}, [['group_ids', 'in', [group.id]]], 'id');
    return getValidIds(assets);
  }, [group?.id, group?.asset_filters?.length]);

  const [assetIds, loadingAsset] = useRemote(fetchAssetIds);
  const loading = loadingContacts || loadingAsset || loadingProducts;

  const [handler] = useSmartGroupDialog({
    group: items[0],
    userIds: getValidIds(contacts),
    productIds,
    assetIds,
    onSuccess: () => options?.onSuccess?.(ISmartGroupActionName.EDIT),
  });

  return {
    name: ISmartGroupActionName.EDIT,
    shouldDisplay,
    icon: 'edit',
    title: loading ? 'Loading...' : 'Edit',
    isDisabled: loading,
    handler,
  };
};
