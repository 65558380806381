import React, { useCallback } from 'react';

import {
  IUseActionsOptionParam,
  assetDividerAction,
  contactDividerAction,
  productDividerAction,
  useAssetActions,
  useContactActions,
  useProductActions,
} from 'utils/actions';
import { useSelectionActions } from 'utils/actions/selection';
import { removeDividers, getValidSelectionActions } from 'utils/actions/utils';
import { IAsset, IContact, IProduct, ISelectionStandardized } from 'types';
import { loadAssetSelectionItems, loadAllContactSelectionItems, loadProductSelectionItems } from 'utils/apis/selection';

import { IItemActionsMenuProps } from './utils';
import { useRemote } from 'utils/hooks';
import { areAllNotDeleted } from 'utils/is-entity-deleted';
import { EntityActionListWrapper } from '.';

export const SelectionListItemActionsList: React.FC<IItemActionsMenuProps<ISelectionStandardized>> = (props) => {
  const { items, options } = props;

  const [, ...actions] = useSelectionActions(items, options);
  return <EntityActionListWrapper actions={getValidSelectionActions(actions)} />;
};

export const AssetSelectionActionsList: React.FC<IItemActionsMenuProps<ISelectionStandardized>> = (props) => {
  const { items, options } = props;

  const selectionActions = useSelectionActions(items, options);

  const fetchSelectionItems = useCallback(async (): Promise<IAsset[]> => {
    return areAllNotDeleted(items) ? loadAssetSelectionItems(items) : [];
  }, [items]);

  const [newSelectionItems] = useRemote<IAsset[]>(fetchSelectionItems, []);
  const assetActionOptions = { ...options, context: 'multi' } as IUseActionsOptionParam<IAsset>;
  const assetActions = useAssetActions(newSelectionItems, assetActionOptions);

  if (!areAllNotDeleted(items)) {
    return <EntityActionListWrapper actions={selectionActions} />;
  }
  const actions = removeDividers([...selectionActions, assetDividerAction, ...assetActions]);
  return <EntityActionListWrapper actions={actions} />;
};

export const ContactSelectionActionsList: React.FC<IItemActionsMenuProps<ISelectionStandardized>> = (props) => {
  const { items, options } = props;

  const fetchSelectionItems = useCallback(async (): Promise<IContact[]> => {
    return areAllNotDeleted(items) ? loadAllContactSelectionItems(items) : [];
  }, [items]);

  const [contacts] = useRemote<IContact[]>(fetchSelectionItems, []);

  const conatctActionOptions = { ...options, context: 'multi' } as IUseActionsOptionParam<IContact>;
  const contactActions = useContactActions(contacts || [], conatctActionOptions);

  const selectionActions = useSelectionActions(items, options);

  if (!areAllNotDeleted(items)) {
    return <EntityActionListWrapper actions={selectionActions} />;
  }

  const actions = removeDividers([...selectionActions, contactDividerAction, ...contactActions]);
  return <EntityActionListWrapper actions={actions} />;
};

export const ProductSelectionActionsList: React.FC<IItemActionsMenuProps<ISelectionStandardized>> = (props) => {
  const { items, options } = props;

  const fetchSelectionItems = useCallback(async (): Promise<IProduct[]> => {
    return areAllNotDeleted(items) ? loadProductSelectionItems(items) : [];
  }, [items]);

  const [newSelectionItems] = useRemote<IProduct[]>(fetchSelectionItems, []);

  const selectionActions = useSelectionActions(items, options);
  const productActionOptions = { ...options, context: 'multi' } as IUseActionsOptionParam<IProduct>;
  const productActions = useProductActions(newSelectionItems || [], productActionOptions);

  if (!areAllNotDeleted(items)) {
    return <EntityActionListWrapper actions={selectionActions} />;
  }

  const actions = removeDividers([...selectionActions, productDividerAction, ...productActions]);
  return <EntityActionListWrapper actions={actions} />;
};
