import React from 'react';
import { observer } from 'mobx-react-lite';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IProduct, IProductLayer } from 'types';
import { InfoBoxV2 } from 'components/info-box-v2';

import {
  ProductEditAboutSection,
  ProductEditDistribution,
  ProductEditMarketScreeningsSection,
  ProductEditOthersSection,
  ProductEditProductionSection,
  ProductEditSearchSection,
} from 'components/product-edit-sections';

interface IProductMetadataTabEditFormViewProps {
  layerForm: IUseMm3FormReturn<IProductLayer>;
  productForm: IUseMm3FormReturn<IProduct>;
  padded?: boolean;
}

export const ProductMetadataTabEditFormView: React.FC<IProductMetadataTabEditFormViewProps> = observer(
  ({ layerForm, productForm, padded = false }) => {
    return (
      <InfoBoxV2 gridTemplateColumns="1fr 1fr" padded={padded} wrapperClassName="w-100 pt-3 pb-3">
        <ProductEditAboutSection layerForm={layerForm} productForm={productForm} />
        <div>
          <ProductEditProductionSection layerForm={layerForm} productForm={productForm} />
          <ProductEditDistribution layerForm={layerForm} productForm={productForm} />
          <ProductEditOthersSection layerForm={layerForm} />
          <ProductEditSearchSection layerForm={layerForm} />
        </div>
        <ProductEditMarketScreeningsSection layerForm={layerForm} productForm={productForm} />
      </InfoBoxV2>
    );
  },
);

export default ProductMetadataTabEditFormView;
