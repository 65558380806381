import React from 'react';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row, yn } from 'components/label-value-pair';
import { IAsset } from 'types';
import { ScheduledChangeIndicator } from 'components/common/scheduled-change-info';
import { AssetStatusIndicator } from 'components/asset';
import { formatField } from 'utils/general';

export const AssetGeneralTabSettingsSection: React.FC<{
  asset: IAsset;
  setAsset: React.Dispatch<React.SetStateAction<IAsset>>;
}> = ({ asset, setAsset }) => {
  const { access_level, status, permissions, protection_levels, sharable } = asset || {};

  return (
    <InfoBoxV2 gridTemplateColumns={'minmax(135px, 1fr) 2fr'} title="Settings" wrapperClassName="mt-3">
      <Row
        label="Status"
        value={
          <>
            {status} <ScheduledChangeIndicator entity={asset} type="status" />
            {asset && <AssetStatusIndicator asset={asset} setAsset={setAsset} />}
          </>
        }
        size="m"
      />
      <Row
        label="Access Level"
        value={
          <>
            {access_level} <ScheduledChangeIndicator entity={asset} type="access" />
          </>
        }
        size="m"
      />
      <Row label="Sharable" value={yn(sharable)} size="m" />
      <Row
        label="Permissions"
        value={
          <>
            {formatField(permissions)} <ScheduledChangeIndicator entity={asset} type="permissions" />
          </>
        }
        size="m"
      />
      <Row
        label="Protection Levels"
        value={
          <>
            {formatField(protection_levels)} <ScheduledChangeIndicator entity={asset} type="protection" />
          </>
        }
        size="m"
      />
    </InfoBoxV2>
  );
};
