import React from 'react';
import { AmGenericAsset3, AmGenericNews, UmGenericListCollection } from '@mediafellows/mm3-types';
import { Combobox } from '@mantine/core';

import {
  IAsset,
  IGroup,
  IOrganization,
  IProduct,
  IContact,
  ICategory,
  ISeparator,
  IItem,
  ItemId,
  ICustomItemRendererProps,
} from 'types';
import { isProduct } from 'utils/general';
import { ProductSearchItem } from 'components/product-search-item';

import { NewsSearchItem } from 'components/news';
import { AssetSearchItem } from 'components/asset/asset-search-item';
import { GroupSearchItem } from 'components/group-search-item';
import { UserSearchItem } from 'components/user-search-item';
import { OrganizationSearchItem } from 'components/organization';
import { CollectionSearchItem } from 'components/collection-search-item';
import { CategorySelectedTabItem } from 'components/category-selected-tab-item';

export enum ItemTypes {
  USER = 'user',
  ASSET = 'asset',
  GROUP = 'group',
  NEWS = 'news',
  PRODUCT = 'product',
  ORGANIZATION = 'organization',
  CATEGORY = 'category',
  LIST_COLLECTION = 'list/collection',
  SEPARATOR = 'separator',
  UNKNOWN = 'unknown',
}

export type IItemType =
  | ItemTypes.USER
  | ItemTypes.ASSET
  | ItemTypes.GROUP
  | ItemTypes.PRODUCT
  | ItemTypes.ORGANIZATION
  | ItemTypes.CATEGORY
  | ItemTypes.NEWS
  | ItemTypes.LIST_COLLECTION
  | ItemTypes.SEPARATOR
  | ItemTypes.UNKNOWN;

export type IFormMultiSelectOption<T = {} | IItem> = {
  value: string;
  label: string;
  itemType?: ItemTypes;
  type?: string;
  id?: ItemId;
} & T;

export type ICustomItemRenderer<T> = (
  item: T,
  handleClick,
  itemProps: ICustomItemRendererProps,
  options?: {
    isAncestryMode?: boolean;
  },
) => JSX.Element | null;

export const itemRenderer: ICustomItemRenderer<IFormMultiSelectOption> = (item, handleClick, itemProps, options) => {
  const { disabled, isNavigationEnabled } = itemProps;

  switch (item.itemType) {
    case ItemTypes.USER:
      return (
        <Combobox.Option value={item.value} key={`user-${item.value}`}>
          <UserSearchItem user={item as IContact} handleSelect={handleClick} />
        </Combobox.Option>
      );
    case ItemTypes.ASSET:
      return (
        <Combobox.Option value={item.value} key={`asset-${item.value}`}>
          <AssetSearchItem asset={item as IAsset} handleSelect={handleClick} />
        </Combobox.Option>
      );
    case ItemTypes.NEWS:
      return (
        <Combobox.Option value={item.value} key={`news-${item.value}`}>
          <NewsSearchItem news={item as AmGenericNews} handleSelect={handleClick} />
        </Combobox.Option>
      );
    case ItemTypes.PRODUCT:
      const { isAncestryMode } = options || {};
      return (
        <ProductSearchItem
          product={item as IProduct}
          handleSelect={handleClick}
          isAncestryMode={isAncestryMode}
          isDisabled={disabled}
          isNavigationEnabled={isNavigationEnabled}
          key={`product-${item.value}`}
          isComboboxOption
        />
      );
    case ItemTypes.GROUP:
      return (
        <Combobox.Option value={item.value} key={`group-${item.value}`}>
          <GroupSearchItem group={item as IGroup} handleSelect={handleClick} />
        </Combobox.Option>
      );
    case ItemTypes.ORGANIZATION:
      return (
        <Combobox.Option value={item.value} key={`organization-${item.value}`}>
          <OrganizationSearchItem organization={item as IOrganization} handleSelect={handleClick} />
        </Combobox.Option>
      );

    case ItemTypes.LIST_COLLECTION:
      return (
        <Combobox.Option value={item.value} key={`collection-${item.value}`}>
          <CollectionSearchItem handleSelect={handleClick} collection={item as UmGenericListCollection} />
        </Combobox.Option>
      );
    case ItemTypes.CATEGORY:
      return (
        <Combobox.Option value={item.value} key={`category-${item.value}`}>
          <CategorySelectedTabItem category={item as ICategory} handleSelect={handleClick} />
        </Combobox.Option>
      );

    default:
      return (
        <Combobox.Option value={item.value} key={item.value || item.label} disabled={disabled} onClick={handleClick}>
          {item.label}
        </Combobox.Option>
      );
  }
};

export const parseItem = (item: IItem | IFormMultiSelectOption | string): IFormMultiSelectOption => {
  if (!item) {
    return { value: '', label: '' };
  }

  if (typeof item === 'string') {
    return {
      label: item,
      value: item,
    };
  }

  if (item['@type'] === ItemTypes.USER) {
    const contact = item as IContact;
    return {
      ...contact,
      label: `${contact.first_name} ${contact.last_name}`,
      value: contact.id.toString(),
      itemType: ItemTypes.USER,
      type: 'user',
    };
  }

  if (item['$type'] === 'am.news') {
    const news = item as AmGenericNews;
    return {
      ...news,
      label: news.title,
      value: news.id?.toString() || '0',
      id: news.id || 0,
      itemType: ItemTypes.NEWS,
      type: 'news',
    };
  }

  if (isProduct(item as IProduct)) {
    return {
      ...(item as IProduct),
      label: (item as IProduct).full_title || '',
      value: item.id?.toString() || '0',
      itemType: ItemTypes.PRODUCT,
    };
  }

  let itemType: ItemTypes = ItemTypes.UNKNOWN;

  if ((item as UmGenericListCollection).type) {
    itemType = ItemTypes.LIST_COLLECTION;
  }

  if ((item as AmGenericAsset3).type?.startsWith('Asset3')) {
    return {
      ...item,
      label: (item as AmGenericAsset3).name || '',
      value: item.id?.toString() || '0',
      itemType: ItemTypes.ASSET,
    } as IFormMultiSelectOption;
  }
  if (item['@type']?.includes('asset')) {
    itemType = ItemTypes.ASSET;
  }
  if (item['@type']?.includes('group')) {
    itemType = ItemTypes.GROUP;
  }
  if (item['@type'] === 'organization') {
    itemType = ItemTypes.ORGANIZATION;
  }
  if (item['@type']?.includes('category')) {
    itemType = ItemTypes.CATEGORY;
  }
  if ((item as ISeparator).type === 'separator') {
    itemType = ItemTypes.SEPARATOR;
  }
  const value = (item.id || (item as IFormMultiSelectOption).value || 0).toString();
  return {
    ...item,
    label: (item as IFormMultiSelectOption).label || (item as IGroup).name || '',
    value,
    itemType,
    type: itemType,
  } as IFormMultiSelectOption;
};
