import React from 'react';

import { IContact } from 'types';

import { InfoBoxV2 } from 'components/info-box-v2';
import { Row, yn } from 'components/label-value-pair';
import { CustomContactSettingsRow } from 'pages/contact-details/contact-overview-tab/custom-contact-row';

export const SettingsSection: React.FC<IContact> = (contact) => {
  const { role_name, access_level, sso, mobile_sync, mfa_enabled, bouncing } = contact;
  return (
    <InfoBoxV2
      gridTemplateColumns="200px 1fr"
      wrapperClassName="py-3"
      className="entity-detail__infobox-wrapper"
      title="Settings"
      withBorder
    >
      <InfoBoxV2 gridTemplateColumns="200px 1fr" padded={false}>
        <Row label="Platform Role" value={role_name} size="m" />
        <Row label="Access Level" value={access_level} size="m" />
        <CustomContactSettingsRow contact={contact} />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="200px 1fr" padded={false}>
        <Row label="SSO Login Enabled" rawLabel value={yn(sso)} size="m" />
        <Row label="Mobile App Access" value={yn(mobile_sync)} size="m" />
        <Row label="MFA Enabled" value={yn(mfa_enabled)} size="m" />
        <Row label="Emails Bouncing" value={yn(bouncing)} size="m" />
      </InfoBoxV2>
    </InfoBoxV2>
  );
};
