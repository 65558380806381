import React from 'react';
import { Badge, Tabs } from '@mantine/core';
import cx from 'classnames';
import { IEntity, ItemId } from 'types';

export type IDataSectionSidebarTabs = {
  preview?: React.ReactElement;
  filters?: React.ReactElement;
  selectedItemRenderer?: (item: IEntity) => React.ReactElement;
};

export enum DataSectionSidebarTabs {
  PREVIEW = 'preview',
  SELECTED_ITEMS = 'selected-items',
  FILTERS = 'dynamic',
}

export const getDefaultTab = (active?: ItemId | null, tabs?: IDataSectionSidebarTabs): DataSectionSidebarTabs => {
  if (tabs?.preview && active) return DataSectionSidebarTabs.PREVIEW;
  return DataSectionSidebarTabs.FILTERS;
};

export const DataSectionSidebarTab: React.FC<{
  value: DataSectionSidebarTabs;
  activeTab: DataSectionSidebarTabs;
  disabled: boolean;
  title?: JSX.Element | string;
  hidden?: boolean;
  rightSectionText?: string;
}> = ({ value, activeTab, disabled, title, hidden, rightSectionText }) => {
  const tabTitle = title || value;
  return (
    <Tabs.Tab
      id={value}
      value={value}
      className={cx(`data-section-sidebar__tab`, {
        'data-section-sidebar__tab--active': activeTab === value,
      })}
      hidden={hidden}
      rightSection={
        rightSectionText === undefined ? undefined : (
          <Badge size="sm" variant="filled" radius="sm" className="data-section-sidebar__count">
            {rightSectionText}
          </Badge>
        )
      }
      disabled={disabled}
    >
      {tabTitle}
    </Tabs.Tab>
  );
};

export const DataSectionSidebarTabPanel: React.FC<{
  value: DataSectionSidebarTabs;
  content: JSX.Element;
}> = ({ value, content }) => {
  return (
    <Tabs.Panel value={value} className="data-section-sidebar__content-wrap">
      {content}
    </Tabs.Panel>
  );
};
