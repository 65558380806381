import React from 'react';
import { Link } from 'react-router-dom';
import { AmAmazonapacLocalizationOrderSeriesSeries } from '@mediafellows/mm3-types';
import { InfoBoxV2 } from 'components/info-box-v2';
import { Row } from 'components/label-value-pair';
import { DetailsPageTabs } from 'types';
import { formatDate } from 'utils/date';
import { formatFullName } from 'utils/general';

import './style.scss';

export const OrderDetailsOverview: React.FC<{
  order: AmAmazonapacLocalizationOrderSeriesSeries;
  assetsCount?: number;
}> = ({ order }) => {
  const { project_name, created_at, sent_at, launch_target, updated_at, product_id, recipient_list, status, owner } =
    order || {};

  return (
    <div className="delivery-details__overview-wrapper">
      <InfoBoxV2 gridTemplateColumns="2fr 3fr" useBackground padded={false} wrapperClassName="p-3 m-3">
        <Row label="Name" value={project_name} size="m" />
        <Row label="Status" value={status} size="m" />
        <Row label="Target at" value={formatDate(launch_target)} rawLabel />
        <Row label="Sent At" value={formatDate(sent_at)} rawLabel />
        <Row label="Sender" value={formatFullName(owner)} size="m" />
        <Row label="Created at" value={formatDate(created_at)} size="m" rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} size="m" rawLabel />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="2fr 3fr" title="About">
        <Row
          label="Product"
          value={<Link to={`../${DetailsPageTabs.PRODUCTS}`}>{[product_id]?.length}</Link>}
          size="m"
        />
        <Row
          label="Notification Recipients"
          value={<Link to={`../${DetailsPageTabs.RECIPIENTS}`}>{recipient_list?.length}</Link>}
          size="m"
        />
      </InfoBoxV2>
    </div>
  );
};
