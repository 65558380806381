import React, { useCallback } from 'react';
import { ProducerGenericReport } from '@mediafellows/mm3-types';
import pick from 'lodash/pick';

import { IUseAction } from 'utils/actions';
import { useDialogStore } from 'store/hooks';

import { IProducerPortalActionName } from './types';
import { useProducerPortalForm } from 'components/producer-portal/form';
import { useStore } from 'store';
import { delay } from 'utils/delay';
import { editProducerReport } from 'utils/apis/producer-portal';
import { ToastError } from 'components/toast';
import { shouldDisplaySingleNotDeleted } from 'utils/actions/common';
import { createPdfPreviewImage } from 'utils/apis/preview-image';

export const useEditAction: IUseAction<ProducerGenericReport, IProducerPortalActionName> = (items, options) => {
  const { close } = useDialogStore();
  const { toastStore } = useStore();

  const handleSubmit = useCallback(
    async (values: ProducerGenericReport & { localFileUrl?: Blob }): Promise<ProducerGenericReport | void> => {
      try {
        if (!values?.id) {
          return;
        }

        let newPreviewImageData = {};
        if (values.localFileUrl && values.source_url !== items[0].source_url) {
          const previewImage = await createPdfPreviewImage(values.localFileUrl);
          newPreviewImageData = { preview_image_id: previewImage?.id };
        }
        const result = await editProducerReport(items[0], {
          ...pick(values, ['id', 'name', 'date_to', 'date_from', 'source_url', 'type', 'producer_organization_id']),
          ...newPreviewImageData,
        });

        await delay();
        close();
        options?.onSuccess?.();
        toastStore.clearAll();
        toastStore.success(`Report updated successfully!`);
        return result;
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [close, options, toastStore, items],
  );
  const handler = useProducerPortalForm({
    title: 'Edit Report',
    report: items[0],
    handleSubmit,
  });

  return {
    name: IProducerPortalActionName.EDIT,
    icon: 'edit',
    title: 'Edit',
    shouldDisplay: shouldDisplaySingleNotDeleted,
    handler,
  };
};
