import React, { useCallback } from 'react';
import { Select } from '@mantine/core';
import ReactPaginate from 'react-paginate';

import { PaginationItem } from 'components/data-section/pagination/pagination-item';
import { ITablePaginationProps } from './types';
import { MantineIcon } from 'utils/ui';

const TablePagination: React.FC<ITablePaginationProps> = ({
  page,
  onPageChange,
  per,
  onPerChange,
  totalCount,
  totalPages,
}) => {
  const handlePageClick = useCallback(
    ({ selected }): void => {
      onPageChange(selected + 1);
    },
    [onPageChange],
  );

  const handlePerChange = useCallback(
    (newItemsPerPage) => {
      const newValue = Number.isNaN(Number(newItemsPerPage)) ? newItemsPerPage : Number(newItemsPerPage);
      onPerChange(newValue);
      handlePageClick({ selected: 0 });
    },
    [handlePageClick, onPerChange],
  );

  const goToPage = useCallback(
    (e) => {
      handlePageClick({ selected: e.target.tabIndex - 1 });
    },
    [handlePageClick],
  );
  const showLastPages = totalCount < 9999;

  return (
    <ul className="data-section__pagination-container">
      <PaginationItem page={page} goToPage={goToPage} tabIndex={1} label="First" />
      <ReactPaginate
        previousLabel={'Prev'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'data-section__pagination__dots mx-1'}
        pageCount={totalPages}
        marginPagesDisplayed={showLastPages ? 2 : 0}
        pageRangeDisplayed={5}
        forcePage={page - 1}
        onPageChange={handlePageClick}
        containerClassName={'data-section__pagination'}
        activeLinkClassName={'data-section__page-btn--active'}
        pageLinkClassName={`data-section__page-btn`}
        previousLinkClassName={`data-section__page-btn`}
        nextLinkClassName={`data-section__page-btn`}
      />
      {showLastPages && <PaginationItem page={page} goToPage={goToPage} tabIndex={totalPages} label="Last" />}
      <Select
        value={per.toString()}
        data={['100', '50', '25']}
        onChange={handlePerChange}
        rightSection={<MantineIcon icon={'double-caret-vertical'} />}
        className="data-section__pagination__selector"
        comboboxProps={{ dropdownPadding: 0 }}
        allowDeselect={false}
        withCheckIcon={false}
      />
    </ul>
  );
};

export default TablePagination;
