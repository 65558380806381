import React, { useCallback } from 'react';
import { Model } from 'helpers/filters/types';
import { useForm } from 'helpers/form';
import { ItemId, IGroupAddContact, ISmartGroup } from 'types';
import { useStore } from 'store';
import { initialValues } from 'components/smart-group/form-hook/initial-values';
import { observer } from 'mobx-react-lite';

import { SmartGroupWizard } from './smart-group-wizard';
import { ISmartGroupFormFields } from 'types/smart-group';
import { ToastError } from 'components/toast';
import { createSmartGroup, updateSmartGroup } from 'utils/apis/smart-group';

import { useCreateFiltersStores } from './hooks';

interface ISmartGroupContainerWizardContainerProps {
  productIds?: ItemId[];
  assetIds?: ItemId[];
  userIds?: ItemId[];
  userSelectionIds?: ItemId[];
  contacts?: IGroupAddContact[];
  onSuccess?: () => void;
  group?: ISmartGroup;
  fitInParent?: boolean;
}

const makePartialOptions = (ids, type: 'contact-selection' | 'contact'): IGroupAddContact[] =>
  ids.map((id) => ({ id, type }));

const customContext = {
  properties: {
    permissions: {
      validations: [{ value_inclusion: { in: ['download'], allow_blank: true } }],
    },
  },
};

export const SmartGroupWizardContainer: React.FC<ISmartGroupContainerWizardContainerProps> = observer(
  ({
    onSuccess,
    productIds = [],
    assetIds = [],
    userIds = [],
    contacts = [],
    userSelectionIds = [],
    group,
    fitInParent = false,
  }) => {
    const { toastStore } = useStore();

    const { productDataSectionStore, assetDataSectionStore, contactDataSectionStore } = useCreateFiltersStores({
      group,
    });

    const handleSubmit = useCallback(
      async ({
        product_selection_method,
        asset_selection_method,
        contact_selection_method,
        product_filters,
        product_ids,
        asset_ids,
        asset_filters,
        contact_ids,
        user_filters,
        ...values
      }) => {
        try {
          toastStore.info('Saving...');
          const submit = values.id ? updateSmartGroup : createSmartGroup;

          await submit({
            ...values,
            product_selection_method,
            asset_selection_method,
            contact_selection_method,
            product_ids: product_selection_method === 'static' ? product_ids : undefined,
            asset_ids: asset_selection_method === 'static' ? asset_ids : undefined,
            contact_ids: contact_selection_method === 'static' ? contact_ids : undefined,
            product_filters: product_selection_method === 'dynamic' ? productDataSectionStore.searchStore?.filters : [],
            asset_filters: asset_selection_method === 'dynamic' ? assetDataSectionStore.searchStore?.filters : [],
            user_filters: contact_selection_method === 'dynamic' ? contactDataSectionStore.searchStore?.filters : [],
          });
          toastStore.clearAll();
          toastStore.success(`Smart Group was ${values.id ? 'updated' : 'created'} successfully`);
          onSuccess?.();
        } catch (error) {
          toastStore.error(<ToastError error={error} />);
        }
      },
      [assetDataSectionStore, contactDataSectionStore, onSuccess, productDataSectionStore, toastStore],
    );

    const form = useForm<ISmartGroupFormFields>(
      {
        ...initialValues,
        product_ids: productIds,
        asset_ids: assetIds,
        contact_ids: userIds,
        contacts: [
          ...contacts,
          ...makePartialOptions(userIds, 'contact'),
          ...makePartialOptions(userSelectionIds, 'contact-selection'),
        ],
        ...(group?.id
          ? {
              ...group,
              product_selection_method: group.product_filters?.length ? 'dynamic' : 'static',
              asset_selection_method: group.asset_filters?.length ? 'dynamic' : 'static',
              contact_selection_method: group.user_filters?.length ? 'dynamic' : 'static',
            }
          : {}),
      },
      Model.SMART_GROUP,
      handleSubmit,
      customContext,
    );

    return (
      <SmartGroupWizard
        contactDataSectionStore={contactDataSectionStore}
        productDataSectionStore={productDataSectionStore}
        assetDataSectionStore={assetDataSectionStore}
        form={form}
        fitInParent={fitInParent}
      />
    );
  },
);
