import React from 'react';
import { IProduct, IProductAsset, ViewMode } from 'types';

import { default as ProductOverviewTab } from 'pages/product-details/product-overview-tab/product-overview-tab';
import { ProductOverviewTabEdit } from 'pages/product-details/product-overview-tab/product-overview-edit-tab';

import { ScrollWrapper } from 'components/scroll-wrapper';

interface IProductMetadataTabModeProps {
  mode: ViewMode;
  product: IProduct;
  setProduct: (product: IProduct) => void;
  setMode: (view: ViewMode) => void;
  assets?: IProductAsset[];
}

export const ProductOverviewTabMode: React.FC<IProductMetadataTabModeProps> = ({ mode, ...props }) => {
  const Component = mode === ViewMode.EDIT ? ProductOverviewTabEdit : ProductOverviewTab;

  return (
    <ScrollWrapper>
      <Component {...props} />
    </ScrollWrapper>
  );
};
