import React from 'react';
import { observer } from 'mobx-react-lite';

import { getEntityLabel, pluralWordInflect } from 'utils/general';
import { Classes } from 'utils/ui';
import { ISelectionStandardized } from 'types';
import { useMainAssetCountInGroup } from 'utils/hooks/asset';

import './style.scss';

const SelectionSelectedTabItem: React.FC<{ selection: ISelectionStandardized }> = observer(({ selection }) => {
  const { name, entityCount, main_entity_type } = selection;
  const [assetCount] = useMainAssetCountInGroup(selection.id);

  return (
    <div>
      <div className="selections-selected-tab-item__content">
        <div className={`text-truncate`}>{name || '\u00a0'}</div>
        <div className={`text-truncate ${Classes.TEXT_MUTED} mb-1`}>
          {pluralWordInflect(getEntityLabel(main_entity_type), assetCount ?? entityCount)}
        </div>
      </div>
    </div>
  );
});

export default SelectionSelectedTabItem;
