import {
  IAddress,
  IGeoScope,
  IImContact,
  IPhone,
  IPreviewImage,
  ISocialMediaProfile,
  IUserRole,
  UserStatus,
} from './index';
import { ItemId } from './utility';

export interface IContact {
  '@type': string;
  access_level?: string;
  activated_at: string;
  address?: IAddress;
  analytics_playback_attachment_all: boolean;
  analytics_subscription: boolean;
  assistant_ids: ItemId[];
  bouncing: boolean;
  company_profile: number[];
  country_id: string;
  country_name?: string;
  created_at: string;
  customer_segment: string;
  date_of_birth: string;
  deleted_at?: string;
  department: string;
  division_ids?: number[] | null;
  email: string;
  first_name: string;
  full_name?: string;
  function: string;
  gender: string;
  geo_scopes?: IGeoScope[];
  id: string;
  im_contacts: IImContact[];
  interests: number[];
  last_login_at: string;
  last_name: string;
  mfa_enabled: boolean;
  middle_name: string;
  mobile_sync: boolean;
  notes: string;
  organization_id: string;
  phones: IPhone[];
  preview_image: IPreviewImage;
  preview_image_id: number | null;
  responsibility: string;
  responsible_user_id: ItemId;
  responsible_user: IContact;
  role_name: string;
  role?: IUserRole;
  role_id: ItemId;
  role_type?: string;
  skip_marketing_emails: boolean;
  social_media_profiles: ISocialMediaProfile[];
  status: UserStatus;
  supervisor_analytics_cc: string;
  supervisor_analytics_playback_attachment: boolean;
  supervisor_analytics_playback_attachment_all: boolean;
  supervisor_analytics_subscription: boolean;
  tags: string[];
  title: string;
  genre_preferences_ids?: number[];
  geo_scope_ids?: ItemId[];
  organization_name?: string;
  sso?: boolean;
  territory_rep?: string;

  notification_preferences?: {
    id?: number;
    email_channel: boolean;
    mobile_channel: boolean;
    sms_channel: boolean;
  };
}

export const notificationPreferences = {
  id: undefined,
  email_channel: false,
  mobile_channel: false,
  sms_channel: false,
};

export interface INewenContact extends IContact {
  newsletter_category?: string[];
  target_market?: 'FRANCO' | 'INTER';
  salutation?: string;
  function_sf?: string;
}

export interface ILgtContact extends IContact {
  disable_watermark?: boolean;
}

export interface ITiffContact extends IContact {
  id_number: string;
  plain_password: string;
  section: string;
  pass_category_a: string;
  pass_category_b: string;
  language_id: string;
}
