import React, { useCallback } from 'react';
import { Button } from '@mantine/core';
import { FormCheckbox, FormDate, FormInput, FormMultiSelect, FormProduct, FormSelect } from 'helpers/form';
import { InfoBoxV2 } from 'components/info-box-v2';
import { maxDate } from 'components/product-edit-sections/utils';
import { today } from 'utils/date';

import { downloadTemplate } from 'utils/apis/localization-order';
import { ILocalizationOrderStep } from 'components/localization-orders/types';

import '../style.scss';

export const OrderItemsStep: React.FC<ILocalizationOrderStep> = ({ orderForm: { formData, handlers } }) => {
  const downloadOrderTemplate = useCallback(async () => {
    await downloadTemplate();
  }, []);

  const uploadTemplate = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('Upload Template');
  }, []);

  const handleChange = ({ trailer }: { trailer: boolean }): void => {
    handlers.onChange({ trailer: trailer ? 'Y' : 'N' });
  };

  return (
    <div className="localization-order-project__details h-100">
      <div className="project-action-details">
        <div className="project-selected">
          <FormInput name="project_name" label="Project Name" {...formData.project_name} {...handlers} disabled />
          <FormProduct
            name="product_ids"
            label="Title Selected"
            large
            {...formData.product_id}
            {...handlers}
            disabled
          />
        </div>
        <div className="action-buttons">
          <Button variant="primary" size="xs" onClick={uploadTemplate}>
            Upload Template
          </Button>
          <Button variant="primary" size="xs" onClick={downloadOrderTemplate}>
            Download Template
          </Button>
        </div>
      </div>

      <InfoBoxV2 gridTemplateColumns="repeat(3, 1fr)" title="PO Information">
        <FormDate
          name="launch_target_string"
          label="Launch Target"
          {...formData.launch_target_string}
          {...handlers}
          emptyValue={null}
          large
          minDate={today}
          maxDate={maxDate}
          withTime
        />
        <FormSelect name="launch_territory" label="Launch Territory" {...formData.launch_territory} {...handlers} />
        <FormSelect
          name="date_to_posthouse"
          label="Delivery Date to Posthouse"
          {...formData.date_to_posthouse}
          {...handlers}
        />
        <FormSelect name="catalog_count" label="Catalog Count" {...formData.catalog_count} {...handlers} />
        <FormInput name="release_cycle" label="Release Cycle" {...formData.release_cycle} {...handlers} />
        <FormSelect name="partener_alias" label="Partner Alias" {...formData.partener_alias} {...handlers} />
        <FormSelect
          name="posthouse_billing"
          label="AV Posthouse Billing"
          {...formData.posthouse_billing}
          {...handlers}
        />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="1fr 1fr 4fr 3fr" title="Deliverables" className="order-product-data">
        <FormSelect name="resolution" label="Resolution" {...formData.resolution} {...handlers} />
        <FormCheckbox
          name="trailer"
          label="Trailer Availability"
          {...formData.trailer}
          {...handlers}
          onChange={handleChange}
          value={formData.trailer.value === 'Y'}
        />
        <div>
          <FormMultiSelect name="subtitles" label="Subtitles" {...formData.subtitles} {...handlers} />
          <FormMultiSelect name="dubbing" label="Dubbing" {...formData.dubbing} {...handlers} />
          <FormMultiSelect name="metadata" label="Metadata" {...formData.metadata} {...handlers} />
          <FormMultiSelect name="artwork_season" label="Artwork Season" {...formData.artwork_season} {...handlers} />
          <FormMultiSelect name="artwork_episode" label="Artwork Episode" {...formData.artwork_episode} {...handlers} />
          <FormSelect
            name="localization_artwork"
            label="Localization Artwork"
            {...formData.localization_artwork}
            {...handlers}
            required
          />
        </div>
        <div>
          <FormInput
            name="partner_deliverables"
            label="Partner Deliverables"
            {...formData.partner_deliverables}
            {...handlers}
          />
          <FormInput name="notes" label="Notes" {...formData.notes} {...handlers} textarea />
        </div>
      </InfoBoxV2>
    </div>
  );
};
