import React, { useCallback } from 'react';
import { Row } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';
import { useRemote } from 'utils/hooks';
import { queryProducts } from 'utils/apis/product';
import { ItemId } from 'types';

import { ILocalizationOrderStep } from 'components/localization-orders/types';
import { loadContact } from 'pages/contact-details/api';

import '../style.scss';

export const OrderSummaryStep: React.FC<ILocalizationOrderStep> = ({ orderForm: { values } }) => {
  const {
    project_name,
    launch_target,
    vendor,
    product_id,
    organization_name,
    order_template,
    vendor_order_ref,
    vendor_project_manager_id,
    organization_order_ref,
    organization_project_manager_id,
    subtitles,
    dubbing,
    metadata,
    artwork_season,
    artwork_episode,
    localization_artwork,
  } = values;

  const fetchProduct = useCallback(async () => {
    const products = await queryProducts({ ids: [product_id as ItemId] });
    return products;
  }, [product_id]);
  const [products] = useRemote(fetchProduct);

  const fetchContact = useCallback((id) => {
    return loadContact(id.toString() || '', 'id, first_name, last_name');
  }, []);

  const fetchVendonContact = useCallback(
    () => fetchContact(vendor_project_manager_id),
    [vendor_project_manager_id, fetchContact],
  );
  const fetchOrganizationContact = useCallback(
    () => fetchContact(organization_project_manager_id),
    [organization_project_manager_id, fetchContact],
  );

  const [vendor_project_manager] = useRemote(fetchVendonContact);
  const [organization_project_manager] = useRemote(fetchOrganizationContact);

  return (
    <div className="localization-order-summary h-100">
      <InfoBoxV2 title="Order Details" gridTemplateColumns="repeat(4, 1fr)" className="order-sumary__data" padded>
        <Row label="Project Name" value={project_name} />
        <Row label="Client Due Date" value={launch_target} />
        <Row label="Vendor" value={vendor} />
        <Row label="Organization" value={organization_name} />
        <Row label="Organization Order Template" value={order_template} />
        <Row label="Title Selected" value={products?.[0]?.title} />
        <Row label="Vendor Order Ref" value={vendor_order_ref} />
        <Row label="Organization Order Ref" value={organization_order_ref} />
        <Row
          label="Vendor Project Manager"
          value={vendor_project_manager?.first_name + ' ' + vendor_project_manager?.last_name}
        />
        <Row
          label="Organization Project Manager"
          value={organization_project_manager?.first_name + ' ' + organization_project_manager?.last_name}
        />
      </InfoBoxV2>

      <InfoBoxV2 title="Deliverables" gridTemplateColumns="repeat(4, 1fr)" className="deliverables-sumary__data" padded>
        <Row label="Metadata" value={metadata} />
        <Row label="Subtitles" value={subtitles} />
        <Row label="Dubbing" value={dubbing} />
        <Row label="Artwork Season" value={artwork_season} />
        <Row label="Artwork Episode" value={artwork_episode} />
        <Row label="Localization Artwork" value={localization_artwork} />
      </InfoBoxV2>
    </div>
  );
};
