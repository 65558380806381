import { ISmartGroupFormFields } from 'types/smart-group';

export const initialValues: ISmartGroupFormFields = {
  access_level: 'viewable',
  asset_ids: [],
  asset_selection_method: 'static',
  contact_ids: [],
  contact_selection_method: 'static',
  contacts: [],
  delegates_access: true,
  email_message: '',
  email_subject: '',
  expires_at: '',
  include_descendants: true,
  include_future_descendants: true,
  name: '',
  organization_ids: [],
  permissions: [],
  permit_download: false,
  product_ids: [],
  product_selection_method: 'static',
};
