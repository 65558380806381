import React from 'react';

import { IAsset, IMm3Asset } from 'types';

import { IAssetFormFields } from 'components/asset/asset-edit-form/sections';

import { AssetMetadataMediaInfoSection } from 'pages/asset-details/asset-metadata-tab/sections/media-info';
import { AssetMetadataEditIdentifiersSection } from 'components/asset/edit-sections/identifiers';
import { AssetMetadataEditCreditsSection } from 'components/asset/edit-sections/credits';
import { AssetMetadataTimestamps } from 'pages/asset-details/asset-metadata-tab/sections/timestamps';
import { AssetMetadataEditSettingsSection } from 'components/asset/edit-sections/settings';
import { AssetMetadataDocumentDataSection } from 'pages/asset-details/asset-metadata-tab/sections/document-data';
import { AssetMetadataSpecificationEditSection } from 'components/asset/edit-sections/specification';
import { AssetMetadataAudioEditSection } from 'components/asset/edit-sections/audio';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IUseFormReturn } from 'helpers/form';

import { AssetMetadataAboutEditSection } from 'components/asset/edit-sections/about';
import 'pages/asset-details/asset-metadata-tab/style.scss';

interface IAssetMetadataEditTabProps {
  asset?: IAsset | IMm3Asset;
  form: IUseFormReturn<IAssetFormFields> | IUseMm3FormReturn<IMm3Asset>;
}

export const AssetMetadataEditTab: React.FC<IAssetMetadataEditTabProps> = ({ asset, form }) => {
  return (
    <div className="asset-metadata-tab h-100">
      <div className="asset-metadata-tab__infoboxes">
        <AssetMetadataSpecificationEditSection
          asset={asset as IAsset}
          form={form as IUseFormReturn<IAssetFormFields>}
        />

        <AssetMetadataAboutEditSection asset={asset as IAsset} form={form as IUseFormReturn<IAssetFormFields>} />

        <AssetMetadataDocumentDataSection asset={asset as IAsset} />

        <AssetMetadataEditSettingsSection asset={asset as IAsset} form={form as IUseFormReturn<IAssetFormFields>} />

        <AssetMetadataTimestamps asset={asset as IAsset} />

        <AssetMetadataEditCreditsSection asset={asset as IAsset} form={form as IUseFormReturn<IAssetFormFields>} />

        <AssetMetadataEditIdentifiersSection asset={asset as IAsset} form={form as IUseFormReturn<IAssetFormFields>} />

        <AssetMetadataMediaInfoSection asset={asset as IAsset} />

        <AssetMetadataAudioEditSection asset={asset as IMm3Asset} form={form as IUseMm3FormReturn<IMm3Asset>} />
      </div>
    </div>
  );
};

export default AssetMetadataEditTab;
