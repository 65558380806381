import React, { useCallback } from 'react';

import { DataTable } from 'components/list-table/data-table';
import { EmptySectionMessage } from 'components/section-message';

import { IAsset } from 'types';
import { getAssetArtifacts } from 'utils/apis/asset';
import { useRemote } from 'utils/hooks';
import { Loading } from 'components/loading';
import { columns } from './utils';

import './style.scss';

interface IAssetArtifactsTabProps {
  asset: IAsset;
}

export const AssetArtifactsTab: React.FC<IAssetArtifactsTabProps> = ({ asset }) => {
  const { id } = asset || {};

  const fetchArtifacts = useCallback(() => getAssetArtifacts(id), [id]);

  const [artifacts = [], loading] = useRemote(fetchArtifacts);

  if (loading) {
    return <Loading text="Loading..." />;
  }

  if (!artifacts.length) {
    return <EmptySectionMessage />;
  }

  return (
    <div className="asset-metadata-tab h-100">
      <DataTable data={artifacts} columns={columns} tableClassName="asset-audio__table" />
    </div>
  );
};
