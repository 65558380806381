import { UmGenericListCollection, UmGenericListShowcase } from '@mediafellows/mm3-types';
import { IResult } from '@mediafellows/chipmunk';
import omit from 'lodash/omit';

import { logger } from 'utils/logger';
import { parseToSearchParams } from 'utils/general';
import { chipmunk, tuco } from 'utils/chipmunk';
import { Model, ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { IFile, ISearchParams, ItemId, IListItem, IWithRequired, IQueryParams, ISearchFilter } from 'types';
import {
  assetListSchema,
  contactListSchema,
  EventSchema,
  categoryListSchema,
  groupListSchema,
  organizationListSchema,
  productListSchema,
  collectionSchema,
} from 'utils/schemas';
import { isSeparator } from 'utils/list';
import { ISortableItem } from 'store/sort-data-store';
import { IObject, IPagination } from '@mediafellows/chipmunk/dist/src/action';
import { newsListSchema } from 'utils/schemas/news';
import { ingestPreviewImage } from './preview-image';

export const fetchLists = async (body: ISearchParams, schema = collectionSchema): Promise<UmGenericListShowcase[]> => {
  const { objects } = await chipmunk.run(({ action }) => {
    return action(Model.SHOWCASES, 'search', { body, schema });
  });

  return objects;
};

export const fetchAllLists = async (
  body?: ISearchParams,
  schema = collectionSchema,
): Promise<UmGenericListShowcase[]> => {
  return chipmunk.run(async ({ unfurl }) => {
    const { objects } = await unfurl(Model.COLLECTIONS, 'search', { body, schema });
    return objects;
  });
};

export const searchLists = async (
  params: IQueryParams,
  additionalFilters: ISearchFilter[] = [],
  schema = collectionSchema,
): Promise<IResult<UmGenericListShowcase>> => {
  return chipmunk.run(({ action }) => {
    return action<UmGenericListShowcase>(Model.SHOWCASES, 'search', {
      body: parseToSearchParams(params, additionalFilters),
      schema,
    });
  });
};

export const getListsCount = async (
  params: IQueryParams,
  additionalFilters: ISearchFilter[] = [],
  schema = collectionSchema,
): Promise<IPagination | undefined> => {
  const { pagination } = await searchLists({ ...params, per: 1 }, additionalFilters, schema);

  return pagination;
};

export const getLists = async <T extends UmGenericListShowcase | UmGenericListCollection>(
  ids?: ItemId[],
  schema = collectionSchema,
): Promise<T[]> => {
  if (!ids?.length) {
    return [];
  }

  const { objects } = await chipmunk.run(({ action }) => {
    return action(Model.SHOWCASES, 'get', { params: { lists_ids: ids }, schema });
  });

  return objects;
};

export const getList = async <T extends UmGenericListShowcase | UmGenericListCollection>(
  id?: ItemId,
  schema = collectionSchema,
): Promise<T | undefined> => {
  if (!id) {
    return;
  }

  const [list] = await getLists<T>([id], schema);

  return list;
};

interface IFetchListsItemsParams {
  per?: number;
  page?: number;
  assetSchema?: string;
  productSchema?: string;
  userSchema?: string;
  organizationSchema?: string;
  groupEventSchema?: string;
  groupSelectionSchema?: string;
  categorySchema?: string;
  listIds: number[];
  loadProductAncestry?: boolean;
}

interface IFetchListItemsResponse {
  objects: IListItem[];
  pagination: { total_count: number };
}

export const fetchListItems = async (opts: IFetchListsItemsParams): Promise<IFetchListItemsResponse> => {
  if (!opts?.listIds) {
    return { objects: [], pagination: { total_count: 0 } } as IFetchListItemsResponse;
  }

  const objects: IObject[] = [];

  const data = await tuco('getListItems', {
    per: 100,
    page: 1,
    assetSchema: assetListSchema,
    productSchema: productListSchema,
    userSchema: contactListSchema,
    organizationSchema: organizationListSchema,
    groupEventSchema: EventSchema,
    groupSelectionSchema: groupListSchema,
    categorySchema: categoryListSchema,
    newsSchema: newsListSchema,
    collectionSchema,
    ...opts,
  });

  objects.push(...(data?.objects || []));

  const pages = data?.pagination?.total_pages;

  if (pages && pages > 1 && !opts.per) {
    const extraDataPromises: Promise<IResult<IObject>>[] = [];
    for (let i = 1; i < pages; i++) {
      const extraDataPromise = tuco('getListItems', {
        per: 100,
        page: i + 1,
        assetSchema: assetListSchema,
        productSchema: productListSchema,
        userSchema: contactListSchema,
        organizationSchema: organizationListSchema,
        groupEventSchema: EventSchema,
        groupSelectionSchema: groupListSchema,
        categorySchema: categoryListSchema,
        newsSchema: newsListSchema,
        collectionSchema,
        ...opts,
      });
      extraDataPromises.push(extraDataPromise);
    }
    const extraData = await Promise.all(extraDataPromises);
    extraData.map((e) => {
      objects.push(...e?.objects);
    });
  }

  const { pagination = { total_count: 0 } } = data;

  return {
    objects: objects.filter((item: IListItem) => item.entity || isSeparator(item)),
    pagination,
  } as IFetchListItemsResponse;
};

export const updateList = (
  list: Pick<UmGenericListShowcase, 'id'> & Partial<UmGenericListShowcase>,
  schema = collectionSchema,
): Promise<IResult<UmGenericListShowcase>> => {
  return chipmunk.run(({ action }) => {
    const body = omit(list, ['listItems', 'itemsCount', 'owner_organization', 'owner']);
    return action<UmGenericListShowcase>(Model.SHOWCASES, 'update', {
      params: { lists_ids: list.id },
      body,
      schema,
    });
  });
};

export async function updateCollection<T extends UmGenericListShowcase | UmGenericListCollection>(
  list: Partial<T>,
  schema = collectionSchema,
): Promise<IResult<T> | void> {
  if (!list?.id) {
    return;
  }

  return chipmunk.run(({ action }) => {
    const body = omit(list, ['listItems', 'itemsCount', 'owner_organization', 'owner']);
    return action<T>(Model.COLLECTIONS, 'update', {
      params: { lists_ids: list.id },
      body,
      schema,
    });
  });
}

export const deleteList = async (
  ids: ItemId | ItemId[],
): Promise<IResult<UmGenericListShowcase | UmGenericListCollection>> => {
  return chipmunk.run(async ({ action }) =>
    action(Model.SHOWCASES, 'delete', {
      params: { lists_ids: ids },
    }),
  );
};

export const createList = async (
  list: UmGenericListShowcase | UmGenericListCollection,
): Promise<IWithRequired<UmGenericListShowcase, 'id'>> => {
  const page = (list as UmGenericListShowcase).meta?.page;
  const type = list.type;
  const model = type === 'List::Collection' ? Model.COLLECTIONS : Model.SHOWCASES;
  const { object: firstItem } = await searchLists(
    { per: 1, sort: ParametronSort.SEQUENCE_NUMBER, order: ParametronOrder.ASCENDING },
    [type === 'List::Collection' ? ['type', 'eq', type] : ['meta.page', 'eq', page || '']],
  );
  const sequenceNumber = firstItem?.id ? (firstItem.sequence_number || 0) - 1 : 1;

  const { object } = await chipmunk.run(async ({ action }) =>
    action(model, 'create', {
      body: { ...list, sequence_number: sequenceNumber },
    }),
  );

  return object;
};

export const uploadPreviewImage = async (
  file: Pick<IFile, 'url'>,
  collection: UmGenericListShowcase | UmGenericListCollection,
  schema = collectionSchema,
): Promise<IResult | null> => {
  if (!file || !file.url) {
    logger.error('No file or file.url provided to uploadPreviewImage');
    return null;
  }
  const object = await ingestPreviewImage(file.url, collection?.preview_image_id);

  return updateList({ id: collection.id, preview_image_id: object?.id }, schema);
};

export const removeCollectionPreviewImage = async (
  collection: UmGenericListCollection,
  schema = collectionSchema,
): Promise<UmGenericListCollection> => {
  return chipmunk.run(async ({ action }) => {
    const updatedCollection = await updateList({ id: collection.id, preview_image_id: null }, schema);

    await action(Model.PREVIEW_IMAGE, 'member.delete', {
      params: {
        id: collection.id,
        preview_image_id: collection.preview_image_id,
      },
    });
    return updatedCollection.object;
  });
};

export const sortShowcases = (items: ISortableItem[]): Promise<unknown[]> => {
  const lists_ids = items.map(({ id }) => id);
  const result = chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.LISTS, 'update', {
      params: { lists_ids },
      body: items.map((item, sequence_number) => ({ id: item.id, sequence_number })),
    });
    return objects;
  });
  return result;
};

export const getShowcaseByPurpose = async (purpose: string): Promise<UmGenericListShowcase | null | undefined> => {
  const { object } = await searchLists({}, [
    ['purpose', 'eq', purpose],
    ['meta.page', 'eq', 'configuration'],
  ]);
  return object;
};
