import React from 'react';

import { useSmartGroupActions } from 'utils/actions/smart-group';
import { IUseActionsOptionParam } from 'utils/actions';

import { ISmartGroup } from 'types';
import { renderMenuActionItems } from 'utils/ui';

import { IItemActionsMenuProps } from './utils';

export interface ISmartGroupActionsListProps extends IItemActionsMenuProps<ISmartGroup> {
  options: IUseActionsOptionParam<ISmartGroup>;
}

export const SmartGroupActionsList: React.FC<ISmartGroupActionsListProps> = (props) => {
  const { items, options } = props;

  const actions = useSmartGroupActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};
