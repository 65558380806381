import React, { useCallback } from 'react';
import { isEqual } from 'lodash';

import { IScrollableTab } from 'components/scrollable-tabs/scrollable-tabs';
import { AssetMetadataTab, AssetMetadataEditTab } from 'pages/asset-details/asset-metadata-tab';
import { AssetGeneralTab } from './asset-general-tab';
import { AssetAnalyticsTab } from './asset-analytics-tab';
import { AssetProductsTab } from './asset-products-tab';
import { AssetTimelineTab } from './asset-timeline-tab';
import { AssetWorkflowTab } from './asset-workflow-tab';
import { AssetOperationsTab } from './asset-operation-tab/asset-operation-tab';
import { AssetArtifactsTab } from './asset-artifacts-tab';

import { isAssetIngesting } from 'utils/asset';
import { refreshPreviewIngestingDelay } from 'utils/constants';
import { useInterval } from 'utils/hooks/use-interval';
import { getIsTabDisabled } from 'utils/general';
import { flags } from 'utils/flags';

import { IUseFormReturn } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IAssetFormFields } from 'components/asset/asset-edit-form/sections/types';
import { DetailsPageTabs, IAsset, IAssetAggregation, IMm3Asset, IMm3AssetType } from 'types';

export const useRefreshIngestingAsset = (
  asset: IAsset | IMm3Asset | undefined,
  fetchAsset: () => Promise<IAsset | IMm3Asset>,
  setAsset: React.Dispatch<React.SetStateAction<IAsset | IMm3Asset>>,
): void => {
  const refresh = useCallback(
    async (id: number): Promise<void> => {
      if (!asset) return;
      if (!isAssetIngesting(asset)) {
        return clearInterval(id);
      }
      const newValue = await fetchAsset();
      setAsset((prev) => {
        return isEqual(prev, newValue) ? prev : newValue;
      });
    },
    [fetchAsset, setAsset, asset],
  );

  useInterval(refresh, refreshPreviewIngestingDelay);
};

export const tabOptions = [
  DetailsPageTabs.OVERVIEW,
  DetailsPageTabs.METADATA,
  DetailsPageTabs.ANALYTICS,
  DetailsPageTabs.SUB_ASSETS,
  DetailsPageTabs.PRODUCTS,
  DetailsPageTabs.TIMELINE,
  DetailsPageTabs.WORKFLOW,
  DetailsPageTabs.NEWS,
  DetailsPageTabs.SUBTITLES,
  DetailsPageTabs.REPORTS,
  DetailsPageTabs.ARTIFACTS,
  DetailsPageTabs.AUDIO_TRACKS,
  DetailsPageTabs.OPERATIONS,
];

export const editableTabs = [DetailsPageTabs.OVERVIEW, DetailsPageTabs.METADATA, DetailsPageTabs.NEWS];

export const getTabsList = ({
  asset,
  editModeEnabled,
  subAssetsAggregations,
  handleTabChange,
  setAsset,
  editForm,
  isVideo,
  getChildTab,
}: {
  asset: IAsset;
  editModeEnabled: boolean;
  subAssetsAggregations?: IAssetAggregation<IMm3AssetType>;
  handleTabChange: (tab: string) => void;
  setAsset: React.Dispatch<React.SetStateAction<IAsset>>;
  editForm: IUseFormReturn<IAssetFormFields> | IUseMm3FormReturn<IMm3Asset>;
  isVideo: boolean;
  getChildTab: (tab: DetailsPageTabs) => JSX.Element;
}): IScrollableTab[] => [
  {
    id: DetailsPageTabs.OVERVIEW,
    panel: (
      <AssetGeneralTab
        asset={asset}
        subAssetsAggregations={subAssetsAggregations}
        setActiveTab={handleTabChange}
        setAsset={setAsset}
        form={editForm}
        editModeEnabled={editModeEnabled}
      />
    ),
    isVisible: true,
    disabled: getIsTabDisabled(DetailsPageTabs.OVERVIEW, editableTabs, editModeEnabled),
  },
  {
    id: DetailsPageTabs.ANALYTICS,
    panel: <AssetAnalyticsTab />,
    isVisible: isVideo,
    disabled: getIsTabDisabled(DetailsPageTabs.ANALYTICS, editableTabs, editModeEnabled),
  },
  {
    id: DetailsPageTabs.METADATA,
    panel: editModeEnabled ? (
      <AssetMetadataEditTab asset={asset} form={editForm} />
    ) : (
      <AssetMetadataTab asset={asset} />
    ),
    isVisible: true,
    disabled: getIsTabDisabled(DetailsPageTabs.METADATA, editableTabs, editModeEnabled),
  },
  {
    id: DetailsPageTabs.SUBTITLES,
    panel: getChildTab(DetailsPageTabs.SUBTITLES),
    isVisible: isVideo,
    disabled: getIsTabDisabled(DetailsPageTabs.SUBTITLES, editableTabs, editModeEnabled),
  },
  {
    id: DetailsPageTabs.AUDIO_TRACKS,
    panel: getChildTab(DetailsPageTabs.AUDIO_TRACKS),
    isVisible: isVideo,
    disabled: getIsTabDisabled(DetailsPageTabs.AUDIO_TRACKS, editableTabs, editModeEnabled),
    formatTitle: () => 'Audios',
  },
  {
    id: DetailsPageTabs.REPORTS,
    panel: getChildTab(DetailsPageTabs.REPORTS),
    isVisible: flags.isMm3Assets && !flags.hasCustomClassifications,
    disabled: getIsTabDisabled(DetailsPageTabs.REPORTS, editableTabs, editModeEnabled),
    formatTitle: () => 'Reports',
  },
  {
    id: DetailsPageTabs.SUB_ASSETS,
    panel: getChildTab(DetailsPageTabs.SUB_ASSETS),
    isVisible: flags.isMm3Assets,
    disabled: getIsTabDisabled(DetailsPageTabs.SUB_ASSETS, editableTabs, editModeEnabled),
    formatTitle: () => 'Ancillary Assets',
  },
  {
    id: DetailsPageTabs.ARTIFACTS,
    panel: <AssetArtifactsTab asset={asset} />,
    isVisible: isVideo,
    disabled: getIsTabDisabled(DetailsPageTabs.ARTIFACTS, editableTabs, editModeEnabled),
  },
  {
    id: DetailsPageTabs.PRODUCTS,
    panel: <AssetProductsTab />,
    isVisible: true,
    disabled: getIsTabDisabled(DetailsPageTabs.PRODUCTS, editableTabs, editModeEnabled),
  },
  {
    id: DetailsPageTabs.TIMELINE,
    panel: <AssetTimelineTab />,
    isVisible: true,
    disabled: getIsTabDisabled(DetailsPageTabs.TIMELINE, editableTabs, editModeEnabled),
  },
  {
    id: DetailsPageTabs.WORKFLOW,
    panel: <AssetWorkflowTab asset={asset} />,
    isVisible: !flags.isMm3Assets,
    disabled: getIsTabDisabled(DetailsPageTabs.WORKFLOW, editableTabs, editModeEnabled),
  },
  {
    id: DetailsPageTabs.OPERATIONS,
    panel: <AssetOperationsTab asset={asset} />,
    isVisible: flags.isMm3Assets,
    disabled: getIsTabDisabled(DetailsPageTabs.OPERATIONS, editableTabs, editModeEnabled),
  },
];
