import { IDivisionFormData } from 'components/divisions/division-form/division-form';
import { Model } from 'helpers/filters/types';
import { IDivision, ItemId } from 'types';
import { chipmunk } from 'utils/chipmunk';
import { divisionListSchema } from 'utils/schemas';

export function getDivisions(division_ids: number[] = []): Promise<IDivision[]> {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(Model.DIVISIONS, 'get', { params: { division_ids } });
    return objects;
  });
}

export const createDivision = (body: IDivisionFormData): Promise<IDivision> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action<IDivision>(Model.DIVISIONS, 'create', {
      body,
      schema: divisionListSchema,
    });
    return objects?.[0];
  });
};

export const updateDivision = (division: Partial<IDivision>): Promise<IDivision> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action<IDivision>(Model.DIVISIONS, 'member.update', {
      params: { division_id: division.id },
      body: division,
    });

    return object;
  });
};

export const deleteDivision = (division_ids: ItemId[]): Promise<IDivision> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action<IDivision>(Model.DIVISIONS, 'delete', {
      params: { division_ids },
    });
    return object;
  });
};
