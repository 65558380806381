import React, { useCallback } from 'react';
import { PmGenericCastCrew } from '@mediafellows/mm3-types';
import { observer } from 'mobx-react';

import { FormSubmitSection } from 'components/form-submit-section';
import { Model } from 'helpers/filters/types';
import { FormInput, FormMultiSelect, FormProducts, FormSelect, getSuggestions, useForm } from 'helpers/form';
import { IProduct, IQueryParams, ItemId } from 'types';
import { fetchCastCrew } from 'utils/apis/cast-crew';

const labels = { confirm: 'Assign' };

export interface IAssignCastCrewToProductFormData {
  products: IProduct[];
  product_ids: ItemId[];
  cast_character: string;
  cast_role: string[];
  crew_position: string[];
  kind: 'Cast' | 'Crew';
  cast_crew_ids: ItemId[];
  cast_crew: PmGenericCastCrew[];
}

const initialValues: IAssignCastCrewToProductFormData = {
  product_ids: [],
  products: [],
  cast_character: '',
  cast_role: [],
  crew_position: [],
  kind: 'Cast',
  cast_crew_ids: [],
  cast_crew: [],
};

const customContext = {
  properties: {
    product_ids: { required: true },
    kind: { required: true },
    cast_crew_ids: { required: true },
  },
};

const options = ['Cast', 'Crew'];

export const getCastRole = (params: IQueryParams): Promise<string[]> =>
  getSuggestions(params, 'default_layer.meta.cast_crew.cast_role');

export const getCrewPosition = (params: IQueryParams): Promise<string[]> =>
  getSuggestions(params, 'default_layer.meta.cast_crew.crew_position');

interface IAssignCastCrewProps {
  data?: Partial<IAssignCastCrewToProductFormData>;
  handleSubmit: (data: IAssignCastCrewToProductFormData) => Promise<void>;
  hiddenFields?: (keyof Pick<IAssignCastCrewToProductFormData, 'product_ids' | 'cast_crew_ids'>)[];
}

export const AssignCastCrewToProductForm: React.FC<IAssignCastCrewProps> = observer(
  ({ handleSubmit, data, hiddenFields }) => {
    const { handlers, formData, onSubmit, values, valid } = useForm<IAssignCastCrewToProductFormData>(
      { ...initialValues, ...(data || {}) },
      Model.ASSETS,
      handleSubmit,
      customContext,
    );
    const onItemSelect = useCallback(
      (products) => {
        handlers.onChange({ products });
      },
      [handlers],
    );
    const onSelectCastChange = useCallback(
      (cast_crew) => {
        handlers.onChange({ cast_crew });
      },
      [handlers],
    );

    return (
      <form onSubmit={onSubmit}>
        {!hiddenFields?.includes('cast_crew_ids') && (
          <FormMultiSelect
            name="cast_crew_ids"
            label="Cast & Crew"
            {...handlers}
            {...formData.cast_crew_ids}
            fetchValues={fetchCastCrew}
            onSelectedItemsChange={onSelectCastChange}
          />
        )}
        {!hiddenFields?.includes('product_ids') && (
          <FormProducts
            name="product_ids"
            label="Products"
            {...handlers}
            onSelectedItemsChange={onItemSelect}
            {...formData.product_ids}
          />
        )}
        <FormSelect name="kind" label="Type" {...handlers} {...formData.kind} options={options} />
        {values.kind === 'Cast' && (
          <>
            <FormInput name="cast_character" label="Character Name" {...handlers} {...formData.cast_character} />
            <FormMultiSelect
              name="cast_role"
              label="Role"
              {...handlers}
              {...formData.cast_role}
              allowNewItems
              fetchValues={getCastRole}
            />
          </>
        )}
        {values.kind === 'Crew' && (
          <FormMultiSelect
            name="crew_position"
            label="Position"
            {...handlers}
            {...formData.crew_position}
            allowNewItems
            fetchValues={getCrewPosition}
          />
        )}
        <FormSubmitSection labels={labels} submitDisabled={!valid} />
      </form>
    );
  },
);
