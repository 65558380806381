import React from 'react';

import ProductSelectedTabItem from 'components/product-selected-tab-item/product-selected-tab-item';
import { IAsset, ICategory, IContact, IGroup, IOrganization, IProduct } from 'types';
import { isProduct } from 'utils/general';
import { AssetSelectedTabItem } from 'components/asset/asset-selected-tab-item';
import { UmGenericListCollection } from '@mediafellows/mm3-types';
import { CollectionSearchItem } from 'components/collection-search-item';
import noop from 'lodash/noop';
import { typeParser } from 'utils/list-item';
import { GroupSelectedItem } from 'components/group-search-item';
import { ContactSelectedTabItem } from 'components/contact-selected-tab-item';
import { OrganizationSelectedTabItem } from 'components/organization';
import { CategorySelectedTabItem } from 'components/category-selected-tab-item';
import { IFormMultiSelectOption } from 'helpers/form';
import { ItemTypes } from 'helpers/form/fields/select-helpers';

export const itemRenderer = (entity: IFormMultiSelectOption): JSX.Element => {
  if (isProduct(entity as IProduct)) {
    return <ProductSelectedTabItem key={entity.value} product={entity as IProduct} />;
  }

  const type = entity['@type'] || entity['$type'] || entity['type'];

  if (type?.toLowerCase()?.includes('asset')) {
    return <AssetSelectedTabItem key={entity.value} asset={entity as IAsset} customParser={typeParser} />;
  }

  if (entity.itemType === ItemTypes.LIST_COLLECTION) {
    return (
      <CollectionSearchItem key={entity.value} collection={entity as UmGenericListCollection} handleSelect={noop} />
    );
  }

  if (type.startsWith('group')) {
    return <GroupSelectedItem key={entity.value} group={entity as IGroup} handleSelect={noop} />;
  }

  if (type.includes('user')) {
    return <ContactSelectedTabItem key={entity.value} contact={entity as IContact} />;
  }

  if (type.includes('organization')) {
    return <OrganizationSelectedTabItem key={entity.value} organization={entity as IOrganization} />;
  }

  if (type.includes('category')) {
    return <CategorySelectedTabItem key={entity.value} category={entity as ICategory} />;
  }

  return <div key={entity.value}>{entity.value}</div>;
};
