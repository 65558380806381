import React, { useCallback, useState, useMemo } from 'react';
import { Button } from '@mantine/core';
import { McGenericRecommendationAsset, McGenericRecommendationProduct } from '@mediafellows/mm3-types';
import cx from 'classnames';
import { ArrowLeft } from 'blueprint5-icons';

import { ToastError } from 'components/toast';
import { IWizardStepButton } from 'components/wizard/utils';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { ICreateWorkFlowPayload } from 'utils/actions/types';
import { createRecommendationDraft } from 'utils/apis/recommendation';
import { Intent, MantineIcon } from 'utils/ui';
import { deleteRecommendation, sendRecommendations, updateRecommendation } from 'utils/apis/recommendation';
import { useStore } from 'store';
import { isAssetRecommendation } from 'utils/recommendation';

import {
  prepareAssetList,
  prepareRecipientLists,
  removeInternalAttributes,
  prepareProductList,
  StepIds,
  AssetStepIds,
} from './utils';

type IWizardProps<
  T extends McGenericRecommendationAsset | McGenericRecommendationProduct = McGenericRecommendationProduct,
> = {
  nextButton: IWizardStepButton;
  backButton: IWizardStepButton;
  currentStepId: number;
  onFinish: (recommendation: T) => void;
  form: IUseMm3FormReturn<ICreateWorkFlowPayload<T>>;
};

export const createGenericRecommendationDraft = async <
  T extends McGenericRecommendationAsset | McGenericRecommendationProduct = McGenericRecommendationProduct,
>(
  values: ICreateWorkFlowPayload<T>,
): Promise<T> => {
  const { cc_list, recipient_list, bcc_list } = await prepareRecipientLists(values);
  const isAssetRecommendation = values.type === 'Recommendation::Asset';
  let asset_ids = values.asset_ids;
  if (isAssetRecommendation) {
    asset_ids = (await prepareAssetList(values)).asset_ids;
  }

  const product_ids = (await prepareProductList(values as ICreateWorkFlowPayload<McGenericRecommendationProduct>))
    .product_ids;
  const include_future_episodes_of_product_ids = values.includeFutureEpisodes ? product_ids : [];

  const submit = values?.id ? updateRecommendation : createRecommendationDraft;
  const payload: T = removeInternalAttributes<T>({
    ...values,
    recipient_list,
    cc_list,
    bcc_list,
    asset_ids,
    product_ids,
    include_future_episodes_of_product_ids,
  });

  const recommendation = await submit<T>(payload);
  return recommendation;
};

export function WizardButtons<
  T extends McGenericRecommendationAsset | McGenericRecommendationProduct = McGenericRecommendationProduct,
>({ backButton, nextButton, currentStepId, form, onFinish }: IWizardProps<T>): React.ReactElement {
  const { toastStore, dialogStore } = useStore();
  const [isSending, setIsSending] = useState<boolean>(false);
  const isGLO = Boolean(form.values?.generate_links_only);
  const deliverRecommendation = useCallback(
    async (e) => {
      try {
        setIsSending(true);
        e.preventDefault();
        const draft = await createGenericRecommendationDraft(form.values);
        const recommendation = await sendRecommendations<T>([draft]).catch((err) => {
          // in the create recommendation wizard, delete draft if sent failed
          if (!form.values.id) {
            deleteRecommendation([draft.id]);
          }
          throw err;
        });

        onFinish?.(recommendation);
        dialogStore.close();
        const toasterSucessMsg = isGLO ? 'Links generated successfully' : 'Recommendation sent successfully';
        toastStore.success(toasterSucessMsg);
      } catch (error) {
        toastStore.error(<ToastError error={error} placeholder="Something went wrong!" />);
      } finally {
        setIsSending(false);
      }
    },
    [dialogStore, form.values, isGLO, onFinish, toastStore],
  );

  const submitButtonTitle = useMemo(() => {
    return isGLO ? 'Generate links' : 'Send';
  }, [isGLO]);
  const showSubmitButton = isAssetRecommendation(form.values)
    ? currentStepId === AssetStepIds.Summary
    : currentStepId === StepIds.Summary;

  return (
    <div className="d-flex w-100 gap-2">
      <Button
        className={cx('me-auto', { invisible: backButton.hide })}
        variant="subtle"
        onClick={backButton.action}
        disabled={backButton.disabled}
        leftSection={<MantineIcon icon={<ArrowLeft />} />}
      >
        {backButton.title}
      </Button>
      <Button
        variant={Intent.PRIMARY}
        onClick={nextButton.action}
        disabled={nextButton.disabled || form.isSending || isSending}
      >
        {nextButton.title}
      </Button>
      {showSubmitButton && (
        <Button
          disabled={nextButton.disabled || form.isSending || isSending}
          variant={Intent.PRIMARY}
          data-action-type="generate_links"
          onClick={deliverRecommendation}
        >
          {submitButtonTitle}
        </Button>
      )}
    </div>
  );
}
