import React, { useCallback } from 'react';
import { FormSelect, FormInput } from 'helpers/form';

import { CustomFormFields } from 'components/delivery/connections/utils';
import { IConnectionForm } from 'components/delivery/connections/types';
import { formatConnectionType } from 'components/connection-list-item/utils';

export const ConnectionEditor: React.FC<IConnectionForm> = ({ form, newConnection }) => {
  const onChange = useCallback(
    ({ type }): void => {
      form.handlers.onChange({ type });
    },
    [form.handlers],
  );

  return (
    <div className="connection-editor">
      <div className="grid-row-1">
        <div className="grid-column-1">
          <FormSelect
            name="type"
            label="Type"
            disabled={!newConnection}
            {...form.handlers}
            {...form.formData.type}
            formatLabel={formatConnectionType}
            onChange={onChange}
          />
        </div>
        <div className="grid-column-2">
          <FormInput name="name" label="Server Name" {...form.handlers} {...form.formData.name} />
        </div>
      </div>
      <div className="grid-row-2">
        <div className="grid-column-2">
          <FormInput
            name="description"
            label="Description"
            textarea
            {...form.handlers}
            {...form.formData.description}
          />
        </div>
      </div>

      <div className="grid-row-3">{CustomFormFields({ form })}</div>
    </div>
  );
};
