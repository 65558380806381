import { create } from 'zustand';
import { Globals, IDataSectionLayout } from 'types';

export enum ISidebarSection {
  assets = 'assets',
  contacts = 'contacts',
  products = 'products',
  groups = 'groups',
  marketing = 'marketing',
  website = 'website',
  meetings = 'meetings',
  analytics = 'analytics',
  producerPortal = 'producer-portal',
  fulfillment = 'fulfillment',
  settings = 'settings',
  orderManagement = 'order-management',
}

export interface IProfileStoreSettings {
  isDarkMode: boolean;
  isSidebarOpen: boolean;
  isDataSectionSidebarOpen: boolean;
  isListViewActive: boolean;
  itemsPerPage: number;
}

export interface IProfileStore {
  activeSidebarSection: ISidebarSection | null;
  settings: IProfileStoreSettings;
  updateSettings: (key, value) => void;
  updateActiveSidebarSection: (activeSidebarSection) => void;
  computed: { layout: IDataSectionLayout };
}

export const useProfileStore = create<IProfileStore>((set, get) => ({
  activeSidebarSection: null,
  settings: {
    isDarkMode: !localStorage.getItem(Globals.THEME_LIGHT_LS),
    isSidebarOpen: !localStorage.getItem(Globals.SIDEBAR_COLLAPSED_LS),
    isDataSectionSidebarOpen: !localStorage.getItem(Globals.DATASECTION_SIDEBAR_COLLAPSED_LS),
    isListViewActive: !localStorage.getItem(Globals.DATASECTION_TILE_VIEW_LS),
    itemsPerPage: Number(localStorage.getItem(Globals.DATASECTION_ITEMS_PER_PAGE_LS)) || 25,
  },
  updateActiveSidebarSection: (activeSidebarSection) => set(() => ({ activeSidebarSection })),
  updateSettings: (key, value) => set((state) => ({ settings: { ...state.settings, [key]: value } })),
  computed: {
    get layout() {
      return get().settings.isListViewActive ? 'list' : 'tiles';
    },
  },
}));
