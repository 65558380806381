import React from 'react';
import cx from 'classnames';
import { CloseButton } from '@mantine/core';

import { UserAvatar } from 'components/user-avatar';
import { IContact } from 'types';
import { formatField } from 'utils/general';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { Classes } from 'utils/ui';
import { IFormMultiSelectOption } from 'helpers/form';

interface IUserSearchItem {
  user: IContact;
  handleSelect?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onRemove?: (item: IFormMultiSelectOption) => void;
}

const UserSearchItem: React.FC<IUserSearchItem> = ({ user, handleSelect, onRemove }) => {
  const { first_name, last_name, id, email, '@type': type, status, organization_name, role_name } = user;
  const isDeleted = isEntityDeleted(user);
  return (
    <li key={`${id}-user`}>
      <div
        className={cx(`searchbox__user ${Classes.MENU_ITEM}`, {
          'searchbox__entity--deleted': isDeleted,
          'searchbox__with-delete': onRemove,
        })}
        data-id={id}
        data-item-type={type}
        onClick={handleSelect}
      >
        <div className="searchbox__avatar">
          <UserAvatar user={user} />
        </div>
        <div>
          <div className="text-truncate">
            {first_name} {last_name}
          </div>
          <div className={`searchbox__email ${Classes.TEXT_MUTED} text-truncate`}>{email}</div>
          <div className={cx('text-truncate', Classes.TEXT_MUTED)}>
            {formatField([status, role_name, organization_name].filter(Boolean), '-', ' | ')}
          </div>
        </div>
        {onRemove && <CloseButton onClick={() => onRemove(user as IFormMultiSelectOption)} />}
      </div>
    </li>
  );
};

export { UserSearchItem };
