import React from 'react';
import { observer } from 'mobx-react-lite';

import OrdersDataSection from 'components/orders-data-section';
import { Pages } from 'utils/actions/types';
import { DataSectionPage } from 'components/layout';

const page = Pages.ORDERS;

const Orders: React.FC = observer(() => {
  return (
    <DataSectionPage>
      <OrdersDataSection persistFilters page={page} />
    </DataSectionPage>
  );
});

export default Orders;
