import React, { useCallback, useState, useEffect } from 'react';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';
import { DeliveriesGenericConnection } from '@mediafellows/mm3-types';
import { handleItemClick } from 'components/data-section/';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { ConnectionAttributeList } from 'components/connection-list-item/connection-attributes-list';
import { formatConnectionType, AvatarType, toPascalCase } from 'components/connection-list-item/utils';
import { Avatar } from 'components/avatar/avatar';

import './style.scss';

interface IConnectiontsListItemProps {
  className?: string;
  connection: DeliveriesGenericConnection;
  contextMenu: React.ReactElement;
}

const ConnectionListItem: React.FC<IConnectiontsListItemProps> = observer((props) => {
  const {
    dataSectionStore: { checked, active, isAllItemsSelected },
  } = useStore();

  const { className, connection, contextMenu } = props;
  const { id } = connection;
  const isChecked = checked.some((checked) => checked.id === connection.id);
  const isActive = active === connection.id;
  const formatedType = toPascalCase(formatConnectionType(connection.type));

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, id as number);
    },
    [id],
  );
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);

  return (
    <div
      className={cx('connection-list-item', className, {
        'connection-list-item--active': isActive,
        'entity-list-item--deleted': isEntityDeleted(connection),
      })}
      onClick={handleClick}
    >
      <div className="connection-list-item__checkbox">
        <Checkbox onChange={handleClick} checked={itemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
      </div>

      <div className="connection-list-item__thumb">
        <Avatar disabled size="small" image={AvatarType[formatedType]} />
      </div>

      <div className="connection-list-item__content">
        <div className="entity-list-item-row__title connection-list-item__title">
          <h4 className={cx('d-inline')}>
            <span className="text-break">{connection.name}</span>
          </h4>
          <div className="connection-list-item__popover-container">{Boolean(contextMenu) && contextMenu}</div>
        </div>

        <ConnectionAttributeList connection={connection} />
      </div>
    </div>
  );
});

export default ConnectionListItem;
