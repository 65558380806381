import React, { useMemo } from 'react';

import ContactDataSection from 'components/contact-data-section';

import { ITitleDividerActionName, Pages } from 'utils/actions';
import { ISearchFilter, ISmartGroup } from 'types';

interface ISmartGroupContactsTab {
  smartGroup: ISmartGroup;
  onSuccess: (() => void) | undefined;
}

export const SmartGroupContactsTab: React.FC<ISmartGroupContactsTab> = ({ smartGroup, onSuccess }) => {
  const groupFilter = useMemo(() => [['group_ids', 'in', [smartGroup.id]]] as ISearchFilter[], [smartGroup.id]);

  return (
    <ContactDataSection
      persistentFilters={groupFilter}
      page={Pages.SMART_GROUP_DETAILS}
      actionsOptions={{
        groupName: ITitleDividerActionName.SMART_GROUP,
        groupParamName: 'smartGroupId',
        page: Pages.SMART_GROUP_DETAILS,
        onSuccess,
      }}
    />
  );
};
