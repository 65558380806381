import React, { useCallback } from 'react';
import { omit } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';
import { IAssetPresetFields } from 'types';
import { MantineIcon } from 'utils/ui';
import { presetKeysToOmit } from 'components/asset/asset-upload-components/constants';

export interface IAssetPresetControlsProps {
  values: Partial<IAssetPresetFields>;
  savePreset: (preset: Partial<IAssetPresetFields>) => void;
}

export const AssetPresetControls: React.FC<IAssetPresetControlsProps> = ({ values, savePreset }) => {
  const handleSavePreset = useCallback(() => {
    const presetValues: Partial<IAssetPresetFields> = omit(values, presetKeysToOmit);
    savePreset(presetValues);
  }, [savePreset, values]);

  return (
    <div className="asset-upload-components__single-asset-form-preset-control my-2">
      <Button leftSection={<MantineIcon icon={'floppy-disk'} />} variant="subtle" onClick={handleSavePreset}>
        Save As Preset
      </Button>
    </div>
  );
};

export default React.memo(observer(AssetPresetControls));
