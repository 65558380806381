import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch } from '@mantine/core';
import { useLocation } from 'react-router-dom';

import { FilterText } from 'helpers/filters/fields';
import { submitFilters, ISubmitFiltersParams, toggleParam } from 'helpers/filters/helpers';
import { parseQueryParams } from 'utils/general';
import { useStore } from 'store';
import { useFilters } from 'helpers/filters';
import { DataSectionPreset } from 'components/data-section-preset';
import { defaultParamComponentValues, IAssetFilters } from './utils';
import { SharedAssetFilters } from 'components/asset/asset-filters/shared-filters';
import { IAsset } from 'types/index';

export const AssetFilters: React.FC<{
  customSubmitFilter?: (obj: ISubmitFiltersParams) => void;
  withMainAssetsFilter?: boolean;
}> = observer(({ customSubmitFilter = submitFilters, withMainAssetsFilter }) => {
  const location = useLocation();

  const { dataSectionStore } = useStore<IAsset>();
  const queryParams = parseQueryParams(location.search);

  const [paramComponentValues, setParamComponentValues] = useState(defaultParamComponentValues(queryParams));

  const handleSubmit = useCallback(
    (action): void => {
      customSubmitFilter({
        action,
        setParamComponentValues,
        customParams: {
          include_deleted: false,
        },
      });
    },
    [customSubmitFilter],
  );

  const [filterValues, filterHandlers] = useFilters<IAssetFilters>(dataSectionStore, handleSubmit);
  const handleToggleParam = (event): void => {
    toggleParam({
      event,
      paramComponentValues,
      setParamComponentValues,
    });
  };

  return (
    <div className="entity-filters">
      <DataSectionPreset filterHandlers={filterHandlers} />
      <div className="entity-filters__new-filter">
        <div className="entity-filters__title mb-3">
          <h4 className="mb-0">New Filter</h4>
        </div>

        <form>
          <FilterText
            label="Search List"
            placeholder="Search"
            name="_"
            filter={filterValues._}
            onChange={filterHandlers.onChange}
          />

          <SharedAssetFilters
            dataSectionStore={dataSectionStore}
            filterValues={filterValues}
            filterHandlers={filterHandlers}
            withMainAssetsFilter={withMainAssetsFilter}
          />

          <Switch
            label="Include deleted"
            data-param={'include_deleted'}
            onChange={handleToggleParam}
            checked={paramComponentValues.include_deleted}
          />
        </form>
      </div>
    </div>
  );
});
