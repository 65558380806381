import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Button } from '@mantine/core';

import { Loading } from 'components/loading';
import { IAsset, ItemId } from 'types';
import { useStore } from 'store';
import { useAssetsEditStore, useDialogStore } from 'store/hooks';
import { useRefreshDataSection } from 'utils/hooks';
import { assetDetailsSchema } from 'utils/schemas/asset';

import { ToastError } from 'components/toast';

import AssetEditForm from './asset-edit-form';
import PresetControls from './components/preset-control';
import { AmGenericAsset3 } from '@mediafellows/mm3-types';

import './index.scss';

interface IMultipleAssetsEditModalProps {
  getAssets: (assetIds: ItemId[], schema?: string) => Promise<IAsset[]>;
  saveAssets: (assets: IAsset[] | Partial<AmGenericAsset3> | Partial<AmGenericAsset3>[]) => Promise<void>;
}

const MultipleAssetsEditModal: React.FC<IMultipleAssetsEditModalProps> = observer(({ getAssets, saveAssets }) => {
  const { assetIds, assets, setAssets, onDataChange } = useAssetsEditStore();
  const { toastStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const { close } = useDialogStore();

  useEffect(() => {
    let saveAssets = (result: IAsset[]): void => {
      setAssets(result);
      setIsLoading(false);
    };

    try {
      if (assetIds?.length) {
        setIsLoading(true);
        getAssets(assetIds, assetDetailsSchema).then((assets) =>
          saveAssets(assets.map((asset) => ({ ...asset, selected: true }))),
        );
      }
    } catch (error) {
      toastStore.error(error.text);
    }

    return () => {
      saveAssets = () => null;
    };
  }, [assetIds, getAssets, toastStore, setAssets]);

  const refresh = useRefreshDataSection();

  const handleCancel = useCallback((): void => {
    close();
    setAssets([]);
  }, [setAssets, close]);

  const [isSending, setIsSending] = useState(false);

  const handleSubmit = useCallback(async () => {
    try {
      setIsSending(true);
      toastStore.info('Saving');
      await saveAssets(assets);
      toastStore.clearAll();
      toastStore.success('Changes applied successfully!');
      refresh();
      close();
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
    } finally {
      setIsSending(false);
    }
  }, [assets, refresh, saveAssets, close, toastStore]);

  return (
    <div className="asset-edit-form-container h-100">
      <div className="h-100 overflow-y-scroll">
        {isLoading && <Loading text="loading Assets..." />}
        {assets.map((asset) => (
          <div key={asset.id} className="multi-asset-edit-form--element">
            <AssetEditForm
              formId={asset.id}
              asset={asset}
              onDataChange={onDataChange}
              setIsValid={setIsValid}
              selectable
            />
            <PresetControls values={asset} />
          </div>
        ))}
      </div>
      <div className="multi-asset-edit-form--footer">
        <Button size="xs" variant="default" onClick={handleCancel}>
          Cancel
        </Button>
        <Button size="xs" variant="primary" onClick={handleSubmit} disabled={!isValid || isSending}>
          Save
        </Button>
      </div>
    </div>
  );
});

export default MultipleAssetsEditModal;
