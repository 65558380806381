import React, { useState } from 'react';
import ReactCheckboxTree from 'react-checkbox-tree';
import { ICheckboxProps } from 'components/checkbox-tree/types';
import { MantineIcon } from 'utils/ui/icon';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const defaultIcons = {
  expandClose: <MantineIcon variant="primary" icon="chevron-right" />,
  expandOpen: <MantineIcon variant="primary" icon="chevron-down" />,
  check: <MantineIcon variant="primary" icon="tick-circle" />,
  uncheck: <MantineIcon icon="circle" />,
  halfCheck: <MantineIcon variant="primary" icon="ban-circle" />,
  expandAll: <></>,
  collapseAll: <></>,
  parentClose: <></>,
  parentOpen: <></>,
  leaf: <></>,
};

const CheckboxTree: React.FC<ICheckboxProps> = ({
  checked,
  onCheck,
  nodes,
  expanded,
  disabled,
  icons,
  setExpanded,
  withExpanded,
}) => {
  const treeIcons = icons ? { ...defaultIcons, ...icons } : defaultIcons;
  const [localExpanded, setLocalExpanded] = useState<string[]>(expanded || []);

  return (
    <ReactCheckboxTree
      checked={checked}
      onCheck={onCheck}
      onExpand={withExpanded ? setLocalExpanded : setExpanded}
      nodes={nodes}
      expanded={withExpanded ? localExpanded : expanded}
      disabled={disabled}
      checkModel="all"
      icons={treeIcons}
    />
  );
};

export default React.memo(CheckboxTree);
