import React, { useCallback, useState, useEffect } from 'react';
import { Checkbox } from '@mantine/core';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import DateElement from 'components/date-list-element/date-list-element';
import { ISelectionDataSectionWrappedComponent } from 'components/selection/default-selection-list-item/types';
import { MemoizedMultiThumbnail } from 'components/multi-component/multi-component';

import { pluralWordInflect, getEntityLabel } from 'utils/general';
import { useRemote } from 'utils/hooks';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { fetchProductThumbnailImages } from 'utils/ui';
import useSelectedOption from 'utils/hooks/selected-option';

import '../style.scss';

// TODO: generalize this component to use it for product lists as well
export const ProductSelectionDataSectionListItem: React.FC<ISelectionDataSectionWrappedComponent> = ({
  selection,
  contextMenu,
  isChecked,
  isAllItemsSelected,
  handleClick,
}) => {
  const { id, name, main_entity_type, entityCount, owner, access_level, created_at } = selection;

  const fetcher = useCallback(() => fetchProductThumbnailImages(id), [id]);
  const [thumbnailImages] = useRemote(fetcher, [{}]);

  const selectedOption = useSelectedOption({ value: access_level });
  const [itemChecked, setItemChecked] = useState(isChecked);

  useEffect(() => {
    setItemChecked(isChecked);
  }, [isChecked]);
  return (
    <div
      className={cx('selections-list-item product-selections', {
        'entity-list-item--deleted': isEntityDeleted(selection),
      })}
      onClick={handleClick}
    >
      <div
        className={cx('selections-list-item__checkbox', {
          [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
        })}
      >
        <Checkbox onChange={handleClick} checked={itemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
      </div>
      <div className="entity-list-item-row__multi-thumb">
        <MemoizedMultiThumbnail thumbnailImages={thumbnailImages || [{}]} />
      </div>
      <div className="selections-list-item__content">
        <div className="d-flex justify-content-between">
          <div
            className="entity-list-item-row__title d-flex flex-row align-items-center selections-list-item__title"
            title={name}
          >
            <Link to={`/product-selections/${id}`}>
              <h4 className="text-nowrap text-truncate">{name}</h4>
            </Link>
          </div>
          {Boolean(contextMenu) && contextMenu}
        </div>
        <div className="selections-list-item__row--products bp3-text-muted">
          <div className="text-nowrap text-truncate text-capitalize product-selections__entity">
            {pluralWordInflect(getEntityLabel(main_entity_type), entityCount)}
          </div>
          <div className="text-nowrap text-truncate product-selections__owner">{owner}</div>
          <div className="text-nowrap text-truncate product-selections__created">
            <DateElement date={created_at} mfxIcon="created-at" />
          </div>
        </div>
      </div>
    </div>
  );
};
