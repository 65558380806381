import { IMobileAppSyncFormData } from 'components/mobile-app-sync-wizard';
import { Product3Types } from 'types/product';

export const initialValues: IMobileAppSyncFormData = {
  name: '',
  access_level: 'private',
  product_ids: [],
  asset_ids: [],
  descendants_ids: [],
  include_descendants: false,
  include_related_products: false,
  relatedProductIds: [],
};

export const relatedProductType = Product3Types.FORMAT;
export const relatedProductTooltipLabel =
  "Includes the selected Format products' related products and, if ticked above, their descendants";
