import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { IActionOpts, IResult } from '@mediafellows/chipmunk';

import { useDataSectionStore } from 'store';
import { DataSection } from 'components/data-section';
import { PreviewSelected } from 'components/preview-selected';
import { ISmartGroupFilters, SmartGroupFilters } from 'components/smart-group/filters-tab/filters-tab';
import { ICustomSortMenuProp, IDataSectionDefaultParams } from 'components/data-section/data-section';
import { SmartGroupDataSectionItem } from 'components/smart-group/data-section-item/data-section-item';
import { SmartGroupPreview } from 'components/smart-group/preview-tab/preview-tab';

import { IFiltersDefinition } from 'helpers/filters';
import { EqFilter, InFilter, Model, QFilter, RangeFilter } from 'helpers/filters/types';
import { ISubmitFiltersParams } from 'helpers/filters/helpers';
import { IUseActionsOptionParam } from 'utils/actions';
import { useRefreshDataSection } from 'utils/hooks';

import { SmartGroupListSchema } from 'utils/schemas/smart-group';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';
import { Pages } from 'utils/actions/types';
import { ISearchFilter, ISmartGroup } from 'types';

import { customSortMenu } from './utils';
import { ActionsMenu } from 'components/action/utils';
import { SmartGroupActionsList } from 'components/action/smart-group';

const defaultFilters: ISmartGroupFilters = {
  _: new QFilter(''),
  updated_at: new RangeFilter('updated_at', '', ''),
  owner_id: new EqFilter('owner_id', ''),
  created_at: new RangeFilter('created_at', '', ''),
  access_level: new InFilter('access_level', []),
};

interface IEventDataSectionProps {
  persistentFilters?: IFilterOption[];
  initFilters?: IFiltersDefinition;
  page?: Pages;
  defaultParams?: IDataSectionDefaultParams;
  customSubmitFilter?: (filters: ISubmitFiltersParams) => void;
  executor?: (opts: IActionOpts) => Promise<IResult<ISmartGroup>>;
  customSortMenu?: ICustomSortMenuProp;
  actionOptions?: Partial<IUseActionsOptionParam<ISmartGroup>>;
  persistFilters?: boolean;
  headerRightSection?: React.ReactElement;
}

const sidebarTabs = {
  preview: <SmartGroupPreview />,
  filters: <SmartGroupFilters />,
  selectedItemRenderer(event: ISmartGroup) {
    return (
      <PreviewSelected image={event?.preview_image?.url} assetType="image">
        {event?.name}
      </PreviewSelected>
    );
  },
};
const persistentFilters: ISearchFilter[] = [['type', 'eq', 'group/smart']];

export const SmartGroupDataSection: React.FC<IEventDataSectionProps> = observer(
  ({
    page = Pages.SMART_GROUPS_LIST,
    headerRightSection,
    initFilters,
    defaultParams,
    customSubmitFilter,
    executor,
    persistFilters,
    actionOptions,
  }) => {
    useControlDataSection({
      persistFilters,
      schema: SmartGroupListSchema,
      executor,
      defaultFilters,
      defaultParams,
      model: Model.SMART_GROUP,
      presetSuffix: 'smart-group',
      filters: persistentFilters,
      stats: 'access_level',
      initFilters,
    });

    const { checked } = useDataSectionStore<ISmartGroup>();
    const refreshDataSection = useRefreshDataSection();

    const itemRenderer = (item: ISmartGroup): React.ReactElement => {
      return (
        <SmartGroupDataSectionItem
          key={item.id}
          group={item}
          contextMenu={
            <ActionsMenu
              component={SmartGroupActionsList}
              items={[item]}
              options={{ context: 'single', page, onSuccess: refreshDataSection, ...(actionOptions || {}) }}
            />
          }
        />
      );
    };

    const customSortMenuHandler = useCallback(
      (sort, handleSort) => {
        return customSortMenu(sort, handleSort, !customSubmitFilter);
      },
      [customSubmitFilter],
    );

    return (
      <DataSection
        className="contacts__data-section"
        tabs={sidebarTabs}
        itemRenderer={itemRenderer}
        defaultParams={defaultParams}
        customSortMenu={customSortMenuHandler}
        contextMenu={
          <ActionsMenu
            component={SmartGroupActionsList}
            items={checked}
            options={{ context: 'multi', page, onSuccess: refreshDataSection, ...(actionOptions || {}) }}
          />
        }
        headerRightSection={headerRightSection}
      />
    );
  },
);
