import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { UmGenericListCollection } from '@mediafellows/mm3-types';
import { useNavigate } from 'react-router';

import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { DetailsPageTabs, IAccessLevels, IWebsitePageValues } from 'types';
import { changeAccessLevelSlider } from 'utils/change-access';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { updateList } from 'utils/apis/showcase';
import { useRefreshDataSection } from 'utils/hooks';
import { ShowcaseActionsList } from 'components/action';
import { Pages } from 'utils/actions/types';
import { IShowcaseActionName } from 'utils/actions/showcase/types';
import { ActionsMenu } from 'components/action/utils';
import { Routes } from 'utils/routes';

interface ICollectionDetailsHeaderProps {
  collection: UmGenericListCollection;
  setCollection: React.Dispatch<React.SetStateAction<undefined | UmGenericListCollection>>;
  activeTab: DetailsPageTabs;
  setActiveTab: (tab: DetailsPageTabs, addEditParam?: boolean) => void;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  collectionForm: IUseMm3FormReturn<UmGenericListCollection>;
  refreshSubCollectionsCount: VoidFunction;
  refreshProductIds: VoidFunction;
}

const CollectionDetailsHeader: React.FC<ICollectionDetailsHeaderProps> = observer(
  ({
    collection,
    setCollection,
    activeTab,
    setActiveTab,
    isEditMode,
    setIsEditMode,
    collectionForm,
    refreshSubCollectionsCount,
    refreshProductIds,
  }) => {
    const { id, name, preview_image, access_level, purpose } = collection;
    const navigate = useNavigate();

    const handleAccessLevelChange = useCallback(
      async (newAccessLevel: IAccessLevels) => {
        return await changeAccessLevelSlider<UmGenericListCollection>({
          apiCall: async ({ access_level }) =>
            updateList({ id, access_level } as Pick<UmGenericListCollection, 'id' | 'access_level'>),
          newAccessLevel,
          entitySetter: setCollection,
        });
      },
      [id, setCollection],
    );

    const { valid, onSubmit, resetFields } = collectionForm || {};

    const actions = useMemo<ISectionHeaderAction[]>(
      () =>
        isEditMode
          ? [
              {
                type: 'save',
                disabled: !valid,
                handler: onSubmit,
              },
              {
                type: 'cancel',
                handler: async () => {
                  resetFields?.();
                  setIsEditMode(false);
                },
              },
            ]
          : [
              {
                type: 'edit',
                handler: () => {
                  setIsEditMode(true);
                  if (activeTab !== DetailsPageTabs.ITEMS) {
                    return;
                  }
                  setActiveTab(DetailsPageTabs.OVERVIEW, true);
                },
              },
            ],
      [isEditMode, valid, onSubmit, resetFields, setIsEditMode, activeTab, setActiveTab],
    );
    const refreshDataSection = useRefreshDataSection();
    const onSuccess = useCallback(
      (action: IShowcaseActionName, updatedCollection?: UmGenericListCollection) => {
        switch (action) {
          case IShowcaseActionName.ADD_ITEMS:
            refreshSubCollectionsCount();
            refreshProductIds();
            break;
          case IShowcaseActionName.UPLOAD_PREVIEW:
            updatedCollection && setCollection(updatedCollection);
            break;
          case IShowcaseActionName.DELETE:
            navigate(Routes.COLLECTIONS);
            break;
          default:
            refreshDataSection();
        }
      },
      [navigate, refreshDataSection, refreshProductIds, refreshSubCollectionsCount, setCollection],
    );

    return (
      <SectionHeader
        title={name}
        subtitle={purpose}
        className={cx({ 'section-header--product-with-image': Boolean(preview_image) })}
        onAccessLevelChange={handleAccessLevelChange}
        accessLevel={access_level}
        actions={actions}
        useBackground
        dropdownMenu={
          <ActionsMenu
            component={ShowcaseActionsList}
            items={[collection]}
            options={{
              context: 'single',
              section: IWebsitePageValues.COLLECTIONS,
              onSuccess: onSuccess,
              page: Pages.DETAILS,
            }}
          />
        }
      />
    );
  },
);

export default CollectionDetailsHeader;
