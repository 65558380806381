import React, { useCallback } from 'react';

import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { ISmartGroup } from 'types';
import { useDataSectionStore } from 'store';

import { handleItemClick } from 'components/data-section';

import { DataSectionListItem } from './data-section-list-item';
import { isEntityDeleted } from 'utils/is-entity-deleted';

interface ISmartGroupDataSectionItemProps {
  className?: string;
  group: ISmartGroup;
  contextMenu?: React.ReactElement;
}

export const SmartGroupDataSectionItem: React.FC<ISmartGroupDataSectionItemProps> = observer((props) => {
  const { contextMenu, className, group } = props;
  const { id } = group;

  const { isAllItemsSelected, getIsActive, getIsChecked } = useDataSectionStore();

  const isChecked = getIsChecked(id);
  const isActive = getIsActive(id);

  const handleClick = useCallback(
    (e): void => {
      handleItemClick(e, id);
    },
    [id],
  );

  return (
    <div
      className={cx('entity-list-item', className, {
        'entity-list-item--active': isActive,
        'entity-list-item--deleted': isEntityDeleted(group),
      })}
      onClick={handleClick}
    >
      <DataSectionListItem
        contextMenu={contextMenu}
        handleClick={handleClick}
        group={group}
        isChecked={isChecked}
        isCheckedDisabled={isAllItemsSelected}
      />
    </div>
  );
});
