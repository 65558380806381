import React, { useCallback } from 'react';

import { FormRemoteSelect, IFormRemoteSelectProps } from 'helpers/form';
import { queryOrganizations } from 'utils/apis/organization';
import { IOrganization, ISearchFilter } from 'types';

export interface IFormOrganizationSelectProps
  extends Partial<
    Exclude<
      IFormRemoteSelectProps,
      'name' | 'label' | 'large' | 'inline' | 'disabled' | 'placeholder' | 'showPositiveValidation'
    >
  > {
  name: string;
  label?: string;
  placeholder?: string;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;
  showPositiveValidation?: boolean;
  additionalFilters?: ISearchFilter[];
}

export function FormOrganizationSelect({
  name,
  label = 'Organization',
  placeholder = 'Select Organization',
  additionalFilters,
  large = true,
  ...rest
}: IFormOrganizationSelectProps): React.ReactElement {
  const fetchOrganizationsOptions = useCallback(
    async (q: string, id: string | number): Promise<IOrganization[]> => {
      const params = { q, ...(id ? { ids: [id] } : {}) };
      return queryOrganizations(params, additionalFilters);
    },
    [additionalFilters],
  );

  return (
    <FormRemoteSelect
      name={name}
      label={label}
      placeholder={placeholder}
      large={large}
      fetchOptions={fetchOrganizationsOptions}
      {...rest}
    />
  );
}

export default FormOrganizationSelect;
