import { ComboboxProps, MantineSize, TreeNodeData } from '@mantine/core';
import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { IOmniTreeNode, IOmniTreeSelectOption, IOptionId, IRawTreeOption, ITreeNode, ITreeSelectOption } from './types';
import { ItemId } from 'types/utility';

export const createDataTree = (
  dataset: Array<ITreeSelectOption | IOmniTreeSelectOption>,
  formatParentNodes?: ((node: ITreeNode) => ITreeNode) | unknown,
): ITreeNode[] => {
  const hashTable = dataset.reduce((acc, node) => ({ ...acc, [node.value]: node }), {});

  const dataTree: ITreeNode[] = [];
  dataset.forEach((node) => {
    if (node.parentId && hashTable[node.parentId]) {
      const parentNode = hashTable[node.parentId];
      if (Array.isArray(parentNode.children)) {
        parentNode.children.push(hashTable[node.value]);
      } else {
        parentNode.children = [hashTable[node.value]];
      }
    } else {
      dataTree.push(hashTable[node.value]);
    }
  });

  if (typeof formatParentNodes === 'function') {
    return dataTree.map(formatParentNodes as (node: ITreeNode) => ITreeNode);
  }

  return dataTree;
};

export const getParentCheckedNodes = (tree: Array<ITreeNode | IOmniTreeNode>, selectedIds: string[]): string[] => {
  if (!Array.isArray(tree)) return [];

  return tree.reduce((acc, node) => {
    if (selectedIds.includes(node.value)) {
      return [...acc, node.value];
    }
    return [...acc, ...getParentCheckedNodes(node.children || [], selectedIds)];
  }, []);
};

export const getAllChildrenNodes = (tree?: Array<IOmniTreeNode>): Array<IOmniTreeNode> => {
  if (!Array.isArray(tree)) return [];

  return tree.reduce((acc: Array<IOmniTreeNode>, node) => {
    if (node?.children) {
      return [...acc, ...getAllChildrenNodes(node.children || [])] as Array<IOmniTreeNode>;
    }
    return [...acc, node];
  }, [] as Array<IOmniTreeNode>);
};

export const getChildrenCheckedNodes = (tree: Array<ITreeNode | IOmniTreeNode>, selectedIds: string[]): string[] => {
  if (!Array.isArray(tree)) return [];
  return tree.reduce((acc, node) => {
    if (selectedIds.includes(node.value) && node?.parentId) {
      return [...acc, node.value];
    }
    return [...acc, ...getChildrenCheckedNodes(node.children || [], selectedIds)];
  }, []);
};

export const getAllChildren = (tree?: Array<ITreeNode | IOmniTreeNode>): string[] => {
  if (!Array.isArray(tree)) return [];

  return tree.reduce((acc, node) => {
    return [...acc, node.value, ...getAllChildren(node.children || [])];
  }, []);
};

export const getAllAncestorNodes = (
  dataset: Array<ITreeSelectOption | IOmniTreeSelectOption>,
  selectedIds: string[],
): string[] => {
  if (!Array.isArray(dataset)) return [];

  return dataset.reduce((acc, node) => {
    if (selectedIds.includes(node.value) && node.parentId) {
      return [...acc, node.parentId, ...getAllAncestorNodes(dataset, [node.parentId])];
    }
    return acc;
  }, []);
};

export const uncheckNode = (tree: ITreeNode[], nodeId: string, parentIds: string[] = []): string[] => {
  if (!Array.isArray(tree)) return [];

  return tree.reduce((acc, node) => {
    if (node.value === nodeId) {
      return [...acc, ...parentIds, node.value, ...getAllChildren(node.children || [])];
    }
    return [...acc, ...uncheckNode(node.children || [], nodeId, [...parentIds, node.value])];
  }, []);
};

export const getAllCheckedNodes = (
  tree?: Array<ITreeNode | IOmniTreeNode>,
  checked: IOptionId[] | null = [],
): string[] => {
  if (!Array.isArray(tree)) return [];

  // we might have values as strings and numbers
  // so we want to use include with the same types
  // otherwice it will not work
  const checkedStrings = (checked || []).map((el) => `${el}`);

  return tree.reduce((acc, node) => {
    if (checkedStrings.includes(`${node.value}`)) {
      return [...acc, node.value, ...getAllChildren(node.children || [])];
    }
    return [...acc, ...getAllCheckedNodes(node.children || [], checked)];
  }, []);
};

export const tagRenderer = ({ label }: ITreeSelectOption): string => label;

export const parseOption = (op: IRawTreeOption): ITreeSelectOption => {
  return { label: op.name, value: op.id, parentId: op.parent_id, className: 'form-tree-select-option' };
};

export const preparePropOptions = (options?: IRawTreeOption[] | null): ITreeSelectOption[] => {
  return options?.map(parseOption) || [];
};

export interface IFormTreeSelectProps
  extends ComboboxProps,
    IFieldData<IOptionId[] | null, IRawTreeOption[] | null>,
    IFieldHandlers<IOptionId[]> {
  name: string;
  label?: string;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  tagRenderer?: (option: ITreeSelectOption) => string;
  hideClearAllButton?: boolean;
  tagsSize?: MantineSize;
}
export class TreeNode implements TreeNodeData {
  checked: boolean;
  label: string;
  value: string;
  children?: TreeNode[];
  parent_id?: string;
  constructor(value: string, label: string, checked: boolean, children?: TreeNode[]) {
    this.value = value;
    this.label = label;
    this.checked = checked;
    this.children = children;
  }

  public toggleCheck(): void {
    if (this.isChecked()) {
      this.uncheck();
    } else {
      this.check();
    }
  }

  public check(): void {
    this.children?.forEach((child) => child.check());
    this.checked = true;
  }

  public uncheck(): void {
    this.children?.forEach((child) => child.uncheck());
    this.checked = false;
  }

  public isChecked(): boolean {
    if (this.children && this.children.length > 0) return this.children.every((cur) => cur.isChecked());
    else return this.checked;
  }

  public isIndeterminate(): boolean {
    if (this.children && this.children.length > 0) {
      const some = this.children.some((cur) => cur.isChecked() || cur.isIndeterminate());
      const every = this.children.every((cur) => cur.isChecked());
      return some && !every;
    } else {
      return false;
    }
  }

  public getSelected(): TreeNode[] {
    if (this.isChecked()) return [this];
    return (
      this.children?.map((child) => child.getSelected()).reduce((acc: TreeNode[], curr) => [...acc, ...curr], []) || []
    );
  }

  public getChecked(): TreeNode[] {
    let result: TreeNode[] = [];
    if (this.isChecked()) result.push(this);
    result = [...result, ...(this.children?.flatMap((child) => child.getChecked()) || [])];
    return result;
  }

  public getIndeterminate(): TreeNode[] {
    let result: TreeNode[] = [];
    if (this.isIndeterminate()) result.push(this);
    result = [...result, ...(this.children?.flatMap((child) => child.getIndeterminate()) || [])];
    return result;
  }
}

export function buildTree<T extends { name?: string; id: ItemId; parent_id?: ItemId }>({
  data,
  parentId = null,
  initialValues,
  parentState,
}: {
  data: T[];
  parentId?: ItemId | null;
  initialValues?: ItemId[];
  parentState?: boolean;
}): { tree: TreeNode[] } {
  const tree = data
    .filter((entity) => entity.parent_id == parentId)
    .map((entity) => {
      const isValueInitialized = Boolean(initialValues?.includes(entity.id.toString()) || parentState);
      const node = new TreeNode(
        entity.id.toString(),
        entity.name || '',
        isValueInitialized,
        buildTree({ data, parentId: entity.id, initialValues, parentState: isValueInitialized }).tree,
      );
      return node;
    });

  return { tree };
}

export const mapNodeValues = (nodes: TreeNode[]): string[] => nodes.map(({ value }) => value);
