import {
  GroupTypes,
  IGroup,
  IIdentifiable,
  IContact,
  IProduct,
  IOrganization,
  ISelectionStandardized,
  IGroupVariety,
} from 'types';
import { IUseActionsOptionParam } from 'utils/actions/types';
import type { IFormMultiSelectOption } from 'helpers/form/fields/select-helpers';
import {
  IShouldDisplayGroupActionFunction,
  IUseAddEntitiesToGroupActionOptions,
} from 'utils/actions/common/add-entities-to-group';
import { IGroupConfig } from 'utils/actions/common/types';
import { IGroupActionName, shouldDisplaySingleNotDeleted as shouldDisplay } from 'utils/actions/common';

import { useAddToAccessPrivilegeDialog } from 'components/add-to-access-privilege-dialog/add-to-access-privilege-dialog';
export interface IGroupActionProps {
  entities?: (IGroup & IIdentifiable)[];
  options: IUseActionsOptionParam & {
    addSelectedProductAncestors?: boolean;
    items?: IFormMultiSelectOption[];
    shouldDisplay?: IShouldDisplayGroupActionFunction;
  };
}

const commonOptions = {
  groupLabel: 'Access privilege',
  shouldDisplay,
  withEmailNotification: true,
};

export const contactsToItems = (contacts: IContact[]): IFormMultiSelectOption[] => {
  return contacts.map(({ id, first_name, last_name }) => ({
    label: `${first_name} ${last_name}`,
    value: id,
  }));
};

export const productsToItems = (products: IProduct[]): IFormMultiSelectOption[] => {
  return products.map(({ id, title }) => ({ label: title, value: id?.toString() }));
};

export const organizationsToItems = (organizations: IOrganization[]): IFormMultiSelectOption[] => {
  return organizations.map(({ id, name }) => ({
    label: `${name}`,
    value: `${id}`,
  }));
};

export const contactSelectionsToItems = (selections: ISelectionStandardized[]): IFormMultiSelectOption[] => {
  return selections
    .filter((item) => item.main_entity_type === 'users')
    .map(({ id, name }) => ({
      label: `${name}`,
      value: `${id}`,
    }));
};

const useAddEntitiesToAccessPrivilegeAction = (
  items: IGroupVariety[],
  options: IUseAddEntitiesToGroupActionOptions,
): IGroupConfig => {
  const [openDialog] = useAddToAccessPrivilegeDialog({
    group: items[0] as unknown as IGroupVariety,
    entityType: options.entityType,
    options,
    items: options.items,
    groupType: options.groupType,
    title: options?.dialogTitle,
    groupLabel: options?.groupLabel,
    passPropsFromExistingItems: options?.passPropsFromExistingItems,
    hideProductsParams: options?.hideProductsParams,
    addSelectedProductAncestors: options?.addSelectedProductAncestors,
    withEmailNotification: options?.withEmailNotification,
    omni: options?.omni,
  });

  return {
    name: IGroupActionName.ADD_ENTITIES,
    shouldDisplay: options.shouldDisplay,
    icon: 'add',
    title: options?.title ?? `Add to this ${options?.groupLabel}`,
    handler: openDialog,
  };
};

export const useAddProductsAction = ({ entities = [], options }: IGroupActionProps): IGroupConfig =>
  useAddEntitiesToAccessPrivilegeAction(entities, {
    ...commonOptions,
    ...options,
    addSelectedProductAncestors: true,
    entityType: 'products',
    groupType: GroupTypes.ACCESS_PRIVILEGE,
    title: (options.items?.length || 0) > 0 ? 'Add to Access Privilege' : 'Add products',
    dialogTitle: 'Add products to this Access Privilege',
    omni: true,
  });

export const useAddContactsAction = ({ entities = [], options }: IGroupActionProps): IGroupConfig =>
  useAddEntitiesToAccessPrivilegeAction(entities, {
    ...commonOptions,
    ...options,

    groupType: GroupTypes.ACCESS_PRIVILEGE,
    entityType: entities.length === 0 ? 'users' : 'contacts_and_selections',
    title: (options.items?.length || 0) > 0 ? 'Add to Access Privilege' : 'Add contacts',
    dialogTitle: 'Add contacts to this Access Privilege',
  });

export const useAddContactSelectionsAction = ({ entities = [], options }: IGroupActionProps): IGroupConfig =>
  useAddEntitiesToAccessPrivilegeAction(entities, {
    ...commonOptions,
    ...options,
    groupType: GroupTypes.ACCESS_PRIVILEGE,
    entityType: 'contact_selections',
    title: (options.items?.length || 0) > 0 ? 'Add to Access Privilege' : 'Add contact selections',
    dialogTitle: 'Add contacts selections to this Access Privilege',
  });

export const useAddOrganizationsAction = ({ entities = [], options }: IGroupActionProps): IGroupConfig =>
  useAddEntitiesToAccessPrivilegeAction(entities, {
    ...commonOptions,
    ...options,
    groupType: GroupTypes.ACCESS_PRIVILEGE,
    entityType: 'organizations',
    title: (options.items?.length || 0) > 0 ? 'Add to Access Privilege' : 'Add organizations',
    dialogTitle: 'Add organizations to this Access Privilege',
  });
