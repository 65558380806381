import { map, omit, sortBy, compact } from 'lodash';
import moment from 'moment';
import { EventInput } from '@fullcalendar/react';
import { ResourceInput } from '@fullcalendar/resource-common';
import { CalGenericLocation } from '@mediafellows/mm3-types';

import { getMeetings, searchMeetings } from 'utils/apis/meeting';
import { getLocations } from 'utils/apis/location';
import { queryContacts } from 'utils/apis/contacts';
import { queryGroups } from 'utils/apis/groups';
import { isBetween } from 'utils/date';

import { IContact, IMobileAppSync, GroupTypes, ItemId, ISearchFilter, IWithRequired, IQueryParams } from 'types';
import { IInfo } from 'types/calendar';
import { convertToMoment, formatTime } from './utils';
import { getShowcaseByPurpose } from 'utils/apis/showcase';
import { getAllListItems } from 'utils/apis/showcase-item';

export const hostSchema = 'id, first_name, last_name, email, function, organization_name';
const hostsPurposeGroupName = 'meetings_hosts';

export const fetchSchedulerHosts = async (queryStringOrParams: string | IQueryParams): Promise<IContact[]> => {
  const queryStr = (
    (typeof queryStringOrParams === 'string' ? queryStringOrParams : queryStringOrParams.q) || ''
  ).trim();
  const ids = typeof queryStringOrParams === 'object' ? queryStringOrParams.ids : undefined;

  if (!ids?.length) {
    const showcase = await getShowcaseByPurpose(hostsPurposeGroupName);
    if (!showcase?.id) {
      return queryContacts({ q: queryStr }, undefined, hostSchema);
    }
    const listItems = await getAllListItems(showcase.id);
    const hostIds = listItems.map((e) => e.entity_id);
    const showcaseContacts = await queryContacts({ q: queryStr, ids: hostIds }, undefined, hostSchema);

    return sortBy(showcaseContacts, ({ id }) => hostIds.indexOf(parseInt(id)));
  }

  return queryContacts({ ids }, undefined, hostSchema);
};

export async function fetchMeetings(info: IInfo): Promise<EventInput[]> {
  const meetings = await getMeetings(info);
  const events = map(meetings, ({ id, starts_at, ends_at, location_id, host_id, ...rest }) => {
    return {
      id: id?.toString(),
      start: starts_at,
      end: ends_at,
      resourceId: location_id?.toString(),
      host_id: host_id,
      ...rest,
    };
  });

  return events;
}

export async function loadMeetings(info: IInfo): Promise<EventInput[]> {
  const params = omit(info, ['start', 'end', 'startStr', 'endStr', 'timeZone', 'timezone']) as ISearchFilter;
  const meetings = await searchMeetings(params);

  const { timeZone = '' } = info;

  const events = map(meetings, ({ id, starts_at, ends_at, location_id, host_id, ...rest }) => {
    const starts_at_utc: moment.Moment = convertToMoment(starts_at, timeZone, null, 'en');
    const ends_at_utc: moment.Moment = convertToMoment(ends_at, timeZone, null, 'en');
    const resourceIds = [location_id?.toString(), host_id?.toString()];

    return {
      id: id?.toString(),
      start: starts_at_utc.format(),
      end: ends_at_utc.format(),
      location_id: location_id,
      resourceIds: compact(resourceIds),
      starts_at,
      ends_at,
      host_id,
      ...rest,
    };
  });
  return events;
}

export const fetchRooms = async (info: IInfo, locationId?: ItemId): Promise<ResourceInput[]> => {
  const { start, end, timeZone } = info;

  let locations: CalGenericLocation[] = [];
  if (locationId) {
    locations = await getLocations(locationId);
  } else {
    locations = await getLocations();
  }
  const filteredLocations = locations.filter((location) => {
    if (moment(location.ends_at).add(1, 'days') <= moment(end)) return;

    return location;
  });
  // transform data to match plugin expectations
  const rooms = map(filteredLocations, (loc) => {
    const locationEndAt = new Date(loc.ends_at);
    const locationStartAt = new Date(loc.starts_at);
    const endsToday = isBetween(locationEndAt, start, end);
    const startsToday = isBetween(locationStartAt, start, end);
    const closedLocation = moment(loc.ends_at) < moment(start) || moment(loc.starts_at) > moment(end);
    const timeOptions: Intl.DateTimeFormatOptions = { timeZone, hourCycle: 'h23' };

    return {
      ...loc,
      id: loc?.id?.toString(),
      title: loc.name,
      timezone: loc.time_zone,
      businessHours: {
        daysOfWeek: closedLocation ? [] : [0, 1, 2, 3, 4, 5, 6],
        startTime: startsToday ? formatTime(locationStartAt, timeOptions) : '00:00',
        endTime: endsToday ? formatTime(locationEndAt, timeOptions) : '24:00',
      },
    };
  });

  return rooms;
};

export const querySelections = async (): Promise<IWithRequired<IMobileAppSync, 'name'>[]> => {
  return queryGroups({}, [['type', 'eq', GroupTypes.MOBILE_APP_SYNC]]) as Promise<
    IWithRequired<IMobileAppSync, 'name'>[]
  >;
};

export const getHostById = async (id: number): Promise<boolean> => {
  const showcase = await getShowcaseByPurpose(hostsPurposeGroupName);
  const listItems = await getAllListItems(showcase?.id);
  const hostIds = listItems.map((e) => e.entity_id);

  return hostIds.includes(id);
};
