import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router';

import { chipmunk } from 'utils/chipmunk';
import { applyParametronFilters, applyPresetToFilters, filtersAreEqual, filtersToPreset } from './helpers';
import { IFiltersDefinition, IPreset, FilterActions } from './types';
import { IDataSectionStore } from 'store/data-section-store';
import { useStore } from 'store';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';

type IChangeHandler<T> = (newValues: Partial<T>) => void;
type ISaveHandler = (name: string) => Promise<[string, boolean]>;
type ILoadPresetHandler = (preset: IPreset) => void;
type IDeletePresetHandler = (preset: IPreset) => void;
type IOnClear = () => void;

export interface IFiltersHandlers<T> {
  onChange: IChangeHandler<T>;
  onSubmit: React.FormEventHandler<HTMLFormElement> & React.MouseEventHandler<HTMLElement>;
  onClear: IOnClear;
  onSavePreset: ISaveHandler;
  onLoadPreset: ILoadPresetHandler;
  onDeletePreset: IDeletePresetHandler;
}

export type IFiltersSubmitHandler = (clearParams: FilterActions | null) => void;
export type IUseFiltersReturn<T> = [T, IFiltersHandlers<T>];

export const useFilters = <T extends IFiltersDefinition, P extends IObject = IObject>(
  dataSectionStore: IDataSectionStore<P>,
  handleSubmit: IFiltersSubmitHandler,
): IUseFiltersReturn<T> => {
  const {
    searchStore,
    defaultFilters,
    initFilters,
    updateStore,
    presets,
    target,
    setParams: setDataSectionParams,
    getParams: getDataSectionParams,
  } = dataSectionStore;
  const filters = dataSectionStore.filters as T;

  const { toastStore } = useStore();
  const action = useRef<FilterActions | null>(null);

  const onChange = useCallback(
    (newValues: Partial<T>) => {
      const hasSameFilterValue = Object.entries(newValues).every(([key, value]) =>
        filtersAreEqual(value, filters[key]),
      );

      if (hasSameFilterValue) {
        return;
      }

      updateStore({ filters: { ...filters, ...newValues } });
    },
    [filters, updateStore],
  );

  const loadPresets = useCallback(() => {
    chipmunk.run(
      async () => {
        const presets = (await chipmunk.action('um.saved_search', 'query', { params: { target } }))
          .objects as IPreset[];
        updateStore({ presets });
      },
      () => {
        toastStore.error('Presets have failed to load');
      },
    );
  }, [target, toastStore, updateStore]);

  useEffect(() => {
    loadPresets();
  }, [loadPresets]);

  const applyFilters = useCallback(() => {
    if (!searchStore) {
      return;
    }
    const hasNewFilters = applyParametronFilters(searchStore, filters, defaultFilters || {}, initFilters || {});

    if (!hasNewFilters && !action.current) {
      return;
    }

    handleSubmit?.(action.current);
    action.current = null;
  }, [handleSubmit, defaultFilters, filters, searchStore, initFilters]);

  // probably won't be used?
  const onSubmit = useCallback<React.ReactEventHandler>(
    (e) => {
      e.preventDefault();

      if (!searchStore) {
        return;
      }
      applyFilters();
    },
    [applyFilters, searchStore],
  );

  const onClear = useCallback(() => {
    if (!searchStore) {
      return;
    }

    updateStore({ filters: { ...defaultFilters }, selectedPreset: null });
    action.current = FilterActions.CLEAR;
  }, [defaultFilters, searchStore, updateStore]);

  const onSavePreset = useCallback(
    (name: string) => {
      const asyncPresetSave = async (): Promise<[string, boolean]> => {
        const search_params = getDataSectionParams();

        // for special case in product asset details
        // include_descendants is actually a param, but UI treats it differently
        delete search_params['include_descendants'];

        const preset = {
          description: name,
          target,
          filters: filtersToPreset(filters),
          search_params,
        };
        const existingPreset = presets.find((preset) => preset.description === name);
        let isUpdate, newPreset;

        chipmunk.run(async () => {
          if (existingPreset) {
            isUpdate = true;
            newPreset = (
              await chipmunk.action('um.saved_search', 'update', { body: preset, params: { id: existingPreset.id } })
            ).objects[0];

            const updatedPresets = presets.map((preset) => (preset.id === newPreset.id ? newPreset : preset));
            updateStore({ presets: updatedPresets, selectedPreset: newPreset });
          } else {
            newPreset = (await chipmunk.action('um.saved_search', 'create', { body: preset })).objects[0];
            updateStore({ presets: [...presets, newPreset], selectedPreset: newPreset });
          }
        });
        return [name, isUpdate];
      };

      return asyncPresetSave();
    },
    [filters, presets, target, updateStore, getDataSectionParams],
  );

  const onDeletePreset = useCallback(
    (preset) => {
      const { description, id } = preset;
      chipmunk.run(
        async (chipmunk) => {
          const params = { body: { id }, schema: `description` };
          await chipmunk.action('um.saved_search', 'delete', params);

          updateStore({ selectedPreset: null });
          onClear();
          loadPresets();
          toastStore.success(`Preset "${description}" deleted`);
        },
        () => {
          toastStore.error(`Deleting "${description}" preset has failed`);
        },
      );
    },
    [onClear, updateStore, loadPresets, toastStore],
  );

  const onLoadPreset = useCallback(
    (preset: IPreset) => {
      setDataSectionParams(preset.search_params as unknown as Record<string, unknown>);
      action.current = FilterActions.LOAD_PRESET;
      const newFilters = applyPresetToFilters(defaultFilters, preset.filters, initFilters);
      updateStore({ filters: { ...filters, ...newFilters }, selectedPreset: preset });
    },
    [filters, updateStore, defaultFilters, setDataSectionParams, initFilters],
  );

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const navigate = useNavigate();
  // this sets the dataSectionStore.resetAllListFilters to the Clear filters action
  // to enable resetting filters from the sidebar sections, see #5039
  useEffect(() => {
    const resetAllListFilters = (): void => {
      const params = getDataSectionParams();
      navigate({ pathname: location.pathname, search: queryString.stringify({ ...params, page: 1 }) });
      onClear();
    };

    updateStore({ resetAllListFilters });
  }, [getDataSectionParams, navigate, onClear, updateStore]);

  const handlers = useMemo(
    () => ({ onChange, onSubmit, onClear, onSavePreset, onLoadPreset, onDeletePreset }),
    [onChange, onSubmit, onClear, onSavePreset, onLoadPreset, onDeletePreset],
  );

  return [filters, handlers];
};
