import React from 'react';
import { observer } from 'mobx-react-lite';

import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { FormSelect, FormChecklist, filterAssetStatusOptions, FormCheckbox, FormAccessLevel } from 'helpers/form';
import type {
  IAssetMm3MetadataEditTabProps,
  IAssetOldMetadataEditTabProps,
} from 'components/asset/edit-sections/types';

import { withAssetVersion } from 'utils/asset';
import { amWriteProtectedAttributes, hasPower } from 'utils/powers';
import { CustomSettingsEditRow } from 'pages/asset-details/asset-metadata-tab/custom-row';
import { AccessLevelLabel } from 'utils/ui';

const formId = 'edit-asset-metadata';

export const AssetOldMetadataEditSettingsSection: React.FC<IAssetOldMetadataEditTabProps> = observer(({ form }) => {
  const { formData, handlers } = form;

  const statusOptions = filterAssetStatusOptions(formData.status?.options);

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="Settings">
      <Row
        label="Status"
        showAsterisk
        value={<FormSelect name="status" large {...{ ...formData.status }} {...handlers} options={statusOptions} />}
        size="m"
      />
      <Row
        label={<AccessLevelLabel label={'Access Level'} required options={formData.access_level?.options} />}
        value={
          <FormAccessLevel
            name="access_level"
            placeholder="Select Access Level"
            large
            {...formData.access_level}
            {...handlers}
          />
        }
        size="m"
      />
      <Row
        label="Sharable"
        showAsterisk
        value={
          <FormCheckbox
            name="sharable"
            label="Allow non-Administrator contacts to share this asset with other contacts"
            formId={formId}
            {...formData.sharable}
            {...handlers}
            disabled={!hasPower(amWriteProtectedAttributes)}
          />
        }
        size="m"
      />
      <Row
        label="Permissions"
        value={<FormChecklist name="permissions" formId={formId} {...formData.permissions} {...handlers} />}
        size="m"
      />
      <Row
        label="protection levels"
        value={<FormChecklist name="protection_levels" formId={formId} {...formData.protection_levels} {...handlers} />}
      />
    </InfoBoxV2>
  );
});

export const AssetMm3MetadataEditSettingsSection: React.FC<IAssetMm3MetadataEditTabProps> = observer(({ form }) => {
  const { formData, handlers } = form;

  const statusOptions = filterAssetStatusOptions(formData.status?.options);

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="Settings">
      <Row
        label="Status"
        showAsterisk
        value={<FormSelect name="status" large {...{ ...formData.status }} {...handlers} options={statusOptions} />}
        size="m"
      />

      <CustomSettingsEditRow formData={formData} handlers={handlers} />

      <Row
        label={<AccessLevelLabel label={'Access Level'} required options={formData.access_level?.options} />}
        value={
          <FormAccessLevel
            name="access_level"
            placeholder="Select Access Level"
            large
            {...formData.access_level}
            {...handlers}
          />
        }
        size="m"
      />
      <Row
        label="Sharable"
        showAsterisk
        value={
          <FormCheckbox
            name="sharable"
            label="Allow non-Administrator contacts to share this asset with other contacts"
            formId={formId}
            {...formData.sharable}
            {...handlers}
            disabled={hasPower(amWriteProtectedAttributes)}
          />
        }
        size="m"
      />
      <Row
        label="Permissions"
        value={<FormChecklist name="permissions" formId={formId} {...formData.permissions} {...handlers} />}
        size="m"
      />
      <Row
        label="protection levels"
        value={<FormChecklist name="protection_levels" formId={formId} {...formData.protection_levels} {...handlers} />}
      />
    </InfoBoxV2>
  );
});

export const AssetMetadataEditSettingsSection = withAssetVersion(
  AssetOldMetadataEditSettingsSection,
  AssetMm3MetadataEditSettingsSection,
);
