import React from 'react';
import { map, startCase } from 'lodash';
import { IAsset, IGroupAssetItem, IProduct, ISearchFilter, ItemId } from 'types';

import { ToastError } from 'components/toast';
import { getRootStore } from 'store';
import { IOmniTreeSelectOption } from 'helpers/form';
import { ProductListItem, AssetListItem } from 'components/marketing-entity-detail';

import { by, rearrangeItems } from 'utils/general';
import { mapProductToAncestryInfo } from 'utils/product';
import {
  loadPropsFromExistingProductItems,
  loadProductGroupItems,
  removeAssetFromGroup,
  addAssetToGroup,
} from 'utils/apis/groups';
import { getProductsWithAncestryInfo, queryAllProducts, getProductsAncestryInfo } from 'utils/apis/product';
import { queryClassificationAssetsOfProducts } from 'utils/apis/product-asset';
interface IProductWithAssets {
  product: IProduct;
  assets: IAsset[];
}

export const getGroupItemsIds = (assets: IAsset[], groupAssets?: IGroupAssetItem[], assetIds?: ItemId[]): string[] => {
  const groupAssetsByIds = by(groupAssets || [], 'asset_id');
  const groupItems = assets.reduce((acc, asset) => {
    if (assetIds && assetIds?.includes(asset.id)) {
      return [...acc, String(asset.id)];
    }
    if (groupAssetsByIds?.[asset.id]) {
      return [...acc, String(asset.id)];
    }
    return acc;
  }, []);
  return groupItems;
};

export const getGroupItemsIdsFromAssetIds = (item_ids, assetIdsToGroupIds): string[] => {
  return item_ids.map((item_id) => assetIdsToGroupIds[item_id]);
};

export const getProductsWithAssets = async (
  groupId?: ItemId,
  product_ids?: number[] | null | undefined,
  assetFilters?: ISearchFilter[],
): Promise<IProductWithAssets[]> => {
  let products: IProduct[] = [];
  // for non group use cases (recommendation), always include all descendants
  let includeDescendants = true;

  if (product_ids) {
    const result = await getProductsWithAncestryInfo({ product_ids });
    products = rearrangeItems(result, product_ids);
  } else if (groupId) {
    includeDescendants = (await loadPropsFromExistingProductItems(groupId)).include_descendants;
    products = await loadProductGroupItems({
      group_ids: groupId,
      allItems: true,
      withAncestryInfo: true,
      sort: 'sequence_number',
      order: 'asc',
    });
  }
  const productIds = products.map((e) => e.id);
  let missingDescendants: IProduct[] = [];
  if (includeDescendants && productIds.length > 0) {
    missingDescendants = await queryAllProducts({}, [
      ['ancestor_ids', 'in', productIds],
      ['id', 'not_in', productIds],
    ]);
  }
  const descendantsAncestryInfo = await getProductsAncestryInfo(missingDescendants.map((e) => e.id));
  const descendants = mapProductToAncestryInfo(missingDescendants, descendantsAncestryInfo);
  const allProducts = [...(products || []), ...(descendants || [])];
  const assets = await queryClassificationAssetsOfProducts(
    map(allProducts, 'id'),
    true,
    true,
    product_ids ? '*video*,*document*' : '*video*',
    assetFilters,
  );

  return [...products, ...descendants].reduce((acc, product) => {
    const productAssets = assets.filter(({ product_ids }) => product_ids?.includes(product.id));
    if (productAssets.length) {
      return [...acc, { product, assets: productAssets }];
    }

    return acc;
  }, []);
};

export const deleteGroupAssets = async ({
  groupId,
  assetsToUpdate,
  assetIdsToGroupIds,
}): Promise<void | IGroupAssetItem[]> => {
  const { toastStore } = getRootStore();
  if (!assetsToUpdate.length) return;
  try {
    const item_ids = getGroupItemsIdsFromAssetIds(assetsToUpdate, assetIdsToGroupIds);
    const deletedGroupItem = await removeAssetFromGroup({ group_ids: [groupId], item_ids });
    toastStore.clearAll();
    toastStore.success('Mobile Selection Assets updated successfully');
    return deletedGroupItem;
  } catch (error) {
    toastStore.error(<ToastError error={error} />);
  }
};

export const addGroupAssets = async ({ groupId, assetsToUpdate }): Promise<void | IGroupAssetItem[]> => {
  const { toastStore } = getRootStore();

  if (!assetsToUpdate.length) return;
  try {
    const addedGroupItem = await addAssetToGroup({ group_id: groupId, item_ids: assetsToUpdate });
    toastStore.clearAll();
    toastStore.success('Mobile Selection Assets updated successfully');
    return addedGroupItem;
  } catch (error) {
    toastStore.error(<ToastError error={error} />);
  }
};
interface IGetFlatOptionsTreeParams {
  setCheckedVideos: React.Dispatch<React.SetStateAction<IOmniTreeSelectOption[]>>;
  productWithAssets?: IProductWithAssets[];
  assetIdsToGroupItemIds?: Record<string | number, string | number | undefined>;
  assetIds?: number[] | null | undefined;
  propGroupId?: ItemId;
  disabled: boolean;
  checked: string[][];
  setCurrentFilters: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  setCheckedClassifications?: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  setClassificationsCount?: React.Dispatch<React.SetStateAction<Record<string, number>>>;
}

const formatSetsToCount = (idsSet: Record<string, Set<ItemId>>): Record<string, number> =>
  Object.entries(idsSet).reduce((acc, [key, ids]) => ({ ...acc, [key]: ids.size }), {});

export const getFlatOptionsTree = ({
  setCheckedVideos,
  productWithAssets,
  assetIdsToGroupItemIds,
  assetIds,
  propGroupId,
  disabled,
  checked,
  setCurrentFilters,
}: IGetFlatOptionsTreeParams): [IOmniTreeSelectOption[][], Record<string, number>, Record<string, number>] => {
  setCheckedVideos([]);

  // we will use a Set to handle the case where the same asset is assigned to multiple products
  const classificationsCount: Record<string, Set<ItemId>> = {};
  const checkedClassifications: Record<string, Set<ItemId>> = {};

  const flatOptionsTree = (productWithAssets || []).map(({ product, assets }, index) => {
    const uncheckedCountByClassification = assets.reduce(
      (acc, { main_classification }: IAsset) => ({ [main_classification || 'unclassified_asset']: 0, ...acc }),
      {},
    );
    const checkedMainClassifications = { ...uncheckedCountByClassification };
    const mainClassificationCount = { ...uncheckedCountByClassification };
    const videos = assets.reduce((acc: IOmniTreeSelectOption[], asset) => {
      const mainClassification = asset.main_classification || 'unclassified_asset';
      mainClassificationCount[mainClassification]++;
      if (disabled) {
        const isRecommendationAndAssetNotInAssetIds = assetIds && !assetIds.includes(Number(asset.id));
        const isGroupAndAssetNotGroupItem = propGroupId && !assetIdsToGroupItemIds?.[asset.id];

        if (isGroupAndAssetNotGroupItem || isRecommendationAndAssetNotInAssetIds) {
          uncheckedCountByClassification[mainClassification]++;
          return acc;
        }
      }

      const assetElement: IOmniTreeSelectOption = {
        label: <AssetListItem asset={asset} />,
        value: String(asset.id),
        className: 'tree-item',
        isParent: false,
        file_size: asset?.file_size,
        classification: asset?.classification,
      };

      const classification = asset?.classification || '';
      // we get the classifications available with number of videos for each
      if (!classificationsCount[classification]) {
        classificationsCount[classification] = new Set([]);
      }
      classificationsCount[classification]?.add(asset.id);

      const isAssetOfClassificationChecked = checked[index]?.includes(String(asset.id));
      if (isAssetOfClassificationChecked) {
        // we get count of checked videos for each classification (non-unique)
        if (!checkedClassifications[classification]) {
          checkedClassifications[classification] = new Set([]);
        }
        checkedClassifications[classification].add(asset.id);
        checkedMainClassifications[mainClassification] = (checkedMainClassifications[mainClassification] ?? 0) + 1;
      }
      return [...acc, { ...assetElement, parentId: mainClassification }];
    }, []);

    const productElement: IOmniTreeSelectOption = {
      className: `tree-item ${!videos.length && 'empty'}`,
      showCheckbox: false,
      label: <ProductListItem product={product as IProduct & { assets: IAsset[] }} />,
      value: String(product.id),
      isParent: true,
    };

    let classifications: IOmniTreeSelectOption[] = [];
    if (videos.length) {
      const checkedVideos = videos.filter((video) => checked[index]?.includes(video.value));
      setCheckedVideos((prev) => [...prev, ...checkedVideos]);
      classifications = Object.entries(mainClassificationCount)
        .filter(([, count]) => count)
        .map(([classification, count]) => ({
          className: 'tree-item tree-item-assets',
          label: `${startCase(classification)}s ${checkedMainClassifications[classification]} / ${count}`,
          value: classification,
          parentId: String(product.id),
          isParent: true,
        }));
    }

    return [productElement, ...classifications, ...videos];
  });

  setCurrentFilters((filters) => {
    const selected = { select_all: true };

    Object.entries(classificationsCount).forEach(([key, ids]) => {
      const allVideosOfClassChecked = ids?.size === checkedClassifications[key]?.size;

      selected.select_all = allVideosOfClassChecked;
      const mainClassification = key.split('/')[0];
      if (mainClassification) {
        selected[`select_${mainClassification}s`] = allVideosOfClassChecked;
      }

      filters[key] = allVideosOfClassChecked;
    });
    return {
      ...filters,
      ...selected,
    };
  });

  return [flatOptionsTree, formatSetsToCount(classificationsCount), formatSetsToCount(checkedClassifications)];
};

interface ICheckUpdatedAssetsParams {
  flatOptionsTree: IOmniTreeSelectOption[][];
  filterName: string;
  isFilterChecked: boolean;
  isSelectAll: boolean;
  setChecked: React.Dispatch<React.SetStateAction<string[][]>>;
  setUpdatedAssets: React.Dispatch<React.SetStateAction<Record<string | number, boolean> | undefined>>;
}
export const checkUpdatedAssets = ({
  flatOptionsTree,
  filterName,
  isFilterChecked,
  isSelectAll,
  setChecked,
  setUpdatedAssets,
}: ICheckUpdatedAssetsParams): void => {
  const isMainCategoryFilter = filterName.startsWith('select_') && filterName.endsWith('s');
  const mainCategory = filterName.replace(/^select_/, '').replace(/s$/, '');

  const assetsToCheck = flatOptionsTree.reduce((acc, productTree, index) => {
    const editedAssetIds = productTree.reduce((classAcc, { value, classification }) => {
      const itemHasSelectedFilterClass =
        classification &&
        (classification === filterName || (isMainCategoryFilter && classification.startsWith(mainCategory)));

      if (!itemHasSelectedFilterClass && !isSelectAll) return classAcc;

      const isAssetFound = productTree.find((treeItem) => treeItem.value === value);
      if (!isAssetFound) return;

      setChecked(([...tree]) => {
        if (isFilterChecked) {
          tree[index] = [...tree[index], value];
        } else {
          tree = tree.map((branch) => branch.filter((id) => id !== value));
        }
        return tree;
      });
      const isValueValidId = !isNaN(Number(value));
      return isValueValidId ? { ...classAcc, [value]: value } : classAcc;
    }, {});

    return editedAssetIds && Object.keys(editedAssetIds).length ? [...acc, ...Object.keys(editedAssetIds)] : acc;
  }, [] as string[]);

  const updatedAssetIds = assetsToCheck.reduce((obj, cur) => {
    return { ...obj, [cur]: isFilterChecked };
  }, {});

  setUpdatedAssets((prev) => {
    return { ...prev, ...updatedAssetIds };
  });
};
