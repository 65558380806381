import React from 'react';
import cx from 'classnames';
import { CloseButton } from '@mantine/core';

import { UmGenericListCollection } from '@mediafellows/mm3-types';

import { Thumbnail } from 'components/thumbnail';
import { typeParser } from 'utils/list-item';
import { IFormMultiSelectOption } from 'helpers/form';
import { Classes } from 'utils/ui';

interface ICollectionSearchItem {
  collection: UmGenericListCollection;
  handleSelect: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onRemove?: (item: IFormMultiSelectOption) => void;
}

const CollectionSearchItem: React.FC<ICollectionSearchItem> = ({ collection, handleSelect, onRemove }) => {
  const { name, id, meta, purpose, preview_image, type } = collection;
  const description = meta?.description ? ` | ${meta.description}` : '';
  const collectionInfo = `${purpose} ${description}`;
  const parsedType = typeParser(collection);

  return (
    <li key={`${id}-collection`} className="entity-search-list-item">
      <div
        className={cx(`searchbox__showcase ${Classes.MENU_ITEM}`, { 'searchbox__with-delete': onRemove })}
        data-id={id}
        data-item-type={parsedType}
        data-item-name={name}
        onClick={handleSelect}
      >
        <div className="searchbox__thumb">
          <Thumbnail image={preview_image?.url} showType customTag={parsedType} assetType={type} />
        </div>
        <div>
          <div className="d-flex">
            <span className="text-truncate">{name}</span>
          </div>
          <div className="d-flex text-truncate">
            <span className={Classes.TEXT_MUTED}>{collectionInfo}</span>
          </div>
        </div>
        {onRemove && <CloseButton onClick={() => onRemove(collection as IFormMultiSelectOption)} />}
      </div>
    </li>
  );
};

export { CollectionSearchItem };
