import React from 'react';
import { ActionIcon, CloseButton, Flex, Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MantineIcon } from 'utils/ui';
import { IFormSelectOption } from 'helpers/form';

const helperText = {
  private: (
    <p>
      <b>Private:</b> available only to you personally (and to high-level administrators).
    </p>
  ),
  division: (
    <p>
      <b>Divisions*:</b> available to all contacts in your division(s); not available to client contacts.
    </p>
  ),
  company: (
    <p>
      <b>Company:</b> available to all contacts in your organization; not available to client contacts.
    </p>
  ),
  viewable: (
    <p>
      <b>Viewable:</b> available to all internal contacts; visible to logged-in client contacts.
    </p>
  ),
  public: (
    <p>
      <b>Public:</b> available to all internal contacts; visible to logged-in client contacts and public visitors (in
      case your platform includes a public catalog).
    </p>
  ),
};

export const AccessLevelLabel: React.FC<{
  label?: string;
  required?: boolean;
  options?: (string | number | IFormSelectOption)[];
}> = ({ label = '', required = false, options }): JSX.Element => {
  const [opened, { close, open }] = useDisclosure(false);
  const optionLabels = options?.map((option) => (typeof option === 'object' ? option.label : option));
  const filteredText = Object.entries(helperText).filter((entry) => optionLabels?.includes(entry[0]));
  const hasDivisions = options?.find((option) => option === 'division');

  return (
    <Flex align="center">
      {required && label ? `${label}*` : label}
      <Popover withArrow opened={opened} position="top">
        <Popover.Target>
          <ActionIcon
            variant="subtle"
            color="gray.5"
            radius="sm"
            className="mx-2"
            onClick={opened ? close : open}
            onBlur={close}
            size="xs"
          >
            <MantineIcon icon="help" size="14" />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown maw={500}>
          <Flex direction="row">
            <div className="p-3">
              {filteredText.map(([, value], index) => (
                <React.Fragment key={index}>{value}</React.Fragment>
              ))}
              {hasDivisions && <p className="muted-text">*only available if Divisions exist in your platform</p>}
            </div>
            <CloseButton onClick={close} className="align-self-start" style={{ background: 'transparent' }} />
          </Flex>
        </Popover.Dropdown>
      </Popover>
    </Flex>
  );
};
