import React, { useCallback } from 'react';
import { FormMultiSelect } from 'helpers/form';

import { IAccessPrivilegeFormComponent, IAccessPrivilegeForm } from './types';
import { isValid } from 'helpers/form';
import { IQueryParams, IItem } from 'types';
import { queryContacts } from 'utils/apis/contacts';
import { queryContactSelections } from 'utils/apis/recipients';
import { queryOrganizations } from 'utils/apis/organization';

import './style.scss';

export const keysContacts = ['contacts', 'organization_ids'];
export const isValidContactsStep = (form: IAccessPrivilegeForm): boolean => {
  if (!isValid(form.formData, keysContacts)) {
    return false;
  }
  return form.values.organization_ids.length > 0 || form.values.contacts.length > 0;
};

const fetchValues = async ({ q, ids }: IQueryParams): Promise<IItem[]> => {
  const contacts = await queryContacts({ q, ids });
  const selections = await queryContactSelections({ q, ids });
  return [...contacts, ...selections];
};

export const AccessPrivilegeGrantFormContacts: React.FC<IAccessPrivilegeFormComponent> = ({ form }) => {
  const onContactChange = useCallback(
    (contacts) => {
      form.handlers.onChange({ contacts, contact_ids: contacts?.map((e) => e?.value) });
    },
    [form.handlers],
  );

  return (
    <div className="d-flex flex-column gap-1 h-100">
      <div className="access-privilege-wizard__recipients-container">
        <FormMultiSelect
          name="contact_ids"
          label="Contacts"
          omni
          fetchValues={fetchValues}
          {...form.formData.contact_ids}
          onSelectedItemsChange={onContactChange}
          onBlur={form.handlers.onBlur}
          required={form.values.organization_ids.length < 1}
          fitInParent
        />
        <FormMultiSelect
          omni
          name="organization_ids"
          label="Organizations"
          fetchValues={queryOrganizations}
          {...form.formData.organization_ids}
          {...form.handlers}
          required={form.values.contacts.length < 1}
          fitInParent
        />
      </div>
    </div>
  );
};
