import React, { useCallback } from 'react';
import { FormMultiSelect } from 'helpers/form';

import { ISmartGroupForm, ISmartGroupFormComponent } from 'types/smart-group';
import { isValid } from 'helpers/form';
import { IQueryParams, IItem } from 'types';
import { queryContacts } from 'utils/apis/contacts';
import { queryContactSelections } from 'utils/apis/recipients';

const keysContacts = ['contact_ids'];
export const isValidContactsStep = (form: ISmartGroupForm): boolean => {
  if (!isValid(form.formData, keysContacts)) {
    return false;
  }
  return Boolean(form.values.contacts?.length);
};

const fetchValues = async ({ q, ids }: IQueryParams): Promise<IItem[]> => {
  const contacts = await queryContacts({ q, ids });
  const selections = await queryContactSelections({ q, ids });
  return [...contacts, ...selections];
};

export const SmartGroupFormContacts: React.FC<ISmartGroupFormComponent> = ({ form }) => {
  const { values, formData, handlers } = form;
  const onContactChange = useCallback(
    (contacts) => {
      form.handlers.onChange({ contacts, contact_ids: contacts?.map((e) => e?.value) });
    },
    [form.handlers],
  );

  return (
    <div className="h-100">
      <FormMultiSelect
        name="contact_ids"
        label="Contacts"
        fitInParent
        omni
        fetchValues={fetchValues}
        {...formData.contact_ids}
        onSelectedItemsChange={onContactChange}
        onBlur={handlers.onBlur}
        required={!values.organization_ids?.length}
      />
    </div>
  );
};
