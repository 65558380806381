import React, { useCallback, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import cx from 'classnames';

import { DraggableType } from 'components/data-section-selected-tab';
import useSelectedOption from 'utils/hooks/selected-option';
import { ISortableItem } from 'store/sort-data-store';
import { IProduct } from 'types';

import { ICard } from './sort-data-section';
import { MantineIcon } from 'utils/ui/icon';

export interface IDraggableProductProps {
  item: ISortableItem;
  moveCard: (id: string, to: number, options?: { wasCardDropped?: boolean }) => void;
  moveByClick: (card: ICard, direction: 'up' | 'down') => void;
  findCard: (id: string) => { index: number };
  onClick: (item: ISortableItem) => void;
  maxLength: number;
  itemRenderer: (item: ISortableItem) => React.ReactElement;
  onRemove?: (id: number | string) => void;
  isActive?: boolean;
  withCustomSorting?: boolean;
  hideDivisionAccessLevel?: boolean;
}

interface IItem {
  type: string;
  id: string;
  originalIndex: string;
}

export const SortDataSectionItem: React.FC<IDraggableProductProps> = (props) => {
  const {
    item,
    moveCard,
    findCard,
    moveByClick,
    maxLength,
    itemRenderer,
    onRemove,
    onClick,
    isActive = false,
    hideDivisionAccessLevel = false,
  } = props;
  const { id } = item;
  const [canDrag, setCanDrag] = useState<boolean>(true);
  const card = findCard(id as string);

  const originalIndex = card?.index;

  const [{ isDragging }, drag] = useDrag({
    item: { type: DraggableType.GROUP_ITEM, id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag,
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();

      if (!didDrop) {
        moveCard(droppedId, originalIndex);
      } else if (props.withCustomSorting) {
        moveCard(droppedId, originalIndex, { wasCardDropped: true });
      }
    },
  });

  const [, drop] = useDrop({
    accept: DraggableType.GROUP_ITEM,
    canDrop: () => false,
    hover({ id: draggedId }: IItem): void {
      if (draggedId === id) {
        return;
      }

      const { index: overIndex } = findCard(id as string);
      moveCard(draggedId, overIndex);
    },
  });

  const handleRef = useCallback(
    (node): void => {
      drag(drop(node));
    },
    [drag, drop],
  );

  const handleClickUp: React.ReactEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      moveByClick(card, 'up');
    },
    [card, moveByClick],
  );

  const handleClickDown: React.ReactEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      moveByClick(card, 'down');
    },
    [card, moveByClick],
  );

  const toggleCanDrag = useCallback((): void => {
    setCanDrag((canDrag) => !canDrag);
  }, []);

  const selectedOption = useSelectedOption({
    value: (item as IProduct)?.access_level || (item?.entity as IProduct)?.access_level,
    hideDivisionAccessLevel,
  });

  const handleRemove = useCallback<React.ReactEventHandler>(
    (e) => {
      e.stopPropagation();
      if (!id) {
        return;
      }
      onRemove?.(id);
    },
    [id, onRemove],
  );

  const handleClick = useCallback<React.ReactEventHandler>(
    (e) => {
      e.stopPropagation();
      onClick(item);
    },
    [item, onClick],
  );
  const showRemove = Boolean(onRemove);
  return (
    <div
      className={cx('sort-data-section-item', {
        'sort-data-section-item--is-dragging': isDragging,
        'sort-data-section-item--is-active': isActive,
        'sort-data-section-item--no-remove-btn': !showRemove,
        [`access-level-${selectedOption?.color}`]: Boolean(!isActive && selectedOption),
        [`sort-data-section-item__active-access-level-${selectedOption?.color}`]: isActive,
      })}
      ref={handleRef}
      onClick={handleClick}
    >
      <div className="sort-data-section-item__arrows" onMouseEnter={toggleCanDrag} onMouseLeave={toggleCanDrag}>
        <div
          className={cx('sort-data-section-item__arrow-btn', {
            'sort-data-section-item__arrow-btn--disabled': originalIndex === 0,
          })}
          onClick={handleClickUp}
        >
          <MantineIcon icon="chevron-up" />
        </div>

        <div
          className={cx('sort-data-section-item__arrow-btn', {
            'sort-data-section-item__arrow-btn--disabled': originalIndex === maxLength - 1,
          })}
          onClick={handleClickDown}
        >
          <MantineIcon icon="chevron-down" />
        </div>
      </div>

      {itemRenderer(item)}
      {showRemove && (
        <div className={'sort-data-section-item__arrow-btn'} onClick={handleRemove}>
          <MantineIcon icon="cross" />
        </div>
      )}
    </div>
  );
};
