import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';

import { Pages } from 'utils/actions/types';
import { IProductActionName } from 'utils/actions/product/types';
import { useRefreshDataSection } from 'utils/hooks';
import { ProductDataSection } from 'components/product-data-section';
import { DataSectionSidebarTabs } from 'components/data-section-sidebar';

import { IProduct, ISearchFilter, Product3Types } from 'types';
import { getSortMenu } from './utils';
import { InFilter } from 'helpers/filters/types';
import { IFilters } from 'components/product-filters';

interface IChildrenTabPreviewProps {
  product: IProduct;
  type: Product3Types;
  refreshAncestry?: () => Promise<void>;
}
const params = {
  sort: 'flat_sequence_number',
  order: 'asc',
};

export const ProductChildrenTabPreview: React.FC<IChildrenTabPreviewProps> = ({ product, type }) => {
  const {
    dataSectionStore: { updateStore },
  } = useStore();
  const [childrenProductsFilter, initialFilters]: [ISearchFilter[], Partial<IFilters>] = useMemo(
    () => [
      [
        ['ancestor_ids', 'eq', product.id],
        ['type', 'in', [type]],
      ],
      { type: new InFilter('type', [type]) },
    ],
    [product.id, type],
  );

  const refreshDataSection = useRefreshDataSection();
  const actionOptions = useMemo(() => {
    const onSuccess = async (action?: IProductActionName): Promise<void> => {
      switch (action) {
        case IProductActionName.REMOVE_FROM_GROUP:
        case IProductActionName.DELETE:
        case IProductActionName.RESTORE:
          setTimeout(refreshDataSection, 1000);
          break;
        default:
          updateStore({ activeTab: DataSectionSidebarTabs.FILTERS, checked: [] });
      }
    };

    return { onSuccess };
  }, [refreshDataSection, updateStore]);

  return (
    <ProductDataSection
      getSortMenu={getSortMenu}
      persistentFilters={childrenProductsFilter}
      params={params}
      initialFilters={initialFilters}
      showParentsOnlyFilter={false}
      page={Pages.PRODUCT_CHILDREN_TAB}
      actionOptions={actionOptions}
    />
  );
};

export default observer(ProductChildrenTabPreview);
