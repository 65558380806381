import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';
import { Plus } from 'blueprint5-icons';

import { useBasicStore, useDataSectionStore, useStore } from 'store';

import { ToastError } from 'components/toast';
import { MantineIcon } from 'utils/ui';

import { useDivisionForm } from './use-division-form';
import { createDivision } from 'utils/apis/divisions';
import { delay } from 'utils/delay';

import { IDivisionFormData } from './division-form';
import { IDivision } from 'types';

export const CreateDivisionButton = observer((): JSX.Element => {
  const {
    toastStore,
    dialogStore: { close },
  } = useStore();
  const { searchStore } = useDataSectionStore<IDivision>();
  const { updateBasicStore, divisions } = useBasicStore();

  const handleSubmit = useCallback(
    async (values: IDivisionFormData): Promise<IDivision | void> => {
      try {
        const result = await createDivision(values);
        await delay();
        close();
        searchStore?.update({
          objects: [result, ...(searchStore?.objects || [])],
        });
        updateBasicStore('divisions', [result, ...(divisions || [])]);
        toastStore.clearAll();
        toastStore.success('Division created successfully!');
        return result;
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [close, divisions, searchStore, toastStore, updateBasicStore],
  );

  const onClick = useDivisionForm({ title: 'Create Division', handleSubmit });

  return (
    <Button size="xs" leftSection={<MantineIcon icon={<Plus />} />} variant="primary" onClick={onClick}>
      Create Division
    </Button>
  );
});
