import { useCallback } from 'react';

import { useStore } from 'store';

import { deleteDivision } from 'utils/apis/divisions';
import { Intent } from 'utils/ui';

import type { IDivision, ItemId } from 'types';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { IDivisionActionConfig, IDivisionActionName } from './types';

export const useDeleteAction = (items: IDivision[], options: IUseActionsOptionParam): IDivisionActionConfig => {
  const { toastStore, dialogStore, basicStore } = useStore();

  const executeDelete = useCallback(async () => {
    try {
      const itemIds = items.map((item) => item.id).filter(Boolean) as ItemId[];
      await deleteDivision(itemIds);
      options?.onSuccess?.(IDivisionActionName.DELETE, items);
      basicStore.updateBasicStore(
        'divisions',
        basicStore.divisions.filter((div) => !itemIds?.includes(div.id)),
      );
      toastStore.success('Division was deleted successfully!');
    } catch (error) {
      toastStore.error(`Division deletion failed: ${error.text}`);
      await options?.onFailure?.();
    }
  }, [items, options, toastStore, basicStore]);

  const handler = useCallback(() => {
    dialogStore.openConfirm({
      title: 'Confirm Delete',
      body: 'Are you sure you want to delete this division and all its children?',
      onConfirm: executeDelete,
    });
  }, [dialogStore, executeDelete]);

  return {
    name: IDivisionActionName.DELETE,
    shouldDisplay: () => true,
    icon: 'trash',
    title: 'Delete',
    intent: Intent.DANGER,
    handler,
  };
};
