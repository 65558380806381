import React, { useCallback } from 'react';

import { useDialogStore, useStore } from 'store';

import { ISelectionEntityType } from 'utils/actions/types';
import { FormSelectionItems } from './form-selection-items';

import { createAssetSelectionWithItems, createProductSelectionWithItems, createContactSelectionWithItems } from './api';
import { IItemId, IFormSelectionItemsForm } from './types';
import { IModalSize } from 'components/dialogs/types';
import { ToastError } from 'components/toast';
import { querySubAssets } from 'utils/apis/asset';
import { ItemId } from 'types';

const entityTypeToCallback = {
  assets: createAssetSelectionWithItems,
  products: createProductSelectionWithItems,
  contacts: createContactSelectionWithItems,
};

interface IUseSelectionDialogProps {
  itemIds: IItemId[];
  entityType: ISelectionEntityType;
}

export const useSelectionDialog = ({ entityType, itemIds }: IUseSelectionDialogProps): [() => void] => {
  const { dialogStore, toastStore } = useStore();
  const { close } = useDialogStore();

  const onConfirm = useCallback(
    async (values: IFormSelectionItemsForm): Promise<void> => {
      let subAssetIds: ItemId[] = [];
      try {
        const selectionParams = {
          name: values.name,
          access_level: values.access_level,
          main_entity_type: entityType,
        };

        if (entityType === 'assets') {
          const subAssets = await querySubAssets(values.item_ids, 'id');
          subAssetIds = subAssets.map((asset) => asset.id);
        }

        const createSelectionWithItems = entityTypeToCallback[entityType];
        await createSelectionWithItems(selectionParams, {
          ...values,
          item_ids: [...values.item_ids, ...(subAssetIds || [])],
        });
        close();

        toastStore.success('Success');
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      }
    },
    [entityType, close, toastStore],
  );

  const body = useCallback((): JSX.Element => {
    return <FormSelectionItems entityType={entityType} itemIds={itemIds} onConfirm={onConfirm} />;
  }, [entityType, itemIds, onConfirm]);

  const openDialog = (): void => {
    dialogStore.openModal({
      title: 'Create a new Selection',
      size: IModalSize.S,
      body,
    });
  };

  return [openDialog];
};
