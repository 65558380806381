import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { DataSection } from 'components/data-section';
import GuildsDataSectionItem from './guilds-data-section-item/guilds-data-section-item';
import { IGuildsFilters, GuildsFilters } from './guilds-filters';
import { GuildsPreview } from './guilds-preview';
import { IGuild } from 'types';
import useControlDataSection, { IFilterOption } from 'utils/hooks/control-data-section';
import { guildListSchema } from 'utils/schemas';
import { useDataSectionStore } from 'store';
import { EqFilter, InFilter, Model, QFilter, RangeFilter } from 'helpers/filters/types';
import { Pages } from 'utils/actions/types';
import { IUseActionsOptionParam } from 'utils/actions';
import { useRefreshDataSection } from 'utils/hooks';
import { ActionsMenu } from 'components/action/utils';
import { GuildActions } from 'components/action';

export const customGuildsParam = {
  include_deleted: false,
  sort: 'updated_at',
};

const groupTypePersistentFilters: IFilterOption[] = [['type', 'eq', 'group/guild']];

const defaultFilters: IGuildsFilters = {
  _: new QFilter(''),
  updated_at: new RangeFilter('updated_at', '', ''),
  owner_id: new EqFilter('owner_id', ''),
  created_at: new RangeFilter('created_at', '', ''),
  access_level: new InFilter('access_level', []),
};

const sidebarTabs = {
  preview: <GuildsPreview />,
  filters: <GuildsFilters />,
  selectedItemRenderer(guild: IGuild) {
    return <div>{guild.name}</div>;
  },
};

interface IGuildsGroupsDataSectionProps {
  persistentFilters?: IFilterOption[];
  page?: Pages;
  actionOptions?: Partial<IUseActionsOptionParam>;
  persistFilters: boolean;
}

const GuildsDataSection: React.FC<IGuildsGroupsDataSectionProps> = observer(
  ({ persistentFilters, page = Pages.LIST, actionOptions, persistFilters }) => {
    const guildsFilters = useMemo(
      () => [...groupTypePersistentFilters, ...(persistentFilters || [])],
      [persistentFilters],
    );

    useControlDataSection({
      persistFilters,
      schema: guildListSchema,
      model: Model.GUILDS,
      presetSuffix: 'guilds',
      filters: guildsFilters,
      defaultFilters,
      stats: 'access_level',
    });

    const refreshDataSection = useRefreshDataSection();

    const { checked } = useDataSectionStore<IGuild>();

    const itemRenderer = useCallback(
      (guild): React.ReactElement => {
        return (
          <GuildsDataSectionItem
            key={guild.id}
            guild={guild}
            contextMenu={
              <ActionsMenu
                component={GuildActions}
                items={[guild]}
                options={{ context: 'single', page, onSuccess: refreshDataSection, ...(actionOptions || {}) }}
              />
            }
          />
        );
      },
      [actionOptions, page, refreshDataSection],
    );

    return (
      <DataSection
        showListTileViewToggleButton={false}
        tabs={sidebarTabs}
        itemRenderer={itemRenderer}
        contextMenu={
          <ActionsMenu
            component={GuildActions}
            items={checked}
            options={{ context: 'multi', page, onSuccess: refreshDataSection, ...(actionOptions || {}) }}
          />
        }
      />
    );
  },
);

export default GuildsDataSection;
