export const localizationOrderSchema = `
  id,
  type,
  project_name,
  created_at,
  launch_target,
  sent_at,
  organization_id,
  status,
  organization,
  delivery_items,
  updated_at,
  recipient_list, 
  owner, 
  product_id,
  organization_name,
  organization { id, name },
  owner_name,
  owner {
    id,
    first_name,
    last_name,
    email
  },
`;
