export const accessPrivilegeListSchema = `
  id,
  access_level,
  am_statistics_data,
  pm_statistics_data,
  created_at,
  updated_at,
  expires_at,
  deleted_at,
  name,
  preview_image {
    url
  },
  users_count,
  owner_id,
  owner,
  owner_organization_id,
  settings {
    protection_levels
  },
`;
export const accessPrivilegeDetailsSchema = `${accessPrivilegeListSchema}, owner_organization`;
