import React from 'react';
import { toNumber } from 'lodash';

import { Row } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';
import { CustomRow } from 'pages/mobile-app-sync-details/custom-row';

import { Routes } from 'utils/routes';
import { DetailsPageTabs, IMobileAppSync } from 'types';
import { FormInput, IUseFormReturn } from 'helpers/form';
import { formatDate } from 'utils/date';
import { IGroupNameEditFormFields } from 'utils/apis/groups';
import { formatOwnership, parseDataSize } from 'utils/general';
import { DetailCountLink } from 'utils/ui';
import { useMainAssetCountInGroup } from 'utils/hooks/asset';

const inputStyle = { gridTemplateColumns: '3fr' };

const MobileAppSyncDetailsOverview: React.FC<{
  mobileAppSync?: IMobileAppSync;
  form: IUseFormReturn<IGroupNameEditFormFields>;
  editModeEnabled: boolean;
}> = ({ mobileAppSync, editModeEnabled, form }) => {
  const { id, deleted_at, created_at, updated_at, pm_statistics_data, am_statistics_data, name } = mobileAppSync || {};

  const mobile_sync_size = toNumber(am_statistics_data?.mobile_sync_size);
  const [mainAssetCount] = useMainAssetCountInGroup(id);

  const ownershipInfo = formatOwnership(mobileAppSync);
  return (
    <div className="recommendation-details__overview-wrapper">
      <InfoBoxV2 gridTemplateColumns="2fr 3fr" useBackground={true} padded={false} wrapperClassName="p-3 m-3">
        <Row
          label="Name"
          value={
            editModeEnabled ? (
              <FormInput name="name" {...form.formData.name} {...form.handlers} required={false} style={inputStyle} />
            ) : (
              name
            )
          }
        />
        <CustomRow mobileAppSync={mobileAppSync} editModeEnabled={editModeEnabled} form={form} />
        <Row label="Ownership" value={ownershipInfo} />
        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
        <Row label="Deleted at" value={formatDate(deleted_at)} rawLabel hideIfNoValue />
      </InfoBoxV2>
      <InfoBoxV2 gridTemplateColumns="2fr 3fr" title="About">
        <Row label="Sync size" value={parseDataSize(mobile_sync_size, '0 B')} rawLabel />
        <DetailCountLink
          entityCount={pm_statistics_data?.count}
          link={`${Routes.MOBILE_APP_SYNC}/${id}/${DetailsPageTabs.PRODUCTS}`}
          label={DetailsPageTabs.PRODUCTS}
        />
        <DetailCountLink
          entityCount={mainAssetCount}
          link={`${Routes.MOBILE_APP_SYNC}/${id}/${DetailsPageTabs.ASSETS}`}
          label={DetailsPageTabs.ASSETS}
        />
      </InfoBoxV2>
    </div>
  );
};

export default MobileAppSyncDetailsOverview;
