import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { Classes } from 'utils/ui';
import { ItemId } from 'types/utility';
import { IDataSectionLayout } from 'types';

interface IParentBreadcrumbProps {
  title: string;
  to: string;
  id?: ItemId | null;
  layout?: IDataSectionLayout | 'preview' | 'detail';
}

export const ParentBreadcrumbs: React.FC<IParentBreadcrumbProps> = ({ title, id, to, layout, children }) => {
  const tilesView = layout === 'tiles';

  if (!id) {
    return (
      <div
        className={cx({
          breadcrumbs__items__tiles: tilesView,
          breadcrumbs__items__list: !tilesView,
          'd-flex align-items-center justify-content-end': children,
        })}
      >
        &nbsp;
        {children}
      </div>
    );
  }

  return (
    <div
      className={cx({
        breadcrumbs__wrapper__list: !tilesView,
        breadcrumbs__wrapper__tiles: tilesView,
        'breadcrumbs__wrapper__preview m-0': layout === 'preview',
        'd-flex align-items-center justify-content-between': children,
      })}
    >
      <ul
        className={cx({
          'breadcrumbs__items__list mw-100': !tilesView,
          breadcrumbs__items__tiles: tilesView,
          'breadcrumbs__items__preview breadcrumbs__items__preview--ancestors': layout === 'preview',
        })}
      >
        <Link
          className={cx({
            breadcrumbs__ancestor__tiles: tilesView,
            'breadcrumbs__ancestor__list mw-100': !tilesView,
            breadcrumbs__ancestor__preview: layout === 'preview',
            'breadcrumbs__ancestor__detail mw-100': layout === 'detail',
          })}
          key={id}
          title={title}
          to={to}
        >
          <span
            className={cx(`${Classes.TEXT_MUTED} d-block text-truncate breadcrumbs__list__text`, {
              breadcrumbs__tiles__text: tilesView,
              breadcrumbs__list__text: !tilesView,
            })}
          >
            {title}
          </span>
        </Link>
      </ul>
      <div className="d-flex align-items-center">{children}</div>
    </div>
  );
};
