import { Model } from 'helpers/filters/types';
import { chipmunk } from 'utils/chipmunk';
import omit from 'lodash/omit';

import { ISubtitle } from 'types';

export const updateSubtitle = async (subtitle: Partial<ISubtitle>): Promise<ISubtitle> => {
  const { object } = await chipmunk.run(({ action }) => {
    return action(Model.SUBTITLES, 'update', {
      params: { subtitle_id: subtitle?.id },
      body: {
        ...omit(subtitle, ['id', 'asset_id']),
      },
    });
  });

  return object;
};

export const deleteSubtitle = async (subtitle: ISubtitle): Promise<ISubtitle> => {
  const { object } = await chipmunk.run(({ action }) => {
    return action(Model.SUBTITLES, 'delete', {
      params: { subtitle_id: subtitle?.id },
    });
  });

  return object;
};
