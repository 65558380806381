import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { DataSection } from 'components/data-section';
import { DataSectionPage } from 'components/layout';
import { ScreeningRoomFilters, defaultFilters } from './filters-tab';
import { ScreeningRoomActionsList } from 'components/action';
import { ScreeningRoomPreviewTab } from './preview-tab';
import { ScreeningRoomDataSectionItem } from './data-section-item';
import { ScreeningRoomSelectedItem } from './selected-item';

import { useDataSectionStore } from 'store/hooks';
import { useControlDataSection, IFilterOption, useRefreshDataSection } from 'utils/hooks';
import { groupListSchema } from 'utils/schemas';
import { Pages } from 'utils/actions/types';

import { GroupTypes, IGroup } from 'types';
import { Model } from 'helpers/filters/types';
import { IActionName } from 'utils/actions/types';
import { IGroupActionName } from 'utils/actions/common';
import { ActionsMenu } from 'components/action/utils';

const screeningRoomPersistentFilters: IFilterOption[] = [['type', 'eq', GroupTypes.SCREENING_ROOM]];
const params = {
  model: Model.GROUPS,
  defaultFilters,
  schema: groupListSchema,
  stats: 'owner_id',
  presetSuffix: 'selections',
  filters: screeningRoomPersistentFilters,
};

const sidebarTabs = {
  preview: <ScreeningRoomPreviewTab />,
  filters: <ScreeningRoomFilters />,
  selectedItemRenderer(screeningRoom: IGroup): React.ReactElement {
    return <ScreeningRoomSelectedItem screeningRoom={screeningRoom} />;
  },
};

export const ScreeningRoomDataSection: React.FC<{}> = observer(() => {
  useControlDataSection(params);

  const { checked } = useDataSectionStore<IGroup>();
  const refreshDataSection = useRefreshDataSection();
  const onSuccess = useCallback(
    async (action?: IActionName): Promise<void> => {
      if (action === IGroupActionName.UPLOAD_PREVIEW) {
        setTimeout(refreshDataSection, 1000);
      } else {
        refreshDataSection();
      }
    },
    [refreshDataSection],
  );

  const itemRenderer = useCallback(
    (screeningRoom: IGroup): React.ReactElement => {
      return (
        <ScreeningRoomDataSectionItem
          key={screeningRoom.id}
          screeningRoom={screeningRoom}
          contextMenu={
            <ActionsMenu
              component={ScreeningRoomActionsList}
              items={[screeningRoom]}
              options={{ context: 'single', onSuccess, page: Pages.LIST }}
            />
          }
        />
      );
    },
    [onSuccess],
  );

  return (
    <DataSectionPage>
      <DataSection
        className="contacts__data-section"
        tabs={sidebarTabs}
        itemRenderer={itemRenderer}
        contextMenu={
          <ActionsMenu
            component={ScreeningRoomActionsList}
            items={checked}
            options={{ context: 'multi', onSuccess, page: Pages.LIST }}
          />
        }
      />
    </DataSectionPage>
  );
});
export default ScreeningRoomDataSection;
