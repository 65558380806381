import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Button } from '@mantine/core';
import NewWindow from 'react-new-window';

import { Loading } from 'components/loading';
import { Model } from 'helpers/filters/types';
import { FormInput, useForm } from 'helpers/form';
import { chipmunk } from 'utils/chipmunk';

interface ISearchField {
  ccid: string;
}

import './style.scss';

export const ProductContentCatalogue: React.FC<{ newWindowUnloaded: VoidFunction }> = ({ newWindowUnloaded }) => {
  const initialValues: ISearchField = { ccid: '' };
  const [metadata, setMetada] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    const { value } = formData['ccid'];

    const product = (
      await chipmunk.action(Model.PRODUCTS, 'product_catalogue_search', {
        body: { ccid: value },
        schema: 'brand,title,series,version',
      })
    ).object;

    setMetada(product);
    setLoading(false);
  };

  const { formData, handlers, onSubmit } = useForm(initialValues, Model.PRODUCTS, handleSubmit);

  return (
    <NewWindow name="Product Catalogue" title="Product Catalogue" onUnload={() => newWindowUnloaded()}>
      <div className="catalog-window">
        <form onSubmit={onSubmit}>
          <FormInput
            label="Search for CCID number"
            name="ccid"
            placeholder="Type the CCID number"
            {...formData['ccid']}
            {...handlers}
          />
          <Button variant="primary" type="submit">
            Search
          </Button>
        </form>

        {loading && <Loading text="Loading metadata" />}

        {!loading && !isEmpty(metadata) && <pre>{JSON.stringify(metadata, null, 2)}</pre>}
      </div>
    </NewWindow>
  );
};
