import { UppyFile } from '@uppy/core/types';

import { IAssetFile } from 'types';

export function formatFileFields({ preview, id, type, name, ...fileData }: UppyFile): IAssetFile {
  return {
    access_level: 'viewable',
    file_name: name,
    fileData,
    id,
    languages: [],
    name: '',
    permissions: [],
    preview,
    protection_levels: [],
    selected: true,
    status: 'available',
    watermark: '',
    type: type || '',
    classification: '',
    products: [],
    'layer.notes': '',
    'product.marketing_use': true,
    sharable: true,
    division_ids: [],
  };
}
