import { NavigateFunction } from 'react-router';

enum STORAGE_KEYS {
  ASSET_UPLOAD_PRESETS = 'MFX_ASSET_UPLOAD_PRESETS',
  ASSET_EDIT_PRESETS = 'MFX_ASSET_EDIT_PRESETS',
  ANALYTICS_DATE = 'analyticsDateRange',
  ANALYTICS_TERRITORIES = 'analyticsTerritories',
  ANALYTICS_CONTACTS = 'analyticsContactIds',
  ANALYTICS_PRODUCTS = 'analyticsProductIds',
  ANALYTICS_ASSETS = 'analyticsAssetIds',
  ANALYTICS_DESCENDANTS = 'analyticsIncludeDescendants',
  ANALYTICS_SENDERS = 'analyticsSenderIds',
  ANALYTICS_RECIPIENTS = 'analyticsRecipientIds',
  ANALYTICS_RESPONSIBLE_USERS = 'analyticsResponsibleUserId',
  ANALYTICS_SCREENING_ASSETS = 'analyticsIncludeNonScreeningAssets',
  REDIRECT_URL = 'MFX_REDIRECT_URL',
  WEBSITE_LOGO = 'affiliation-logo',
  WEBSITE_BRAND_HEADER = 'affiliation-brand-header',
  TWO_FACTOR_AUTH_PURPOSE = 'purpose-2fa',
}

const setStorageItem = <T>(key: STORAGE_KEYS, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getStorageItem = <T = object>(key: STORAGE_KEYS, fallback = '{}'): T => {
  const item = localStorage.getItem(key);
  if (!item || item === 'undefined' || item === 'null') {
    return JSON.parse(fallback);
  }
  return JSON.parse(item);
};

const deleteStorageItem = (key: STORAGE_KEYS): void => {
  localStorage.removeItem(key);
};
export { STORAGE_KEYS, getStorageItem, setStorageItem, deleteStorageItem };

export const persistRedirectUrl: VoidFunction = () => {
  const { pathname, search } = window.location;
  localStorage.setItem(STORAGE_KEYS.REDIRECT_URL, [pathname, search].join(''));
};

export const redirectAfterLogin = (navigate: NavigateFunction): void => {
  const redirectUrl = localStorage.getItem(STORAGE_KEYS.REDIRECT_URL);

  if (!redirectUrl) {
    return;
  }
  localStorage.removeItem(STORAGE_KEYS.REDIRECT_URL);
  navigate(redirectUrl);
};
