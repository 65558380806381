import { IJsonLDSpec, IJsonSchemaSpec, IResult } from '@mediafellows/chipmunk';
import { action, IObservableArray, observable } from 'mobx';
import { ITreeDataProvider } from './tree-data-provider';
import { ITreeFilterNode } from 'types';

export interface ISimpleTreeNode {
  value: string | number;
  label: string;
  ancestry: string;
}

export type IDataProviderExecutor = () => Promise<IResult | IJsonLDSpec | IJsonSchemaSpec>;
export type IDataProviderMapper = (data: IResult | IJsonLDSpec | IJsonSchemaSpec) => ITreeFilterNode[];

export class DynamicTreeDataProvider implements ITreeDataProvider {
  @observable loading = false;

  @observable
  public data: IObservableArray<ITreeFilterNode> = observable([]);

  protected dataLoaded = false;

  constructor(protected executor: IDataProviderExecutor, protected mapper: IDataProviderMapper) {}

  @action async init(): Promise<void> {
    if (!this.dataLoaded) {
      await this.loadData();
    }
  }

  async loadData(): Promise<void> {
    this.loading = true;
    const result = await this.executor();
    const mappedResult = this.mapper(result) || [];
    this.data.replace(mappedResult);

    this.dataLoaded = true;
    this.loading = false;
  }
}
