import { chipmunk } from 'utils/chipmunk';
import { parseKeyPathToObject } from 'utils/payload';
import { IAsset, IAssetFile, IAssetPayload, IMm3AssetPayload, IUploadedFile } from 'types';
import { Model } from 'helpers/filters/types';
import { prepareAssetCreationPayload, prepareMm3AssetCreationPayload } from 'pages/assets-upload/utils';
import { AssetFilters, getAssetModel, getMainType } from 'utils/asset';
import { flags } from 'utils/flags';

export type IUploadedFileById = {
  [key: string]: IUploadedFile;
};

export async function createAssets(files: IAssetFile[], filesRemoteById: IUploadedFileById): Promise<IAsset[]> {
  return chipmunk.run(async ({ action }) => {
    const assetsUploadPromises = files.map(async ({ id, ...rest }) => {
      const file = parseKeyPathToObject<Partial<IAssetFile>, IAssetPayload | IMm3AssetPayload>(rest);
      const data = flags.isMm3Assets
        ? prepareMm3AssetCreationPayload(filesRemoteById, id, file as IMm3AssetPayload)
        : prepareAssetCreationPayload(filesRemoteById, id, file as IAssetPayload);

      const { object: asset } = await action(getAssetModel(), 'ingest', {
        body: data,
      });

      const shouldAddOrientation = (file, asset): boolean => {
        const type = getMainType(asset) as AssetFilters;
        return file?.product?.marketing_use && ['video', 'image'].includes(type);
      };

      const promises = (file as IMm3AssetPayload).parent_id
        ? []
        : file.products.map((product_id) =>
            action(Model.PRODUCT_ASSET, 'create', {
              params: { rawRequest: true },
              body: {
                asset_id: asset.id,
                product_id,
                name: asset.name,
                access_level: asset.access_level,
                preview_image_id: asset.preview_image_id,
                asset_type: asset['@type'],
                classification: asset.classification,
                marketing_use: Boolean(file?.product?.marketing_use),
                ...(shouldAddOrientation(file, asset)
                  ? { preview_image_orientation: filesRemoteById[id].orientation }
                  : {}),
                sharable: asset.sharable,
                include_descendants: false,
              },
            }),
          );

      await Promise.all(promises);
      return asset;
    });

    return await Promise.all(assetsUploadPromises);
  });
}
