import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import cx from 'classnames';

import {
  IAssetMainClassification,
  IAssetAggregation,
  ItemId,
  IAsset,
  IMm3Asset,
  IProductAsset,
  IMm3AssetType,
} from 'types';
import { Loading } from 'components/loading';

import './style.scss';
import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';
import { fetchGroupAssets } from 'utils/apis/asset';
import { useRemote } from 'utils/hooks';
import { flags } from 'utils/flags';

export const iconByType = {
  video: 'mobile-video',
  image: 'media',
  audio: 'volume-down',
  document: 'document',
  report: 'control',
  'Asset3::Digital::Video': 'mobile-video',
  'Asset3::Digital::Image': 'media',
  'Asset3::Digital::Audio': 'volume-down',
  'Asset3::Digital::Document': 'document',
  'Asset3::Digital::Other': 'manually-entered-data',
  'Asset3::Digital::Subtitle': 'align-center',
  ancillary: 'folder-shared-open',
};

export const assetClassifications = Object.keys(iconByType).sort().reverse();

interface IAssetLinkUIBasicProps {
  baseUrl?: string;
  muted?: boolean;
  disabled?: boolean;
  fallback?: string;
  className?: string;
  typeKey?: string;
}

interface IAssetLinkUIProps extends IAssetLinkUIBasicProps {
  assetTypesCount: Record<IAssetMainClassification, number>;
}

export const AssetsLinkUI: React.FC<IAssetLinkUIProps> = ({
  assetTypesCount,
  baseUrl,
  muted,
  disabled,
  className,
  fallback,
  typeKey,
}) => {
  const hasAssets = Object.values(assetTypesCount).some((e) => e > 0);

  return (
    <div className={cx(className, { ['d-flex flex-row']: !className?.includes('d-block') })}>
      {hasAssets
        ? assetClassifications.reduce((acc, assetType) => {
            return assetTypesCount[assetType]
              ? [
                  ...acc,
                  <AssetIconLink
                    disabled={disabled}
                    muted={muted}
                    key={assetType}
                    count={assetTypesCount[assetType]}
                    type={assetType}
                    baseUrl={baseUrl}
                    typeKey={typeKey}
                  />,
                ]
              : acc;
          }, [])
        : fallback}
    </div>
  );
};

interface IProductAssetsProps extends IAssetLinkUIBasicProps {
  assets?: { classification?: string | null; type?: string | null; asset?: IAsset | IMm3Asset }[];
}

export const AssetIconLink: React.FC<{
  type: string;
  count?: number;
  disabled?: boolean;
  baseUrl?: string;
  muted?: boolean;
  skipTypeParam?: boolean;
  typeKey?: string;
}> = ({
  type,
  count,
  baseUrl = '.',
  muted = true,
  disabled,
  skipTypeParam,
  typeKey = flags.isMm3Assets ? 'type' : 'main_classification',
}) => {
  let link = baseUrl;

  if (!skipTypeParam) {
    link += `?${typeKey}=${type}`;
  }

  return (
    <Link
      className={cx('asset-icon-link', { [`${Classes.TEXT_MUTED}`]: muted, 'asset-icon-link--disabled': disabled })}
      to={link}
    >
      <MantineIcon icon={iconByType[type]} />
      <span className="mx-1">{count}</span>
    </Link>
  );
};

export const AssetsMainClassificationLink: React.FC<IProductAssetsProps> = ({
  assets, // an array of product/asset or asset
  baseUrl,
  muted,
  fallback = '0',
  disabled,
  className,
}) => {
  const assetTypesCount = useMemo<Record<string, number>>(() => {
    const countByType: Record<Partial<IAssetMainClassification & IMm3AssetType>, number> = {};

    compact(assets).map((pAsset) => {
      const type = (pAsset.asset as IMm3Asset)?.type;
      const c = pAsset?.classification || (pAsset as IProductAsset)?.asset?.classification || '';
      const [key] = flags.isMm3Assets && type ? [type] : c?.split('/');

      if (key) {
        countByType[key] = (countByType[key] || 0) + 1;
      }
    });

    return countByType;
  }, [assets]);

  return <AssetsLinkUI {...{ assetTypesCount, baseUrl, muted, fallback, disabled, className }} />;
};

interface IAssetTypeAggregationsProps {
  aggregations?:
    | IAssetAggregation & {
        count_by_type?: {
          count: number;
          value: IAssetMainClassification;
        }[];
      };
  baseUrl?: string;
  muted?: boolean;
  disabled?: boolean;
}

export const AssetTypeAggregations: React.FC<IAssetTypeAggregationsProps> = ({
  aggregations,
  baseUrl,
  muted,
  disabled = false,
}) => {
  const buckets = aggregations?.count_by_main_classification || aggregations?.count_by_type || [];
  if (!buckets.length) {
    return <div>0</div>;
  }
  const isTypeLink = Boolean(aggregations?.count_by_type?.length);

  const sortedData = sortBy(buckets, ({ value }) => assetClassifications.indexOf(value));
  return (
    <div className="d-flex flex-row">
      {sortedData.map(({ value, count }) => {
        return (
          <AssetIconLink
            muted={muted}
            key={value}
            count={count}
            type={value}
            baseUrl={baseUrl}
            disabled={disabled}
            typeKey={isTypeLink ? 'type' : 'main_classification'}
          />
        );
      })}
    </div>
  );
};

interface IGroupAssetTypeAggregationsProps {
  groupId?: ItemId;
  baseUrl?: string;
  muted?: boolean;
}

export const GroupAssetTypeAggregations: React.FC<IGroupAssetTypeAggregationsProps> = ({ groupId, baseUrl, muted }) => {
  const fetcher = useCallback(
    () =>
      fetchGroupAssets(
        groupId,
        { per: 1 },
        { rawResult: true, stats: flags.isMm3Assets ? 'type' : 'main_classification' },
      ),
    [groupId],
  );

  const [result, loading] = useRemote(fetcher);

  if (loading) {
    return (
      <div className="w-25">
        <Loading />
      </div>
    );
  }

  return <AssetTypeAggregations baseUrl={baseUrl} muted={muted} aggregations={result?.aggregations} />;
};

export const AssetsShortcut: React.FC<{ route: string }> = ({ route }) => {
  return (
    <Link className="bp3-text-muted" to={`${route}/assets`}>
      <MantineIcon className="mx-1" icon="folder-shared-open" />
    </Link>
  );
};

export default AssetsMainClassificationLink;
