import React, { useCallback } from 'react';

import { useStore } from 'store';

import { IUploadedFile } from 'types';

import { IUseActionsOptionParam } from 'utils/actions/types';
import { UploadAssetsForm } from 'components/upload-asset-product-dialog/components/upload-assets-form';
import { ToastError } from 'components/toast';
import { byId, pluralWordInflect } from 'utils/general';
import { createAssets } from 'pages/assets-upload/api';
import { IModalSize } from 'components/dialogs/types';

export type IUseUploadAssetDialogCallback = () => void;

export function useUploadAssetDialog<T>(options?: IUseActionsOptionParam<T>): [IUseUploadAssetDialogCallback] {
  const { dialogStore, toastStore } = useStore();
  const onSubmit = useCallback(
    async ({ isValid, files, filesRemote, showErrors, setIsSending, isSending }) => {
      try {
        if (isSending) {
          return;
        }
        if (!isValid) {
          return showErrors();
        }
        setIsSending(true);
        const filesRemoteById = byId<IUploadedFile>(filesRemote);
        await createAssets(files, filesRemoteById);
        options?.onSuccess?.();
        toastStore.success(`${pluralWordInflect('asset', files.length)} will be added`);
        dialogStore.close();
      } catch (error) {
        toastStore.error(<ToastError error={error} />);
      } finally {
        setIsSending(false);
      }
    },
    [toastStore, dialogStore, options],
  );

  const openDialog = useCallback(() => {
    dialogStore.openModal({
      title: `Upload Asset(s)`,
      className: 'upload-asset-dialog__dialog',
      size: IModalSize.XL,
      body: () => (
        <UploadAssetsForm
          productId={String(options?.productId || '')}
          onSubmit={onSubmit}
          withParentField={options?.withParentField}
        />
      ),
    });
  }, [dialogStore, onSubmit, options?.productId, options?.withParentField]);

  return [openDialog];
}
