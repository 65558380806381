import { IAggregation } from 'helpers/filters/types';
import { TreeNode } from 'helpers/form/fields/form-select/tree-selector-utils';
import { ITreeFilterNode } from 'types';

export class FilterTreeNode extends TreeNode {
  secondaryLabel?: string;
  disabled?: boolean;

  constructor(
    value: string,
    label: string,
    checked: boolean,
    children?: TreeNode[],
    secondaryLabel?: string,
    disabled?: boolean,
  ) {
    super(value, label, checked, children);
    this.secondaryLabel = secondaryLabel;
    this.disabled = disabled;
  }

  public isChecked(): boolean {
    // The parent doesn't get checked automatically when all children are checked
    return this.checked;
  }
}

export function buildTreeWithAggregations({
  data,
  initialValues,
  parentState,
  aggregations,
}: {
  data: ITreeFilterNode[];
  initialValues?: string[];
  parentState?: boolean;
  aggregations?: IAggregation[];
}): { tree: FilterTreeNode[] } {
  const tree = data.map((entity) => {
    const isValueInitialized = Boolean(initialValues?.includes(entity.id) || parentState);
    const aggr = aggregations?.find((a) => a.value == entity.id);
    const children = entity.children
      ? buildTreeWithAggregations({
          data: entity.children,
          initialValues,
          parentState: isValueInitialized,
          aggregations,
        }).tree
      : undefined;

    const childrenDisabled = children?.every((child) => child.disabled) ?? true;
    const disabled = entity.children ? Boolean(childrenDisabled && !aggr) : !aggr;

    const node = new FilterTreeNode(
      entity.id,
      entity.label,
      isValueInitialized,
      children,
      aggr ? `${aggr.count}` : entity.secondaryLabel,
      disabled,
    );

    return node;
  });

  return { tree };
}
