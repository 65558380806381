import { chipmunk, IResult } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';
import { AmAmazonapacLocalizationOrderSeriesSeries } from '@mediafellows/mm3-types';
import { ItemId, IThumbnailImage } from 'types';
import { queryProducts } from './product';
import { localizationOrderSchema } from 'utils/schemas';

export const downloadTemplate = async (): Promise<Blob> => {
  const params = { product_type: 'series', order_template: 'series' };

  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'download_template', {
      params,
    });
    return object;
  });
  // try {
  //     const response = await fetch('/https://am.api.mediastore.dev/v2021/localization_orders/download_template?product_type=series&order_template=series', {
  //         method: 'GET',
  //     });

  //     if (!response.ok) {
  //         throw new Error('Failed to download file');
  //     }

  //     const blob = await response.blob();

  //     // Create a Blob URL
  //     const url = window.URL.createObjectURL(blob);

  //     // Create a link element
  //     const link = document.createElement('a');
  //     link.href = url;

  //     // Extract filename from Content-Disposition header if available
  //     const contentDisposition = response.headers.get('content-disposition');
  //     const fileName = contentDisposition
  //         ? contentDisposition.split('filename=')[1].replace(/"/g, '')
  //         : 'demo.xlsx'; // Fallback filename

  //     link.setAttribute('download', fileName);

  //     // Append to the DOM and trigger click
  //     document.body.appendChild(link);
  //     link.click();

  //     // Clean up
  //     link.remove();
  //     window.URL.revokeObjectURL(url); // Release Blob URL
  // } catch (error) {
  //     console.error('Error downloading the file:', error);
  // }
};

export const uploadTemplate = async ({ data }: { data: AmAmazonapacLocalizationOrderSeriesSeries }): Promise<void> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'parse_template_upload', { body: data });

    return objects;
  });
};

export const fetchOrders = async (): Promise<void> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'search');

    return objects;
  });
};

export const loadLocalizationOrder = async (id: string): Promise<AmAmazonapacLocalizationOrderSeriesSeries> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'get', {
      params: { id },
      schema: localizationOrderSchema,
    });

    return object;
  });
};

export const createOrder = async (data): Promise<AmAmazonapacLocalizationOrderSeriesSeries> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'create', {
      body: { ...data },
    });

    return object;
  });
};

export const updateOrder = async (data): Promise<AmAmazonapacLocalizationOrderSeriesSeries> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'update', {
      params: { id: data.id },
      body: data,
    });

    return object;
  });
};

export const sendOrder = async (data): Promise<AmAmazonapacLocalizationOrderSeriesSeries> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.LOCALIZATION_ORDERS_SERIES, 'send_order', {
      params: { id: [data.id] },
    });

    return object;
  });
};

export const deleteOrder = async (ids?: (number | null | undefined)[]): Promise<IResult | void> => {
  return chipmunk.run(async ({ action }) => {
    return action(Model.DELIVERY_PACKAGE, 'delete', {
      params: { id: ids },
    });
  });
};

export const loadOrderThumbnails = async (id?: ItemId | null): Promise<IThumbnailImage[]> => {
  if (!id) {
    return [];
  }

  const products = await queryProducts({ ids: [id] });

  return products.reduce((acc, product) => {
    const url = product?.inherited_preview_image?.url;
    return url ? [{ image: url }, ...acc] : acc;
  }, []);
};
