import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { useStore } from 'store';
import { DetailsPageTabs, IOrganization } from 'types';
import { handleItemClick } from 'components/data-section';

import './style.scss';
import DateElement from 'components/date-list-element/date-list-element';
import { OrganizationLogo } from 'components/organization/organization-logo';
import { getWebsiteLinks } from 'components/entity-list';
import { Routes } from 'utils/routes';
import { isEntityDeleted } from 'utils/is-entity-deleted';
import { ListItemCountLink } from 'utils/ui';
import { Classes } from 'utils/ui';

interface IAssetListItemProps {
  className?: string;
  organization: IOrganization;
  contextMenu: React.ReactElement;
}

const OrganizationListItem: React.FC<IAssetListItemProps> = observer((props) => {
  const {
    basicStore: { countries },
    dataSectionStore: { checked, active, isAllItemsSelected },
  } = useStore();
  const [isItemChecked, setChecked] = useState(false);
  const { className, organization, contextMenu } = props;
  const { id, name, classification, country_id, created_at, updated_at, urls, users_count } = organization;

  const isActive = active === organization.id;
  const country = country_id && countries.find((country) => country.id == country_id);

  useEffect(() => {
    const isChecked = checked.some((checked) => checked.id === organization.id);

    setChecked(isChecked);
  }, [organization.id, checked]);

  const handleClick = useCallback(
    (e): void => {
      if (e.target.nodeName !== 'A') {
        handleItemClick(e, id);
      }
    },
    [id],
  );
  const orgClassification = Array.isArray(classification) ? classification.join(', ') : classification;

  return (
    <div
      className={cx('organization-list-item', className, {
        'organization-list-item--active': isActive,
        'entity-list-item--deleted': isEntityDeleted(organization),
      })}
      onClick={handleClick}
    >
      <div className="organization-list-item__checkbox">
        <Checkbox onChange={handleClick} checked={isItemChecked || isAllItemsSelected} disabled={isAllItemsSelected} />
      </div>

      <div className="organization-list-item__thumb">
        <OrganizationLogo organization={organization} />
      </div>

      <div className="organization-list-item__content">
        <div className="entity-list-item-row__title organization-list-item__title" title={name}>
          <Link to={`/organizations/${id}`}>
            <h4 className="text-nowrap text-truncate">{name}</h4>
          </Link>
          {Boolean(contextMenu) && contextMenu}
        </div>

        <div className={`organization-list-item__attributes ${Classes.TEXT_MUTED}`}>
          <div className="text-truncate organization-list-item__classification">{orgClassification}</div>
          <div className="text-truncate organization-list-item__users">
            <ListItemCountLink
              entityCount={users_count}
              link={`${Routes.ORGANIZATIONS}/${id}/${DetailsPageTabs.CONTACTS}`}
              linkText="Contact"
            />
          </div>
          <div className="text-truncate organization-list-item__url">{urls ? getWebsiteLinks(urls) : '—'}</div>
          <div className="text-truncate organization-list-item__country">{country?.name || '—'}</div>
          <div className="text-truncate organization-list-item__created">
            <DateElement date={created_at} mfxIcon="created-at" />
          </div>
          <div className="text-truncate organization-list-item__updated">
            <DateElement date={updated_at} mfxIcon="updated-at" />
          </div>
        </div>
      </div>
    </div>
  );
});

export default OrganizationListItem;
